import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoriqueEntretienPreventifEntite } from 'src/app/entites/mecanique/historique-entretien-preventif';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { MenuItem, TreeNode } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { PieceBonTravailEntite } from 'src/app/entites/mecanique/piece-bon-travail';
import { PieceService } from 'src/app/services/piece-service';

export interface ParametresHistoriquePosePiece {
    pieceId: number;
    uniteId?: number;
}

@Component({
    templateUrl: './historique-pose-piece.component.html',
})
export class HistoriquePosePieceComponent extends BaseComponent {

    donnees: ParametresHistoriquePosePiece;

    historique: PieceBonTravailEntite[];

    afficherColonnesUnite: boolean = false;

    contenuMenuContextuel: MenuItem[];
    @ViewChild('menuContextuel') menuContextuel: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        private pieceService: PieceService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {

            super(sdb, profilService, route, routerService, appMain); }

    ngOnInit() {

        this.donnees = this.config.data;

        if (this.donnees.uniteId) {
            this.pieceService.ObtenirHistoriquePoseUnite(this.donnees.pieceId, this.donnees.uniteId).then(res => {
                this.historique = res.valeur;
                this.CompleterChargement();
            });
        } else {
            this.afficherColonnesUnite = true;
            this.pieceService.ObtenirHistoriquePoseComplet(this.donnees.pieceId).then(res => {
                this.historique = res.valeur;
                this.CompleterChargement();
            });
        }
    }

     fermer() {
        this.ref.close(null);
    }

    surMenuContextuel(event, piece: PieceBonTravailEntite) {

        this.contenuMenuContextuel = [
            {
                label: 'Voir le bon de travail',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec'),
                command: () => { this.ref.close(null); this.naviguer('mecanique/bontravail/' + piece.bonTravail.bonTravailId); }
            },
        ];

        this.menuContextuel.toggle(event);

    }
}