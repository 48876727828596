import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from '../../services/liste-achat-service';
import { ListeAchatEntite } from 'src/app/entites/liste-achat';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ModeleListeAchatService } from 'src/app/services/modele-liste-achat-service';
import { ModeleListeAchatEntite } from 'src/app/entites/modele-liste-achat';
import { AppMainComponent } from 'src/app/app.main.component';


@Component({
    templateUrl: './saisir-liste-achat-e1.component.html',
    styleUrls: ['./saisir-liste-achat-e1.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class SaisirListeAchatEtape1Component extends BaseComponent implements OnInit {

    modeles: ModeleListeAchatEntite[];
    selectionListe: ModeleListeAchatEntite;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private modeleListeAchatService: ModeleListeAchatService,
        private listeAchatService: ListeAchatService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Saisir une liste d\'achat' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('liste_achat');

        this.listeAchatService.listeAchat = null;

        this.modeleListeAchatService.ObtenirModeles().then(x => {
           this.modeles = x.valeur; 

           this.CompleterChargement();
        });

       
    }

    nextPage() {

        this.listeAchatService.modeleListeAchatId = this.selectionListe.modeleListeAchatId;

        this.routerService.navigate(['listeachat/etape2']);
    }

}
