import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { RequeteTransfererVersCtrl } from './requetes/requete-transfert-ctrl';
import { SoumissionEntite } from '../entites/soumission';
import { ItemSoumissionEntite } from '../entites/item-soumission';
import { SerieSoumission } from '../entites/serie-soumission';
import { RequeteCreerSerie } from './requetes/requete-creer-serie';
import { RequeteEnvoyerCourrielsSoumissions } from './requetes/requete-envoyer-courriels-soumissions';
import { RequeteTransfererPrixSoumissions } from './requetes/requete-transferer-prix-soumissions';
import { RequeteDupliquerSoumission } from './requetes/requete-dupliquer-soumission';

@Injectable({ providedIn: 'root' })
export class SoumissionService {

  private obtenirListeUrl = environment.apiBaseUrl + 'soumission';
  private obtenirListeParStatutUrl = environment.apiBaseUrl + 'soumission/?statutId={statutId}';
  private obtenirDetailUrl = environment.apiBaseUrl + 'soumission/{soumissionId}';
  private creerUrl = environment.apiBaseUrl + 'soumission';
  private modifierUrl = environment.apiBaseUrl + 'soumission/modifier';
  private creerItemSoumissionUrl = environment.apiBaseUrl + 'soumission/item';
  private creerItemsSoumissionUrl = environment.apiBaseUrl + 'soumission/items';
  private supprimerUrl = environment.apiBaseUrl + 'soumission/supprimer';
  private supprimerItemUrl = environment.apiBaseUrl + 'soumission/supprimerItems';
  private obtenirSeriesUrl = environment.apiBaseUrl + 'soumission/series';
  private obtenirSerieUrl = environment.apiBaseUrl + 'soumission/serie/{dateDebut}/{dateFin}';
  private creerSerieUrl = environment.apiBaseUrl + 'soumission/serie';
  private dupliquerUrl = environment.apiBaseUrl + 'soumission/dupliquer';
  private envoyerCourrielsUrl = environment.apiBaseUrl + 'soumission/courriels';
  private transfererPrixUrl = environment.apiBaseUrl + 'soumission/transfertprix';
  private obtenirParItemUrl = environment.apiBaseUrl + 'soumission/item/{itemId}';
  

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirSoumissions(statutId: number): Promise<ReponseBase<SoumissionEntite[]>> {
      
    if (statutId) {
      var p = this.http.get<ReponseBase<SoumissionEntite[]>>(this.obtenirListeParStatutUrl.replace('{statutId}',statutId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
    } else {
      var p = this.http.get<ReponseBase<SoumissionEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
    }
  }


  public ObtenirSoumissionsParItem(itemId: number): Promise<ReponseBase<SoumissionEntite[]>> {
      
      var p = this.http.get<ReponseBase<SoumissionEntite[]>>(this.obtenirParItemUrl.replace('{itemId}',itemId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

public SupprimerSoumission(soumissionId: number): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerUrl, JSON.stringify(soumissionId), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerItemsSoumission(soumissionIds: number[]): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerItemUrl, JSON.stringify(soumissionIds), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerSoumission(requete: SoumissionEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.creerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public DupliquerSoumission(requete: RequeteDupliquerSoumission): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.dupliquerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ModifierSoumission(requete: SoumissionEntite): Promise<ReponseBase<SoumissionEntite>> {
  var p = this.http.post<ReponseBase<SoumissionEntite>>(this.modifierUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerItemSoumission(requete: ItemSoumissionEntite): Promise<ReponseBase<ItemSoumissionEntite>> {
  var p = this.http.post<ReponseBase<ItemSoumissionEntite>>(this.creerItemSoumissionUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerItemsSoumission(requete: ItemSoumissionEntite[]): Promise<ReponseBase<ItemSoumissionEntite[]>> {
  var p = this.http.post<ReponseBase<ItemSoumissionEntite[]>>(this.creerItemsSoumissionUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirSoumission(soumissionId: number): Promise<ReponseBase<SoumissionEntite>> {
  var p = this.http.get<ReponseBase<SoumissionEntite>>(this.obtenirDetailUrl.replace('{soumissionId}',soumissionId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirSeries(): Promise<ReponseBase<SerieSoumission[]>> {
  var p = this.http.get<ReponseBase<SerieSoumission[]>>(this.obtenirSeriesUrl).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirSerie(dateDebut: Date, dateFin: Date): Promise<ReponseBase<SoumissionEntite[]>> {
  var p = this.http.get<ReponseBase<SoumissionEntite[]>>(this.obtenirSerieUrl.replace('{dateDebut}', dateDebut.toISOString()).replace('{dateFin}', dateFin.toISOString())).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerSerie(requete: RequeteCreerSerie): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.creerSerieUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public EnvoyerCourriels(requete: RequeteEnvoyerCourrielsSoumissions): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.envoyerCourrielsUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public TransfererPrix(requete: RequeteTransfererPrixSoumissions): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.transfererPrixUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
