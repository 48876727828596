<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelEtape popup="popup" [model]="contenuMenuContextuelEtape"></p-menu>
<p-menu #menuContextuelPiece popup="popup" [model]="contenuMenuContextuelPiece"></p-menu>
<p-menu #menuContextuelNote popup="popup" [model]="contenuMenuContextuelNote"></p-menu>
<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Bon de travail #{{bonTravail.numero}}
				<span [class]="'statut ' + bonTravail.statut.nom.toLowerCase().replace(' ','')">
					{{bonTravail.statut.nom}}
				</span>
				<span *ngIf="modeApercu"> 
					<i class="pi pi-id-card p-ml-4" ></i>
					<a class="lienFicheComplete" (click)="ouvrirFicheComplete()">Aller à la fiche complète</a>
				</span>
			</h5>
			<h6 *ngIf="bonTravail.dateEnvoi"><i class="pi pi-send" style="font-size: 0.8rem"></i> Assigné le :
				{{bonTravail.dateDebut | date:'yyyy-MM-dd HH:mm:ss'}}</h6>

			<p-menubar [model]="menu" *ngIf="!modeApercu">
				<p-button *ngIf="(estAutorise('bontravail_modifier') || estAutorise('temp_mec')) && modeConsultation() && estModifiable"
					(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success p-mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView orientation="left">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-1">
							<label for="numeroItem">Unité</label>
							<a style="font-weight: 700;" [routerLink]="'/item/' + bonTravail.item.itemId">
								<span class="champConsultation">{{bonTravail.item.numero | valeurVide}}</span>
							</a>
						</div>
						<div class="p-field p-col-12 p-md-4">
							<label for="descriptionItem">Description</label>
							<span class="champConsultation">{{bonTravail.item.description | valeurVide}}</span>
						</div>
						<div class="p-field p-col-12 p-md-2">
							<label for="heuresItem">Heures (compteur/réel)</label>
							<span class="champConsultation">{{bonTravail.heuresCompteur | valeurVide}} / {{bonTravail.heuresReelles | valeurVide}}</span>
						</div>
						<div class="p-field p-col-12 p-md-2">
							<label for="kmItem">KM (compteur/réel)</label>
							<span class="champConsultation">{{bonTravail.kmCompteur | valeurVide}} / {{bonTravail.kmReels | valeurVide}}</span>
						</div>
						<div class="p-col-12 p-md-6 p-lg-3 p-mb-3">
							<label for="kmItem">Type</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{selectionTypeBonTravail?.valeur | valeurVide}}</span>
							<p-selectButton *ngIf="!modeConsultation()" [options]="typesBonTravail" [(ngModel)]="selectionTypeBonTravail" (onChange)="surChangementTypeBonTravail()" optionLabel="valeur">
							</p-selectButton>
						</div>
				
					</div>
					
					<div class="p-fluid p-formgrid p-grid">

					
						<div class="p-field p-col-12 p-md-6 p-lg-1">
							<label for="firstname">Numéro</label>
							<span class="champConsultation">{{bonTravail.numero}}</span>
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-2">
							<label for="dateBdc">Date de création</label>
							<span class="champConsultation">{{bonTravail.dateCreation | date:'yyyy-MM-dd' | valeurVide}}</span>
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-2">
							<label for="dateBdc">Date du travail</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.dateTravail | date:'yyyy-MM-dd' | valeurVide}}</span>
							<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateTravail"
								dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
								yearRange="2000:2040"></p-calendar>
							<small class="p-error" *ngIf="submitted && !bonTravail.dateCreation">La date de création est
								obligatoire.</small>
						</div>
						
						<div class="p-field p-col-12 p-md-6 p-lg-2">
							<label for="dateBdc">Date d'assignation</label>
							<span class="champConsultation">{{bonTravail.dateAssignation | date:'yyyy-MM-dd' | valeurVide}}</span>
						</div>
						
						<div class="p-field p-col-12 p-md-6 p-lg-2">
							<label for="dateBdc">Date de fermeture</label>
							<span class="champConsultation" >{{bonTravail.dateFermeture | date:'yyyy-MM-dd' | valeurVide}}</span>
						</div>
						<div class="p-field p-col-12 p-md-3">
							<label for="lastname">Raison</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.raison?.description | valeurVide}}</span>
							<p-dropdown *ngIf="!modeConsultation()" [options]="raisons" [(ngModel)]="bonTravail.raison" optionLabel="description"
								filter="true" filterBy="code,description"
								placeholder="Sélectionner une raison...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonTravail.raison">
										<div>{{bonTravail.raison.description}} ({{bonTravail.raison.code}})</div>
									</div>
								</ng-template>
								<ng-template let-raison pTemplate="item">
									<div>
										<div>{{raison.description}} ({{raison.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						
						<div class="p-field p-col-12 p-md-3">
							<label for="contreMaitre">Contre-Maître<span *ngIf="!modeConsultation()" class="requis">*</span></label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.contreMaitre | nomEmploye | valeurVide}}</span>
							<p-dropdown *ngIf="!modeConsultation()" [options]="employes" [(ngModel)]="bonTravail.contreMaitre" optionLabel="nom"
								[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
								placeholder="Sélectionner un contre-maître...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonTravail.contreMaitre">
										<div>{{bonTravail.contreMaitre.prenom}} {{bonTravail.contreMaitre.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-employe pTemplate="item">
									<div>
										<div>{{employe.prenom}} {{employe.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						
						</div>

						<div class="p-field p-col-12 p-md-3" *ngIf="selectionTypeBonTravail.cle == 1">
							<label for="mecanicien">Mécanicien</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.mecanicien | nomEmploye | valeurVide}}</span>
							<p-dropdown *ngIf="!modeConsultation()" [options]="employes" [(ngModel)]="bonTravail.mecanicien" optionLabel="nom"
								[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
								placeholder="Sélectionner un mécanicien...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonTravail.mecanicien">
										<div>{{bonTravail.mecanicien.prenom}} {{bonTravail.mecanicien.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-employe pTemplate="item">
									<div>
										<div>{{employe.prenom}} {{employe.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						
						</div>

						<div class="p-field p-col-12 p-md-3" *ngIf="selectionTypeBonTravail.cle == 2">
							<label for="lastname">Fournisseur</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.fournisseur?.nom | valeurVide}}</span>
							<p-dropdown [options]="fournisseurs" [(ngModel)]="bonTravail.fournisseur" optionLabel="nom"
								*ngIf="!modeConsultation()" filter="true" filterBy="numero,nom"
								placeholder="Sélectionner un fournisseur...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonTravail.fournisseur">
										<div>{{bonTravail.fournisseur.nom}} ({{bonTravail.fournisseur.numero}})</div>
									</div>
								</ng-template>
								<ng-template let-fournisseur pTemplate="item">
									<div>
										<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						
						<div class="p-field p-col-12 p-md-3">
							<label for="contreMaitre">Emplacement</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.emplacement?.nom | valeurVide}}</span>
							<p-dropdown *ngIf="!modeConsultation()" [options]="emplacements" [(ngModel)]="bonTravail.emplacement" optionLabel="nom"
								[showClear]="true" filter="true" filterBy="nom" appendTo="body"
								placeholder="Sélectionner un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonTravail.emplacement">
										<div>{{bonTravail.emplacement.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						
						
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label for="note">Note du bon de travail</label>
							<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.note | valeurVide}}</span>
							<input #champNote *ngIf="!modeConsultation()" type="text" pInputText id="note"
								[(ngModel)]="bonTravail.note" autocomplete="off" />
						</div>
					</div>

					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<label for="emplacement">Note mécanique de l'unité</label>
							<span *ngIf="modeConsultation()" class="champConsultation" style="white-space: pre-line; font-size: 1.5rem; color: red;">{{bonTravail.item.noteMecanique | valeurVide}}</span>
						</div>
					</div>

					<!-------------  TRAVAIL  -------------->

					<p-table #dtTravail [value]="bonTravail.etapes" [columns]="selectedColumns"
						dataKey="idTemporaire" tableStyleClass="tableNormale" autoLayout="true"
						[(selection)]="selectionMultipleEtapes" (onRowSelect)="surChangementSelectionEtape()"
						(onRowUnselect)="surChangementSelectionEtape()" (onHeaderCheckboxToggle)="surChangementSelectionEtape()"
						styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="[description]">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtTravail.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-plus"
										class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="surAjoutEtape()"></button>

									<button *ngIf="!modeConsultation() && selectionMultipleEtapes && selectionMultipleEtapes.length > 0" pButton pRipple icon="pi pi-times"
										class="p-button-danger p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="supprimerEtapes($event, selectionMultipleEtapes)"></button>


								</div>
								<h5 class="titreTableau p-m-0">Travail</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header" let-columns>
							<tr>
								<th *ngIf="!modeConsultation()" style="min-width: 3rem; width: 3rem;">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="noEtape">
									<div class="p-d-flex p-jc-between p-ai-center">
										#
										<p-sortIcon field="noEtape"></p-sortIcon>
										<p-columnFilter type="text" field="noEtape" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="typeEtapeBonTravail.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Type
										<p-sortIcon field="typeEtapeBonTravail.nom"></p-sortIcon>
										<p-columnFilter type="text" field="typeEtapeBonTravail.nom" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="categorie.description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Catégorie
										<p-sortIcon field="categorie.description"></p-sortIcon>
										<p-columnFilter type="text" field="categorie.description" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="note">
									<div class="p-d-flex p-jc-between p-ai-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th>
									<!-- <div class="p-d-flex p-jc-between p-ai-center">
										A faire
										<p-sortIcon field="fait"></p-sortIcon>
										<p-columnFilter type="text" field="fait" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div> -->
								</th>
								<th pSortableColumn="fait">
									<div class="p-d-flex p-jc-between p-ai-center">
										Fait
										<p-sortIcon field="fait"></p-sortIcon>
										<p-columnFilter type="text" field="fait" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fait">
									<div class="p-d-flex p-jc-between p-ai-center">
										Par
										<p-sortIcon field="fait"></p-sortIcon>
										<p-columnFilter type="text" field="fait" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="modeConsultation()" style="min-width: 7rem; width: 7rem;"></th>
								<th *ngIf="!modeConsultation()" style="min-width: 25rem; width: 25rem;"></th>

							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree let-columns="columns">
							<tr>
								<td *ngIf="!modeConsultation()">
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.noEtape}}</td>
								<td>{{entree.typeEtapeBonTravail?.code}}</td>
								<td>{{entree.categorie | categorieEntretien}}</td>
								<td>{{entree.description}}</td>
								<td>{{entree.note}}
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-pencil"
										class="p-button-primary p-button-outlined p-mr-2 boutonGrille"
										(click)="modifierNote($event, entree)"></button>
								</td>
								<td>
									<!-- <p-checkbox [disabled]="modeConsultation()" [(ngModel)]="entree.indAFaire" [binary]="true"></p-checkbox> -->
								</td>
								<td>
									<p-checkbox [disabled]="modeConsultation()" [(ngModel)]="entree.indComplete" [binary]="true"></p-checkbox>
								</td>
								<td>
									<span *ngIf="modeConsultation()">{{entree.mecanicien | nomEmploye}}</span>
									<p-dropdown *ngIf="!modeConsultation()" [options]="employes" [(ngModel)]="entree.mecanicien" optionLabel="initiales"
										[showClear]="true" filter="true" filterBy="prenom,nom,initiales" appendTo="body"
										placeholder="...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="entree.mecanicien">
												<div>{{entree.mecanicien.initiales}}</div>
											</div>
										</ng-template>
										<ng-template let-employe pTemplate="item">
											<div>
												<div>{{employe.prenom}} {{employe.nom}}</div>
											</div>
										</ng-template>
									</p-dropdown>
									<i *ngIf="!modeConsultation() && entree.mecanicien" class="pi pi-copy p-ml-2" style="font-size: 1rem; cursor: pointer;" (click)="copierMecanicien(entree)" ></i>
								</td>
								<td>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-search-plus"
										class="p-button-primary p-mr-2 boutonGrille"
										(click)="ajouterPiece($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-trash"
										class="p-button-danger p-mr-2 boutonGrille"
										(click)="supprimerEtapes($event, [entree])"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-angle-double-down"
										[disabled]="bonTravail.etapes.indexOf(entree) == bonTravail.etapes.length - 1"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="descendreEtapeComplet($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-chevron-down"
										[disabled]="bonTravail.etapes.indexOf(entree) == bonTravail.etapes.length - 1"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="descendreEtape($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-chevron-up"
										[disabled]="bonTravail.etapes.indexOf(entree) == 0"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="monterEtape($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-angle-double-up"
										[disabled]="bonTravail.etapes.indexOf(entree) == 0"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="monterEtapeComplet($event, entree)"></button>
									<button  pButton pRipple icon="pi pi-bars"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="surMenuContextuelEtape($event, entree)"></button>
								</td>

							</tr>
						</ng-template>
						
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun travail.</td>
							</tr>
						</ng-template>
					</p-table>

					<br>

					<!-------------  PIÈCES  -------------->

					<p-table #dtPieces [value]="pieces" [columns]="selectedColumns"
						dataKey="pieceBonTravailId" tableStyleClass="tableNormale" autoLayout="true"
						[(selection)]="selectionMultiplePieces" 
						styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','quantite']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtPieces.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<!-- <button *ngIf="estModifiable && !modeApercu && selectionMultipleEtapes && selectionMultipleEtapes.length == 1" pButton pRipple icon="pi pi-plus"
										class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="surAjoutPiece()"></button> -->

									<button *ngIf="!modeConsultation() && selectionMultiplePieces && selectionMultiplePieces.length > 0" pButton pRipple icon="pi pi-times"
										class="p-button-danger p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="supprimerPieces($event, selectionMultiplePieces)"></button>


								</div>
								<h5 class="titreTableau p-m-0">Pièces</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header" let-columns>
							<tr>
								<th *ngIf="!modeConsultation()" style="min-width: 3rem; width: 3rem;">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="noEtape">
									<div class="p-d-flex p-jc-between p-ai-center">
										#
										<p-sortIcon field="noEtape"></p-sortIcon>
										<p-columnFilter type="text" field="noEtape" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
								<th pSortableColumn="item.numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.marque">
									<div class="p-d-flex p-jc-between p-ai-center">
										Marque
										<p-sortIcon field="item.marque"></p-sortIcon>
										<p-columnFilter type="text" field="item.marque" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.modele">
									<div class="p-d-flex p-jc-between p-ai-center">
										Modèle
										<p-sortIcon field="item.modele"></p-sortIcon>
										<p-columnFilter type="text" field="item.modele" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte nécessaire
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="numeric" field="quantite" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="qteInventaire">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte inventaire
										<p-sortIcon field="qteInventaire"></p-sortIcon>
										<p-columnFilter type="numeric" field="qteInventaire" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="note">
									<div class="p-d-flex p-jc-between p-ai-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th style="min-width: 5rem; width: 5rem;"></th>

							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree let-columns="columns">
							<tr>
								<td *ngIf="!modeConsultation()">
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.noEtape}}</td>
								<td>{{entree.item.numero}}</td>
								<td>{{entree.item.description}}</td>
								<td>{{entree.item.marque}}</td>
								<td>{{entree.item.modele}}</td>
								<td>{{entree.quantite}}</td>
								<td>
									<span [ngClass]="entree.qteInventaire && entree.qteInventaire > 0 ? entree.qteInventaire >= entree.quantite ? 'inventaire disponible' : 'inventaire insuffisant' : 'inventaire nondisponible'">
										{{entree.qteInventaire}}
									</span>
								</td>
								<td>
									{{entree.note}}
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-pencil"
										class="p-button-primary p-button-outlined p-mr-2 boutonGrille"
										(click)="modifierNotePiece($event, entree)"></button>
								</td>
								<td>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-trash"
										class="p-button-danger p-mr-2 boutonGrille"
										(click)="supprimerPieces($event, [entree])"></button>
									<button *ngIf="modeConsultation()" pButton pRipple icon="pi pi-bars"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="surMenuContextuelPiece($event, entree)"></button>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucune pièce.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>
				<p-tabPanel header="Notes de l'unité" leftIcon="pi pi-pencil">


					<p-table #dtNotes [value]="notes" dataKey="noteId" tableStyleClass="tableNormale"
							autoLayout="true" styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true"
							[filterDelay]="0" [globalFilterFields]="['description']">
							<ng-template pTemplate="caption">
								<div class="p-d-flex p-ai-center p-jc-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtNotes.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										
										<button *ngIf="estAutorise('item_note_creer') && !modeApercu" pButton pRipple icon="pi pi-plus"
											class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
											(click)="ajouterNoteUnite()"></button>

									</div>
									<h5 class="titreTableau p-m-0">Notes</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="p-d-flex p-jc-between p-ai-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="description">
										<div class="p-d-flex p-jc-between p-ai-center">
											Note
											<p-sortIcon field="description"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="demandeur.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Auteur
											<p-sortIcon field="demandeur"></p-sortIcon>
											<p-columnFilter type="text" field="demandeur" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-note>

								<tr>
									<td>{{note.date | date:'yyyy-MM-dd'}}</td>
									<td>{{note.description}}</td>
									<td>{{note.employe | nomEmploye}}</td>
									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_note_modifier') || estAutorise('item_note_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary p-mr-2 boutonGrille"
											(click)="surMenuContextuelNote($event, note)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucune note.</td>
								</tr>
							</ng-template>
						</p-table>

				</p-tabPanel>
				<p-tabPanel header="Garanties" leftIcon="pi pi-shield">

				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>