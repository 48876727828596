import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur } from '../../entites/cleValeur';
import { ItemMin } from '../../entites/item-min';
import { Statut } from '../../entites/statut';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { BaseComponent } from '../../base/base-component';
import { ItemService } from '../../services/item-service';
import { FournisseurService } from '../../services/fournisseur-service';
import { EmployeEntite } from '../../entites/employe';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ProfilService } from '../../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequisitionService } from '../../services/requisition-service';
import { AppMainComponent } from '../../app.main.component';
import { CodeProjetEntite } from '../../entites/code-projet';
import { CodeActiviteEntite } from '../../entites/code-activite';
import { CodeCategorieEntite } from '../../entites/code-categorie';
import { CodeEquipementEntite } from '../../entites/code-equipement';
import { CodeExtraEntite } from '../../entites/code-extra';

export interface ParametresSelectionSimplePiece {
    diversPermis: boolean
}

@Component({
    selector: 'app-selection-simple-piece',
    templateUrl: './selection-simple-piece.component.html'
})
export class SelectionSimplePieceComponent extends BaseComponent {

    //Général
    donnees: ParametresSelectionSimplePiece;

    typesSaisie: CleValeur[];
    selectionTypeSaisie: CleValeur;
    estTypeSaisieDisponible: boolean = true;

    submitted: boolean;
    blocked: boolean = false;

    items: ItemMin[];
    selectionItem: ItemMin = { itemId: 0 };
    categories: string[];
    statuts: Statut[] = this.sdb.Statuts();

    itemSelectionne: boolean = false;

    afficherColonneNumero: boolean = true;
    afficherColonneStatut: boolean = true;
    afficherColonneInventaire: boolean = true;
    afficherColonneNumeroEmplacement: boolean = true;


    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        public itemService: ItemService,
        public requisitionService: RequisitionService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.typesSaisie = [
            { "cle": "I", "valeur": "Inventorié" },
            { "cle": "N", "valeur": "Nouveau" }
        ];
        if (this.donnees.diversPermis) {
            this.typesSaisie.push({ "cle": "D", "valeur": "Divers" });
        }
        this.selectionTypeSaisie = this.typesSaisie[0];

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];
      
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.itemService.ObtenirItems().then(x => {
            //TODO: Hard-codé catégorie 6...
            this.items = x.valeur.filter(x => x.categorie.categorieId == 6);

            this.CompleterChargement();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);

        });
    }


    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.surSelectionItemInventaire(this.tableItems.filteredValue[0]);
        }

    }

    selectionner(close: boolean) {

        this.submitted = true;

        if (!this.validerSelection()) {
            this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        //TODO : Gérer la vérification d'inventaire.
        this.blocked = true;

    
        // Sélection de type "Inventaire"
        if (this.selectionTypeSaisie.cle == 'N') {

            //TODO : ajustement d'inventaire
            
            this.itemService.CreerItem({
                "itemId": 0,
                "categorie": {
                    "categorieId": 6,
                    "nom": "",
                    "estSuivi": false,
                    "estUnique": false
                },
                "description": this.selectionItem.description
            }).then(x => {
                if (x.estUnSucces) {

                    this.blocked = false;
                    this.ref.close(this.itemService.ItemVersItemMin(x.valeur));

                } else {
                    //TODO : Gérer l'erreur
                    x.messages.forEach(x => {
                        this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                    });

                }

            });
        } else if (this.selectionTypeSaisie.cle == 'D') {

            //TODO : le 5 hard codé
            this.itemService.CreerItem({
                "itemId": 0,
                "categorie": {
                    "categorieId": 5,
                    "nom": "",
                    "estSuivi": false,
                    "estUnique": false
                },
                "description": this.selectionItem.description
            }).then(x => {
             
                if (x.estUnSucces) {
         
                    this.blocked = false;
                    this.ref.close(this.itemService.ItemVersItemMin(x.valeur));

                } else {
                    //TODO : Gérer l'erreur
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });

                }

            });
        }

    }

    validerSelection() {

        if (this.selectionTypeSaisie.cle == 'N') {
            return this.selectionItem.description
        } else if (this.selectionTypeSaisie.cle == 'D') {
            return this.selectionItem.description
        }

        return true;
    }

    annuler() {
        this.ref.close(null);
    }

    typeSaisieInventaire(): boolean {
        return this.selectionTypeSaisie.cle == 'I';
    }

    surSelectionItemInventaire(item: ItemMin) {

        this.ref.close(item);

    }
}