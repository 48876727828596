import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

export interface ParametresSaisieDetailItemSoumission {
    numeroFournisseur?: string;
    prix?: number;
}

@Component({
    templateUrl: './saisie-detail-item-soumission.component.html',
})
export class SaisieDetailItemSoumissionComponent {

    
    donnees: ParametresSaisieDetailItemSoumission;

    numeroFournisseur?: string;
    prix?: number;
    
    @ViewChild('champPrix') champPrix;
    @ViewChild('champNoFournisseur') champNoFournisseur;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {

        
        this.donnees = this.config.data;

        this.numeroFournisseur = this.donnees.numeroFournisseur;
        this.prix = this.donnees.prix;

        setTimeout(() => { 
            this.champNoFournisseur.nativeElement.focus();
            this.champNoFournisseur.nativeElement.select();
         }, 100);
        
    }

    surEntree() {
        this.confirmer();
    }

    confirmer() {

        var retour: ParametresSaisieDetailItemSoumission = {
            numeroFournisseur: this.numeroFournisseur,
            prix: this.prix
        }

      this.ref.close(retour);
    }

    annuler() {
        this.ref.close(null);
    }
}