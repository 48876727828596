import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { GarantieEntite } from 'src/app/entites/garantie';
import { GarantieService } from 'src/app/services/garantie-service';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './utilisateurs.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class UtilisateursComponent extends BaseComponent implements OnInit {

    constructor(
        public sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Utilisateurs' }
        ]);
    }


    ngOnInit() {
        this.verifierAccesPage('admin_utilisateur');
    }

    gererUtilisateurs() {
        window.open(`${environment.auth0.urlGestionUtilisateurs}`, "_blank");
    }
}
