import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';
import { ListeVerificationEntite } from 'src/app/entites/mecanique/liste-verification';

@Component({
    templateUrl: './listes-verification.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class ListesVerificationComponent extends BaseComponent implements OnInit {

    listes: ListeVerificationEntite[];

    contenuMenuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuel') menuContextuel: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private listeVerificationService: ListeVerificationService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Modèles de liste d\'achat' }
        ]);
    }

    ngOnInit() {

           this.listeVerificationService.ObtenirListes().then(x => {
               this.listes = x.valeur;
               this.CompleterChargement();
           });

       
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+') {
        //     this.Ajouter();
        // }
    }

    surMenuContextuelItem(event, liste: ListeVerificationEntite) {

        this.contenuMenuContextuel = [
            /* { 
                label: 'Voir le fournisseur', 
                icon: 'pi pi-fw pi-eye', 
                command: () => this.routerService.navigateByUrl('fournisseur/' + bonCommande.fournisseur.fournisseurId) 
            }, */
            {separator: true},
            { 
                label: 'Supprimer', 
                icon: 'pi pi-fw pi-times', 
                command: () => this.supprimer(liste)
            }
        ];

        this.menuContextuel.toggle(event);
    }

    supprimer(liste: ListeVerificationEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la liste sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.listeVerificationService.SupprimerListe(liste.listeVerificationId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.listes = this.listes.filter(val => val.listeVerificationId != liste.listeVerificationId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Liste supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    Ajouter(){
        this.routerService.navigateByUrl("mecanique/pilotage/listeverification/nouveau")
    }

    OuvrirDetail(liste: ListeVerificationEntite) {
        this.routerService.navigateByUrl(`mecanique/pilotage/listeverification/${liste.listeVerificationId}`)
    }
}
