import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Fournisseur } from '../../entites/fournisseur';
import { FournisseurService } from '../../services/fournisseur-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './fournisseurs.component.html',
    styleUrls: ['./fournisseurs.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class FournisseursComponent extends BaseComponent implements OnInit {

    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;

    menuContextuelFournisseurs: MenuItem[];

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private FournisseurService: FournisseurService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Fournisseurs' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('fournisseurs');
    
           this.FournisseurService.ObtenirFournisseurs().then(x => {
               this.fournisseurs = x.valeur;
               this.CompleterChargement();

               setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
           });

        this.menuContextuelFournisseurs = [
            { label: 'Ouvrir', icon: 'pi pi-fw pi-search', command: () => this.OuvrirDetailFournisseur(this.selectionFournisseur.fournisseurId) },
            {separator: true},
            { label: 'Archiver', icon: 'pi pi-fw pi-times', command: () => this.SupprimerFournisseur(this.selectionFournisseur) }
        ];

       
    }

    surEntreeRecherche() {

        if (this.table.filteredValue != undefined &&
            this.table.filteredValue.length == 1) {
                this.OuvrirDetailFournisseur(this.table.filteredValue[0].fournisseurId);
            }

    }

    ajouterFournisseur() {
        this.routerService.navigateByUrl(`fournisseur/ajout`)
    }

    OuvrirDetailFournisseur(fournisseurId: number) {
        this.routerService.navigateByUrl(`fournisseur/${fournisseurId}`)
    }
   

    SupprimerFournisseur(fournisseur: Fournisseur) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment archiver le fournisseur ${fournisseur.nom}`,
            accept: () => {
                this.fournisseurs = this.fournisseurs.filter((p) => p.fournisseurId !== fournisseur.fournisseurId);
                this.messageService.add({ severity: 'info', summary: 'Archivage complété', detail: `Fournisseur ${fournisseur.nom} archivé` });
                this.selectionFournisseur = null;
            }
        });
    }

}
