import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlockUIModule } from 'primeng/blockui';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ReparationEntite } from 'src/app/entites/reparation';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { Emplacement } from 'src/app/entites/emplacement';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { AchatService } from 'src/app/services/achat-service';
import { ReparationService } from 'src/app/services/reparation-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EModeAffichage } from 'src/app/base/mode-affichage';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ItemBonCommande } from 'src/app/entites/item-bon-commande';
import { CodeProjetEntite } from 'src/app/entites/code-projet';
import { CodeActiviteEntite } from 'src/app/entites/code-activite';
import { CodeCategorieEntite } from 'src/app/entites/code-categorie';
import { CodeEquipementEntite } from 'src/app/entites/code-equipement';
import { CodeExtraEntite } from 'src/app/entites/code-extra';

export interface ParametresModificationItemBonCommandeComponent {
    itemBonCommande: ItemBonCommande;
}

export interface ResultatModificationItemBonCommandeComponent {

}

@Component({
    templateUrl: './modification-item-bon-commande.component.html'
})
export class ModificationItemBonCommandeComponent extends BaseComponent {

    donnees: ParametresModificationItemBonCommandeComponent;
    
    itemBonCommande: ItemBonCommande;

    listeCodeProjet: CodeProjetEntite[];
    selectionCodeProjet: CodeProjetEntite;

    listeCodeActivite: CodeActiviteEntite[];
    selectionCodeActivite: CodeActiviteEntite;

    listeCodeCategorie: CodeCategorieEntite[];
    selectionCodeCategorie: CodeCategorieEntite;

    listeCodeEquipement: CodeEquipementEntite[];
    selectionCodeEquipement: CodeEquipementEntite;

    listeCodeExtra: CodeExtraEntite[];
    selectionCodeExtra: CodeExtraEntite;

    listeEmplacement: Emplacement[];
    selectionEmplacement: Emplacement;

    
    codeEquipementBloque: boolean = false;
    codeCategorieBloque: boolean = false;

    submitted: boolean;

    @ViewChild('champCodeProjet') champCodeProjet;
    @ViewChild('champCodeActivite') champCodeActivite;
    @ViewChild('champCodeCategorie') champCodeCategorie;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        public itemService: ItemService,
        public achatService: AchatService,
        public reparationService: ReparationService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.itemBonCommande = { ...this.donnees.itemBonCommande };

        this.itemBonCommande.item.categorie.estSuivi


        this.listeCodeProjet = this.sdb.CodesProjet();

        if (this.itemBonCommande.codeProjet) {
            var cp = this.itemBonCommande.codeProjet.codeProjetId;
            this.selectionCodeProjet = this.listeCodeProjet.find(x => x.codeProjetId == cp);
            this.surChangementCodeProjet();

            if (this.itemBonCommande.codeActivite) {
                var ca = this.itemBonCommande.codeActivite.codeActiviteId;
                this.selectionCodeActivite = this.listeCodeActivite.find(x => x.codeActiviteId == ca);
            }
        }

        this.listeCodeCategorie = this.sdb.CodesCategorie();
        this.listeCodeEquipement = this.sdb.CodesEquipement();
        this.listeCodeExtra = this.sdb.CodesExtra();
        this.listeEmplacement = this.sdb.Emplacements();

        if (this.itemBonCommande.codeCategorie) {
            var cc = this.itemBonCommande.codeCategorie.codeCategorieId;
            this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.codeCategorieId == cc);
        }

        if (this.itemBonCommande.codeEquipement) {
            var cc = this.itemBonCommande.codeEquipement.codeEquipementId;
            this.selectionCodeEquipement = this.listeCodeEquipement.find(x => x.codeEquipementId == cc);
        }
        
        if (this.itemBonCommande.codeExtra) {
            var cc = this.itemBonCommande.codeExtra.codeExtraId;
            this.selectionCodeExtra = this.listeCodeExtra.find(x => x.codeExtraId == cc);
        }

        if (this.itemBonCommande.emplacement) {
            var cc = this.itemBonCommande.emplacement.emplacementId;
            this.selectionEmplacement = this.listeEmplacement.find(x => x.emplacementId == cc);
        }

        this.surChangementCodeActivite();

         this.CompleterChargement();


    }

    surChangementCodeProjet() {
        if (this.selectionCodeProjet) {
        this.selectionCodeActivite = null;
        this.listeCodeActivite = this.selectionCodeProjet.codesActivite;
        }
    }

    surChangementCodeActivite() {
        if (this.selectionCodeActivite && 
            (this.selectionCodeActivite.code == 'CG08' || this.selectionCodeActivite.code == 'M5' || this.selectionCodeActivite.code == 'M6' || this.selectionCodeActivite.code == 'M8' || this.selectionCodeActivite.code == 'M9')) {
            this.selectionCodeEquipement = null;
            this.codeEquipementBloque = true;
        } else {
            this.codeEquipementBloque = false;
        }

    }

    surFermetureCodeProjet() {
        this.champCodeActivite.applyFocus();
    }

    surFermetureCodeActivite() {
        this.champCodeCategorie.applyFocus();
    }

    annuler() {
        this.ref.close(null);
    }

    sauvegarder() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'modificationItem', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.itemBonCommande.codeProjet = this.selectionCodeProjet;
        this.itemBonCommande.codeActivite = this.selectionCodeActivite;
        this.itemBonCommande.codeCategorie = this.selectionCodeCategorie;
        this.itemBonCommande.codeEquipement = this.selectionCodeEquipement;
        this.itemBonCommande.codeExtra = this.selectionCodeExtra;
        this.itemBonCommande.emplacement = this.selectionEmplacement;
        
        this.achatService.ModifierItemBonCommande(this.itemBonCommande).then(res => {
            if (res.estUnSucces) {
                this.Debloquer();
                this.ref.close(res.valeur);
            }
        });
        
    }

    valider() {

        if (!this.itemBonCommande.item.categorie.estSuivi && !this.itemBonCommande.item.description) {
            return false;
        }

        return this.itemBonCommande.quantite
        && (this.itemBonCommande.prix || this.itemBonCommande.prix == 0)
        && this.selectionCodeProjet
        && this.selectionCodeActivite
        && this.selectionCodeCategorie;
    }
}