import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ListeVerificationEntite } from '../entites/mecanique/liste-verification';
import { ItemListeVerificationEntite } from '../entites/mecanique/item-liste-verification';

@Injectable({ providedIn: 'root' })
export class ListeVerificationService {

  private obtenirListeUrl = environment.apiBaseUrl + 'listeverification';
  private obtenirDetailUrl = environment.apiBaseUrl + 'listeverification/{id}';
  private creerUrl = environment.apiBaseUrl + 'listeverification';
  private creerItemListeVerificationEntiteUrl = environment.apiBaseUrl + 'listeverification/item';
  private modifierUrl = environment.apiBaseUrl + 'listeverification/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'listeverification/supprimer';
  private dupliquerUrl = environment.apiBaseUrl + 'listeverification/dupliquer/{id}';
  private supprimerItemUrl = environment.apiBaseUrl + 'listeverification/supprimerItems';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListes(): Promise<ReponseBase<ListeVerificationEntite[]>> {

      var p = this.http.get<ReponseBase<ListeVerificationEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListe(modeleListeAchatId: number): Promise<ReponseBase<ListeVerificationEntite>> {
    var p = this.http.get<ReponseBase<ListeVerificationEntite>>(this.obtenirDetailUrl.replace('{id}',modeleListeAchatId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
}

public SupprimerListe(modeleListeAchatId: number): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerUrl, JSON.stringify(modeleListeAchatId), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerItemsListe(modeleListeAchatIds: number[]): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerItemUrl, JSON.stringify(modeleListeAchatIds), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerListe(requete: ListeVerificationEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.creerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ModifierListe(requete: ListeVerificationEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.modifierUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public DupliquerListe(id: number, liste: ListeVerificationEntite): Promise<ReponseBase<ListeVerificationEntite>> {
  var p = this.http.post<ReponseBase<ListeVerificationEntite>>(this.dupliquerUrl.replace("{id}",id.toString()), JSON.stringify(liste), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerItemListe(requete: ItemListeVerificationEntite): Promise<ReponseBase<ItemListeVerificationEntite>> {
  var p = this.http.post<ReponseBase<ItemListeVerificationEntite>>(this.creerItemListeVerificationEntiteUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
