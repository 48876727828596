<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Bon de commande
				<span class="statut nouveau">
					Nouveau
				</span>
			</h5>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-col-12 p-md-6 p-lg-3 p-mb-3">
					<p-selectButton [options]="listeTypeBdc" [(ngModel)]="typeBdc" optionLabel="valeur">
					</p-selectButton>
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="lastname">Date<span class="requis">*</span></label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateBonCommande" dateFormat="yy-mm-dd"
						[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>

				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="codeProjet">Code projet<span class="requis">*</span></label>
					<p-dropdown #champCodeProjet [options]="listeCodeProjet" [(ngModel)]="selectionCodeProjet"
						optionLabel="code" [showClear]="true" (onHide)="surFermetureCodeProjet()" filter="true"
						filterBy="code,nom" (onChange)="surChangementCodeProjet()" appendTo="body"
						placeholder="Sélectionner un code de projet...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionCodeProjet">
								<div>{{selectionCodeProjet.code}}</div>
							</div>
						</ng-template>
						<ng-template let-code pTemplate="item">
							<div>
								<div>{{code.code}} - {{code.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionCodeProjet">Le code de projet est
						obligatoire.</small>
				</div>

				<div class="p-field p-col-12 p-lg-3">
					<label for="lastname">Fournisseur<span class="requis">*</span></label>
					<p-dropdown [options]="fournisseurs" [(ngModel)]="selectionFournisseur" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="numero,nom"
						placeholder="Sélectionner un fournisseur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionFournisseur">
								<div>{{selectionFournisseur.nom}} ({{selectionFournisseur.numero}})</div>
							</div>
						</ng-template>
						<ng-template let-fournisseur pTemplate="item">
							<div>
								<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionFournisseur">Le fournisseur est
						obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-lg-3">
					<label for="lastname">Fréquence</label>
					<p-dropdown [options]="frequences" [(ngModel)]="selectionFrequence" optionLabel="valeur"
					 filter="true" filterBy="numero,nom"
						placeholder="Sélectionner une fréquence...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionFrequence">
								<div>{{selectionFrequence.valeur}}</div>
							</div>
						</ng-template>
						<ng-template let-frequence pTemplate="item">
							<div>
								<div>{{frequence.valeur}}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>

			</div>

			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="lastname">Emplacement de livraison<span class="requis">*</span></label>
					<p-dropdown [options]="listeEmplacement" [(ngModel)]="selectionEmplacement" optionLabel="nom"
						appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
						placeholder="Sélectionner un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionEmplacement">
								<div>{{selectionEmplacement.nom}} ({{selectionEmplacement.code}})</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionEmplacement">L'emplacement de livraison est
						obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="lastname">Requis le</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="requisLe" dateFormat="yy-mm-dd"
						[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
			</div>

			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12">
					<label for="note">Note</label>
					<input #champNote type="text" pInputText id="note" [(ngModel)]="note"
						autocomplete="off" />
				</div>
			</div>
			<!-- <div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12">
					<label for="conditions">Conditions</label>
					<input #champNote type="text" pInputText id="conditions" [(ngModel)]="conditions"
						autocomplete="off" />
				</div>
			</div> -->
			<div class="p-col-12 p-text-right">
				<p-button label="Suivant" (click)="CreerBdc()" icon="pi pi-save" iconPos="left"></p-button>
			</div>
		</div>
	</div>


</div>