import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './requisitions.component.html',
    styleUrls: ['./requisitions.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class RequisitionsComponent extends BaseComponent implements OnInit {

    requisitions: RequisitionMin[];
    selectionRequisition: RequisitionMin;

    detailRequisitionId: number;
    afficherDetailRequisition: boolean = false;

    menuContextuelRequisitions: MenuItem[];

    
    filtreEmplacement: string[];

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private RequisitionsService: RequisitionService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Réquisitions' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('requisitions');
;
        this.colonnesDisponibles = [
            { champ: 'demandeur', entete: 'Demandeur', afficheParDefaut: true },
            { champ: 'camion', entete: 'Camion', afficheParDefaut: false },
            { champ: 'remorque', entete: 'Remorque', afficheParDefaut: false },
            { champ: 'chauffeur', entete: 'Chauffeur', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.filtreEmplacement = [];
        this.sdb.Emplacements().forEach(x => {
            this.filtreEmplacement.push(x.nom);
        });


           this.RequisitionsService.ObtenirRequisitions().then(x => {
               console.log(x);
               this.requisitions = x.valeur;

               this.CompleterChargement();
           });
       
    }

    clearFilters(table: Table) {
        this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.routerService.navigate(['requisitions']));
}

    OuvrirDetailRequisition(requisitionId: number) {
        this.routerService.navigateByUrl(`requisition/${requisitionId}`)
    }

    OuvrirDetailRequisitionNouvelOnglet(requisitionId: number) {
        this.detailRequisitionId = requisitionId;
        this.afficherDetailRequisition = true;
    }

    AjouterRequisition() {
        this.routerService.navigateByUrl('requisition/nouveau');
    }

    EnvoyerRequisition(req: RequisitionMin) {
        const ref = this.dialogService.open(EnvoiRequisitionComponent, {
            header: 'Envoi d\'une réquisition',
            width: '50%'
        });
    }
    

    SupprimerRequisition(req: RequisitionMin) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer la réquisition #${req.numero}`,
            accept: () => {
                this.requisitions = this.requisitions.filter((p) => p.requisitionId !== req.requisitionId);
                this.messageService.add({ severity: 'info', summary: 'Suppression complétée', detail: `Requisition #${req.numero} supprimée` });
                this.selectionRequisition = null;
            }
        });
    }

}
