import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { SoumissionEntite } from 'src/app/entites/soumission';
import { SoumissionService } from 'src/app/services/soumission-service';

@Component({
    templateUrl: './detail-serie.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailSerieComponent extends BaseComponent implements OnInit {

    menu: MenuItem[];

    dateDebutSource: Date;
    dateFinSource: Date;

    dateDebut: Date;
    dateFin: Date;
    dateDebutSaisie: Date;
    dateFinSaisie: Date;

    soumissions: SoumissionEntite[];
    selectionSoumission: SoumissionEntite[];

    submitted: boolean = false;

    estConfirme: boolean = false;
    nbSoumissionsCrees: number;

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private soumissionService: SoumissionService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Nouvelle série de soumission' }
        ]);

        this.route.params.subscribe(params => {
            this.dateDebutSource = new Date(params.dateDebut as Date);
            this.dateFinSource = new Date(params.dateFin as Date);
        });
    }

    ngOnInit() {

        this.verifierAccesPage('soumission_action_nouvelle_serie');

        this.soumissionService.ObtenirSerie(this.dateDebutSource, this.dateFinSource).then(x => {

            console.log(this.dateFinSource);

            this.dateDebut = new Date(this.dateFinSource);
            this.dateDebut.setDate(this.dateDebut.getDate() + 1);

            this.dateFin = new Date(this.dateFinSource);
            this.dateFin.setDate(this.dateFin.getDate() + 182);

            this.dateDebutSaisie = new Date(this.dateFin);
            this.dateDebutSaisie.setDate(this.dateDebutSaisie.getDate() - 15);

            this.dateFinSaisie = new Date(this.dateFin);
            this.dateFinSaisie.setDate(this.dateFinSaisie.getDate() - 2);


            this.soumissions = x.valeur;
            this.selectionSoumission = this.soumissions;
            this.initialiserMenu();
            this.CompleterChargement();
        });


    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('soumission/serie')
            },
            {
                label: 'Confirmer la nouvelle série', icon: 'pi pi-fw pi-save', command: () => this.confirmer()
            }
        ];
    }

    confirmer() {
        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.soumissionService.CreerSerie({
            listeSoumissionId: this.selectionSoumission.map(x => x.soumissionId),
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            dateDebutSaisie: this.dateDebutSaisie,
            dateFinSaisie: this.dateFinSaisie
        }).then(res => {
            this.Debloquer();
            if (res.estUnSucces) {
                this.nbSoumissionsCrees = res.valeur;
                this.estConfirme = true;
                
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }

    valider(): boolean {


        if (!this.dateDebut || !this.dateFin || !this.dateDebutSaisie || !this.dateFinSaisie) {
            return false;
        }
            
        return true;
    }

    naviguerSoumissions() {
        this.routerService.navigateByUrl('soumissions');
    }
}
