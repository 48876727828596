import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from 'src/app/entites/item-min';
import { ItemService } from 'src/app/services/item-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { BonCommandeEntite } from 'src/app/entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { AchatService } from 'src/app/services/achat-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { AppMainComponent } from 'src/app/app.main.component';
import { RechercheService } from 'src/app/services/recherche-service';
import { ProfilService } from 'src/app/services/profil-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { BaseComponent } from 'src/app/base/base-component';
import { CleValeur } from 'src/app/entites/cleValeur';
import { ItemBonCommande } from 'src/app/entites/item-bon-commande';

@Component({
    templateUrl: './resultat-recherche.component.html',
    styleUrls: ['./resultat-recherche.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ResultatRechercheComponent extends BaseComponent implements OnInit {

    chaineRecherche: string;

    
    typesRecherche: CleValeur[];
    selectionTypeRecherche: CleValeur;

    rechercheDeclenchee: boolean = false;
    chargementComplete: boolean = false;

    items: ItemMin[];
    reqs: RequisitionMin[];
    fournisseurs: Fournisseur[];
    bdcs: ItemBonCommande[];

    mainApp: AppMainComponent;

    @ViewChild('champRecherche') champRecherche;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private requisitionService: RequisitionService,
        private fournisseurService: FournisseurService,
        private achatService: AchatService,
        private itemsService: ItemService,
        private rechercheService: RechercheService,
        public routerService: Router,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public auth: AuthService,
        @Inject(DOCUMENT) private doc: Document) {

            super(sdb, profilService, route, routerService, appMain);

            this.mainApp = appMain;

        this.breadcrumbService.setItems([
            { label: 'Recherche' }
        ]);
    }

    ngOnInit() {

        this.typesRecherche = [
            { "cle": "I", "valeur": "Items" },
            { "cle": "R", "valeur": "Réquisitions" },
            { "cle": "B", "valeur": "Bons de commandes" }
        ];

        this.selectionTypeRecherche = this.typesRecherche[0];

        /* this.chaineRecherche = this.mainApp.recherche;

        this.Rechercher(this.chaineRecherche); */

        setTimeout(() => { this.champRecherche.nativeElement.focus() }, 100);

    }

    surChangementTypeRecherche() {
        this.chaineRecherche = '';
        this.rechercheDeclenchee = false;
        this.chargementComplete = false;
        setTimeout(() => { this.champRecherche.nativeElement.focus() }, 100);
    }

    Rechercher(chaine: string)
    {
        this.items = [];
        this.reqs = [];
        this.fournisseurs = [];
        this.bdcs = [];

        this.chargementComplete = false;
    
        this.rechercheDeclenchee = true;

        if (this.selectionTypeRecherche.cle == 'I') {

            this.rechercheService.RechercherItems(this.chaineRecherche).then(x => {
                this.items = x.valeur;
                this.chargementComplete = true;
            });

        } else if (this.selectionTypeRecherche.cle == 'R') {
            
            this.rechercheService.RechercherRequisitions(this.chaineRecherche).then(x => {
                this.reqs = x.valeur;
                this.chargementComplete = true;
            });

        } else if (this.selectionTypeRecherche.cle == 'B') {
            
            if (this.estAutorise('bdcs')) {
                this.rechercheService.RechercherBonsCommande(this.chaineRecherche).then(x => {
                    this.bdcs = x.valeur;
                    this.chargementComplete = true;
                });
            } else {
                this.rechercheService.RechercherBonsCommandeCP(this.chaineRecherche, this.profilService.ProfilCourant().employeId).then(x => {
                    this.bdcs = x.valeur;
                    this.chargementComplete = true;
                });
            }

            

        }

        

        

        

       /*  this.achatService.ObtenirBonsCommande(null, null).then(x => {
            this.bdcs = x.valeur.slice(0,3);

            setTimeout(() => { this.bdcsLoaded = true; }, 5000);
        }); */
    }
    
    OuvrirDetailItem(itemId: number) {
        
        this.routerService.navigateByUrl(`item/${itemId}`)
    }

    OuvrirDetailRequisition(requisitionId: number) {
        
        this.routerService.navigateByUrl(`requisition/${requisitionId}`)
    }

    OuvrirDetailFournisseur(fournisseurId: number) {
        this.routerService.navigateByUrl(`fournisseur/${fournisseurId}`)
    }

    OuvrirDetailBdc(bonCommandeId: number) {
        this.routerService.navigateByUrl(`achat/${bonCommandeId}`)
    }
    
}
