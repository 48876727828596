<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Rapport d'entretiens PEP et SAAQ
			</h5>

<!-- 
			<p-menubar [model]="menu">
			</p-menubar> -->
		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView orientation="left" [(activeIndex)]="tabIndex" >
				<p-tabPanel header="Critères" leftIcon="pi pi-info-circle">
				
					
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date de début<span class="requis">*</span></label>
							<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date de fin<span class="requis">*</span></label>
							<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						</div>
					</div>
					
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<button pButton pRipple label="Générer rapport" icon="pi pi-chart-bar" class="p-button-primary"
            					(click)="generer()"></button>
						</div>
					</div>
					

				</p-tabPanel>
				
				
			</p-tabView>
		</div>
	</div>

</div>
