import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from '../../services/liste-achat-service';
import { ListeAchatEntite } from '../../entites/liste-achat';
import { ItemListeAchatEntite } from '../../entites/item-liste-achat';
import { ModeleListeAchatService } from 'src/app/services/modele-liste-achat-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { BaseComponent } from 'src/app/base/base-component';
import { EStatuts } from 'src/app/entites/enums/statuts';
import * as EventEmitter from 'events';
import { AppMainComponent } from 'src/app/app.main.component';
import { CodeProjetEntite } from 'src/app/entites/code-projet';

@Component({
    templateUrl: './saisir-liste-achat-e2.component.html',
    styleUrls: ['./saisir-liste-achat-e2.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class SaisirListeAchatEtape2Component extends BaseComponent implements OnInit {

    listeAchat: ListeAchatEntite;

    codesProjet: CodeProjetEntite[];

    estCharge: boolean = false;

    blocked: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private modeleListeAchatService: ModeleListeAchatService,
        private listeAchatService: ListeAchatService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: '' }
        ]);
    }

    ngOnInit() {     
        
        this.verifierAccesPage('liste_achat');

        if (!this.listeAchatService.modeleListeAchatId) {
            this.routerService.navigateByUrl('listeachat/etape1');
            return;
        }

        this.blocked = true;

        this.codesProjet = this.sdb.CodesProjet();

        if (this.listeAchatService.listeAchat) {

            this.listeAchat = this.listeAchatService.listeAchat;
            this.CompleterChargement();
            
        } else {

            this.modeleListeAchatService.ObtenirModele(this.listeAchatService.modeleListeAchatId).then(res => {
                if (res.estUnSucces) {
                    this.listeAchat = {
                        statut: this.sdb.Statut(EStatuts.EnApprobation),
                        dateSaisie: new Date(),
                        employe: this.profilService.ProfilCourant(),
                        itemsListeAchat: [],
                        modele: res.valeur
                    }
                    res.valeur.itemsModeleListeAchat.forEach(x => {
                        this.listeAchat.itemsListeAchat.push({
                            item: x.item,
                            codeProjet: this.sdb.CodeProjet(x.codeProjet?.codeProjetId)
                        })
                    })
                    this.CompleterChargement();
                    this.blocked = false;
                }
            });

        }

        
    }

    surSaisie(event, entree: ItemListeAchatEntite) {
        if (event.originalEvent.key == "ArrowDown") {
            entree.quantite = entree.quantite + 1;
        } else if (event.originalEvent.key == "ArrowUp") {
            entree.quantite = entree.quantite - 1;
        }

        if (entree.quantite == 0) {
            entree.quantite = null;
        }
    }

    nextPage() {

        this.listeAchatService.listeAchat = this.listeAchat;

        this.routerService.navigate(['listeachat/etape3']);

    }

}
