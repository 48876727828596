<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemRequisition popup="popup" [model]="contenuMenuContextuelItemRequisition"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>
				{{requisition.type.nom}} #{{requisition.numero}}
				<span [class]="'statut ' + requisition.statut.nom.toLowerCase()">
					{{requisition.statut.nom}}
				</span>
				<app-bouton-favori [titrePage]="titrePage"></app-bouton-favori>
				<span *ngIf="modeApercu">
					<i class="pi pi-id-card p-ml-4"></i>
					<a class="lienFicheComplete" (click)="ouvrirFicheComplete()">Aller à la fiche complète</a>
				</span>
			</h5>

			<p-menubar [model]="menu" *ngIf="!modeApercu">
				<p-button *ngIf="estAutorise('requisition_modifier') && modeConsultation() && estModifiable"
					(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success p-mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>

		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView #tabView [(activeIndex)]="tabIndex" orientation="left" (onChange)="surChangementOnglet($event)">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<!-- <div class="p-col-12 p-text-right">
						<p-button label="Modifier" icon="pi pi-pencil" iconPos="left"></p-button>
					</div> -->
					<!-- <div *ngIf="modeConsultation()" class="p-fluid p-formgrid p-grid">

						<div class="p-field p-col-4 p-md-2 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">Numéro :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.numero}}
						</div>
						<div class="p-field p-col-4 p-md-2 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">Date :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.date | date:'yyyy-MM-dd'}}
						</div>
						<div class="p-field p-col-4 p-md-2 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">Demandeur :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.demandeur | nomEmploye}}
						</div>
						<div class="p-field p-col-4 p-md-2 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">Transporteur :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.transporteur}}
						</div>
						<div class="p-field p-col-12 p-md-4 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">De :</label>
						</div>
						<div class="p-field p-col-12 p-md-8 p-lg-5">
							{{requisition.de.nom}} ({{requisition.de.code}})
						</div>
						<div class="p-field p-col-12 p-md-4 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">À :</label>
						</div>
						<div class="p-field p-col-12 p-md-8 p-lg-5">
							{{requisition.a.nom}} ({{requisition.a.code}})
						</div>
						<div class="p-field p-col-12 p-md-4 p-lg-1" style="text-align: right;">
							<label style="font-weight: bold;" for="">Note :</label>
						</div>
						<div class="p-field p-col-12 p-md-8 p-lg-11">
							{{requisition.note}}
						</div>
					</div> -->

					<!-- <div *ngIf="modeConsultation()" class="p-fluid p-formgrid p-grid">

						<div class="p-field p-col-4 p-md-2 p-lg-1">
							<label style="font-weight: bold;" for="">Numéro :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.numero}}
						</div>
						<div class="p-field p-col-4 p-md-2 p-lg-1">
							<label style="font-weight: bold;" for="">Date :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.date | date:'yyyy-MM-dd'}}
						</div>
						<div class="p-field p-col-4 p-md-2 p-lg-1">
							<label style="font-weight: bold;" for="">Demandeur :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.demandeur | nomEmploye}}
						</div>
						<div class="p-field p-col-4 p-md-2 p-lg-1">
							<label style="font-weight: bold;" for="">Transporteur :</label>
						</div>
						<div class="p-field p-col-8 p-md-4 p-lg-2">
							{{requisition.transporteur}}
						</div>
						<div class="p-field p-col-12 p-md-4 p-lg-1">
							<label style="font-weight: bold;" for="">De :</label>
						</div>
						<div class="p-field p-col-12 p-md-8 p-lg-5">
							{{requisition.de.nom}} ({{requisition.de.code}})
						</div>
						<div class="p-field p-col-12 p-md-4 p-lg-1">
							<label style="font-weight: bold;" for="">À :</label>
						</div>
						<div class="p-field p-col-12 p-md-8 p-lg-5">
							{{requisition.a.nom}} ({{requisition.a.code}})
						</div>
						<div class="p-field p-col-12 p-md-4 p-lg-1">
							<label style="font-weight: bold;" for="">Note :</label>
						</div>
						<div class="p-field p-col-12 p-md-8 p-lg-11">
							{{requisition.note}}
						</div>
					</div> -->

					<div class="p-fluid p-formgrid p-grid">

						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="firstname">Numéro</label>
							<input id="firstname" type="text" pInputText readonly value="{{requisition.numero}}">
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date</label>
							<input *ngIf="modeConsultation()" id="lastname" type="text" pInputText readonly
								value="{{requisition.date | date:'yyyy-MM-dd'}}">
							<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true
								[(ngModel)]="requisition.date" dateFormat="yy-mm-dd" [yearNavigator]="true"
								[monthNavigator]="true" yearRange="2000:2040"></p-calendar>
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Demandeur</label>
							<input *ngIf="modeConsultation()" id="lastname" type="text" pInputText
								value="{{requisition.demandeur | nomEmploye}}">
							<p-dropdown *ngIf="!modeConsultation()" #champDemandeur [options]="listeEmployes"
								[(ngModel)]="requisition.demandeur" optionLabel="nom" [showClear]="true" filter="true"
								filterBy="prenom,nom" appendTo="body" placeholder="Sélectionner un demandeur...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="requisition.demandeur">
										<div>{{requisition.demandeur.prenom}} {{requisition.demandeur.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-employe pTemplate="item">
									<div>
										<div>{{employe.prenom}} {{employe.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !requisition.demandeur">Le demandeur est
								obligatoire.</small>
						</div>

						<div class="p-field p-col-12 p-lg-6">
							<label for="lastname">De</label>
							<input id="lastname" type="text" pInputText readonly
								value="{{requisition.de.nom}} ({{requisition.de.code}})">
						</div>
						<div class="p-field p-col-12 p-lg-6">
							<label for="lastname">À</label>
							<input id="lastname" type="text" pInputText readonly
								value="{{requisition.a.nom}} ({{requisition.a.code}})">
						</div>

						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="camion">Camion</label>
							<input id="camion" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="requisition.camion">
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="remorque">Remorque</label>
							<input id="remorque" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="requisition.remorque">
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="chauffeur">Chauffeur</label>
							<input id="chauffeur" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="requisition.chauffeur">
						</div>


						<div class="p-field p-col-12">
							<label for="lastname">Note</label>
							<i *ngIf="estAutorise('requisition_modifier') && modeConsultation()"
								class="pi pi-pencil p-ml-3" style="font-size: 1rem" (click)="modifierNote($event)"></i>
							<!-- <input id="lastname" type="text" pInputText [readonly]="modeConsultation()" [(ngModel)]="requisition.note"> -->
							<textarea [rows]="1" pInputTextarea autoResize="autoResize" [readonly]="modeConsultation()"
								[(ngModel)]="requisition.note"></textarea>
						</div>
					</div>




					<p-table #dt [value]="requisition.itemsRequisition" [columns]="selectedColumns"
						dataKey="itemRequisitionId" tableStyleClass="tableNormale"
						[(selection)]="selectionMultipleItemRequisition" (onRowSelect)="surChangementSelection()"
						(onRowUnselect)="surChangementSelection()" (onHeaderCheckboxToggle)="surChangementSelection()"
						autoLayout="true" styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true"
						[filterDelay]="0" [globalFilterFields]="['item.numero','item.description','demandeur']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<button
										*ngIf="estModifiable && !modeApercu && estTypeRequisition() && estAutorise('requisition_modifier')"
										pButton pRipple icon="pi pi-plus"
										class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="surAjoutItem()"></button>

								</div>
								<h5 class="titreTableau p-m-0">Items</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>

								<th *ngIf="estAutorise('requisition_modifier')" style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte
										<p-sortIcon field="quantiteDemande"></p-sortIcon>
										<p-columnFilter type="text" field="quantiteDemande" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th pSortableColumn="item.categorie.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Catégorie
										<p-sortIcon field="item.categorie.nom"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<!-- <th pSortableColumn="item.quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Inv.
										<p-sortIcon field="item.quantite"></p-sortIcon>
										<p-columnFilter type="number" field="item.quantite" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th> -->
								<th pSortableColumn="demandeur.prenom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Demandeur
										<p-sortIcon field="demandeur.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="demandeur.prenom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="expediteur">
									<div class="p-d-flex p-jc-between p-ai-center">
										Expéditeur
										<p-sortIcon field="expediteur"></p-sortIcon>
										<p-columnFilter type="text" field="expediteur" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="!modeApercu" style="min-width: 7rem; width: 7rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>

							<tr>
								<td *ngIf="estAutorise('requisition_modifier')">
									<p-tableCheckbox [value]="item"></p-tableCheckbox>
								</td>
								<td>{{item.quantite}}</td>
								<td>{{item.item.categorie.nom}}</td>
								<td>{{item.item.numero}}</td>
								<td>{{item.item.description}}</td>
								<td>{{item.demandeur | nomEmploye}}</td>
								<td>{{item.expediteur}}</td>
								<td *ngIf="!modeApercu">
									<button *ngIf="estAutorise('requisition_supprimer_item') && estModifiable"
										[disabled]="item.item.estLie" pButton pRipple icon="pi pi-trash"
										class="p-button-danger p-mr-2 boutonGrille"
										(click)="supprimerItems([item])"></button>
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="surMenuContextuelItem($event, item)"></button>

								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>
				<!-- TAB : ITEMS EN COMMANDE -->
				<p-tabPanel
					*ngIf="estAutorise('requisition_ajout_item') && requisition.type.typeRequisitionId == 1 && estModifiable && !modeApercu"
					[header]="'Items en commande (' + this.itemsCommande.length + ')'" leftIcon="pi pi-book">

					<p-table #dt [value]="itemsCommande" [columns]="selectedColumns" dataKey="itemCommandeId"
						autoLayout="true" [(selection)]="selectionMultipleItemCommande"
						(onRowSelect)="surChangementSelectionCommande()"
						(onRowUnselect)="surChangementSelectionCommande()"
						(onHeaderCheckboxToggle)="surChangementSelectionCommande()"
						styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','demandeur']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
								</div>
								<h5 class="titreTableau p-m-0">Items en commande</h5>
								<div>
									<!-- <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees"
										optionLabel="header" selectedItemsLabel="{0} colonnes sélectionnés"
										[style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect> -->
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="text" field="quantite" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<!-- <th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte <br> à venir
										<p-sortIcon field="quantiteDemande"></p-sortIcon>
										<p-columnFilter type="text" field="quantiteDemande" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th> -->
								<!-- <th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte <br> prêt
										<p-sortIcon field="quantiteDemande"></p-sortIcon>
										<p-columnFilter type="text" field="quantiteDemande" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th> -->
								<th pSortableColumn="numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Numéro
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="statut.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter field="statut" matchMode="in" display="menu"
											[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
													(onChange)="filter($event.value)" optionLabel="name">
													<ng-template let-option pTemplate="item">
														<div class="p-multiselect-representative-option">

															<span class="p-ml-1">{{option.nom}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="demandeur.prenom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Demandeur
										<p-sortIcon field="demandeur.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="demandeur.prenom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
								<th style="min-width: 7rem; width: 7rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item let-columns="columns">
							<tr>
								<td>
									<p-tableCheckbox [value]="item"></p-tableCheckbox>
								</td>
								<td>{{item.quantite}} / {{item.quantiteDemande}}</td>
								<td>{{item.item.numero}}</td>
								<td>{{item.item.description}}</td>
								<td><span [class]="'statut ' + item.statut.nom.replace(' ','').toLowerCase()">
										{{item.statut.nom}}
									</span>
								</td>
								<td>{{item.demandeur | nomEmploye}}</td>
								
								<td class="celluleTableau">
									<button [disabled]="item.item.estLie" pButton pRipple icon="pi pi-check-square"
										class="p-button-success p-mr-2 boutonGrille"
										(click)="transfertItemCommande(item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>



				</p-tabPanel>
				<!-- TAB : ITEMS DISPONIBLES -->
				<p-tabPanel
					*ngIf="estAutorise('requisition_ajout_item') && (requisition.type.typeRequisitionId == 2 || requisition.type.typeRequisitionId == 3) && estModifiable && !modeApercu"
					[header]="'Items disponibles (' + this.itemsDisponibles.length + ')'" leftIcon="pi pi-book">

					<p-table #dtItemsDisponibles [value]="itemsDisponibles" [columns]="selectedColumns" dataKey="itemId"
						autoLayout="true" [(selection)]="selectionMultipleItemDisponible"
						(onRowSelect)="surChangementSelectionDisponible()"
						(onRowUnselect)="surChangementSelectionDisponible()"
						(onHeaderCheckboxToggle)="surChangementSelectionDisponible()"
						styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['numero','description']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtItemsDisponibles.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
								</div>
								<h5 class="titreTableau p-m-0">Items disponibles</h5>
								<div>
									<p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees"
										optionLabel="header" selectedItemsLabel="{0} colonnes sélectionnés"
										[style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Qte
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="text" field="quantite" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Numéro
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="statut.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter field="statut" matchMode="in" display="menu"
											[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
													(onChange)="filter($event.value)" optionLabel="name">
													<ng-template let-option pTemplate="item">
														<div class="p-multiselect-representative-option">

															<span class="p-ml-1">{{option.nom}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="col.champ" *ngFor="let col of columns">
									<div class="p-d-flex p-jc-between p-ai-center">
										{{col.entete}}
										<p-sortIcon field="col.champ"></p-sortIcon>
										<p-columnFilter type="text" field="col.champ" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th style="min-width: 7rem"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item let-columns="columns">
							<tr>
								<td>
									<p-tableCheckbox [value]="item"></p-tableCheckbox>
								</td>
								<td>{{item.quantite}}</td>
								<td>{{item.numero}}</td>
								<td>{{item.description}}</td>
								<td><span [class]="'statut ' + item.statut.nom.replace(' ','').toLowerCase()">
										{{item.statut.nom}}
									</span>
								</td>
								<td *ngFor="let col of columns">
									{{item[col.champ]}}
								</td>
								<td class="celluleTableau">
									<button [disabled]="item.estLie" pButton pRipple icon="pi pi-check-square"
										class="p-button-success p-mr-2 boutonGrille"
										(click)="transfertItemDisponible(item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>



				</p-tabPanel>
				<p-tabPanel *ngIf="estAutorise('requisition_documents')" header="Documents" leftIcon="pi pi-file-pdf">

					<p-card *ngIf="!chargementCompleteDocuments">
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>

					<p-menu #menuContextuelDocuments popup="popup" [model]="contenuMenuContextuelDocuments">
					</p-menu>

					<div *ngIf="chargementCompleteDocuments && !modeApercu" class="p-fluid p-formgrid p-grid p-mb-4">

						<div class="p-col-6 p-lg-3">
							<button pButton type="button" icon="pi pi-plus" (click)="ajouterDocument()"
								label="Ajouter un document" class="p-mb-1"></button>
						</div>
					</div>


					<div *ngIf="chargementCompleteDocuments" class="p-fluid p-formgrid p-grid">
						<div class="p-col-12">


							<p-dataView [value]="documentsComplet" #dv layout="grid" emptyMessage="Aucun document">
								<ng-template let-doc pTemplate="gridItem">
									<div class="p-col-12 p-md-4 p-lg-3">
										<div class="card tuileFichier">
											<div class="imageFichier">
												<img id="myimage" [src]='doc.apercu' />
											</div>

											<div class="">
												<div class="nomFichier">{{doc.nom}}</div>
												<div class="dateFichier">{{doc.date | date:'yyyy-MM-dd'}}</div>
											</div>
											<div class="sectionBoutonsFichier">
												<div class="p-fluid p-formgrid p-grid">
													<div class="p-field p-col-6 p-text-left">
														<p-button *ngIf="doc.typeFichier == 1 || doc.typeFichier == 2"
															(click)="afficherImage(doc)" icon="pi pi-search"
															class="p-button-success p-mr-2"></p-button>
														<p-button (click)="telechargerDocument(doc)"
															icon="pi pi-download"></p-button>
													</div>
													<div *ngIf="!modeApercu" class="p-field p-col-6 p-text-right">
														<button pButton pRipple icon="pi pi-bars"
															class="p-button-secondary"
															(click)="surMenuContextuelDocuments($event, doc)"></button>
													</div>
												</div>

											</div>
										</div>
									</div>
								</ng-template>
							</p-dataView>

						</div>
					</div>






				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>