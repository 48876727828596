import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

export interface ParametresSelectionEmploye {
    employeId?: number;
}

@Component({
    templateUrl: './selection-employe.component.html',
})
export class SelectionEmployeComponent {

    donnees: ParametresSelectionEmploye;

    employes: EmployeEntite[];
    selection: EmployeEntite;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {

        this.donnees = this.config.data;

        this.employes = this.sdb.Employes();
        this.employes.forEach(emp => {
            emp.prenomNom = `${emp.prenom} ${emp.nom}`;
        });

        if (this.donnees && this.donnees.employeId) {
            this.selection = this.employes.find(x => x.employeId == this.donnees.employeId);
        }
    }   

    Envoyer() {
        this.ref.close(this.selection);
    }

    Annuler() {
        this.ref.close(null);
    }
}