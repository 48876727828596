import { Component, Input, ViewChild } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { MessageService, ConfirmationService, MenuItem, TreeNode } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { Router } from '@angular/router';
import { CategorieMin } from '../../entites/categorie';

@Component({
    templateUrl: './choix-categorie.component.html',
    styleUrls: ['./choix-categorie.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ChoixCategorieComponent {

    categories: TreeNode[];
    selectionCategorie: TreeNode;

    loading: boolean;

    constructor(
        private ItemsService: ItemService,
        public dialogService: DialogService,
        public routerService: Router,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig) {
    }

    ngOnInit() {


        this.categories = [
            {
                "label": "Documents",
                "data": "Documents Folder",
                "expandedIcon": "pi pi-folder-open",
                "collapsedIcon": "pi pi-folder",
                "children": [{
                    "label": "Work",
                    "data": "Work Folder",
                    "expandedIcon": "pi pi-folder-open",
                    "collapsedIcon": "pi pi-folder",
                    "children": [{ "label": "Expenses.doc", "icon": "pi pi-file", "data": "Expenses Document" }, { "label": "Resume.doc", "icon": "pi pi-file", "data": "Resume Document" }]
                },
                {
                    "label": "Home",
                    "data": "Home Folder",
                    "expandedIcon": "pi pi-folder-open",
                    "collapsedIcon": "pi pi-folder",
                    "children": [{ "label": "Invoices.txt", "icon": "pi pi-file", "data": "Invoices for this month" }]
                }]
            },
            {
                "label": "Pictures",
                "data": "Pictures Folder",
                "expandedIcon": "pi pi-folder-open",
                "collapsedIcon": "pi pi-folder",
                "children": [
                    { "label": "barcelona.jpg", "icon": "pi pi-image", "data": "Barcelona Photo" },
                    { "label": "logo.jpg", "icon": "pi pi-image", "data": "PrimeFaces Logo" },
                    { "label": "primeui.png", "icon": "pi pi-image", "data": "PrimeUI Logo" }]
            },
            {
                "label": "Movies",
                "data": "Movies Folder",
                "expandedIcon": "pi pi-folder-open",
                "collapsedIcon": "pi pi-folder",
                "children": [{
                    "label": "Al Pacino",
                    "data": "Pacino Movies",
                    "children": [{ "label": "Scarface", "icon": "pi pi-video", "data": "Scarface Movie" }, { "label": "Serpico", "icon": "pi pi-video", "data": "Serpico Movie" }]
                },
                {
                    "label": "Robert De Niro",
                    "data": "De Niro Movies",
                    "children": [{ "label": "Goodfellas", "icon": "pi pi-video", "data": "Goodfellas Movie" }, { "label": "Untouchables", "icon": "pi pi-video", "data": "Untouchables Movie" }]
                }]
            }
        ];

    }


    selectionnerCategorie(categorie: CategorieMin) {
        this.ref.close(categorie);
    }


}