import { Component, ViewChild, Input } from '@angular/core';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppMainComponent } from '../app.main.component';

@Component({
    selector: 'app-bouton-favori',
    templateUrl: './bouton-favori.component.html',
})
export class BoutonFavoriComponent extends BaseComponent {

    @Input() titrePage: string;
    estFavori: boolean = false;

    @ViewChild('champQuantite') inputQuantite;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        private messageService: MessageService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {
        this.verifierFavori();

        this.profilService.changementFavoris.subscribe(x => {
            this.verifierFavori();
        });
    }



    ajouterFavori() {
        if (!this.estFavori) {
            this.route.url.subscribe(x => {
                this.profilService.ajouterFavori({
                    favoriId: 0,
                    url: x.join('/'),
                    description: this.titrePage,
                    employe: this.profilService.ProfilCourant()
                }).then(x => {
                    if (x.estUnSucces) {
                        //TODO ajouter le favori dans la liste
                        this.profilService.rafraichirFavoris(this.profilService.ProfilCourant().employeId)
                        this.estFavori = true;
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Impossible d\'ajouter le favori.' });
                    }
                });
            });
        }
    }

    verifierFavori() {
        this.route.url.subscribe(x => {
            this.profilService.verifierFavori(this.profilService.ProfilCourant().employeId, x.join('/')).then(res => {
                this.estFavori = res;
            });
        });


    }

}