import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { ListeAchatService } from '../../services/liste-achat-service';
import { AchatService } from '../../services/achat-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { Statut } from 'src/app/entites/statut';

@Component({
    templateUrl: './achats.component.html',
    styleUrls: ['./achats.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class AchatsComponent extends BaseComponent implements OnInit {

    bonsCommande: BonCommandeEntite[];
    selectionBonCommande: BonCommandeEntite;

    detailBdcId: number;
    afficherDetailBdc: boolean = false;

    statuts: Statut[] = this.sdb.Statuts();

    menuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private achatService: AchatService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Bons de commande' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPageAuMoisUnAcces(['bdcs','bdcs_proprietaire']);

        this.colonnesDisponibles = [
            { champ: 'projet', entete: 'Projet', afficheParDefaut: true },
            { champ: 'nbItems', entete: 'Nb items', afficheParDefaut: false },
            { champ: 'coutTotal', entete: 'Coût total', afficheParDefaut: true },
            { champ: 'employe', entete: 'Employé', afficheParDefaut: true },
            { champ: 'dateEnvoi', entete: 'Date d\'envoi', afficheParDefaut: true },
            { champ: 'envoyeA', entete: 'Envoyé à', afficheParDefaut: true }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

            if (this.estAutorise('bdcs')) {
                this.achatService.ObtenirBonsCommande(null, null).then(x => {
                    this.bonsCommande = x.valeur;

                    this.bonsCommande.forEach(bc => {
                        if(bc.employe) {
                            bc.employe.prenomNom = `${bc.employe.prenom} ${bc.employe.nom}`;
                        }
                    });
                    
                    this.CompleterChargement();
                });
            } else {
                this.achatService.ObtenirBonsCommande(null, this.profilService.ProfilCourant().employeId).then(x => {
                    this.bonsCommande = x.valeur;

                    this.bonsCommande.forEach(bc => {
                        if(bc.employe) {
                            bc.employe.prenomNom = `${bc.employe.prenom} ${bc.employe.nom}`;
                        }
                    });

                    this.CompleterChargement();
                });
            }

       
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+') {
        //     this.AjouterBdc();
        // }
    }

    menuContextuelEntrees: MenuItem[];
    surMenuContextuelItem(event, bonCommande: BonCommandeEntite) {
        this.selectionBonCommande = bonCommande;

        this.menuContextuelEntrees = [
            { 
                label: 'Voir le fournisseur', 
                icon: 'pi pi-fw pi-eye', 
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + bonCommande.fournisseur.fournisseurId) 
            },
            {separator: true},
            { 
                label: 'Supprimer', 
                icon: 'pi pi-fw pi-times', 
                visible: this.estAutorise('bdc_supprimer'),
                disabled: bonCommande.statut.statutId != EStatuts.EnCours,
                command: () => this.supprimerBonCommande(bonCommande)
            }
        ];

        this.menuContextuelItemCommande.toggle(event);
    }

    supprimerBonCommande(bonCommande: BonCommandeEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le bon de commande sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.achatService.SupprimerBonCommande(bonCommande.bonCommandeId).then(res => {
                    if (res.estUnSucces) {
                        this.bonsCommande = this.bonsCommande.filter(val => val.bonCommandeId != bonCommande.bonCommandeId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    AjouterBdc(){
        this.routerService.navigateByUrl("achat/nouveau")
    }

    OuvrirDetailBdc(bonCommandeId: number) {
        this.routerService.navigateByUrl(`achat/${bonCommandeId}`)
    }

    OuvrirDetailBdcNouvelOnglet(itemId: number) {
        this.detailBdcId = itemId;
        this.afficherDetailBdc = true;
    }
}
