import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { RequeteTransfererVersCtrl } from './requetes/requete-transfert-ctrl';
import { ModeleListeAchatEntite } from '../entites/modele-liste-achat';
import { ItemModeleListeAchatEntite } from '../entites/item-modele-liste-achat';

@Injectable({ providedIn: 'root' })
export class ModeleListeAchatService {

  private obtenirListeUrl = environment.apiBaseUrl + 'listeachat/modeles';
  private obtenirDetailUrl = environment.apiBaseUrl + 'listeachat/modele/{modeleListeAchatId}';
  private creerUrl = environment.apiBaseUrl + 'listeachat/modele';
  private creerItemModeleListeAchatEntiteUrl = environment.apiBaseUrl + 'listeachat/modele/item';
  private supprimerUrl = environment.apiBaseUrl + 'listeachat/modele/supprimer';
  private supprimerItemUrl = environment.apiBaseUrl + 'listeachat/modele/supprimerItems';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirModeles(): Promise<ReponseBase<ModeleListeAchatEntite[]>> {

      var p = this.http.get<ReponseBase<ModeleListeAchatEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirModele(modeleListeAchatId: number): Promise<ReponseBase<ModeleListeAchatEntite>> {
    var p = this.http.get<ReponseBase<ModeleListeAchatEntite>>(this.obtenirDetailUrl.replace('{modeleListeAchatId}',modeleListeAchatId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
}

public SupprimerModele(modeleListeAchatId: number): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerUrl, JSON.stringify(modeleListeAchatId), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerItemsModele(modeleListeAchatIds: number[]): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerItemUrl, JSON.stringify(modeleListeAchatIds), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerModele(requete: ModeleListeAchatEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.creerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerItemModele(requete: ItemModeleListeAchatEntite): Promise<ReponseBase<ItemModeleListeAchatEntite>> {
  var p = this.http.post<ReponseBase<ItemModeleListeAchatEntite>>(this.creerItemModeleListeAchatEntiteUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
