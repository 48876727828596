import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { SousCategorieEntite } from '../entites/sousCategorie';
import { ItemService } from './item-service';

@Injectable({ providedIn: 'root' })
export class SousCategorieService {

  private obtenirListeUrl = environment.apiBaseUrl + 'categorie/sc';
  private obtenirListeParFournisseurUrl = environment.apiBaseUrl + 'categorie/sc/categorie/{id}';
  private obtenirUrl = environment.apiBaseUrl + 'categorie/sc/{id}';
  private ajouterUrl = environment.apiBaseUrl + 'categorie/sc';
  private modifierUrl = environment.apiBaseUrl + 'categorie/sc/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'categorie/sc/{id}';
  private fusionnerUrl = environment.apiBaseUrl + 'categorie/sc/fusionner/{idSource}/{idDestination}';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<SousCategorieEntite[]>> {
    var p = this.http.get<ReponseBase<SousCategorieEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeParCategorie(id: number): Promise<ReponseBase<SousCategorieEntite[]>> {
    var p = this.http.get<ReponseBase<SousCategorieEntite[]>>(this.obtenirListeParFournisseurUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Obtenir(id: number): Promise<ReponseBase<SousCategorieEntite>> {
    var p = this.http.get<ReponseBase<SousCategorieEntite>>(this.obtenirUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(sousCategorie: SousCategorieEntite): Promise<ReponseBase<SousCategorieEntite>> {
    var p = this.http.post<ReponseBase<SousCategorieEntite>>(this.ajouterUrl, JSON.stringify(sousCategorie), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Modifier(sousCategorie: SousCategorieEntite): Promise<ReponseBase<SousCategorieEntite>> {
    var p = this.http.post<ReponseBase<SousCategorieEntite>>(this.modifierUrl, JSON.stringify(sousCategorie), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Supprimer(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      p.then(() => this.itemService.ReinitialiserItems());

      return p;
  }

  public Fusionner(idSource: number, idDestination: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.fusionnerUrl.replace('{idSource}', idSource.toString()).replace('{idDestination}', idDestination.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      p.then(() => this.itemService.ReinitialiserItems());

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
