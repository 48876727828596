<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h5>
                Employe - {{employe.prenom}} {{employe.nom}}
                <span *ngIf="!employe.employeId" class="statut nouveau">
					Nouveau
				</span>
            </h5>

            <p-menubar [model]="menu"></p-menubar>

        </div>
    </div>
</div>

<div *ngIf="EstCharge()" class="p-grid">
    
    <div class="p-col-12">

        <div class="card">

            <div class="p-fluid p-formgrid p-grid" *ngIf="!employe.employeId">
                <div class="p-field p-col-12">
                    <p-message severity="warn" text="ATTENTION : Ajouter un employé ne lui donne pas accès à SIHC. Les accès sont gérés via la section 'Accès'."></p-message> 
                </div>
            </div>


            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="prenom">Prénom<span class="requis">*</span></label>
                    <input #prenomEmploye type="text" pInputText id="prenom" [(ngModel)]="employe.prenom" autocomplete="off"
                        autofocus />
                    <small class="p-error" *ngIf="submitted && !employe.prenom">Le prénom est
                        obligatoire.</small>
                </div>
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="nom">Nom</label>
                    <input #nomEmploye type="text" pInputText id="nom" [(ngModel)]="employe.nom" autocomplete="off"
                        autofocus />
                </div>
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="courriel">Courriel</label>
                    <input type="text" pInputText id="courriel" [(ngModel)]="employe.courriel"
                         autocomplete="off" />
                </div>

            </div>
            
       <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="noEmploye">No employé</label>
                    <input type="text" pInputText id="noEmploye" [(ngModel)]="employe.noEmploye" autocomplete="off"
                        autofocus />
                </div>
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="cellulaire">Cellulaire</label>
                    <input type="text" pInputText id="cellulaire" [(ngModel)]="employe.cellulaire" autocomplete="off"
                        autofocus />
                </div>
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="usagerCtrl">Usager Ctrl</label>
                    <input type="text" pInputText id="usagerCtrl" [(ngModel)]="employe.usagerCtrl"
                         autocomplete="off" />
                </div>
                <div class="p-field p-col-12 p-md-6 p-lg-3">
                    <label for="usagerCtrl">Initiales Ctrl</label>
                    <input type="text" pInputText id="initialesCtrl" [(ngModel)]="employe.initialesCtrl"
                         autocomplete="off" />
                </div>
                
            </div>

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3 p-xl-2 p-mb-3">
                    <label for="lastname">Employé lié à la mécanique</label>
                    <br>
                    <p-checkbox [binary]="true" [(ngModel)]="employe.indMecanique"></p-checkbox>
                </div>
            </div>

            
      

            <div class="p-col-12 p-text-right p-mt-4">
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-primary"
                (click)="sauvegarder()"></button>
            <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
                (click)="annuler()"></button>
            
            </div>
        </div>
    </div>
</div>
