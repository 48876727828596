import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { DocumentService } from 'src/app/services/document-service';
import { DocumentEntite } from 'src/app/entites/document';
import { RequeteAjouterDocuments } from 'src/app/services/requetes/requete-ajouter-documents';
import { ETypeLienDocument } from 'src/app/entites/enums/type-lien-document';
import { ContexteService } from 'src/app/services/contexte-service';
import { FileUpload } from 'primeng/fileupload/fileupload';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { TypeDocumentEntite } from 'src/app/entites/type-document';
import { EStatuts } from 'src/app/entites/enums/statuts';

@Component({
    templateUrl: './ajout-document.component.html',
    styleUrls: ['./ajout-document.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutDocumentComponent extends BaseComponent implements OnInit {

    modeDocument: ETypeLienDocument;

    menu: MenuItem[];

    dateDocument: Date;

    typesDocument: TypeDocumentEntite[];
    selectionTypeDocument: TypeDocumentEntite;

    submitted: boolean = false;

    @ViewChild('fu') fileUploadControl: FileUpload;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private documentService: DocumentService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Ajout de documents' }
        ]);
    }

    ngOnInit() {

        if (this.contexteService.ValiderPresence(["itemId", "dossier", "numero"])) {

            this.modeDocument = ETypeLienDocument.Item;

            this.typesDocument = this.sdb.TypesDocument();

            this.selectionTypeDocument = this.typesDocument.find(x => x.dossier == this.contexteService.Obtenir("dossier"));

        } else if (this.contexteService.ValiderPresence(["requisitionId", "numero"])) {

            this.modeDocument = ETypeLienDocument.Requisition;

        } else if (this.contexteService.ValiderPresence(["locationId", "numero"])) {

            this.modeDocument = ETypeLienDocument.Location;

        } else {
            this.routerService.navigateByUrl('error');
        }

        this.dateDocument = new Date();

        this.initialiserMenu();

        this.CompleterChargement();
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.retour()
            }
        ];
    }

    retour() {

        if (this.contexteService.ValiderPresence(["itemId"])) {
            this.contexteService.Ajouter("tab", "3");
            const itemId = Number(this.contexteService.Obtenir("itemId"));
            this.routerService.navigateByUrl('item/' + itemId);
        } else if (this.contexteService.ValiderPresence(["requisitionId"])) {
            this.contexteService.Ajouter("tab", "2");
            const requisitionId = Number(this.contexteService.Obtenir("requisitionId"));
            this.routerService.navigateByUrl('requisition/' + requisitionId);
        } else if (this.contexteService.ValiderPresence(["locationId"])) {
            this.contexteService.Ajouter("tab", "1");
            const locationId = Number(this.contexteService.Obtenir("locationId"));
            this.routerService.navigateByUrl('location/' + locationId);
        } else {
            this.routerService.navigateByUrl('error');
        }
    }

    onBasicUpload(event) {
    }

    onUpload(event) {

        this.submitted = true;

        if (!this.valider()) {
            return;
        }

        this.Bloquer();


        var documents: DocumentEntite[] = [];
        var promises: Promise<boolean>[] = [];

        for (let file of event.files) {

            var p = new Promise<boolean>((resolve) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (e) => {
                    if (this.contexteService.ValiderPresence(["itemId"])) {
                        documents.push({
                            contenu: reader.result.toString(),
                            itemId: Number(this.contexteService.Obtenir("itemId")),
                            date: this.dateDocument,
                            nomFichier: file.name,
                            typeDocument: this.selectionTypeDocument,
                            statut: this.sdb.Statut(EStatuts.Actif),
                            employe: this.profilService.ProfilCourant()
                        });
                    } else if (this.contexteService.ValiderPresence(["requisitionId"])) {
                        documents.push({
                            contenu: reader.result.toString(),
                            requisitionId: Number(this.contexteService.Obtenir("requisitionId")),
                            date: this.dateDocument,
                            nomFichier: file.name,
                            typeDocument: null,
                            statut: this.sdb.Statut(EStatuts.Actif),
                            employe: this.profilService.ProfilCourant()
                        });
                     }  else if (this.contexteService.ValiderPresence(["locationId"])) {
                        documents.push({
                            contenu: reader.result.toString(),
                            locationId: Number(this.contexteService.Obtenir("locationId")),
                            date: this.dateDocument,
                            nomFichier: file.name,
                            typeDocument: null,
                            statut: this.sdb.Statut(EStatuts.Actif),
                            employe: this.profilService.ProfilCourant()
                        });
                     }

                    resolve(true);
                }
            });

            promises.push(p);
        }

        Promise.all(promises).then(x => {

            if (this.contexteService.ValiderPresence(["itemId"])) {
               
                var requeteAjouterDocument: RequeteAjouterDocuments = {
                    typeLien: ETypeLienDocument.Item,
                    idLien: Number(this.contexteService.Obtenir("itemId")),
                    dossier: this.contexteService.Obtenir("dossier"),
                    documents: documents
                };
    
                this.documentService.AjouterDocumentsItem(requeteAjouterDocument).then(res => {
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Les documents ont été ajoutés.' });
                    this.fileUploadControl.clear();
                    this.Debloquer();
                });

            } else if (this.contexteService.ValiderPresence(["requisitionId"])) {
               
                var requeteAjouterDocument: RequeteAjouterDocuments = {
                    typeLien: ETypeLienDocument.Requisition,
                    idLien: Number(this.contexteService.Obtenir("requisitionId")),
                    dossier: null,
                    documents: documents
                };
    
                this.documentService.AjouterDocumentsRequisition(requeteAjouterDocument).then(res => {
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Les documents ont été ajoutés.' });
                    this.fileUploadControl.clear();
                    this.Debloquer();
                });

            } else if (this.contexteService.ValiderPresence(["locationId"])) {
               
                var requeteAjouterDocument: RequeteAjouterDocuments = {
                    typeLien: ETypeLienDocument.Location,
                    idLien: Number(this.contexteService.Obtenir("locationId")),
                    dossier: null,
                    documents: documents
                };
    
                this.documentService.AjouterDocumentsLocation(requeteAjouterDocument).then(res => {
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Les documents ont été ajoutés.' });
                    this.fileUploadControl.clear();
                    this.Debloquer();
                });

            } else {
                this.routerService.navigateByUrl('error');
            }

            
            
        });
    }



    valider(): boolean {


        if (!this.dateDocument && !this.selectionTypeDocument) {
            return false;
        }

        if (this.modeDocument == ETypeLienDocument.Item && !this.selectionTypeDocument) {
            return false;
        }

        return true;
    }
}
