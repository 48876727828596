<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>{{emplacement.nom}} ({{emplacement.code}})
				<span [class]="'statut ' + emplacement.statut.nom.toLowerCase()">
					{{emplacement.statut.nom}}
				</span>
			</h5>


			<p-menubar [model]="menu">
				<p-button
					*ngIf="estAutorise('emplacement_modifier') && modeConsultation() && emplacement.statut.statutId != 2"
					(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success p-mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView orientation="left" (onChange)="surChangementOnglet($event)">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<!-- <div class="p-col-12 p-text-right">
						<p-button label="Modifier" icon="pi pi-pencil" iconPos="left"></p-button>
					</div> -->
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-12 p-lg-3">
							<label for="code">Code</label>
							<input id="code" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.code">
							<small class="p-error" *ngIf="submitted && !emplacement.code">Le code
								est
								obligatoire.</small>
						</div>
						<div class="p-field p-col-12 p-md-12 p-lg-9">
							<label for="nom">Nom</label>
							<input id="nom" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.nom">
							<small class="p-error" *ngIf="submitted && !emplacement.nom">Le nom
								est
								obligatoire.</small>
						</div>
					</div>
					<h5 style="display: inline-block;">Adresse</h5>
					<i *ngIf="!modeConsultation()" class="pi pi-search p-ml-3" style="font-size: 1rem"
						(click)="rechercherAdresse($event)"></i>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-9 p-lg-9">
							<label for="nom">Nom</label>
							<i *ngIf="!modeConsultation()" class="pi pi-copy p-ml-3" style="font-size: 1rem"
								(click)="copierDescription()"></i>
							<input id="nom" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.nom">
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-3 p-lg-2">
							<label for="numero">Numéro</label>
							<input id="numero" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.numero">
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-7">
							<label for="rue">Rue</label>
							<input id="rue" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.rue">
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-6">
							<label for="municipalite">Ville</label>
							<input id="municipalite" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.municipalite">
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-3 p-lg-3">
							<label for="province">Province</label>
							<input id="province" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.province">
						</div>
						<div class="p-field p-col-12 p-md-3 p-lg-3">
							<label for="pays">Pays</label>
							<input id="pays" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.pays">
						</div>
						<div class="p-field p-col-12 p-md-3 p-lg-3">
							<label for="codePostal">Code Postal</label>
							<input id="codePostal" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.codePostal">
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-3 p-lg-3">
							<label for="telephone">Téléphone</label>
							<input id="telephone" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="emplacement.adresse.telephone">
						</div>
					</div>
				</p-tabPanel>
				<p-tabPanel *ngIf="estAutorise('emplacement_inventaire') " header="Inventaire" leftIcon="pi pi-list">
					<p-menu #menuContextuelInventaire popup="popup" [model]="contenuMenuContextuelInventaire"></p-menu>

					<p-card *ngIf="!chargementCompleteInventaire">
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>


					<p-table *ngIf="chargementCompleteInventaire" #dtInventaire [value]="inventaire" dataKey="itemId"
						tableStyleClass="tableNormale" autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
						[rows]="100" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100,500]"
						[rowHover]="true" [filterDelay]="0" [globalFilterFields]="['numero','quantite','description']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtInventaire.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>

								</div>
								<h5 class="titreTableau p-m-0">Inventaire</h5>
								<div>
									<button pButton pRipple icon="pi pi-file-pdf"
										class="p-button-secondary p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="exportExcel()"></button>
										<p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
										optionLabel="entete" (onChange)="surChangementColonnes($event)"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Numéro
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th>
									<div class="p-d-flex p-jc-between p-ai-center">
										Statut
										<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
											[showOperator]="false" [showAddButton]="false">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
													(onChange)="filter($event.value)" optionLabel="name">
													<ng-template let-option pTemplate="item">
														<div class="p-multiselect-representative-option">
		
															<span class="p-ml-1">{{option.nom}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="categorie.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Catégorie
										<p-sortIcon field="categorie.nom"></p-sortIcon>
										<p-columnFilter field="categorie.nom" matchMode="in" display="menu"
											[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect [ngModel]="value" [options]="filtreCategories" placeholder="Any"
													(onChange)="filter($event.value)" optionLabel="name">
													<ng-template let-option pTemplate="item">
														<div class="p-multiselect-representative-option">
															<span class="p-ml-1">{{option}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('sousCategorie')" pSortableColumn="sousCategorie.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Sous-catégorie
										<p-sortIcon field="sousCategorie.nom"></p-sortIcon>
										<p-columnFilter field="sousCategorie.nom" matchMode="in" display="menu"
										[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="filtreSousCategories" placeholder="Any"
												(onChange)="filter($event.value)" optionLabel="name">
												<ng-template let-option pTemplate="item">
													<div class="p-multiselect-representative-option">
														<span class="p-ml-1">{{option}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="marque">
									<div class="p-d-flex p-jc-between p-ai-center">
										Marque
										<p-sortIcon field="marque"></p-sortIcon>
										<p-columnFilter type="text" field="marque" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
									<div class="p-d-flex p-jc-between p-ai-center">
										Modèle
										<p-sortIcon field="modele"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('noCtrl')" pSortableColumn="noCtrl">
									<div class="p-d-flex p-jc-between p-ai-center">
										No Ctrl
										<p-sortIcon field="noCtrl"></p-sortIcon>
										<p-columnFilter type="text" field="noCtrl" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('compagnie')" pSortableColumn="compagnie.nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Compagnie
										<p-sortIcon field="compagnie.nom"></p-sortIcon>
										<p-columnFilter field="compagnie.nom" matchMode="in" display="menu"
										[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="filtreCompagnies" placeholder="Any"
												(onChange)="filter($event.value)" optionLabel="name">
												<ng-template let-option pTemplate="item">
													<div class="p-multiselect-representative-option">
														<span class="p-ml-1">{{option}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="quantite">
									<div class="p-d-flex p-jc-between p-ai-center">
										Quantité
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="numereic" field="quantite" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<ng-container *ngFor="let col of colonnesSelectionnees">
									<th *ngIf="!col.base">
										<div class="p-d-flex p-jc-between p-ai-center">
											{{col.entete}}
										</div>
									</th>
								</ng-container>

								<th style="min-width: 9rem; max-width: 9rem"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>

							<tr>
								<td>{{item.numero}}
								</td>
								<td>{{item.description}}</td>
								<td><span [class]="'statut ' + item.statut.nom.toLowerCase()">
										{{item.statut.nom}}
									</span>
								</td>
								<td *ngIf="EstColonneSelectionnee('categorie')">
									{{item.categorie.nom}}
								</td>
								<td *ngIf="EstColonneSelectionnee('sousCategorie')">
									{{item.sousCategorie?.nom}}
								</td>
								<td *ngIf="EstColonneSelectionnee('marque')">
									{{item.marque}}
								</td>
								<td *ngIf="EstColonneSelectionnee('modele')">
									{{item.modele}}
								</td>
								<td *ngIf="EstColonneSelectionnee('noCtrl')">
									{{item.noCtrl}}
								</td>
								<td *ngIf="EstColonneSelectionnee('compagnie')">
									{{item.compagnie?.nom}}
								</td>
								<td>{{item.quantite}}</td>
								<ng-container *ngFor="let col of colonnesSelectionnees">
									<td *ngIf="!col.base">
										{{obtenirValeurAttribut(item, col.champ)}}
									</td>
								</ng-container>
								<td>
									<button *ngIf="estAutorise('item_detail')" pButton pRipple icon="pi pi-external-link"
                                class="p-button-secondary p-mr-2 boutonGrille"
                                (click)="OuvrirDetailItemNouvelOnglet(item.itemId)"></button>
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="surMenuContextuelInventaire($event, item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucune garantie.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>

				<p-tabPanel *ngIf="emplacement.statut.statutId != 2"
					header="Responsables" leftIcon="pi pi-users">
					<p-menu #menuContextuelInventaire popup="popup" [model]="contenuMenuContextuelInventaire"></p-menu>

					<p-card *ngIf="!chargementCompleteResponsables">
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>


					<p-table *ngIf="chargementCompleteResponsables" #dtResponsables [value]="responsables"
						dataKey="employeId" tableStyleClass="tableNormale" autoLayout="true"
						styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['nom','prenom','courriel']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtResponsables.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />

									</span>
									<button *ngIf="estAutorise('emplacement_modifier')" pButton pRipple
										icon="pi pi-plus" class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="ajouterResponsable()"></button>

								</div>
								<h5 class="titreTableau p-m-0">Responsables</h5>
								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="prenom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Prénom
										<p-sortIcon field="prenom"></p-sortIcon>
										<p-columnFilter type="text" field="prenom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Nom
										<p-sortIcon field="nom"></p-sortIcon>
										<p-columnFilter type="text" field="nom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="courriel">
									<div class="p-d-flex p-jc-between p-ai-center">
										Courriel
										<p-sortIcon field="courriel"></p-sortIcon>
										<p-columnFilter type="text" field="courriel" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th style="min-width: 5rem; width: 5rem"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-employe>

							<tr>
								<td>{{employe.prenom}}</td>
								<td>{{employe.nom}}</td>
								<td>{{employe.courriel}}</td>
								<td>
									<button *ngIf="estAutorise('emplacement_modifier')" pButton pRipple
										icon="pi pi-trash" class="p-button-danger p-mr-2 boutonGrille"
										(click)="supprimerResponsable(employe)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucun responsable.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>

				<p-tabPanel *ngIf="emplacement.statut.statutId != 2"
					header="Chargés de projet" leftIcon="pi pi-users">
				

					<p-card *ngIf="!chargementCompleteChargesProjet">
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-text-center">
								<p-progressSpinner></p-progressSpinner>
							</div>
						</div>
					</p-card>


					<p-table *ngIf="chargementCompleteChargesProjet" #dtChargesProjet  [value]="chargesProjet"
						dataKey="employeId" tableStyleClass="tableNormale" autoLayout="true"
						styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['nom','prenom','courriel']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtChargesProjet.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />

									</span>
									<button *ngIf="estAutorise('emplacement_modifier')" pButton pRipple
										icon="pi pi-plus" class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="ajouterChargeProjet()"></button>

								</div>
								<h5 class="titreTableau p-m-0">Chargés de projet</h5>
								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="prenom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Prénom
										<p-sortIcon field="prenom"></p-sortIcon>
										<p-columnFilter type="text" field="prenom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Nom
										<p-sortIcon field="nom"></p-sortIcon>
										<p-columnFilter type="text" field="nom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="courriel">
									<div class="p-d-flex p-jc-between p-ai-center">
										Courriel
										<p-sortIcon field="courriel"></p-sortIcon>
										<p-columnFilter type="text" field="courriel" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th style="min-width: 5rem; width: 5rem"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-employe>

							<tr>
								<td>{{employe.prenom}}</td>
								<td>{{employe.nom}}</td>
								<td>{{employe.courriel}}</td>
								<td>
									<button *ngIf="estAutorise('emplacement_modifier')" pButton pRipple
										icon="pi pi-trash" class="p-button-danger p-mr-2 boutonGrille"
										(click)="supprimerChargeProjet(employe)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucun chargé de projet.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>

			</p-tabView>
		</div>
	</div>


</div>

<!-- 

<p-dialog [(visible)]="dialogueAjustementInventaire" [style]="{width: '40%'}" header="Ajustement d'inventaire"
	[modal]="true" [contentStyle]="{'overflow': 'visible'}" styleClass="p-fluid p-formgrid p-grid">
	<ng-template pTemplate="content">

		<div class="p-formgrid p-grid">
			<div class="p-field p-col-12 p-lg-8">
				<label for="noFournisseur">Quantité</label>
				<p-inputNumber id="noFournisseur">
				</p-inputNumber>
			</div>
		</div>

	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
			(click)="hideDialog()"></button>
		<button pButton pRipple label="Ajuster" icon="pi pi-check" class="p-button-text"
			(click)="ajusterInventaire()"></button>
	</ng-template>
</p-dialog> -->

<p-sidebar [(visible)]="afficherDetailItem" [style]="{width:'95%'}" position="left">
    <app-detail-item [itemId]="detailItemId" *ngIf="afficherDetailItem"></app-detail-item>
</p-sidebar>