<p-toast key="detailEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-6">
			<label for="note">Description</label>
			<input #champDescription id="note" type="text" pInputText [(ngModel)]="item.description" (keyup.enter)="enregistrer()" > 
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="lastname">Code de catégorie</label>
            <p-dropdown [options]="listeCategories" [(ngModel)]="item.categorie"
                optionLabel="description" [showClear]="true" filter="true" filterBy="code,description" appendTo="body"
                placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="item.categorie">
                        <div>{{item.categorie.code}} - {{item.categorie.description}}
                        </div>
                    </div>
                </ng-template>
                <ng-template let-categorie pTemplate="item">
                    <div>
                        <div>{{categorie.code}} - {{categorie.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
       <!--  <div class="p-field p-col-12 p-md-6">
            <label for="note">Note</label>
            <input id="note" type="text" pInputText [(ngModel)]="pieceUnite.note">
        </div> -->
    </div>

  
</div>


<div class="p-text-right">

    <button pButton pRipple label="Enregistrer" icon="pi pi-save" class="p-button-primary"
        (click)="enregistrer()"></button>
    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

</div>