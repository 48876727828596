import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { CategorieService } from 'src/app/services/categorie-service';
import { AttributEntite } from 'src/app/entites/attribut';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { SousCategorieService } from 'src/app/services/sous-categorie-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { DetailSousCategorieComponent } from './controles/detail-sous-categorie.component';
import { FusionSousCategorieComponent } from './controles/fusion-sous-categorie.component';

@Component({
    templateUrl: './detail-categorie.component.html',
    styleUrls: ['./detail-categorie.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailCategorieComponent extends BaseComponent implements OnInit {

    categorieId: number;
    categorie: CategorieEntite;

    submitted: boolean;

    sousCategories: SousCategorieEntite[];
    selectionSousCategorie: SousCategorieEntite;
    contenuMenuContextuelSousCategorie: MenuItem[];
    @ViewChild('menuContextuelSousCategorie') menuContextuelSousCategorie: ContextMenu;

    menu: MenuItem[];

    loaded:boolean = false;

    @ViewChild('dt') table: Table;

    @ViewChild('nomAttribut') champNom: ElementRef;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private categorieService: CategorieService,
        private sousCategorieService: SousCategorieService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Détail de la catégorie' }
        ]);

        
        this.route.params.subscribe(params => this.categorieId = params.id);
    }

    ngOnInit() {

           this.categorieService.Obtenir(this.categorieId).then(x => {
               this.categorie = x.valeur;

               this.sousCategorieService.ObtenirListeParCategorie(this.categorieId).then(y => {

                this.sousCategories = y.valeur;
                
                this.CompleterChargement();

               });

           });

           this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('pilotage/categories')
            }
        ];

      /*   this.menuContextuel = [
            { label: 'Ouvrir', icon: 'pi pi-fw pi-search', command: () => this.OuvrirDetail(this.selectionCategorie.categorieId) },
            {separator: true},
            { label: 'Archiver', icon: 'pi pi-fw pi-times'}
        ]; */

       
    }

    btnModifier_click() {
        this.Modifier();
    }

    btnAnnuler_click() {
        this.Consulter();
    }


    
    surMenuContextuelSousCategorie(event, sousCategorie: SousCategorieEntite) {

        this.contenuMenuContextuelSousCategorie = [
            { 
                label: 'Modifier', 
                icon: 'pi pi-fw pi-pencil',
                command: () => this.modifierSousCategorie(sousCategorie)
            },
            {separator: true},
            { 
                label: 'Fusionner', 
                icon: 'pi pi-fw pi-times', 
                command: () => this.fusionnerSousCategorie(sousCategorie)
            },
            { 
                label: 'Supprimer', 
                icon: 'pi pi-fw pi-times', 
                command: () => this.supprimerSousCategorie(sousCategorie)
            }
        ];

        this.menuContextuelSousCategorie.toggle(event);
    }

    ajouterSousCategorie() {
        const ref = this.dialogService.open(DetailSousCategorieComponent, {
            data: {
                categorieId: this.categorieId
            },
            header: 'Ajouter une sous-catégorie',
            width: '95%'
        }).onClose.subscribe((res: SousCategorieEntite) => {
            if (res) {
                this.sousCategories.push(res)
                this.sdb.ReinitialiserSousCategories();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Sous-catégorie ajoutée.` });
            }
        });
    }

    modifierSousCategorie(sousCategorie: SousCategorieEntite) {
        const ref = this.dialogService.open(DetailSousCategorieComponent, {
            data: {
                categorieId: this.categorieId,
                sousCategorieId: sousCategorie.sousCategorieId
            },
            header: 'Modifier une sous-catégorie',
            width: '95%'
        }).onClose.subscribe((res: SousCategorieEntite) => {
            if (res) {
                sousCategorie.nom = res.nom;
                sousCategorie.prefixe = res.prefixe;
                this.sdb.ReinitialiserSousCategories();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Sous-catégorie modifiée.` });
            }
        });
    }

    supprimerSousCategorie( sousCategorie: SousCategorieEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'ATTENTION: Tous les items présents dans cette sous catégorie se retrouveront sans sous-catégorie. Voulez-vous vraiment supprimer la sous-catégorie sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.sousCategorieService.Supprimer(sousCategorie.sousCategorieId).then(res => {
                    if (res.estUnSucces) {
                        this.sousCategories = this.sousCategories.filter(x => x.sousCategorieId != sousCategorie.sousCategorieId);
                        this.sdb.ReinitialiserSousCategories();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Sous-catégorie supprimée.` });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    fusionnerSousCategorie( sousCategorie: SousCategorieEntite) {
        const ref = this.dialogService.open(FusionSousCategorieComponent, {
            data: {
                categorieId: this.categorieId,
                idSource: sousCategorie.sousCategorieId,
                nom: sousCategorie.nom
            },
            header: 'Fusionner une sous-catégorie',
            width: '95%'
        }).onClose.subscribe((res: SousCategorieEntite) => {
            if (res) {
                this.sousCategories = this.sousCategories.filter(x => x.sousCategorieId != sousCategorie.sousCategorieId);
                this.sdb.ReinitialiserSousCategories();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Sous-catégorie fusionnée.` });
            }
        });
    }


}
