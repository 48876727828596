<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuel popup="popup" [model]="contenuMenuContextuel"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">
            <p-table #dt [value]="listes" dataKey="listeVerificationId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionModele"
                [rowHover]="true" [rows]="25" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['nom']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                                <button pButton pRipple icon="pi pi-plus"
                                class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                                (click)="Ajouter()"></button>
                        </div>
                        <h5 class="titreTableau p-m-0">Listes de vérification</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                            
                        </th>
                        <th pSortableColumn="description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                            
                        </th>
                        <th pSortableColumn="nbPoints">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nb points
                                <p-sortIcon field="nbPoints"></p-sortIcon>
                                <p-columnFilter type="text" field="nbPoints" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                            
                        </th>
                        <th style="min-width: 7rem; width: 7rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-liste>
                    <tr>
                        <td>{{liste.nom}}</td>
                        <td>{{liste.description}}</td>
                        <td>{{liste.nbPoints}}</td>
                       
                        <td>
      
                                <button *ngIf="estAutorise('temp_mec')" pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetail(liste)"></button>

                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary p-mr-2 boutonGrille"
                                (click)="surMenuContextuelItem($event, liste)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucune liste.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>