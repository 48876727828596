import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur } from '../../entites/cleValeur';
import { ItemMin } from '../../entites/item-min';
import { Statut } from '../../entites/statut';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { BaseComponent } from '../../base/base-component';
import { ItemService } from '../../services/item-service';
import { FournisseurService } from '../../services/fournisseur-service';
import { EmployeEntite } from '../../entites/employe';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ProfilService } from '../../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequisitionService } from '../../services/requisition-service';
import { AppMainComponent } from '../../app.main.component';
import { CodeProjetEntite } from '../../entites/code-projet';
import { CodeActiviteEntite } from '../../entites/code-activite';
import { CodeCategorieEntite } from '../../entites/code-categorie';
import { CodeEquipementEntite } from '../../entites/code-equipement';
import { CodeExtraEntite } from '../../entites/code-extra';
import { PieceService } from 'src/app/services/piece-service';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';

export interface ParametresSelectionItem {
    uniteId: number;
}

export interface ResultatSelectionItem {
    item?: ItemMin;
    quantiteDemande?: number;
    expediteur?: string;
    demandeur?: EmployeEntite;
    noFournisseur?: string;
    prix?: number;
    note?: string;
    codeProjet?: CodeProjetEntite;
    codeActivite?: CodeActiviteEntite;
    codeCategorie?: CodeCategorieEntite;
    codeEquipement?: CodeEquipementEntite;
    codeExtra?: CodeExtraEntite;
    dateRequis?: Date;
    dateDemande?: Date;

}

@Component({
    selector: 'app-selection-piece',
    templateUrl: './selection-piece.component.html'
})
export class SelectionPieceComponent extends BaseComponent {

    //Général
    donnees: ParametresSelectionItem;

    typesSaisie: CleValeur[];
    selectionTypeSaisie: CleValeur;
    estTypeSaisieDisponible: boolean = true;

    submitted: boolean;
    blocked: boolean = false;

    items: ItemMin[];
    selectionItem: ItemMin = { itemId: 0 };
    categories: string[];
    statuts: Statut[] = this.sdb.Statuts();

    pieces: PieceUniteEntite[];

    itemSelectionne: boolean = false;

    afficherColonneNumero: boolean = true;
    afficherColonneStatut: boolean = true;
    afficherColonneInventaire: boolean = true;
    afficherColonneNumeroEmplacement: boolean = true;



    //Mode bon de commande

    noFournisseur: string;
    quantiteDemande: number;
    prix: number;
    note: string;


    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('champQuantite') inputQuantite;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        public itemService: ItemService,
        public requisitionService: RequisitionService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public pieceService: PieceService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.typesSaisie = [
            { "cle": "P", "valeur": "Pièces" },
            { "cle": "I", "valeur": "Inventorié" },
            { "cle": "N", "valeur": "Nouveau" },
            { "cle": "D", "valeur": "Divers" }
        ];
        this.selectionTypeSaisie = this.typesSaisie[0];

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: true },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
            { champ: 'noProduit', entete: 'N pièce fournisseur', afficheParDefaut: true }
        ];
      
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);


        var p1 = new Promise<number>((resolve, reject) => {
            this.itemService.ObtenirItems().then(x => {
                //TODO: Hard-codé catégorie 6...
                this.items = x.valeur.filter(x => x.categorie.categorieId == 6);
                resolve(null);
            });
        });

        
        var p2 = new Promise<number>((resolve, reject) => {
            this.pieceService.ObtenirParUnite(this.donnees.uniteId).then(x => {
                this.pieces = x.valeur;
                resolve(null);
            });
        });

        Promise.all([p1, p2]).then(x => {
            this.CompleterChargement();
            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
        });

        
    }


    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.surSelectionItemInventaire(this.tableItems.filteredValue[0]);
        }

    }

    selectionner(close: boolean) {

        this.submitted = true;

        if (!this.validerSelection()) {
            this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        //TODO : Gérer la vérification d'inventaire.
        this.blocked = true;

        var retour: ResultatSelectionItem = {
        }

        if (this.selectionTypeSaisie.cle == 'P') {

            retour.item = this.selectionItem;
            this.completerRetour(retour)

            this.blocked = false;
            this.ref.close(retour);

            
        } else if (this.selectionTypeSaisie.cle == 'I') {

            retour.item = this.selectionItem;
            this.completerRetour(retour)

            this.blocked = false;
            this.ref.close(retour);

            
        } else if (this.selectionTypeSaisie.cle == 'N') {

            //TODO : ajustement d'inventaire
            
            this.itemService.CreerItem({
                "itemId": 0,
                "categorie": {
                    "categorieId": 6,
                    "nom": "",
                    "estSuivi": false,
                    "estUnique": false
                },
                "description": this.selectionItem.description
            }).then(x => {
                if (x.estUnSucces) {

                    this.itemService.AjusterInventaire({
                        employe: this.profilService.ProfilCourant(),
                        itemId: x.valeur.itemId,
                        emplacementId: 10000,
                        date: new Date(),
                        ajustement: this.quantiteDemande
                    }).then(resAjustInv => {
                        if (resAjustInv.estUnSucces) {
                            
                            retour.item = this.itemService.ItemVersItemMin(x.valeur);
                            this.completerRetour(retour)

                            this.blocked = false;
                            this.ref.close(retour);
                            
                        } else {
                            x.messages.forEach(x => {
                                this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                            });
                        }
                    });

                    
                } else {
                    //TODO : Gérer l'erreur
                    x.messages.forEach(x => {
                        this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                    });

                }

            });
        } else if (this.selectionTypeSaisie.cle == 'D') {

            //TODO : le 5 hard codé
            this.itemService.CreerItem({
                "itemId": 0,
                "categorie": {
                    "categorieId": 5,
                    "nom": "",
                    "estSuivi": false,
                    "estUnique": false
                },
                "description": this.selectionItem.description
            }).then(x => {
                if (x.estUnSucces) {
                    retour.item = this.itemService.ItemVersItemMin(x.valeur);
                    this.completerRetour(retour)

                    this.blocked = false;
                    this.ref.close(retour);
                } else {
                    //TODO : Gérer l'erreur
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });

                }

            });
        }

    }

    completerRetour(retour: ResultatSelectionItem) {
        retour.quantiteDemande = this.quantiteDemande;
        retour.noFournisseur = this.noFournisseur;
        retour.prix = this.prix;
        retour.note = this.note;
    }

    validerSelection() {

        if (this.selectionTypeSaisie.cle == 'I') {
            return this.quantiteDemande;
        } else if (this.selectionTypeSaisie.cle == 'N') {
            return this.selectionItem.description
                && this.quantiteDemande;
        } else if (this.selectionTypeSaisie.cle == 'D') {
            return this.selectionItem.description
                && this.quantiteDemande;
        }

        return true;
    }

    annuler() {
        this.ref.close(null);
    }

    typeSaisieInventaire(): boolean {
        return this.selectionTypeSaisie.cle == 'I';
    }

    typeSaisiePiece(): boolean {
        return this.selectionTypeSaisie.cle == 'P';
    }


    RechercherItem() {
        this.selectionItem = null;

        this.quantiteDemande = null;

        this.itemSelectionne = false;
    }

    surSelectionItemInventaire(item: ItemMin) {

        this.selectionItem = item;

        this.blocked = true;

        //TODO : TEP en attendant d'Aller chercher le prix le plus bas.
        this.itemSelectionne = true;
        setTimeout(() => this.inputQuantite.input.nativeElement.focus(), 50)
        this.blocked = false;


       /*  this.blocked = true;

            this.fournisseurService.ObtenirListePrix(this.donnees.fournisseurId, this.selectionItem.itemId).then(res => {
                if (res.estUnSucces) {
                    var prixCourant = res.valeur.filter(x => !x.dateFin);
                    if (prixCourant && prixCourant.length > 0) {
                        this.noFournisseur = prixCourant[0].numeroFournisseur;
                        this.prix = prixCourant[0].prix;
                    }
                }

                this.itemSelectionne = true;
                setTimeout(() => this.inputQuantite.input.nativeElement.focus(), 50)
                this.blocked = false;

            }); */
       

    }

    surSelectionItemPiece(piece: PieceUniteEntite) {

        this.selectionItem = piece.piece;

        this.itemSelectionne = true;
        setTimeout(() => this.inputQuantite.input.nativeElement.focus(), 50)
    }
}