<p-blockUI [blocked]="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>
<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Complétez la liste d'achat.
        </ng-template>
        <ng-template pTemplate="content">
            <p-table #dt *ngIf="EstCharge()" [value]="listeAchat.itemsListeAchat" dataKey="entreeId" tableStyleClass="tableMoyen" autoLayout="true"
            [globalFilterFields]="['quantite','item.numero','item.description']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header">
                    <tr>
                        <th>Quantité</th>
                        <th>Numéro</th>
                        <th>Description</th>
                        <!-- <th>Code projet</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entree>
                    <tr>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <!-- <input pInputText type="text" [(ngModel)]="entree.quantite"> -->
                                    <p-inputNumber (onInput)="surSaisie($event, entree)" [(ngModel)]="entree.quantite"></p-inputNumber>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{entree.quantite}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                          {{entree.item.numero}}
                        </td>
                        <td>
                          {{entree.item.description}}
                        </td>
                        <!-- <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <p-dropdown [options]="codesProjet" appendTo="body" [(ngModel)]="entree.codeProjet" optionLabel="code" [showClear]="true" 
									filter="true" filterBy="code,nom"
									placeholder="Sélectionner un code de projet...">
									<ng-template pTemplate="selectedItem">
										<div *ngIf="entree.codeProjet">
											<div>{{entree.codeProjet?.nom}} ({{entree.codeProjet?.code}})</div>
										</div>
									</ng-template>
									<ng-template let-codeprojet pTemplate="item">
										<div>
											<div>{{codeprojet?.nom}} ({{codeprojet?.code}})</div>
										</div>
									</ng-template>
								</p-dropdown>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{entree.codeProjet?.nom}} ({{entree.codeProjet?.code}})
                                </ng-template>
                            </p-cellEditor>
                        </td> -->
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="p-grid p-nogutter p-justify-end">
                <p-button label="Suivant" (onClick)="nextPage()"  icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
         
    </p-card>
</div>