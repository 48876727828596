import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlockUIModule } from 'primeng/blockui';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationEntite } from 'src/app/entites/location';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { AchatService } from 'src/app/services/achat-service';
import { ReparationService } from 'src/app/services/reparation-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { GarantieService } from 'src/app/services/garantie-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { SelectionDelaiComponent } from 'src/app/controles/selection-delai.component';
import { LocationService } from 'src/app/services/location-service';

export interface ParametresConfirmerDebutLocation {
    location: LocationEntite;
}

export interface ResultatConfirmerDebutLocation {
    location: LocationEntite;
}

@Component({
    templateUrl: './confirmer-debut-location.component.html'
})
export class ConfirmerDebutLocationComponent extends BaseComponent {

    donnees: ParametresConfirmerDebutLocation;

    location: LocationEntite;

    submitted: boolean;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public achatService: AchatService,
        public reparationService: ReparationService,
        public fournisseurService: FournisseurService,
        private garantieService: GarantieService,
        private dialogService: DialogService,
        private locationService: LocationService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.location = this.donnees.location;

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    sauvegarder() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'debutLocation', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.locationService.ConfirmerDebutLocation(this.location).then(res => {
            this.Debloquer();

            if (res.estUnSucces) {
                this.location.statut = res.valeur.statut;
                this.ref.close({
                    location: this.location
                });
            } else {
               
                res.messages.forEach(x => {
                    this.messageService.add({ key: 'debutLocation', severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }

    valider() {
        return this.location.dateDebut && 
        (this.location.heuresDebut || this.location.heuresDebut == 0);
    }
}