import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReparationEntite } from 'src/app/entites/reparation';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { ProfilService } from 'src/app/services/profil-service';
import { RetourReparationComponent } from 'src/app/controles/retour-reparation.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { ReparationComponent } from 'src/app/controles/reparation.component';
import { ReparationService } from 'src/app/services/reparation-service';
import { DefinitionColonne } from 'src/app/entites/definition-colonne';

@Component({
    templateUrl: './reparations.component.html',
    styleUrls: ['./reparations.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ReparationsComponent extends BaseComponent implements OnInit {

    items: ReparationEntite[];
    selectionItem: ReparationEntite;
    selectionMultipleItem: ReparationEntite[] = [];

    menu: MenuItem[];
    public listeFiltreCategories: string[];

    menuContextuelItems: MenuItem[];

    dialogueReparation: boolean = false;
    dateRetour: Date;


    itemsHistorique: ReparationEntite[];
    chargementCompleteHistorique: boolean = false;
    selectionHistorique: ReparationEntite;
    contenuMenuContextuelHistorique: MenuItem[];
    @ViewChild('menuContextuelHistorique') menuContextuelHistorique: ContextMenu;
    colonnesDisponiblesHistorique: DefinitionColonne[];
    colonnesSelectionneesHistorique: DefinitionColonne[];


    @ViewChild('dt') table: Table;
    @ViewChild('menuContxtuelItem') menuContxtuelItem: ContextMenu;

    @ViewChild('tabView') tabView;

    constructor(
        public sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private reparationService: ReparationService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);



        this.breadcrumbService.setItems([
            { label: 'Items' }
        ]);
    }


    ngOnInit() {

        this.verifierAccesPage('reparations');

        this.listeFiltreCategories = this.sdb.Categories().map(x => x.nom);

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: false },
            { champ: 'fournisseur', entete: 'Fournisseur', afficheParDefaut: true },
            { champ: 'dateEnvoi', entete: 'Date envoi', afficheParDefaut: true },
            { champ: 'dateRetour', entete: 'Date retour prévue', afficheParDefaut: true },
            { champ: 'nbJours', entete: 'Nb Jours', afficheParDefaut: true },
            { champ: 'emplacementRetour', entete: 'Emplacement retour', afficheParDefaut: false },
            { champ: 'indRemplace', entete: 'Remplacé', afficheParDefaut: false }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.colonnesDisponiblesHistorique = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: false },
            { champ: 'fournisseur', entete: 'Fournisseur', afficheParDefaut: true },
            { champ: 'dateEnvoi', entete: 'Date envoi', afficheParDefaut: true },
            { champ: 'dateRetour', entete: 'Date retour', afficheParDefaut: true }
        ];
        this.colonnesSelectionneesHistorique = this.colonnesDisponiblesHistorique.filter(x => x.afficheParDefaut);

        this.reparationService.ObtenirListe().then(x => {
            this.items = x.valeur;
            this.CompleterChargement();
        });

        this.menu = [
            {
                label: 'Imprimer (À venir)',
                icon: 'pi pi-fw pi-print',
                disabled: true
            },
            {
                label: 'Actions',
                icon: 'pi pi-fw pi-bars',
                items: [
                    {
                        label: 'Retour de réparation',
                        icon: 'pi pi-fw pi-plus-circle',
                        command: () => this.retourReparations(this.selectionMultipleItem),
                        disabled: true
                    }
                ]
            },
        ];

        this.menuContextuelItems = [

            {
                label: 'Voir l\'item',
                icon: 'pi pi-fw pi-id-card',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl(`item/${this.selectionItem.item.itemId}`)
            },
            {
                label: 'Voir fournisseur',
                icon: 'pi pi-fw pi-user',
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl(`fournisseur/${this.selectionItem.fournisseur.fournisseurId}`)
            },
            { separator: true, visible: this.estAutorise('reparation_retour') },
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('reparation_creer'),
                command: () => this.modifierReparation(this.selectionItem)
            },
            {
                label: 'Retour de réparation',
                icon: 'pi pi-fw pi-plus-circle',
                visible: this.estAutorise('reparation_retour'),
                command: () => this.retourReparation(this.selectionItem)
            },
        ];


    }

    clearFilters(table: Table) {
            this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.routerService.navigate(['items/reparations']));
    }

    EstColonneSelectionneeHistorique(champ: string): boolean {
        return this.colonnesSelectionneesHistorique.filter(x => x.champ == champ).length > 0;
    }

    surChangementOnglet(event) {
        this.gererChangementOnglet(event.index);
    }

    gererChangementOnglet(index: number) {

        
        if (this.tabView.tabs[index]._header.startsWith('Historique') && !this.chargementCompleteHistorique) {
            this.reparationService.ObtenirListeHistorique().then(x => {
                this.itemsHistorique = x.valeur;

                this.chargementCompleteHistorique = true;
            });
        }
        
    }
    
  
    surMenuContextuelItem(event, reparation: ReparationEntite) {
        this.selectionItem = reparation;
        this.menuContxtuelItem.toggle(event);
    }

    retourReparation(reparation: ReparationEntite) {
        this.retourReparations([reparation]);
    }

    retourReparations(reparations: ReparationEntite[]) {

        const ref = this.dialogService.open(RetourReparationComponent, {
            data: {
                reparations: reparations
            },
            header: 'Retour de réparation',
            width: '60%'
        }).onClose.subscribe((res: boolean) => {
            if (res) {
                this.items = this.items.filter(x => x.reparationId != this.selectionItem.reparationId);
                this.selectionItem = null;

                this.selectionMultipleItem.forEach(s => {
                    this.items = this.items.filter(x => x.reparationId != s.reparationId);
                });
                this.selectionMultipleItem = []

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Retour complété` });
            }


        });
    }

    modifierReparation(reparation: ReparationEntite) {

        const ref = this.dialogService.open(ReparationComponent, {
            data: {
                itemId: reparation.item.itemId,
                reparationId: reparation.reparationId
            },
            header: 'Modification d\'une réparation',
            width: '60%'
        }).onClose.subscribe((res: ReparationEntite) => {
            if (res) {
                var rep = this.items.find(x => x.reparationId == res.reparationId);
                if (rep) {
                    rep.statut = res.statut;
                    rep.fournisseur = res.fournisseur;
                    rep.emplacement = res.emplacement;
                    rep.dateEnvoi = res.dateEnvoi;
                    rep.dateRetourPrevue = res.dateRetourPrevue;
                    rep.dateRetour = res.dateRetour;
                    rep.nbJours = res.nbJours;
                    rep.indRemplace = res.indRemplace;
                }
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Réparation modifiée.' });
            }
        });

    }


    surMenuContextuelItemHistorique(event, reparation: ReparationEntite) {
        this.contenuMenuContextuelHistorique = [
            {
                label: 'Voir l\'item',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() => this.routerService.navigate(['item/' + reparation.item.itemId]))
            }
        ];

        this.menuContextuelHistorique.toggle(event);

    }
}
