import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Fournisseur } from '../../entites/fournisseur';
import { FournisseurService } from '../../services/fournisseur-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './ajout-fournisseur.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutFournisseurComponent extends BaseComponent implements OnInit {

    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;

    menuContextuelFournisseurs: MenuItem[];

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private FournisseurService: FournisseurService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Ajouter un fournisseur' }
        ]);
    }

    ngOnInit() {
    
           this.FournisseurService.ObtenirFournisseursCtrl().then(x => {
               this.fournisseurs = x.valeur;
               this.CompleterChargement();

               setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
           });

       
    }


    ajouterFournisseur(fournisseur: Fournisseur) {

        this.Bloquer();

        this.FournisseurService.CreerFournisseur(fournisseur).then(res => {

            this.Debloquer();

            if (res.estUnSucces) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le fournisseur a été ajouté.' });
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });


    }

}
