import { Component, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';
import { Fournisseur } from '../entites/fournisseur';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { FournisseurService } from '../services/fournisseur-service';
import { BoiteOutilEntite } from '../entites/boite-outil';
import { BoiteOutilService } from '../services/boite-outil-service';
import { DepartementEntite } from '../entites/departement';
import { Emplacement } from '../entites/emplacement';

export interface ParametresSelection {
    selection?: number;
}

@Component({
    templateUrl: './selection-emplacement.component.html',
})
export class SelectionEmplacementComponent extends BaseComponent {

    donnees: ParametresSelection;

    emplacements: Emplacement[];
    selectionEmplacement: Emplacement;

    submitted: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        private boiteOutilService: BoiteOutilService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }


    ngOnInit() {

        this.donnees = this.config.data;

        this.emplacements = this.sdb.Emplacements();

        if (this.donnees?.selection) {
            this.selectionEmplacement = this.emplacements.find(x => x.emplacementId == this.donnees.selection);
        }

        this.CompleterChargement();
    }

    confirmer() {

        this.submitted = true;

        if(!this.selectionEmplacement) {
            return;
        }
        this.ref.close(this.selectionEmplacement);
    }

    annuler() {
        this.ref.close(null);
    }
}