<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            
            <h5>Les utilisateurs sont gérés via la plateforme externe Auth0.</h5>

            <button pButton pRipple label="Gérer les utilisateurs" icon="pi pi-external-link" class="p-button-primary"
                (click)="gererUtilisateurs()"></button>

        </div>
    </div>
</div>