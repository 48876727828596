import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { CleValeur, CleValeurNumerique } from 'src/app/entites/cleValeur';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ERaisonBonTravail } from 'src/app/entites/enums/raison-bon-travail';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { RequeteAjouterEtapesBonTravail } from 'src/app/services/requetes/requete-ajouter-etapes-bon-travail';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { ItemService } from 'src/app/services/item-service';

export interface ParametresAssignerEntretiens {
    itemId: number;
    entretiensPreventifs: EntretienPreventifEntite[];
    entretiensCorrectifs: EntretienCorrectifEntite[];
    contreMaitreId?: number;
    employeId: number;
}

export interface ResultatAssignerEntretiens {
    employeId: number;
    date: Date;
}

@Component({
    templateUrl: './assigner-entretiens.component.html',
})
export class AssignerEntretiensComponent extends BaseComponent {

    donnees: ParametresAssignerEntretiens;

    typesBonTravail: CleValeurNumerique[];
    selectionTypeBonTravail: CleValeurNumerique;

    employes: EmployeEntite[];
    selectionContreMaitre: EmployeEntite;
    selectionMecanicien: EmployeEntite;

    emplacements: Emplacement[];
    selectionEmplacement: Emplacement;

    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;

    date: Date = new Date();

    types: CleValeur[];
    selectionType: CleValeur;

    bonsTravail: BonTravailEntite[];


    submitted: boolean;
    blocked: boolean = false;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        public sdb: DonneesBaseService,
        public bonTravailService: BonTravailService,
        public fournisseurService: FournisseurService,
        public itemService: ItemService,
        public ref: DynamicDialogRef,
        public messageService: MessageService,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.types = [
            { "cle": "E", "valeur": "Bon de travail existant" },
            { "cle": "N", "valeur": "Nouveau bon de travail" }
        ];
        this.selectionType = this.types[0];

        this.typesBonTravail = [
            { "cle": ETypeBonTravail.Interne, "valeur": "Interne" },
            { "cle": ETypeBonTravail.Externe, "valeur": "Externe" }
        ];
        this.selectionTypeBonTravail = this.typesBonTravail[0];


        this.emplacements = this.sdb.Emplacements();
        

        this.employes = this.sdb.Employes();
        this.employes.forEach(emp => {
            emp.prenomNom = `${emp.prenom} ${emp.nom}`;
        });

        /* if (this.donnees && this.donnees.employeId) {
            this.selectionMecanicien = this.employes.find(x => x.employeId == this.donnees.employeId);
        } */

        if (this.donnees.contreMaitreId) {
            this.selectionContreMaitre = this.employes.find(x => x.employeId == this.donnees.contreMaitreId);
        } else {
            this.selectionContreMaitre = this.employes.find(x => x.employeId == this.donnees.employeId);
        }

        var p1 = new Promise<number | void>((resolve, reject) => {
            this.bonTravailService.ObtenirOuvertsParItem(this.donnees.itemId).then(x => {
                this.bonsTravail = x.valeur;
                resolve();
            });
        });

        var p2 = new Promise<number | void>((resolve, reject) => {
            this.fournisseurService.ObtenirFournisseurs().then(x => {
                this.fournisseurs = x.valeur;
                
                resolve();
            });
        });

        var p2 = new Promise<number | void | void>((resolve, reject) => {
            if (this.donnees.itemId) {
                this.itemService.ObtenirItem(this.donnees.itemId).then(item => {
                    var contreMaitre = null;
                    if (item.valeur.emplacement) {
                        this.selectionEmplacement = this.emplacements.find(x => x.emplacementId == item.valeur.emplacement.emplacementId);
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });

        /* var p2 = new Promise<number>((resolve, reject) => {
            this.releveKmService.ObtenirListeParItem(this.itemId).then(x => {
                this.item.relevesKm = x.valeur;
                resolve();
            });
        });
 */

        Promise.all([p1,p2]).then(x => {
            if (this.bonsTravail.length == 0) {
                this.selectionType = this.types[1];

            }
            this.CompleterChargement();
        });
    }

    assigner() {

        this.submitted = true;
        this.messageService.clear();

        if (!this.valider()) {
            this.messageService.add({ key: 'assignerEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        console.log('z');
        console.log(this.selectionFournisseur);

        var bonTravail: BonTravailEntite = {
            bonTravailId: 0,
            dateTravail: this.date,
            dateCreation: new Date(),
            type: this.selectionTypeBonTravail.cle,
            mecanicien: this.selectionMecanicien,
            contreMaitre: this.selectionContreMaitre,
            fournisseur: this.selectionFournisseur,
            emplacement: this.selectionEmplacement,
            raison: this.sdb.RaisonBonTravail(ERaisonBonTravail.EntretienPreventif),
            item: { itemId: this.donnees.itemId }
        };

        if (this.selectionMecanicien) {
            bonTravail.statut = this.sdb.Statut(EStatuts.EnCours);
        } else {
            bonTravail.statut = this.sdb.Statut(EStatuts.EnAttente);
        }

        this.bonTravailService.Creer(bonTravail).then(x => {
            console.log(x);
            if (x.estUnSucces) {

                var etapes: EtapeBonTravailEntite[] = [];

                var requete: RequeteAjouterEtapesBonTravail = {
                    bonTravailId: x.valeur.bonTravailId,
                    etapesPreventif: [],
                    etapesCorrectif: []
                }

                console.log('K');
                console.log(requete);
                console.log(requete.etapesCorrectif);

                if (this.donnees.entretiensPreventifs) {
                    this.donnees.entretiensPreventifs.forEach(ent => {
                        var etape: EtapeBonTravailEntite = {
                            etapeBonTravailId: 0,
                            entretienPreventif: ent,
                            categorie: ent.categorie,
                            description: ent.description,
                            indAFaire: true,
                            indComplete: false,
                            typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienPreventif)
                        }
    
                       
                        requete.etapesPreventif.push(etape);
                    });
                } else {
                    this.donnees.entretiensCorrectifs.forEach(ent => {
                        var etape: EtapeBonTravailEntite = {
                            etapeBonTravailId: 0,
                            entretienCorrectif: ent,
                            categorie: ent.categorie,
                            description: ent.description,
                            note: ent.note,
                            indAFaire: true,
                            indComplete: false,
                            typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienCorrectif)
                        }
    
                        console.log(requete.etapesCorrectif);

                        requete.etapesCorrectif.push(etape);
                    });
                }

               
                this.bonTravailService.AjouterEtapes(requete).then(resAjoutEtape => {
                    if (resAjoutEtape.estUnSucces) {

                        this.Debloquer();
                        this.ref.close(x.valeur);

                    } else {
                        resAjoutEtape.messages.forEach(x => {
                            this.messageService.add({ key: 'assignerEntretien', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
                        this.Debloquer();
                    }
                });


            } else {
                x.messages.forEach(x => {
                    this.messageService.add({ key: 'assignerEntretien', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
                this.Debloquer();
            }
        });


    }


    valider() {
        return this.selectionContreMaitre && this.date;
    }

    annuler() {
        this.ref.close(null);
    }

    selectionnerBonTravail(bdt: BonTravailEntite) {
        this.submitted = true;
        this.messageService.clear();

        this.Bloquer();

        var etapes: EtapeBonTravailEntite[] = [];

        var requete: RequeteAjouterEtapesBonTravail = {
            bonTravailId: bdt.bonTravailId,
            etapesPreventif: [],
            etapesCorrectif: []
        }

        if (this.donnees.entretiensPreventifs) {
            this.donnees.entretiensPreventifs.forEach(ent => {
                var etape: EtapeBonTravailEntite = {
                    etapeBonTravailId: 0,
                    entretienPreventif: ent,
                    categorie: ent.categorie,
                    description: ent.description,
                    indAFaire: true,
                    indComplete: false,
                    typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienPreventif)
                }
    
                requete.etapesPreventif.push(etape);
            });
        } else {
            this.donnees.entretiensCorrectifs.forEach(ent => {
                var etape: EtapeBonTravailEntite = {
                    etapeBonTravailId: 0,
                    entretienCorrectif: ent,
                    categorie: ent.categorie,
                    description: ent.description,
                    note: ent.note,
                    indAFaire: true,
                    indComplete: false,
                    typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienCorrectif)
                }
    
                requete.etapesCorrectif.push(etape);
            });
        }

        

        this.bonTravailService.AjouterEtapes(requete).then(resAjoutEtape => {
            if (resAjoutEtape.estUnSucces) {

                this.Debloquer();
                this.ref.close(bdt);

            } else {
                resAjoutEtape.messages.forEach(x => {
                    this.messageService.add({ key: 'assignerEntretien', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
                this.Debloquer();
            }
        });
    }
}