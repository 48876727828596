<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">

            <p-menu #menuContextuelNote popup="popup" [model]="contenuMenuContextuelNote"></p-menu>

            <p-table #dtNotes [value]="notes" dataKey="noteId" tableStyleClass="tableNormale"
							autoLayout="true" styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true"
							[filterDelay]="0" [globalFilterFields]="['description']">
							<ng-template pTemplate="caption">
								<div class="p-d-flex p-ai-center p-jc-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtNotes.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>

									</div>
									<h5 class="titreTableau p-m-0">Notes</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="p-d-flex p-jc-between p-ai-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="description">
										<div class="p-d-flex p-jc-between p-ai-center">
											Note
											<p-sortIcon field="description"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="demandeur.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Auteur
											<p-sortIcon field="demandeur"></p-sortIcon>
											<p-columnFilter type="text" field="demandeur" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-note>

								<tr>
									<td>{{note.date | date:'yyyy-MM-dd'}}</td>
									<td>{{note.description}}</td>
									<td>{{note.employe | nomEmploye}}</td>
									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('temp_mec') || estAutorise('temp_mec')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary p-mr-2 boutonGrille"
											(click)="surMenuContextuelNote($event, note)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucune note.</td>
								</tr>
							</ng-template>
						</p-table>
        </div>
    </div>


</div>