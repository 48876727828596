import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ContactEntite } from '../entites/contact';
import { ReponseObtenirDocuments } from './reponses/reponse-obtenir-documents';
import { RequeteObtenirDocuments } from './requetes/requete-obtenir-documents';
import { RequeteObtenirImage } from './requetes/requete-obtenir-image';
import { DocumentEntite } from '../entites/document';
import { RequeteAjouterDocuments } from './requetes/requete-ajouter-documents';
import { DocumentLieEntite } from '../entites/document-lie';

@Injectable({ providedIn: 'root' })
export class DocumentService {

  private obtenirListeItemUrl = environment.apiBaseUrl + 'document/obteniritem';
  private obtenirListeItemLies = environment.apiBaseUrl + 'document/obtenirlies';
  private obtenirListeRequisitionUrl = environment.apiBaseUrl + 'document/obtenirrequisition';
  private obtenirListeLocationUrl = environment.apiBaseUrl + 'document/obtenirlocation';
  private ajouterDocumentItemUrl = environment.apiBaseUrl + 'document/creerdocumentsitem';
  private ajouterDocumentLieUrl = environment.apiBaseUrl + 'document/creerdocumentlie';
  private ajouterDocumentsRequisitionUrl = environment.apiBaseUrl + 'document/creerdocumentsrequisition';
  private ajouterDocumentsLocationUrl = environment.apiBaseUrl + 'document/creerdocumentslocation';
  private modifierDocumentUrl = environment.apiBaseUrl + 'document/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'document/{id}';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListeItem(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeItemUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeItemLies(requete: RequeteObtenirDocuments): Promise<ReponseBase<DocumentLieEntite[]>> {
    var p = this.http.post<ReponseBase<DocumentLieEntite[]>>(this.obtenirListeItemLies, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  
  public ObtenirListeRequisition(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeRequisitionUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  
  public ObtenirListeLocation(requete: RequeteObtenirDocuments): Promise<ReponseBase<ReponseObtenirDocuments>> {
    var p = this.http.post<ReponseBase<ReponseObtenirDocuments>>(this.obtenirListeLocationUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterDocumentsItem(requete: RequeteAjouterDocuments): Promise<ReponseBase<string>> {
    var p = this.http.post<ReponseBase<string>>(this.ajouterDocumentItemUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterDocumentsLie(requete: DocumentLieEntite): Promise<ReponseBase<DocumentLieEntite>> {
    var p = this.http.post<ReponseBase<DocumentLieEntite>>(this.ajouterDocumentLieUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterDocumentsRequisition(requete: RequeteAjouterDocuments): Promise<ReponseBase<string>> {
    var p = this.http.post<ReponseBase<string>>(this.ajouterDocumentsRequisitionUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterDocumentsLocation(requete: RequeteAjouterDocuments): Promise<ReponseBase<string>> {
    var p = this.http.post<ReponseBase<string>>(this.ajouterDocumentsLocationUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ModifierDocument(requete: DocumentEntite): Promise<ReponseBase<DocumentEntite>> {
    var p = this.http.post<ReponseBase<DocumentEntite>>(this.modifierDocumentUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public SupprimerDocument(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
