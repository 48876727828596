import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { CategorieService } from 'src/app/services/categorie-service';
import { AttributEntite } from 'src/app/entites/attribut';
import { AttributService } from 'src/app/services/attribut-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AttributCategorieEntite } from 'src/app/entites/attribut-categorie';
import { AppMainComponent } from 'src/app/app.main.component';

export interface LienCategorieAttribut {
    categorieId: number;
    nomCategorie: string;
    utilise: boolean;
    indObligatoire: boolean;
}

@Component({
    templateUrl: './detail-attribut.component.html',
    styleUrls: ['./detail-attribut.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailAttributComponent extends BaseComponent implements OnInit {

    attributId: number;
    attribut: AttributEntite;

    submitted: boolean;
    menu: MenuItem[];

    liensCategorie: LienCategorieAttribut[] = [];

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private attributService: AttributService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Détail de l\'attribut' }
        ]);

        this.route.params.subscribe(params => {
            this.attributId = params.id as number;
        });
    }

    ngOnInit() {

        var p = new Promise((resolve, reject) => {

            if (this.attributId != 0) {

                this.attributService.Obtenir(this.attributId).then(x => {
                    this.attribut = x.valeur;
                    this.initialiserMenu();

                    resolve();

                });
            } else {
                this.attribut = {
                    typeChamp: 1,
                    indObligatoire: false
                }
                this.initialiserMenu();

                resolve();
            }

        }).then(() => {
            this.attributService.ObtenirLiens(this.attributId).then(res => {

                console.log(res);

                var liens: AttributCategorieEntite[] = res.valeur;

                this.sdb.Categories().forEach(c => {

                    if (c.estSuivi) {

                        console.log('liens');
                        console.log(liens);

                        var lien = liens.find(y => y.categorieId == c.categorieId);

                        this.liensCategorie.push({
                            categorieId: c.categorieId,
                            nomCategorie: c.nom,
                            utilise: lien != undefined,
                            indObligatoire: lien != undefined ? lien.indObligatoire : false
                        });
                    }

                });

            });

            this.CompleterChargement();
        });




    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('pilotage/attributs')
            }
        ];
    }

    /*  supprimer(attr: AttributEntite) {
         this.confirmationService.confirm({
             message: 'Voulez-vous vraiment supprimer l\'attribut ' + attr.nom + ' et toutes les valeurs qui lui sont rattachées ?',
             header: 'Confirmation',
             icon: 'pi pi-exclamation-triangle',
             accept: () => {
                 this.attributService.Supprimer(attr.attributId).then(res => {
                     if (res.estUnSucces) {
                         this.attributs = this.attributs.filter(val => val.attributId !== attr.attributId);
                         this.attribut = {};
                         this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Attribut supprimé' });
                     } else {
                         res.messages.forEach(x => {
                             this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                         });
                     }
                 });
             }
         });
     } */

    annuler() {
        this.routerService.navigateByUrl('pilotage/attributs');
    }

    sauvegarder() {
        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.attribut.typeChamp = parseInt(this.attribut.typeChamp.toString());

        if (this.attribut.attributId) {

            this.attributService.Modifier(this.attribut).then(res => {
                if (res.estUnSucces) {
                    this.attribut = res.valeur;

                    this.attributService.ModifierLiens(this.attribut.attributId, this.construireTableauLiens()).then(res2 => {

                        if (res2.estUnSucces) {
                            this.routerService.navigateByUrl('pilotage/attributs');
                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Attribut modifié' });
                        } else {
                            res2.messages.forEach(x => {
                                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                            });
                        }
                        
                    });
                    
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });

        }
        else {

            this.attributService.Ajouter(this.attribut).then(res => {
                if (res.estUnSucces) {
                    this.attribut = res.valeur;

                    this.attributService.ModifierLiens(this.attribut.attributId, this.construireTableauLiens()).then(res2 => {
                        if (res2.estUnSucces) {
                            this.routerService.navigateByUrl('pilotage/attributs');
                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Attribut modifié' });
                        } else {
                            res2.messages.forEach(x => {
                                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                            });
                        }
                    });
                    
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });
        }

    }

    construireTableauLiens(){
        
        var attributsCategories: AttributCategorieEntite[] = [];

        console.log('liens');
        console.log(this.liensCategorie);

        this.liensCategorie.forEach(x => {
            if (x.utilise) {
                attributsCategories.push({
                    attributId: this.attribut.attributId,
                    categorieId: x.categorieId,
                    indObligatoire: x.indObligatoire
                });
            }
        });

        return attributsCategories;
    }

    valider() {
        return this.attribut.nom
    }


}
