import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/Requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { RapportService } from 'src/app/services/rapport-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ItemRequisitionEntite } from 'src/app/entites/item-Requisition';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { Statut } from 'src/app/entites/statut';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { ProfilService } from 'src/app/services/profil-service';
import { DatePipe } from '@angular/common';
import { InfoChangementStatut } from 'src/app/entites/info-changement-statut';
import { AjustementInventaireComponent, ResultatAjustementInventaire } from 'src/app/controles/ajustement-inventaire.component';
import { Requisition } from 'src/app/entites/requisition';
import { ItemCommandeEntite } from 'src/app/entites/item-commande';
import { CommandeService } from 'src/app/services/commande-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { SelectionEmployesComponent } from 'src/app/controles/selection-employes.component';
import { EmployeEntite } from 'src/app/entites/employe';
import { ETypesRequisition } from 'src/app/entites/enums/type-requisition';
import { SelectionBoiteOutilsComponent } from 'src/app/controles/selection-boite-outils.component';
import { DocumentEntite } from 'src/app/entites/document';
import { environment } from 'src/environments/environment';
import { DocumentService } from 'src/app/services/document-service';
import { ContexteService } from 'src/app/services/contexte-service';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { DetailDocumentComponent } from '../documents/controles/detail-document.component';
import { SaisieNoteComponent } from 'src/app/controles/saisie-note.component';
import { ReleveHeureEntite } from 'src/app/entites/releve-heures';
import { BoiteOutilEntite } from 'src/app/entites/boite-outil';

@Component({
    selector: 'app-detail-requisition',
    templateUrl: './detail-requisition.component.html',
    styleUrls: ['./detail-requisition.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService, DatePipe],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailRequisitionComponent extends BaseComponent implements OnInit {

    @Input('requisitionId') inputRequisitionId: number;

    requisitionId: number;
    requisition: Requisition;

    modeApercu: boolean = false;

    selectionItemRequisition: ItemRequisitionEntite;
    selectionMultipleItemRequisition: ItemRequisitionEntite[] = [];
    contenuMenuContextuelItemRequisition: MenuItem[];

    itemsCommande: ItemCommandeEntite[] = [];
    selectionItemCommande: ItemCommandeEntite;
    selectionMultipleItemCommande: ItemCommandeEntite[] = [];
    contenuMenuContextuelItemCommande: MenuItem[];
    statuts: Statut[] = [];

    listeEmployes: EmployeEntite[] = [];

    itemsDisponibles: ItemMin[] = [];
    selectionItemDisponible: ItemMin;
    selectionMultipleItemDisponible: ItemMin[] = [];
    contenuMenuContextuelItemDisponible: MenuItem[];

    ref: DynamicDialogRef;

    menu: MenuItem[];
    ongletRequisition: boolean = true;
    ongletCommande: boolean = false;
    ongletDisponible: boolean = false;

    submitted: boolean = false;

    tabIndex: number = 0;

    // DOCUMENTS
    chargementCompleteDocuments: boolean = false;
    documentsComplet: DocumentEntite[];
    contenuMenuContextuelDocuments: MenuItem[];
    @ViewChild('menuContextuelDocuments') menuContextuelDocuments: ContextMenu;



    @ViewChild('menuContextuelItemRequisition') menuContextuelItemRequisition: ContextMenu;
    @ViewChild('tabView') tabView;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private requisitionService: RequisitionService,
        private commandeService: CommandeService,
        private EmplacementService: EmplacementService,
        private ItemsService: ItemService,
        private messageService: MessageService,
        private contexteService: ContexteService,
        private documentService: DocumentService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public rapportService: RapportService,
        public routerService: Router,
        public datepipe: DatePipe) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([{ label: 'Détail de la Requisition' }]);

        this.route.params.subscribe(params => {

            this.requisitionId = params.id as number;
        });


    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutItemActive) {
        //     this.surAjoutItem();
        // }
    }

    ngOnInit() {

        if (this.inputRequisitionId) {
            this.requisitionId = this.inputRequisitionId;
            this.modeApercu = true;
        }

        this.verifierAccesPage('requisition_detail');

        this.DemarrerChargement();

        this.requisitionService.ObtenirRequisition(this.requisitionId).then(x => {

            this.requisition = x.valeur.requisition;

            this.requisition.date = new Date(this.requisition.date);

            if (this.contexteService.ValiderPresence(["tab"])) {
                this.tabIndex = Number(this.contexteService.Obtenir("tab"));
                this.contexteService.Supprimer("tab");

                this.chargerDocuments();
            }

            var p1 = new Promise((resolve, reject) => {

                if (this.requisition.type.typeRequisitionId == ETypesRequisition.Requisition) {
                    this.requisitionService.ObtenirCommande(this.requisition.a.emplacementId).then(x => {
                        this.itemsCommande = x.valeur;
                        resolve();
                    });
                } else if (this.requisition.type.typeRequisitionId == ETypesRequisition.Retour ||
                    this.requisition.type.typeRequisitionId == ETypesRequisition.Transfert) {
                    this.EmplacementService.ObtenirInventaire(this.requisition.de.emplacementId).then(x => {
                        this.itemsDisponibles = x.valeur.filter(x => x.categorie.estSuivi);
                        resolve();
                    });
                } else {
                    resolve();
                }

            }).then(x => {
                this.CompleterChargement(`${this.requisition.type.nom} #${this.requisition.numero}`);
                this.initialiserMenu();
            });

            /* this.requisitionService.ObtenirCommande(this.requisition.a.emplacementId).then(x => {

                this.itemsCommande = x.valeur;

                var p1 = new Promise((resolve, reject) => {
                    if (this.requisition.type.typeRequisitionId == ETypesRequisition.Retour ||
                        this.requisition.type.typeRequisitionId == ETypesRequisition.Transfert) {

                            this.EmplacementService.ObtenirInventaire(this.requisition.de.emplacementId).then(x => {
                                this.itemsDisponibles = x.valeur.filter(x => x.categorie.estSuivi);
                                resolve()
                            });

                    } else {
                        resolve()
                    }
                }).then(x => {
                    this.CompleterChargement(`Requisition #${this.requisition.numero}`);
                    this.initialiserMenu();
                });
            }); */
        });

        this.listeEmployes = this.sdb.Employes();

        this.statuts = [
            this.sdb.Statut(EStatuts.Charge),
            this.sdb.Statut(EStatuts.Pret)
        ]

        this.colonnesDisponibles = [
            { champ: 'demandeur.nom', entete: 'Demandeur', afficheParDefaut: true },
            { champ: 'expediteur', entete: 'Expéditeur', afficheParDefaut: true },
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);
    }

    /* GÉNÉRAL */

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('requisitions')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print',
                command: () => this.imprimer()
            },
            {
                label: 'Envoyer', icon: 'pi pi-fw pi-send',
                visible: this.estAutorise('requisition_courriel'),
                command: () => this.envoyer()
            },
            {
                label: 'Actions',
                icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('requisition_ajout_item') && this.requisition.type.typeRequisitionId == ETypesRequisition.Requisition && this.estModifiable,
                items: [
                    {
                        label: 'Ajouter une boite d\'outils',
                        icon: 'pi pi-fw pi-briefcase',
                        visible: this.estAutorise('requisition_ajout_item'),
                        command: () => this.ajouterBoiteOutil()
                    },
                    {
                        separator: true
                    },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-fw pi-times',
                        disabled: this.requisition.itemsRequisition.length > 0,
                        command: () => this.supprimerRequisition()
                    }
                ]
            },
            {
                label: 'Actions multiples',
                icon: 'pi pi-fw pi-bars',
                visible: this.ongletRequisition && this.estModifiable,
                disabled: this.selectionMultipleItemRequisition.length == 0,
                items: [
                    {
                        label: 'Retourner en commande',
                        icon: 'pi pi-fw pi-undo',
                        visible: this.estAutorise('commande_ajout_item'),
                        command: () => this.retourEnCommandeMultiple(this.selectionMultipleItemRequisition)
                    },
                    {
                        separator: true, visible: this.estAutorise('requisition_supprimer_item')
                    },
                    {
                        label: 'Supprimer', icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('requisition_supprimer_item')
                    },
                ]
            },
            {
                label: 'Actions multiples',
                icon: 'pi pi-fw pi-bars',
                visible: this.ongletCommande,
                disabled: this.selectionMultipleItemCommande.length == 0,
                items: [
                    {
                        label: 'Transférer', icon: 'pi pi-fw pi-check-square',
                        visible: this.estAutorise('commande_ajout_item'),
                        command: () => this.transfertMultipleItemCommande(this.selectionMultipleItemCommande)
                    }
                ]
            },
            {
                label: 'Actions multiples',
                icon: 'pi pi-fw pi-bars',
                visible: this.ongletDisponible,
                disabled: this.selectionMultipleItemDisponible.length == 0,
                items: [
                    {
                        label: 'Transférer', icon: 'pi pi-fw pi-check-square',
                        visible: this.estAutorise('commande_ajout_item'),
                        command: () => this.transfertMultipleItemDisponible(this.selectionMultipleItemDisponible, 0)
                    }
                ]
            },
        ];

        this.estModifiable = this.requisition.statut.statutId == EStatuts.Actif;
    }



    surChangementOnglet(event) {
        this.ongletRequisition = event.index == 0;
        this.ongletCommande = this.requisition.type.typeRequisitionId == ETypesRequisition.Requisition
            && event.index == 1;
        this.ongletDisponible = (this.requisition.type.typeRequisitionId == ETypesRequisition.Retour
            || this.requisition.type.typeRequisitionId == ETypesRequisition.Transfert)
            && event.index == 1;


        if (this.tabView.tabs[event.index]._header.startsWith('Documents') && !this.chargementCompleteDocuments) {
            this.chargerDocuments();
        }

        this.initialiserMenu();
    }

    btnModifier_click() {
        this.Modifier();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.requisitionService.ObtenirRequisition(this.requisitionId).then(x => {
            this.requisition = x.valeur.requisition;
            this.CompleterChargement();
        });

        this.Consulter();
    }

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.validerModificationItem()) {
            this.DemarrerChargement();
            this.requisitionService.ModifierRequisition(this.requisition).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Réquisition modifiée.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModificationItem(): boolean {

        if (!this.requisition.date) {
            return false;
        }

        //TODO : Compléter les validations

        return true;
    }

    modifierNote() {
        const ref = this.dialogService.open(SaisieNoteComponent, {
            data: {
                note: this.requisition.note
            },
            header: 'Modifier une note',
            width: '80%'
        }).onClose.subscribe((res: string) => {
            if (res) {

                this.Bloquer();

                const ancienneNote = this.requisition.note;
                this.requisition.note = res
                this.requisitionService.ModifierRequisition(this.requisition).then(resModifReq => {

                    this.Debloquer();

                    if (resModifReq.estUnSucces) {

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

                    } else {
                        this.requisition.note = ancienneNote;
                        resModifReq.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }
        });
    }

    supprimerRequisition() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la réquisition ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.requisitionService.SupprimerRequisition(this.requisition.requisitionId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('requisitions');
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    ouvrirFicheComplete() {
        this.routerService.navigateByUrl('requisition/' + this.requisitionId);
    }

    /* RAPPORTS */

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportRequisition(this.requisition.requisitionId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    envoyer() {

        this.Bloquer();

        this.EmplacementService.ObtenirResponsables(this.requisition.a.emplacementId).then(resObtentionResponsables => {


            this.Debloquer();
            
            var ids: number[] = [];

            /* if (this.requisition.demandeur) {
                ids.push(this.requisition.demandeur.employeId);
            } */

           /*  this.requisition.itemsRequisition.forEach(ir => {
                if (ir.demandeur && !ids.includes(ir.demandeur.employeId)) {
                    ids.push(ir.demandeur.employeId);
                }
            }); */

            if (resObtentionResponsables) {
                resObtentionResponsables.valeur.forEach(r => {
                    if (!ids.includes(r.employeId)) {
                        ids.push(r.employeId);
                    }
                }); 
            }

            const ref = this.dialogService.open(SelectionEmployesComponent, {
                data: {
                    employesIds: ids
                },
                header: 'Envoi de la réquisition',
                width: '60%'
            }).onClose.subscribe((res: EmployeEntite[]) => {
                if (res) {

                    this.Bloquer();

                    var destinataires: string[] = res.map(x => x.courriel);

                    this.rapportService.EnvoyerRapportRequisition(this.requisition.requisitionId, destinataires).then(res => {

                        this.Debloquer();

                        if (res) {
                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'La réquisition a été envoyée.' });
                        } else {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite lors de l\'envoi du courriel.' });
                        }
                    });
                }
            });

        });


    }

    /* DÉTAIL RÉQUISITION */

    estFenetreAjoutItemActive: boolean = false;
    surAjoutItem() {
        
        this.estFenetreAjoutItemActive = true;
        const ref = this.dialogService.open(SelectionItemComponent, {
            data: {
                type: ETypeSelectionItem.Requisition,
                demandeurId: this.requisition.demandeur?.employeId,
                emplacementIdVerificationTransfertExpress: this.requisition.de.emplacementId
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {
            this.estFenetreAjoutItemActive = false;
            if (res) {
                var itemRequisition: ItemRequisitionEntite = {
                    itemRequisitionId: 0,
                    requisitionId: this.requisition.requisitionId,
                    item: res.item,
                    statut: this.sdb.Statut(EStatuts.Actif),
                    employe: this.profilService.ProfilCourant(),
                    quantite: res.quantiteDemande,
                    expediteur: res.expediteur,
                    demandeur: res.demandeur,
                    unite: res.unite
                }

                this.requisitionService.CreerItemRequisition({
                    itemsRequisition: [itemRequisition]
                }).then(res => {
                    if (res.estUnSucces) {
                        res.valeur.itemsRequisition.forEach(ir => {
                            this.requisition.itemsRequisition.push(ir);    
                        });
                        //itemRequisition.itemRequisitionId = res.valeur.itemsRequisition[0].itemRequisitionId;
                        //this.requisition.itemsRequisition.push(itemRequisition);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${itemRequisition.item.description} (${itemRequisition.item.numero})' a été ajouté.` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }


        });

    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    /* visible: boolean = true;
    updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }
 */
    supprimerItems(items: ItemRequisitionEntite[]) {
        var ids = items.map(b => b.itemRequisitionId);
        this.confirmationService.confirm({
            message: items.length == 1 ? 'Voulez-vous vraiment supprimer l\'item sélectionné ?' : 'Voulez-vous vraiment supprimer les items sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.requisitionService.SupprimerItemsRequisition(ids).then(res => {
                    if (res.estUnSucces) {
                        this.requisition.itemsRequisition = this.requisition.itemsRequisition.filter((ir: ItemRequisitionEntite) => !res.valeur.includes(ir.itemRequisitionId))
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Items supprimés.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    surMenuContextuelItem(event, itemRequisition: ItemRequisitionEntite) {
        this.selectionItemRequisition = itemRequisition;

        this.contenuMenuContextuelItemRequisition = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl(`item/${itemRequisition.item.itemId}`) },
            { 
                visible: this.estModifiable && this.estAutorise('requisition_modifier'), 
                disabled: itemRequisition.item.estLie || itemRequisition.item.categorie.estUnique,
                label: 'Changer la quantité', 
                icon: 'pi pi-fw pi-sort' 
            },
            { visible: this.estModifiable, separator: true },
            { 
                visible: this.estModifiable && this.estAutorise('requisition_modifier'), 
                label: 'Ajuster l\'inventaire', 
                icon: 'pi pi-fw pi-sliders-h', 
                disabled: !itemRequisition.item.categorie.estSuivi || itemRequisition.item.estLie || itemRequisition.item.categorie.estUnique, 
                command: () => this.ajustementInventaire(itemRequisition) },
            { visible: this.estModifiable, separator: true },
            // { 
            //     visible: this.estModifiable && this.estAutorise('item_heure_creer'), 
            //     label: 'Ajouter un relevé d\'heures', 
            //     icon: 'pi pi-fw pi-clock', 
            //     disabled: !itemRequisition.item.categorie.estUnique, 
            //     command: () => this.ajouterReleveHeure(itemRequisition.item) },
            // { visible: this.estModifiable, separator: true },
            { 
                visible: this.estModifiable  && this.estAutorise('item_heure_creer'), 
                disabled: itemRequisition.item.estLie,
                label: 'Retourner en commande', 
                icon: 'pi pi-fw pi-undo', 
                command: () => this.retourEnCommande(itemRequisition) }
        ];

        this.menuContextuelItemRequisition.toggle(event);
    }

    retourEnCommande(itemRequisition: ItemRequisitionEntite) {

        const ref2 = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: itemRequisition.quantite,
                min: 1,
                max: itemRequisition.quantite
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((resQte: number) => {
            if (resQte) {
                // itemRequisition.quantite = resQte;
                var item = {...itemRequisition};
                item.quantite = resQte;
                this.appliquerRetourEnCommande([item]);
            }
        });

        /* this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment retourner l\'item "' + itemRequisition.item.description + '" en commande ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.appliquerRetourEnCommande([itemRequisition]);
            }
        }); */
    }

    retourEnCommandeMultiple(itemsRequisition: ItemRequisitionEntite[]) {
        this.confirmationService.confirm({
            key: "popup",
            target: event.target,
            message: 'Voulez-vous vraiment retourner les items sélectionnés en commande ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.appliquerRetourEnCommande(itemsRequisition);
            }
        });
    }

    appliquerRetourEnCommande(itemsRequisition: ItemRequisitionEntite[]) {
        this.Bloquer();
        this.requisitionService.RetournerCommande({
            employe: this.profilService.ProfilCourant(),
            emplacement: this.requisition.a,
            itemsRequisition: itemsRequisition
        }).then(res => {
            this.Debloquer();
            if (res.estUnSucces) {

                res.valeur.itemRequisitionSupprimes.forEach(x => {
                    this.requisition.itemsRequisition = this.requisition.itemsRequisition.filter(ic => ic.itemRequisitionId != x.itemRequisitionId);
                });

                res.valeur.itemRequisitionModifies.forEach(x => {
                    var itemRequisitionAModifier = this.requisition.itemsRequisition.find(ir => ir.itemRequisitionId == x.itemRequisitionId);
                    itemRequisitionAModifier.quantite = x.quantite;
                });

                res.valeur.itemCommandeAjoutes.forEach(x => {
                    this.itemsCommande.push(x);
                });

                res.valeur.itemCommandeModifies.forEach(x => {
                    var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                    itemCommandeAModifier.quantite = x.quantite;
                });

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Retour en commande complété` });

                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }

    ajustementInventaire(itemRequisition: ItemRequisitionEntite) {
        const ref = this.dialogService.open(AjustementInventaireComponent, {
            data: {
                itemId: itemRequisition.item.itemId,
                emplacementId: 10000, // TODO: Gérer le multi entrepot.
                numero: itemRequisition.item.numero,
                description: itemRequisition.item.description,
                emplacement: `TODO`,
                quantiteCourante: itemRequisition.item.quantite
            },
            header: 'Ajustement d\'inventaire',
            width: '50%'
        }).onClose.subscribe((res: ResultatAjustementInventaire) => {
            if (res) {
                itemRequisition.item.quantite = res.nouvelleQuantite;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'inventaire de l'item '${itemRequisition.item.description} (${itemRequisition.item.numero})' a été ajusté.` });
            }
        });
    }

    // ajouterReleveHeure(item: ItemMin) {
    //     const ref = this.dialogService.open(AjoutReleveHeureComponent, {
    //         data: {
    //             item: item,
    //             date: new Date()
    //         },
    //         header: 'Ajout d\'un relevé d\'heures',
    //         width: '50%'
    //     }).onClose.subscribe((res: ReleveHeureEntite) => {
    //         if (res) {
    //             this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Heures ajoutées.` });
    //         }
    //     });
    // }

    ajouterBoiteOutil() {
        const ref = this.dialogService.open(SelectionBoiteOutilsComponent, {
            header: 'Sélection d\'une boite d\'outils',
            width: '50%'
        }).onClose.subscribe((resSelectionBoite: BoiteOutilEntite) => {
            if (resSelectionBoite) {

                this.Bloquer();

                this.requisitionService.AjouterBoiteOutil({
                    requisitionId: this.requisition.requisitionId,
                    boiteOutilId: resSelectionBoite.boiteOutilId,
                    employeId: this.profilService.ProfilCourant().employeId
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        res.valeur.itemsRequisition.forEach(ir => {
                            this.requisition.itemsRequisition.push(ir);    
                        });
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `La boite '${resSelectionBoite.nom}' a été ajoutée.` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })

                // TODO

                //this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'inventaire de l'item '${itemRequisition.item.description} (${itemRequisition.item.numero})' a été ajusté.` });
            }
        });
    }

    /* ONGLET EN COMMANDE */

    surChangementSelectionCommande() {
        this.initialiserMenu();
    }

    transfertItemCommande(itemCommande: ItemCommandeEntite) {
        this.transfertMultipleItemCommande([itemCommande]);
    }

    transfertMultipleItemCommande(itemsCommande: ItemCommandeEntite[]) {
        this.Bloquer();
        this.requisitionService.TransfertCommande({
            employe: this.profilService.ProfilCourant(),
            requisitionId: this.requisition.requisitionId,
            itemsCommande: itemsCommande
        }).then(res => {
            this.Debloquer();
            this.selectionMultipleItemCommande = [];
            this.initialiserMenu()
            if (res.estUnSucces) {


                res.valeur.itemCommandeSupprimes.forEach(x => {
                    this.itemsCommande = this.itemsCommande.filter(ic => ic.itemCommandeId != x.itemCommandeId);
                });

                res.valeur.itemRequisitionAjoutes.forEach(x => {
                    this.requisition.itemsRequisition.push(x);
                });

                res.valeur.itemRequisitionModifies.forEach(x => {
                    var itemRequisitionAModifier = this.requisition.itemsRequisition.find(ic => ic.itemRequisitionId == x.itemRequisitionId);
                    itemRequisitionAModifier.quantite = x.quantite;
                });

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Transfert complété` });

                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }


    /* ONGLET DISPONIBLE */

    surChangementSelectionDisponible() {
        this.initialiserMenu();
    }

    transfertItemDisponible(item: ItemMin) {

        const ref = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: item.quantite,
                min: 1,
                max: item.quantite
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((resQte: number) => {
            if (resQte) {
                this.transfertMultipleItemDisponible([item], resQte);
            }
        });
    }

    transfertMultipleItemDisponible(items: ItemMin[], quantite: number) {

        if (items.length == 1 && quantite == 0) {
            quantite = items[0].quantite;
        }
        this.Bloquer();

        var itemsRequisition: ItemRequisitionEntite[] = [];

        items.forEach(i => {
            itemsRequisition.push({
                itemRequisitionId: 0,
                requisitionId: this.requisition.requisitionId,
                item: i,
                statut: this.sdb.Statut(EStatuts.Actif),
                employe: this.profilService.ProfilCourant(),
                quantite: items.length == 1 ? quantite : i.quantite,
                expediteur: '',
                demandeur: null
            });
        });

        this.requisitionService.CreerItemRequisition({
            itemsRequisition: itemsRequisition
        }).then(res => {
            this.Debloquer();
            this.selectionMultipleItemDisponible = [];
            this.initialiserMenu();
            if (res.estUnSucces) {

                res.valeur.itemsRequisition.forEach(x => {
                    //TODO: Dans le cas d'un transfert vérifier aussi le demandeur car on pourrait vouloir 2 lignes différentes pour le même item.
                    var itemRequisitionAAjuster = this.requisition.itemsRequisition.find(y => y.item.itemId == x.item.itemId);
                    if (itemRequisitionAAjuster) {
                        itemRequisitionAAjuster.quantite += x.quantite;

                    } else {
                        this.requisition.itemsRequisition.push(x);
                    }
                    this.itemsDisponibles = this.itemsDisponibles.filter(ic => ic.itemId != x.item.itemId);
                });

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Transfert complété` });

                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }


    /* DOCUMENTS */

    chargerDocuments() {
        const requete: RequeteObtenirDocuments = {
            id: this.requisition.requisitionId
        }
        this.documentService.ObtenirListeRequisition(requete).then(res => {
            if (res.estUnSucces) {
                res.valeur.documents.forEach(doc => {
                    doc.apercu = 'data:image/jpeg;base64,' + doc.apercu;
                });


                this.documentsComplet = res.valeur.documents;

                this.chargementCompleteDocuments = true;
            }
        });
    }

    ajouterDocument() {

        this.contexteService.Vider();
        this.contexteService.Ajouter("requisitionId", this.requisition.requisitionId.toString());
        this.contexteService.Ajouter("numero", this.requisition.numero.toString());

        this.routerService.navigateByUrl('document/nouveau');
    }

    afficherImage(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}vr/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    telechargerDocument(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}tr/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    surMenuContextuelDocuments(event, doc: DocumentEntite) {
        //this.selectionDocument = doc;

        this.contenuMenuContextuelDocuments = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                command: () => this.modifierDocument(doc)
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                command: () => this.supprimerDocument(doc)
            }
        ];

        this.menuContextuelDocuments.toggle(event);
    }

    modifierDocument(doc: DocumentEntite) {
        const ref = this.dialogService.open(DetailDocumentComponent, {
            data: {
                document: doc
            },
            header: 'Modifier un document',
            width: '60%'
        }).onClose.subscribe((res: DocumentEntite) => {
            if (res) {
                doc.date = res.date;
                doc.nom = res.nom;
                doc.description = doc.description;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Document modifié.` });
            }

        });
    }

    supprimerDocument(doc: DocumentEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le document sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.documentService.SupprimerDocument(doc.documentId).then(res => {
                    if (res.estUnSucces) {
                        this.documentsComplet = this.documentsComplet.filter(val => val.documentId != doc.documentId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Document supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    estTypeRequisition() {
        return this.requisition.type.typeRequisitionId == ETypesRequisition.Requisition;
    }

}
