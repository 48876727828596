import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { Statut } from 'src/app/entites/statut';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { NoteEntite } from 'src/app/entites/note';
import { NoteService } from 'src/app/services/note-service';
import { SelectionUniteMecaniqueComponent } from 'src/app/controles/mecanique/selection-unite-mecanique.component';
import { ItemMin } from 'src/app/entites/item-min';

@Component({
    templateUrl: './notes.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class NotesComponent extends BaseComponent implements OnInit {

    notes: NoteEntite[];

    menuContextuel: MenuItem[];

    contenuMenuContextuelNote: MenuItem[];
    @ViewChild('menuContextuelNote') menuContextuelNote: ContextMenu;

    @ViewChild('dt') table: Table;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private bonTravailService: BonTravailService,
        private messageService: MessageService,
        private noteService: NoteService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Bons de travail' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('temp_mec');

           this.noteService.ObtenirListe().then(x => {
               this.notes = x.valeur;
               this.CompleterChargement();
           });

       
    }

    surMenuContextuelNote(event, note: NoteEntite) {

        this.contenuMenuContextuelNote = [
            {
                label: 'Assigner',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec'),
                command: () => this.assignerNote(note)
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_note_supprimer'),
                command: () => this.supprimerNote(note)
            },
        ];

        this.menuContextuelNote.toggle(event);
    }

    supprimerNote(note: NoteEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la note sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //TODO
                this.notes = this.notes.filter(x => x.noteId != note.noteId);
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note supprimée.' });
                /* this.achatService.SupprimerBonTravail(bonTravail.bonTravailId).then(res => {
                    if (res.estUnSucces) {
                        this.bonsTravail = this.bonsTravail.filter(val => val.bonTravailId != bonTravail.bonTravailId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }); */

            }
        });
    }

    assignerNote(note: NoteEntite) {

        const ref = this.dialogService.open(SelectionUniteMecaniqueComponent, {
            header: 'Sélection d\'une unité',
            width: '95%'
        }).onClose.subscribe((res: ItemMin) => {
            if (res) {
                //TODO

                this.notes = this.notes.filter(x => x.noteId != note.noteId);

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note réassignée.' });
            }
        });
        
    }
}
