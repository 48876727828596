<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContxtuelItem popup="popup" [model]="menuContextuelItems"></p-menu>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">

    <div class="p-col-12">

        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-3">
                <label for="noUnite">Type de garantie</label>
                <p-dropdown [options]="types" [(ngModel)]="type" optionLabel="nom" appendTo="body"
                    filter="true" filterBy="nom" [showClear]="true"
                    placeholder="Sélectionnez un type...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="type">
                            <div>{{type.nom}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-type pTemplate="item">
                        <div>
                            <div>{{type.nom}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="noUnite">Emplacement</label>
                <p-dropdown [options]="emplacements" [(ngModel)]="emplacement" optionLabel="nom"
                    appendTo="body" filter="true" filterBy="nom,code" [showClear]="true"
                    placeholder="Sélectionnez un emplacement...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="emplacement">
                            <div>{{emplacement.nom}} ({{emplacement.code}})</div>
                        </div>
                    </ng-template>
                    <ng-template let-emplacement pTemplate="item">
                        <div>
                            <div>{{emplacement.nom}} ({{emplacement.code}})</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            
            <div class="p-col-12 p-md-6 p-lg-3">
                <label>&nbsp;</label>
                <button style="width: auto; margin-top: 22px; margin-right: 10px;" pButton pRipple label="Filtrer" icon="pi pi-filter" class="p-button-primary"
                    (click)="filtrer()"></button>
                <button style="width: auto; margin-top: 22px;" pButton pRipple label="Annuler" icon="pi pi-filter-slash" class="p-button-secondary"
                    (click)="retirerFiltres()"></button>
            </div>
        </div>


        <div class="card">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="garantieId" autoLayout="true"
                tableStyleClass="tableNormale" styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true"
                [rows]="100" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['item.numero','item.description','item.noSerie','item.noTag','item.modele','item.marque','item.sousCategorie.nom','item.categorie.nom','type.nom','description','dateFin','statut.nom']">

                <ng-template pTemplate="caption" let-state>
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau p-m-0">Garanties</h5>
                        </div>

                        <div>
                            <button pButton pRipple icon="pi pi-file-pdf"
                            class="p-button-secondary p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                            (click)="exportExcel()"></button>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="item.numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="item.numero"></p-sortIcon>
                                <p-columnFilter type="text" field="item.numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="item.description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="item.description"></p-sortIcon>
                                <p-columnFilter type="text" field="item.description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('categorie')"  pSortableColumn="item.categorie?.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                No Catégorie
                                <p-sortIcon field="item.categorie?.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="item.categorie?.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('sousCategorie')"  pSortableColumn="item.sousCategorie?.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Sous catégorie
                                <p-sortIcon field="item.sousCategorie?.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="item.sousCategorie?.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('marque')"  pSortableColumn="item.marque">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Marque
                                <p-sortIcon field="item.marque"></p-sortIcon>
                                <p-columnFilter type="text" field="item.marque" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('modele')"  pSortableColumn="item.modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Modèle
                                <p-sortIcon field="item.modele"></p-sortIcon>
                                <p-columnFilter type="text" field="item.modele" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('noTag')"  pSortableColumn="item.noTag">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                No Tag
                                <p-sortIcon field="item.noTag"></p-sortIcon>
                                <p-columnFilter type="text" field="item.noTag" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('noSerie')" pSortableColumn="item.noSerie">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                No Série
                                <p-sortIcon field="item.noSerie"></p-sortIcon>
                                <p-columnFilter type="text" field="item.noSerie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        <th pSortableColumn="type.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Type
                                <p-sortIcon field="type.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="type.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="statut"></p-sortIcon>
                                <p-columnFilter type="text" field="statut" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateFin">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de fin
                                <p-sortIcon field="dateFin"></p-sortIcon>
                                <p-columnFilter type="date" field="dateFin" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nbJoursRestants">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nb jours restants
                                <p-sortIcon field="nbJoursRestants"></p-sortIcon>
                                <p-columnFilter type="numeric" field="nbJoursRestants" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="item.emplacement.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Emplacement
                                <p-sortIcon field="emplacement"></p-sortIcon>
                                <p-columnFilter field="emplacement.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="filtreEmplacement" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">
                                                    <span class="p-ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 5rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-garantie>
                    <tr [pContextMenuRow]="garantie">
                        <td>{{garantie.item.numero}}
                        </td>
                        <td>{{garantie.item.description}}</td>
                        <td *ngIf="EstColonneSelectionnee('categorie')">{{garantie.item.categorie?.nom}}</td>
                        <td *ngIf="EstColonneSelectionnee('sousCategorie')">{{garantie.item.sousCategorie?.nom}}</td>
                        <td *ngIf="EstColonneSelectionnee('marque')">{{garantie.item.marque}}</td>
                        <td *ngIf="EstColonneSelectionnee('modele')">{{garantie.item.modele}}</td>
                        <td *ngIf="EstColonneSelectionnee('noTag')">{{garantie.item.noTag}}</td>
                        <td *ngIf="EstColonneSelectionnee('noSerie')">{{garantie.item.noSerie}}</td>
                        <td>{{garantie.type.nom}}</td>
                        <td><span [class]="'statut ' + garantie.statut.nom.replace(' ','').toLowerCase()">
                                {{garantie.statut.nom}}
                            </span>
                        </td>

                        <td>{{garantie.description}}</td>
                        <td>{{garantie.dateFin | date:'yyyy-MM-dd'}}</td>
    
                        <td>
                            <span *ngIf="garantie.nbJoursRestants" [ngClass]="garantie.nbJoursRestants > seuilRouge ? garantie.nbJoursRestants > seuilJaune ? 'seuil vert' : 'seuil jaune' : 'seuil rouge'">
                                {{garantie.nbJoursRestants}}
                            </span>
                        </td>
                        <td>
                            {{garantie.item.emplacement | nomEmplacement}}
                        </td>
                        <td>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary p-mr-2 boutonGrille"
									(click)="surMenuContextuelItem($event, garantie)"></button>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucune garantie.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft" let-state>
                    {{state.totalRecords}} entrée(s).
                </ng-template>
            </p-table>



        </div>
    </div>


</div>