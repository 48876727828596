<p-toast key="selectionTransporteur"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            
            <div *ngFor="let t of transporteurs" class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <p-radioButton [label]="t.camion + ' / ' + t.remorque + ' / ' + t.chauffeur" name="transporteur" class="p-mb-3"
                        [value]="t" [(ngModel)]="transporteur"></p-radioButton>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6 p-md-3 p-lg-2">
                    <p-radioButton label="Autre" name="transporteur" [value]="" [(ngModel)]="transporteur"></p-radioButton>
                </div>
                <div class="p-field p-col-6 p-md-3 p-lg-3">
                    <label>Camion</label>
                    <input #champAutre [disabled]="transporteur" id="autre" type="text" pInputText [(ngModel)]="autreCamion" (keyup.enter)="confirmer()">
                    <small class="p-error" *ngIf="submitted && !transporteur && !autreCamion">Le camion est obligatoire.</small>
                </div>
                <div class="p-field p-col-6 p-md-3 p-lg-3">
                    <label>Remorque</label>
                    <input [disabled]="transporteur" id="autre" type="text" pInputText [(ngModel)]="autreRemorque" (keyup.enter)="confirmer()">
                    <small class="p-error" *ngIf="submitted && !transporteur && !autreRemorque">La remorque est obligatoire.</small>
                </div>
                <div class="p-field p-col-6 p-md-3 p-lg-3">
                    <label>Chauffeur</label>
                    <input [disabled]="transporteur" id="autre" type="text" pInputText [(ngModel)]="autreChauffeur" (keyup.enter)="confirmer()">
                    <small class="p-error" *ngIf="submitted && !transporteur && !autreChauffeur">Le chauffeur est obligatoire.</small>
                </div>
            </div>

         

        </div>
    
    </div>

    <div class="p-text-right">


        <button #boutonConfirmer pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
            (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

    </div>

</div>