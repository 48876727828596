<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Rapport d'utilisation des équipements
			</h5>

<!-- 
			<p-menubar [model]="menu">
			</p-menubar> -->
		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView orientation="left" [(activeIndex)]="tabIndex" >
				<p-tabPanel header="Critères" leftIcon="pi pi-info-circle">
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date de début<span class="requis">*</span></label>
							<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date de fin<span class="requis">*</span></label>
							<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-4">
							<label for="categorie">Catégorie</label>
							<p-dropdown [(ngModel)]="selectionCategorie" inputId="categorie" [options]="categories"
								optionLabel="nom" placeholder="Sélectionnez..."
								appendTo="body">
								<ng-template let-option pTemplate="item">
									<span>{{option.nom}}</span>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !selectionCategorie">La catégorie est obligatoire.</small>
						</div>
						<div class="p-field p-col-12 p-lg-4">
	
							<label for="lastname">Emplacement courant</label>
							<p-dropdown [options]="emplacements" [(ngModel)]="selectionEmplacement" optionLabel="nom"
								appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
								placeholder="Sélectionner un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="selectionEmplacement">
										<div>{{selectionEmplacement.nom}} ({{selectionEmplacement.code}})</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="p-field p-col-12 p-lg-4">
							<label for="lastname">Type de facturation</label>
							<p-dropdown [(ngModel)]="typeFacturation" inputId="typeFacturation"
								[options]="typesFacturation"  optionLabel="nom"
								placeholder="Sélectionnez..." appendTo="body">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="typeFacturation">
										<div>{{typeFacturation.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-option pTemplate="item">
									<div>
										<div>{{option.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
					</div>
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<button pButton pRipple label="Générer rapport" icon="pi pi-chart-bar" class="p-button-primary"
            					(click)="generer()"></button>
						</div>
					</div>
					

				</p-tabPanel>
				<p-tabPanel header="Résultat" leftIcon="pi pi-list">
					<p-menu #menuContextuelInventaire popup="popup" [model]="contenuMenuContextuelInventaire"></p-menu>

					<p-table  #dtResultats [value]="resulats" dataKey="resulatId"
							 tableStyleClass="tableNormale" autoLayout="true"
							styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
							[globalFilterFields]="['item.numero','item.description','item.noCtrl','emplacement.nom','emplacement.code','dateDebut','dateFin','nbJours']">
							<ng-template pTemplate="caption">
								<div class="p-d-flex p-ai-center p-jc-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtResultats.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>

									</div>
									<h5 class="titreTableau p-m-0">Résultats</h5>
									<div>
										<button pButton pRipple icon="pi pi-file-pdf"
										class="p-button-secondary p-mr-2 p-ml-4 p-mb-2 boutonGrille"
										(click)="exportExcel()"></button>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="item.numero">
										<div class="p-d-flex p-jc-between p-ai-center">
											Numéro
											<p-sortIcon field="item.numero"></p-sortIcon>
											<p-columnFilter type="text" field="item.numero" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.noCtrl">
										<div class="p-d-flex p-jc-between p-ai-center">
											Numéro CTRL
											<p-sortIcon field="item.noCtrl"></p-sortIcon>
											<p-columnFilter type="text" field="item.noCtrl" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.description">
										<div class="p-d-flex p-jc-between p-ai-center">
											Description
											<p-sortIcon field="item.description"></p-sortIcon>
											<p-columnFilter type="text" field="item.description" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="emplacement.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Emplacement (Nom)
											<p-sortIcon field="emplacement.nom"></p-sortIcon>
											<p-columnFilter field="emplacement.nom" matchMode="in" display="menu"
												[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
												<ng-template pTemplate="filter" let-value let-filter="filterCallback">
													<p-multiSelect [ngModel]="value" [options]="filtreEmplacement" placeholder="Any"
														(onChange)="filter($event.value)" optionLabel="name">
														<ng-template let-option pTemplate="item">
															<div class="p-multiselect-representative-option">
																<span class="p-ml-1">{{option}}</span>
															</div>
														</ng-template>
													</p-multiSelect>
												</ng-template>
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="emplacement.code">
										<div class="p-d-flex p-jc-between p-ai-center">
											Emplacement (Code)
											<p-sortIcon field="emplacement.code"></p-sortIcon>
											<p-columnFilter type="text" field="emplacement.code" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									
									<th pSortableColumn="dateDebut">
										<div class="p-d-flex p-jc-between p-ai-center">
											Date de début
											<p-sortIcon field="dateDebut"></p-sortIcon>
											<p-columnFilter type="date" field="dateDebut" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="dateFin">
										<div class="p-d-flex p-jc-between p-ai-center">
											Date de fin
											<p-sortIcon field="dateFin"></p-sortIcon>
											<p-columnFilter type="date" field="dateFin" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="nbJours">
										<div class="p-d-flex p-jc-between p-ai-center">
											Nombre de jours
											<p-sortIcon field="nbJours"></p-sortIcon>
											<p-columnFilter type="numeric" field="nbJours" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									
									<th style="min-width: 5rem"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-info>

								<tr >
									<td>{{info.item.numero}}</td>
									<td>{{info.item.noCtrl}}</td>
									<td>{{info.item.description}}</td>
									<td>{{info.emplacement.nom}}</td>
									<td>{{info.emplacement.code}}</td>
									<td>{{info.dateDebut | date:'yyyy-MM-dd'}}</td>
									<td>{{info.dateFin | date:'yyyy-MM-dd'}}</td>
									<td>{{info.nbJours}}</td>
									<td>
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary p-mr-2 boutonGrille"
											(click)="surMenuContextuelInventaire($event, info)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="6">Aucune utilisation a afficher.</td>
								</tr>
							</ng-template>
						</p-table>

				</p-tabPanel>
				
			</p-tabView>
		</div>
	</div>


</div>

<!-- 

<p-dialog [(visible)]="dialogueAjustementInventaire" [style]="{width: '40%'}" header="Ajustement d'inventaire"
	[modal]="true" [contentStyle]="{'overflow': 'visible'}" styleClass="p-fluid p-formgrid p-grid">
	<ng-template pTemplate="content">

		<div class="p-formgrid p-grid">
			<div class="p-field p-col-12 p-lg-8">
				<label for="noFournisseur">Quantité</label>
				<p-inputNumber id="noFournisseur">
				</p-inputNumber>
			</div>
		</div>

	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
			(click)="hideDialog()"></button>
		<button pButton pRipple label="Ajuster" icon="pi pi-check" class="p-button-text"
			(click)="ajusterInventaire()"></button>
	</ng-template>
</p-dialog> -->