import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ValeurAttribut } from 'src/app/entites/valeur-attribut';
import { CategorieMin } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { SelectionItemSimpleComponent, ResultatSelectionItemSimple } from 'src/app/controles/selection-item-simple.component';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { SoumissionEntite } from 'src/app/entites/soumission';
import { SoumissionService } from 'src/app/services/soumission-service';
import { ItemSoumissionEntite } from 'src/app/entites/item-soumission';
import { SaisieDetailItemSoumissionComponent, ParametresSaisieDetailItemSoumission } from 'src/app/controles/saisie-detail-item-soumission.component';
import { SelectionItemMultipleComponent, ResultatSelectionItemMultiple } from 'src/app/controles/selection-item-multiple.component';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './detail-soumission.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailSoumissionComponent extends BaseComponent implements OnInit {

    soumissionId: number;
    soumission: SoumissionEntite;

    menu: MenuItem[];

    fournisseurs: Fournisseur[];

    selectionItem: ItemSoumissionEntite;
    selectionMultipleItem: ItemSoumissionEntite[];
    contenuMenuContextuelItem: MenuItem[];
    @ViewChild('menuContextuelItem') menuContextuelItem: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private soumissionService: SoumissionService,
        private messageService: MessageService,
        private rapportService: RapportService,
        private fournisseurService: FournisseurService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Détail de la soumission' }
        ]);

        this.route.params.subscribe(params => this.soumissionId = params.id);
    }

    ngOnInit() {

        

        this.verifierAccesPage('soumission_detail');

        this.fournisseurService.ObtenirFournisseurs().then(res => {
            if (res) {
                this.fournisseurs = res.valeur;
            }
        });

        this.soumissionService.ObtenirSoumission(this.soumissionId).then(res => {
        
            this.soumission = res.valeur;

            this.soumission.dateDebut = new Date(this.soumission.dateDebut);
            this.soumission.dateFin = new Date(this.soumission.dateFin);
            this.soumission.dateDebutSaisie = new Date(this.soumission.dateDebutSaisie);
            this.soumission.dateFinSaisie = new Date(this.soumission.dateFinSaisie);

            this.CompleterChargement(`Soumission - ${this.soumission.fournisseur.nom}`);

           this.initialiserMenu();
        });       
    }

    initialiserMenu() {
        this.estModifiable = this.soumission.statut.statutId == EStatuts.EnPreparation,
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('soumissions')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print',
                command: _ => this.imprimer()
            },
            {
                label: 'Envoyer', icon: 'pi pi-fw pi-send',
                visible: this.estAutorise('soumission_courriel'),
                disabled: this.soumission.statut.statutId != EStatuts.EnPreparation && this.soumission.statut.statutId != EStatuts.EnAttente && this.soumission.statut.statutId != EStatuts.EnCours,
                command: () => this.routerService.navigateByUrl('soumission/courriel/' + this.soumissionId)
            },
            {
                label: 'Actions', icon: 'pi pi-fw pi-bars',
                items: [
                    {
                        label: 'Compléter', icon: 'pi pi-fw pi-dollar',
                        visible: this.estAutorise('soumission_supprimer'),
                        disabled: this.soumission.statut.statutId != EStatuts.EnPreparation && this.soumission.statut.statutId != EStatuts.EnAttente && this.soumission.statut.statutId != EStatuts.EnCours,
                         command: () => this.completer()
                    },
                    {
                        label: 'Transférer en prix fournisseurs', icon: 'pi pi-fw pi-dollar',
                        visible: this.estAutorise('soumission_action_transfert_prix'),
                        disabled: this.soumission.statut.statutId != EStatuts.Complete,
                         command: () => this.transfererEnPrix()
                    }
                ]
            },
            {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars',
                disabled: !this.selectionMultipleItem || this.selectionMultipleItem.length == 0,
                items: [
                    { 
                        label: 'Supprimer', 
                        icon: 'pi pi-fw pi-times', 
                        disabled: !this.estModifiable,
                        command: () => this.supprimerItems(this.selectionMultipleItem) 
            }
                ]
            },
        ];
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportSoumission(this.soumissionId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    btnModifier_click() {
        this.Modifier();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.soumissionService.ObtenirSoumission(this.soumissionId).then(res => {

            this.soumission = res.valeur;
            this.CompleterChargement();
            this.Consulter();

        });        
    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.validerModificationItem()) {
            this.DemarrerChargement();
            this.soumissionService.ModifierSoumission(this.soumission).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Soumission modifiée.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModificationItem(): boolean {

        if (!this.soumission.fournisseur || !this.soumission.dateDebut || !this.soumission.dateFin || !this.soumission.dateDebutSaisie || !this.soumission.dateFinSaisie) {
            return false;
        }

        return true;
    }

    surAjoutItems() {
        
        const ref = this.dialogService.open(SelectionItemMultipleComponent, {
            data: {
                itemIdsExclus: this.soumission.itemsSoumission.map(x => x.item.itemId)
            },
            header: 'Sélection des items',
            width: '95%'
        }).onClose.subscribe((resSelection: ResultatSelectionItemMultiple) => {
            if (resSelection) {
                console.log(resSelection);

                var items: ItemSoumissionEntite[] = [];

                resSelection.items.forEach(item => {
                    items.push({
                        soumissionId: this.soumission.soumissionId,
                        item: item
                    })
                });

                this.soumissionService.CreerItemsSoumission(items).then(resCreationItems => {
                    this.Debloquer();
                    if (resCreationItems.estUnSucces) {
                        resCreationItems.valeur.forEach(element => {
                            this.soumission.itemsSoumission.push(element);    
                        });
                        
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Les items ont été ajoutés.` });

                    } else {
                        resCreationItems.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });

    }

    surAjoutItem() {
        

        const ref = this.dialogService.open(SelectionItemSimpleComponent, {
            data: {
                choixCategorieDisponible: false,
                categorieIds: [3]
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItemSimple) => {
            if (res) {
                this.Bloquer();

                this.fournisseurService.ObtenirDernierPrix(this.soumission.fournisseur.fournisseurId, res.item.itemId).then(resPrix => {

                    this.Debloquer();

                const ref2 = this.dialogService.open(SaisieDetailItemSoumissionComponent, {
                    data: {
                        numeroFournisseur: resPrix?.valeur?.numeroFournisseur,
                        prix: resPrix?.valeur?.prix
                    },
                    header: 'Détail de l\'item en soumission',
                    width: '50%'
                }).onClose.subscribe((resPrix: ParametresSaisieDetailItemSoumission) => {
                    if (resPrix) {
                       this.Bloquer();
                        this.soumissionService.CreerItemSoumission({
                            soumissionId: this.soumission.soumissionId,
                            item: res.item,
                            numeroFournisseur: resPrix.numeroFournisseur,
                            prix: resPrix.prix,
                            note: ''
                        }).then(res => {
                            this.Debloquer();
                            if (res.estUnSucces) {
                                this.soumission.itemsSoumission.push(res.valeur);
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res.valeur.item.description} (${res.valeur.item.numero})' a été ajouté.` });
        
                            } else {
                                res.messages.forEach(x => {
                                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                });
                            }
                        });
                    }
    
                });

                });

                
            }

            


        }); 

    }

    OuvrirDetailItem(itemId: number) {
        this.routerService.navigateByUrl(`item/${itemId}`)
        this.messageService.add({ severity: 'info', summary: 'Item Selected', detail: itemId.toString() });
    }

    transfererEnPrix() {

        this.routerService.navigateByUrl('soumission/transfertprix/' + this.soumissionId)

    }

    completer()
    {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment marquer la soumission comme étant "Complétée" ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                

            }
        });
    }

    surMenuContextuelItem(event, item: ItemSoumissionEntite) {
        this.selectionItem = item;

        this.contenuMenuContextuelItem = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl('item/' + this.selectionItem.item.itemId) },
            { separator: true },
            { label: 'Supprimer', icon: 'pi pi-fw pi-times', 
                disabled: !this.estModifiable,
                command: () => this.supprimerItem(item) 
            }
        ];

        this.menuContextuelItem.toggle(event);

    }

    supprimerItem(item: ItemSoumissionEntite) {
        
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer l\'item sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.soumissionService.SupprimerItemsSoumission([item.itemSoumissionId]).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.soumission.itemsSoumission = this.soumission.itemsSoumission.filter(x => x.itemSoumissionId != item.itemSoumissionId)
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    supprimerItems(items: ItemSoumissionEntite[]) {
        
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer les items sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                var ids = items.map(x => x.itemSoumissionId);
                this.soumissionService.SupprimerItemsSoumission(ids).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.soumission.itemsSoumission = this.soumission.itemsSoumission.filter(x => !ids.includes(x.itemSoumissionId))
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Items supprimés.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    surChangementSelection() {
        this.initialiserMenu();
    }
}
