import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppMainComponent } from '../app.main.component';

export enum ETypeBoutonTableau {
    Consulter = 1,
    Modifier = 2,
    Supprimer = 3,
    Menu = 4
}

@Component({
    selector: 'app-bouton-tableau',
    templateUrl: './bouton-tableau.component.html',
})
export class BoutonTableauComponent extends BaseComponent {

    @Input() type: ETypeBoutonTableau;
    estFavori: boolean = false;

    @Output('click') evenementClick = new EventEmitter<any>();

    @ViewChild('champQuantite') inputQuantite;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        private messageService: MessageService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {
        
    }


    surClick(event) {
        this.evenementClick.emit(event);
    }

}