import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { SeveriteEntite } from 'src/app/entites/mecanique/severite';
import { CodeVmrsEntite } from 'src/app/entites/mecanique/code-vmrs';
import { EmployeEntite } from 'src/app/entites/employe';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ItemMin } from 'src/app/entites/item-min';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { PieceService } from 'src/app/services/piece-service';
import { Item } from 'src/app/entites/item';
import { CategorieMin } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { Emplacement } from 'src/app/entites/emplacement';
import { CompagnieEntite } from 'src/app/entites/compagnie';
import { LocalisationEntrepotEntite } from 'src/app/entites/localisation-entrepot';
import { TypeFacturationEntite } from 'src/app/entites/type-facturation';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { EStatutsSpeciaux } from 'src/app/entites/enums/statuts-speciaux';

export interface ParametresCreationItem {
    description?: string;
    marque?: string;
    modele?: string;
}

@Component({
    selector: 'app-creation-item',
    templateUrl: './creation-item.component.html'
})
export class CreationItemComponent extends BaseComponent {

    donnees: ParametresCreationItem;

    nouvelItem: Item;
    listeLocalisations: LocalisationEntrepotEntite[];
    
    listeFournisseurs: Fournisseur[];
    prixFournisseur: PrixFournisseurEntite;

    descriptions: string[];
    suggestionDescription: string[];

    marques: string[];
    suggestionMarque: string[];

    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champDescription') inputDescription;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public pieceService: PieceService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public dialogService: DialogService,
        public itemService: ItemService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.descriptions = this.sdb.Descriptions();
        this.marques = this.sdb.Marques();

        this.listeLocalisations = this.sdb.LocalisationsEntrepot();


        this.nouvelItem = {
            itemId: 0,
            //TODO
            categorie: this.sdb.Categorie(3),
            description: this.donnees.description,
            marque: this.donnees.marque,
            modele: this.donnees.modele
        };

        this.prixFournisseur = {
            prixFournisseurId: 0,
            fournisseur: null
        };

        this.fournisseurService.ObtenirFournisseurs().then(res => {
            this.listeFournisseurs = res.valeur;
            this.CompleterChargement();

            setTimeout(() => { this.inputDescription.focusInput(); }, 100);
        });
      
    }

    annuler() {
        this.ref.close(null);
    }

    enregistrer() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        this.itemService.CreerItem(this.nouvelItem).then(x => {
            if (x.estUnSucces) {
                this.Debloquer()
                this.blocked = false;
                this.ref.close(x.valeur);
            } else {
                x.messages.forEach(x => {
                    this.messageService.add({ key: "creationPiece", severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });

            }

        });

    }

    valider() {
        if (!this.nouvelItem.categorie) {
            return false;
        }

    
        return true;
        
    }

    rechercherDescritpion(event) {
        //this.suggestionsFiltrees = this.suggestionsDescription.filter(x => x.toLowerCase().startsWith(this.nouvelItem.description.toLowerCase()));
        this.suggestionDescription = this.descriptions.filter(x => this.nouvelItem.description.localeCompare(x.substring(0, this.nouvelItem.description.length), 'en', { sensitivity: 'base' }) == 0);
    }

    rechercherMarque(event) {
        this.suggestionMarque = this.marques.filter(x => this.nouvelItem.marque.localeCompare(x.substring(0, this.nouvelItem.marque.length), 'en', { sensitivity: 'base' }) == 0);
    }


}