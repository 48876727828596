import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { BaseComponent } from 'src/app/base/base-component';
import { ItemMin } from 'src/app/entites/item-min';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { RequisitionService } from 'src/app/services/requisition-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { ListeVerificationEntite } from 'src/app/entites/mecanique/liste-verification';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { RequeteAjouterEtapesBonTravail } from 'src/app/services/requetes/requete-ajouter-etapes-bon-travail';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { DetailEntretienCorrectifComponent } from 'src/app/controles/mecanique/detail-entretien-correctif.component';
import { EStatuts } from 'src/app/entites/enums/statuts';

//TODO : Ajouter les paramètres pour désactiver les entretiens déjà présents dans le bon de travail.
export interface ParametresSelectionEtapes {
    itemId: number;
    bonTravailId: number;
    entretienPreventifIds?: number[];
    entretienCorrectifIds?: number[];
}

export interface ResultatSelectionEtapes {
    etapesPreventif?: EtapeBonTravailEntite[];
    etapesCorrectif?: EtapeBonTravailEntite[];
    etapesVerification?: EtapeBonTravailEntite[];
    etapeManuel?: EtapeBonTravailEntite;
}

@Component({
    selector: 'app-selection-etapes',
    templateUrl: './selection-etapes.component.html'
})
export class SelectionEtapesComponent extends BaseComponent {

    donnees: ParametresSelectionEtapes;

    submitted: boolean;

    menu: MenuItem[];

    entretiensPreventifs: EntretienPreventifEntite[];
    selectionentretiensPreventifs: EntretienPreventifEntite[];

    entretiensCorrectifs: EntretienCorrectifEntite[];
    selectionentretiensCorrectifs: EntretienCorrectifEntite[];

    listesVerification: ListeVerificationEntite[];
    selectionListesVerification: ListeVerificationEntite[];

    categoriesEntretien: CategorieEntretienEntite[];
    selectionCategorieEntretien: CategorieEntretienEntite;
    description: string;
    note: string;

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        public itemService: ItemService,
        public entretienPreventifService: EntretienPreventifService,
        public entretienCorrectifService: EntretienCorrectifService,
        public fournisseurService: FournisseurService,
        public listeVerificationService: ListeVerificationService,
        public bonTravailService: BonTravailService,
        public messageService: MessageService,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.categoriesEntretien = this.sdb.CategoriesEntretien();

        var p1 = new Promise<number>((resolve, reject) => {
            this.entretienPreventifService.ObtenirParItem(this.donnees.itemId).then(x => {
                if (this.donnees.entretienPreventifIds) {
                    this.entretiensPreventifs = x.valeur.filter(e => !this.donnees.entretienPreventifIds.includes(e.entretienPreventifId));
                } else {
                    this.entretiensPreventifs = x.valeur;
                }

                resolve();

            });
        });

        var p2 = new Promise<number>((resolve, reject) => {
            this.entretienCorrectifService.ObtenirParItem(this.donnees.itemId).then(x => {
                if (this.donnees.entretienPreventifIds) {
                    this.entretiensCorrectifs = x.valeur.filter(e => !this.donnees.entretienCorrectifIds.includes(e.entretienCorrectifId) && e.statut.statutId != EStatuts.Complete && e.statut.statutId != EStatuts.EnCours);
                } else {
                    this.entretiensCorrectifs = x.valeur;
                }

                resolve();

            });
        });

        var p3 = new Promise<number>((resolve, reject) => {
            this.listeVerificationService.ObtenirListes().then(x => {
                this.listesVerification = x.valeur;

                resolve();

            });
        });


        Promise.all([p1, p2, p3]).then(x => {

            this.CompleterChargement();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
        });

    }

    annuler() {
        this.ref.close(null);
    }

    surSelection() {
        var retour: ResultatSelectionEtapes = {
        };

        if (this.selectionentretiensPreventifs) {
            retour.etapesPreventif = [];

            this.selectionentretiensPreventifs.forEach(ent => {
                var etape: EtapeBonTravailEntite = {
                    etapeBonTravailId: 0,
                    entretienPreventif: ent,
                    categorie: ent.categorie,
                    description: ent.description,
                    indAFaire: true,
                    indComplete: false,
                    typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienPreventif)
                }
    
                retour.etapesPreventif.push(etape);
            });

        }

        if (this.selectionentretiensCorrectifs) {
            retour.etapesCorrectif = [];

            this.selectionentretiensCorrectifs.forEach(ent => {
                var etape: EtapeBonTravailEntite = {
                    etapeBonTravailId: 0,
                    entretienCorrectif: ent,
                    categorie: ent.categorie,
                    description: ent.description,
                    note: ent.note,
                    indAFaire: true,
                    indComplete: false,
                    typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienCorrectif)
                }
    
                retour.etapesCorrectif.push(etape);
            });
        }

        if (this.description) {
            retour.etapeManuel = {
                etapeBonTravailId: 0,
                categorie: this.selectionCategorieEntretien,
                description: this.description,
                note: this.note,
                indAFaire: true,
                indComplete: false,
                typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.Autre)
            }
        }

        if (this.selectionListesVerification) {
            retour.etapesVerification = [];

            this.selectionListesVerification.forEach(liste => {

                liste.items.forEach(item => {
                   var etape: EtapeBonTravailEntite = {
                       etapeBonTravailId: 0,
                       itemListeVerification: item,
                       categorie: item.categorie,
                       description: item.description,
                       indAFaire: true,
                       indComplete: false,
                       typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.ListeVerificaton)
                   }
       
                   retour.etapesVerification.push(etape);
                });
               });
        }

        this.ref.close(retour);
    }

    surEntreeRecherchePreventifs() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            console.log(this.tableItems.filteredValue[0].itemId)
            this.surSelectionEntretiensPreventifs([this.tableItems.filteredValue[0]]);
        }

    }



    surSelectionEntretiensPreventifs(entretiens: EntretienPreventifEntite[]) {

        var retour: ResultatSelectionEtapes = {
            etapesPreventif: []
        };
        entretiens.forEach(ent => {
            var etape: EtapeBonTravailEntite = {
                etapeBonTravailId: 0,
                entretienPreventif: ent,
                categorie: ent.categorie,
                description: ent.description,
                indAFaire: true,
                indComplete: false,
                typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienPreventif)
            }

            retour.etapesPreventif.push(etape);
        });

        this.ref.close(retour);

    }


    surEntreeRechercheCorrectifs() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            console.log(this.tableItems.filteredValue[0].itemId)
            this.surSelectionEntretiensCorrectifs([this.tableItems.filteredValue[0]]);
        }

    }



    surSelectionEntretiensCorrectifs(entretiens: EntretienCorrectifEntite[]) {

        var retour: ResultatSelectionEtapes = {
            etapesCorrectif: []
        };

        entretiens.forEach(ent => {
            var etape: EtapeBonTravailEntite = {
                etapeBonTravailId: 0,
                entretienCorrectif: ent,
                categorie: ent.categorie,
                description: ent.description,
                note: ent.note,
                indAFaire: true,
                indComplete: false,
                typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienCorrectif)
            }

            retour.etapesCorrectif.push(etape);
        });

        this.ref.close(retour);
    }

    nouveauEntretienCorrectif() {
        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: {
                    entretienCorrectifId: 0,
                    item: {
                        itemId: this.donnees.itemId
                    },
                    employe: this.profilService.ProfilCourant()
                }
            },
            header: `Ajouter un entretien correctif`,
            width: '75%'
        }).onClose.subscribe(res => {

            if (res) {
                if (!this.entretiensCorrectifs) {
                    this.entretiensCorrectifs = [];
                }
                this.entretiensCorrectifs.push(res);
                var selections: number[] = [];
                if (this.selectionentretiensCorrectifs) {
                    selections = this.selectionentretiensCorrectifs.map(x => x.entretienCorrectifId);
                }
                selections.push(res.entretienCorrectifId);
                this.selectionentretiensCorrectifs = this.entretiensCorrectifs.filter(x => selections.includes(x.entretienCorrectifId));
                //this.selectionentretiensCorrectifs.push(res);
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif créé.' });
            }

        });
    }

    ajouterManuel() {

        var retour: ResultatSelectionEtapes = {
            etapeManuel: {
                etapeBonTravailId: 0,
                categorie: this.selectionCategorieEntretien,
                description: this.description,
                note: this.note,
                indAFaire: true,
                indComplete: false,
                typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.Autre)
            }
        };

        this.ref.close(retour);
      
    }


    surEntreeRechercheListeVerification() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            console.log(this.tableItems.filteredValue[0].itemId)
            this.surSelectionEntretiensCorrectifs([this.tableItems.filteredValue[0]]);
        }

    }



    surSelectionListeVerifications(listes: ListeVerificationEntite[]) {

        var retour: ResultatSelectionEtapes = {
            etapesVerification: []
        };


        listes.forEach(liste => {

         liste.items.forEach(item => {
            var etape: EtapeBonTravailEntite = {
                etapeBonTravailId: 0,
                itemListeVerification: item,
                categorie: item.categorie,
                description: item.description,
                indAFaire: true,
                indComplete: false,
                typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.ListeVerificaton)
            }

            retour.etapesVerification.push(etape);
         });
        });

        this.ref.close(retour);

    }

}