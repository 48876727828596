import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';



@Component({
    templateUrl: './dupliquer-liste-verification.component.html'
})
export class DupliquerListeVerificationComponent extends BaseComponent {

    nom?: string;
    description?: string;
    
            
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        appMain: AppMainComponent,
        public routerService: Router,
        public ref: DynamicDialogRef, 
        private listeVerificationService: ListeVerificationService,
        public config: DynamicDialogConfig) {
            super(sdb, profilService, route, routerService, appMain);
         }

    ngOnInit() {

    }


    Confirmer() {
        this.Bloquer();
        this.listeVerificationService.DupliquerListe(this.config.data, {
            nom: this.nom,
            description: this.description
        }).then(x => {
            this.Debloquer();
            if (x.estUnSucces) {
                this.ref.close(x.valeur.listeVerificationId)
            } 

        });


    }

    Annuler() {
        this.ref.close(null);
    }
}