export class ReponseBase<T> {

    valeur: T;
    estUnSucces: boolean;
    messages: Message[] = [];

    constructor() {
    }

}


export class Message {
    type: string;
    code: string;
    description: string;

    constructor() {
    }
}
