<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-menubar [model]="menu">
			</p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-6 p-lg-3">
						<label for="lastname">Date</label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDocument" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
					</div>
					<div *ngIf="modeDocument == 1" class="p-field p-col-12 p-md-6 p-lg-3">
						<label for="lastname">Type</label>
						<p-dropdown [options]="typesDocument" [(ngModel)]="selectionTypeDocument" optionLabel="nom"
								 appendTo="body" filter="true" filterBy="nom"
								placeholder="Sélectionnez un type de document...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="selectionTypeDocument">
										<div>{{selectionTypeDocument.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-compagnie pTemplate="item">
									<div>
										<div>{{compagnie.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !selectionTypeDocument">Le type de document.</small>
					</div>
					<div class="p-field p-col-12">
						<p-fileUpload #fu name="myfile[]" 
						multiple="multiple"
						customUpload="true"
						mode="advanced"
						maxFileSize="209715200"
						invalidFileSizeMessageSummary="{0}: Fichier trop volumineux, "
						invalidFileSizeMessageDetail="la grosseur maximale est {0}."
						chooseLabel="Ajouter"
						uploadLabel="Confirmer"
						cancelLabel="Annuler"
						(onUpload)="onBasicUpload($event)" 
						(uploadHandler)="onUpload($event)">
					</p-fileUpload>
					</div>
				</div>
		</div>
	</div>


</div>