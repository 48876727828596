export enum EStatuts {
    Actif = 1,
    Archive = 2,
    EnCours = 3,
    Annule = 4,
    EnAttente = 5,
    EnApprobation = 6,
    Envoye = 7,
    Commande = 8,
    Pret = 9,
    EnTraitement = 10,
    Charge = 11,
    EnReparation = 12,
    Complete = 13,
    EnRetard = 14,
    Incomplet = 15,
    Nouveau = 16,
    Traite = 17,
    EnErreur = 18,
    Inactif = 19,
    EnPreparation = 20,
    EnCorrection = 21,
    EnSoumission = 22,
    Ouvert = 23,
    OK = 24,
    AFaire = 25,
    Inconnu = 26,
    ATraiter = 27
}