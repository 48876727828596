<p-toast key="rechercheAdresse"></p-toast>


<div #contenu >
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6">
        <label for="numero">Numéro</label>
        <input #champNumero type="text" pInputText id="numero" [(ngModel)]="numero" autocomplete="off" (keyup.enter)="confirmer()" />
    </div>
    <div class="p-field p-col-12 p-md-6">
        <label for="codePostal">Code postal</label>
        <input type="text" pInputText id="codePostal" [(ngModel)]="codePostal" autocomplete="off" (keyup.enter)="confirmer()" />
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12 p-text-right ">
        <button pButton pRipple label="Rechercher" icon="pi pi-save" class="p-button-primary" [disabled]="!numero || !codePostal"
        (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>