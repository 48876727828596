<p-toast key="ajustement"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>



<div #contenu *ngIf="EstCharge()">

    <div class="p-col-12">
        <div class="card">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6 p-lg-4">
                    <label for="lastname">Date</label>
                    <p-calendar appendTo="body" showIcon=true [(ngModel)]="heure.dateNouvellesKms" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !heure.dateNouvellesKms">La date est obligatoire.</small>
                </div>
              
                <div class="p-field p-col-12 p-md-6 p-lg-4">
                    <label for="chauffeur">Nb KM</label>
                    <p-inputNumber #champNbHeures  [(ngModel)]="heure.kms" (keyup.enter)="creerReleveHeure()"></p-inputNumber>
                    <small class="p-error" *ngIf="submitted && !heure.kms && heure.kms != 0">Le nombre de KM est obligatoire.</small>
                </div>
            </div>

            <div class="p-grid">
                <div class="p-col-12 p-text-right p-mt-3">
                    <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
                        (click)="confirmer()"></button>
                    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
                        (click)="annuler()"></button>
                </div>
            </div>

        </div>
    </div>

</div>