<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>



<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
				<div class="p-fluid p-formgrid p-grid">
					
					<div class="p-field p-col-12 p-lg-6">
						<label for="lastname">Fournisseur</label>
								<p-dropdown [options]="fournisseurs" [(ngModel)]="selectionFournisseur" optionLabel="nom" [showClear]="true" 
									filter="true" filterBy="numero,nom"
									placeholder="Sélectionner un fournisseur...">
									<ng-template pTemplate="selectedItem">
										<div *ngIf="selectionFournisseur">
											<div>{{selectionFournisseur.nom}} ({{selectionFournisseur.numero}})</div>
										</div>
									</ng-template>
									<ng-template let-fournisseur pTemplate="item">
										<div>
											<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
										</div>
									</ng-template>
								</p-dropdown>
								<small class="p-error" *ngIf="submitted && !selectionFournisseur">Le fournisseur est obligatoire.</small>
					</div>
				</div>
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-6 p-lg-3">
						<label for="lastname">Date de début d'effectivité</label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						<small class="p-error" *ngIf="submitted && !dateDebut">La date de début d'effectivité est obligatoire.</small>
					</div>
					<div class="p-field p-col-12 p-md-6 p-lg-3">
						<label for="lastname">Date de fin d'effectivité</label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						<small class="p-error" *ngIf="submitted && !datefin">La date de fin d'effectivité est obligatoire.</small>
					</div>
					<div class="p-field p-col-12 p-md-6 p-lg-3">
						<label for="lastname">Date de début de disponibilité</label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebutSaisie" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						<small class="p-error" *ngIf="submitted && !dateDebutSaisie">La date de début de disponibilité est obligatoire.</small>
					</div>
					<div class="p-field p-col-12 p-md-6 p-lg-3">
						<label for="lastname">Date de fin de disponibilité</label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFinSaisie" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						<small class="p-error" *ngIf="submitted && !dateFinSaisie">La date de fin de disponibilité est obligatoire.</small>
					</div>
				</div>
				
			<div class="p-col-12 p-text-right">
				<p-button label="Dupliquer" (click)="dupliquer()" icon="pi pi-save"
					iconPos="left"></p-button>
					<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
			</div>
		</div>
	</div>


</div>