import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';
import { AdresseEntite } from '../entites/adresse';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { MessageService } from 'primeng/api';

@Component({
    templateUrl: './recherche-adresse.component.html',
})
export class RechercheAdresseComponent extends BaseComponent {

    numero?: string;
    codePostal?: string;
    
    @ViewChild('champNumero') champNumero;
            
    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public messageService: MessageService,
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {
            super(sdb, profilService, route, routerService, appMain);
         }

    ngOnInit() {

        setTimeout(() => { 
            this.champNumero.nativeElement.focus();
         }, 100);
        
    }

    surEntree() {
        this.confirmer();
    }

    confirmer() {



        this.Bloquer();

        this.sdb.ObtenirAdresse(this.numero, this.codePostal).then(res => {
            this.Debloquer();
            if (res.estUnSucces) {
                var retour: AdresseEntite = {
                    numero: res.valeur.numero,
                    rue: res.valeur.rue,
                    municipalite: res.valeur.municipalite,
                    codePostal: res.valeur.codePostal,
                    province: 'Québec',
                    pays: 'Canada'
                }
        
              this.ref.close(retour);
            } else {
                this.messageService.add({ key: 'rechercheAdresse', severity: 'error', summary: 'Erreurs', detail: 'Adresse introuvable.' });
            }
        })

       
    }

    annuler() {
        this.ref.close(null);
    }
}