import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { CategorieService } from 'src/app/services/categorie-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { EmployeService } from 'src/app/services/employe-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

export interface LienCategorieEmploye {
    categorieId: number;
    nomCategorie: string;
    utilise: boolean;
    indObligatoire: boolean;
}

@Component({
    templateUrl: './detail-employe.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailEmployeComponent extends BaseComponent implements OnInit {

    employeId: number;
    employe: EmployeEntite;

    submitted: boolean;
    menu: MenuItem[];

    liensCategorie: LienCategorieEmploye[] = [];

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private employeService: EmployeService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Détail de l\'employe' }
        ]);

        this.route.params.subscribe(params => {
            this.employeId = params.id as number;
        });
    }

    ngOnInit() {

        if (this.employeId != 0) {

            this.employeService.Obtenir(this.employeId).then(x => {
                this.employe = x.valeur;
                this.initialiserMenu();

                this.CompleterChargement();


            });
        } else {
            this.employe = {
                estSysteme: false
            }
            this.initialiserMenu();

            this.CompleterChargement();

        }
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('admin/employes')
            }
        ];
    }

    annuler() {
        this.routerService.navigateByUrl('admin/employes');
    }

    sauvegarder() {
        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        if (this.employe.employeId) {

            this.employeService.Modifier(this.employe).then(res => {
                if (res.estUnSucces) {
                    this.employe = res.valeur;

                    this.sdb.ReinitialiserEmployes();

                    this.routerService.navigateByUrl('admin/employes');
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Employe modifié' });

                    
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });

        }
        else {

            this.employeService.Ajouter(this.employe).then(res => {
                if (res.estUnSucces) {
                    this.employe = res.valeur;

                    this.sdb.ReinitialiserEmployes();

                    this.routerService.navigateByUrl('admin/employes');
                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Employe modifié' });

          
                    
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });
        }

    }

    valider() {
        return this.employe.prenom
    }

}
