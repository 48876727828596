import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { BoiteOutilService } from 'src/app/services/boite-outil-service';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { SousCategorieService } from 'src/app/services/sous-categorie-service';
import { MessageService,  ConfirmationService } from 'primeng/api';

export interface Parametres {
    sousCategorieId?: number;
    categorieId?: number;
}

@Component({
    templateUrl: './detail-sous-categorie.component.html',
})
export class DetailSousCategorieComponent extends BaseComponent {

    donnees: Parametres;

    sousCategorie: SousCategorieEntite;

    submitted: boolean = false;
    
    @ViewChild('champNom') champNom;
            
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        private sousCategorieService: SousCategorieService,
        public ref: DynamicDialogRef,
        public messageService: MessageService,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {
        
        this.donnees = this.config.data;

        if (this.donnees.sousCategorieId) {

            this.Modifier();
            this.sousCategorieService.Obtenir(this.donnees.sousCategorieId).then(x => {
                this.sousCategorie = x.valeur;
                this.CompleterChargement();

                setTimeout(() => { 
                    this.champNom.nativeElement.focus();
                 }, 100);
            });
            
        } else {

            this.Ajouter();

            this.sousCategorie = {
                categorieId: Number(this.donnees.categorieId)
            };

            this.CompleterChargement();

            setTimeout(() => { 
                this.champNom.nativeElement.focus();
             }, 100);
        }

        
        
    }

    confirmer() {

        this.submitted = true;

        if (!this.valider()) {
            return;
        }

        if (this.modeAjout()) {

            this.sousCategorieService.Ajouter(this.sousCategorie).then(x => {
                if (x.estUnSucces) {
                    this.ref.close(x.valeur);
                }
                else{
                    x.messages.forEach(x => {
                        this.messageService.add({ key: 'detailSousCategorie', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                }
            });

        } else {

            this.sousCategorieService.Modifier(this.sousCategorie).then(x => {
                if (x.estUnSucces) {
                    this.ref.close(x.valeur);
                }
                else{
                    x.messages.forEach(x => {
                        this.messageService.add({ key: 'detailSousCategorie', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                }
            });

        }


    }

    valider() {
        if (!this.sousCategorie.nom) {
            return false;
        }

        return true;
    }

    annuler() {
        this.ref.close(null);
    }
}