import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { BonCommandeEntite } from '../entites/bon-commande';
import { ItemBonCommande } from '../entites/item-bon-commande';
import { RequeteTransfererVersCtrl } from './requetes/requete-transfert-ctrl';
import { ItemService } from './item-service';

@Injectable({ providedIn: 'root' })
export class AchatService {

  private obtenirListeUrl = environment.apiBaseUrl + 'achat/bonsCommande';
  private obtenirListeParStatutUrl = environment.apiBaseUrl + 'achat/bonsCommande/?statutId={statutId}';
  private obtenirListeParEmployeUrl = environment.apiBaseUrl + 'achat/bonsCommande/?employeId={employeId}';
  private obtenirDetailUrl = environment.apiBaseUrl + 'achat/bonCommande/{bonCommandeId}';
  private creerUrl = environment.apiBaseUrl + 'achat/bonCommande';
  private modifierBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/modifier';
  private creerItemBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/item';
  private modifierItemBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/item/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'achat/bonCommande/supprimer';
  private supprimerItemUrl = environment.apiBaseUrl + 'achat/bonCommande/supprimerItems';
  private obtenirNumeroCtrlUrl = environment.apiBaseUrl + 'achat/bonCommande/obtenirNumeroCTRL';
  private transfererVersCtrlUrl = environment.apiBaseUrl + 'achat/bonCommande/transfertCTRL';
  private obtenirHsitoriqueItemUrl = environment.apiBaseUrl + 'achat/historiqueItem/{itemId}';
  private obtenirBonCommandeOuvertFournisseurUrl = environment.apiBaseUrl + 'achat/bonCommandeOuvert/{fournisseurId}/{employeId}';
  private obtenirBonCommandeLocationOuvertFournisseurUrl = environment.apiBaseUrl + 'achat/bonCommandeLocationOuvert/{fournisseurId}/{employeId}';
  private corrigerBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/corriger';
  private obtenirHistoriqueAchatPiecesUrl = environment.apiBaseUrl + 'achat/historiqueachatpieces/{uniteId}';
  private completerBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/completer';
  
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirBonsCommande(statutId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite[]>> {
      
    if (statutId) {
      var p = this.http.get<ReponseBase<BonCommandeEntite[]>>(this.obtenirListeParStatutUrl.replace('{statutId}',statutId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
    } else if (employeId) {
      var p = this.http.get<ReponseBase<BonCommandeEntite[]>>(this.obtenirListeParEmployeUrl.replace('{employeId}',employeId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
    } else {
      var p = this.http.get<ReponseBase<BonCommandeEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
    }
  }

  public ObtenirHistoriqueItem(itemId: number): Promise<ReponseBase<ItemBonCommande[]>> {
    var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.obtenirHsitoriqueItemUrl.replace('{itemId}',itemId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
}

public ObtenirHistoriqueAchatPieces(uniteId: number): Promise<ReponseBase<ItemBonCommande[]>> {
  var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.obtenirHistoriqueAchatPiecesUrl.replace('{uniteId}',uniteId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerBonCommande(bonCommandeId: number): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerUrl, JSON.stringify(bonCommandeId), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerItemsBonCommande(bonCommandeIds: number[]): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerItemUrl, JSON.stringify(bonCommandeIds), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.creerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public CreerItemBonCommande(requete: ItemBonCommande): Promise<ReponseBase<ItemBonCommande>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.creerItemBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public ModifierItemBonCommande(requete: ItemBonCommande): Promise<ReponseBase<ItemBonCommande>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.modifierItemBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public CorrigerBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<BonCommandeEntite>>(this.corrigerBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirNumeroCtrl(bonCommandeId: number): Promise<ReponseBase<string>> {
  var p = this.http.post<ReponseBase<string>>(this.obtenirNumeroCtrlUrl, JSON.stringify(bonCommandeId), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public TransfererVersCtrl(requete: RequeteTransfererVersCtrl): Promise<ReponseBase<string>> {
  var p = this.http.post<ReponseBase<string>>(this.transfererVersCtrlUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public ObtenirBonCommande(bonCommandeId: number): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite>>(this.obtenirDetailUrl.replace('{bonCommandeId}',bonCommandeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


public ModifierBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.modifierBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


public CompleterBonCommandeSansTransfertCtrl(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.completerBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirBonCommandeOuvertFournisseur(fournisseurId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite>>(this.obtenirBonCommandeOuvertFournisseurUrl.replace('{fournisseurId}',fournisseurId.toString()).replace('{employeId}',employeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirBonCommandeLocationOuvertFournisseur(fournisseurId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite[]>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite[]>>(this.obtenirBonCommandeLocationOuvertFournisseurUrl.replace('{fournisseurId}',fournisseurId.toString()).replace('{employeId}',employeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
