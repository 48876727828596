<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-contextMenu #cm [model]="menuContextuelRequisitions"></p-contextMenu>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">
           
            <p-table #dt [value]="requisitions" [columns]="selectedColumns" dataKey="requisitionId"  autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionRequisition"
                [contextMenu]="cm" [rowHover]="true" [rows]="100" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['numero','description']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                                <button *ngIf="estAutorise('requisition_creer')" pButton pRipple icon="pi pi-plus"
											class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                                            (click)="AjouterRequisition()"></button>
                                            <button pButton class="p-button-outlined boutonGrille p-mr-2 p-ml-4 p-mb-2" icon="pi pi-filter-slash" (click)="clearFilters(dt)"></button>
                        </div>
                        <h5 class="titreTableau p-m-0">Réquisitions</h5>
                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        
                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="numeric" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="date">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date
                                <p-sortIcon field="date"></p-sortIcon>
                                <p-columnFilter type="date" field="date" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                       <!--  <th pSortableColumn="type">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Type
                                <p-sortIcon field="type"></p-sortIcon>
                                <p-columnFilter type="text" field="type" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th> -->
                        <th pSortableColumn="de.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                De
                                <p-sortIcon field="de.nom"></p-sortIcon>
                                <p-columnFilter field="de.nom" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [ngModel]="value" [options]="filtreEmplacement" placeholder="Any"
                                    
                                        (onChange)="filter($event.value)" optionLabel="name">
                                        <ng-template let-option pTemplate="item">
                                            <div class="p-multiselect-representative-option">
                                                <span class="p-ml-1">{{option}}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="a.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                A
                                <p-sortIcon field="a.nom"></p-sortIcon>
                                <p-columnFilter field="a.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="filtreEmplacement" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">
                                                    <span class="p-ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('demandeur')" pSortableColumn="demandeur.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Demandeur
                                <p-sortIcon field="demandeur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="demandeur.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('camion')" pSortableColumn="camion">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Camion
                                <p-sortIcon field="camion"></p-sortIcon>
                                <p-columnFilter type="text" field="camion" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('remorque')" pSortableColumn="remorque">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Remorque
                                <p-sortIcon field="remorque"></p-sortIcon>
                                <p-columnFilter type="text" field="remorque" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('chauffeur')" pSortableColumn="chauffeur">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Chauffeur
                                <p-sortIcon field="chauffeur"></p-sortIcon>
                                <p-columnFilter type="text" field="chauffeur" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th ></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-requisition let-columns="columns">
                    <tr [pContextMenuRow]="requisition">
                        <!-- <td>
							<p-tableCheckbox [value]="Item"></p-tableCheckbox>
                        </td> -->
                   
                        <td>{{requisition.numero}}
                            <span>
                                <i *ngIf="requisition.type.nom == 'Retour'" class="pi pi-arrow-left"
                                    style="font-size: 1rem"></i>
                                    <i *ngIf="requisition.type.nom == 'Réquisition'" class="pi pi-arrow-right"
                                    style="font-size: 1rem"></i>
                                    <i *ngIf="requisition.type.nom == 'Transfert'" class="pi pi-minus"
                                    style="font-size: 1rem"></i>
                                </span>
                        </td>
                        <td>{{requisition.date | date:'yyyy-MM-dd'}}</td>
                       <!--  <td><span>
                                <i *ngIf="requisition.type.nom == 'Retour'" class="pi pi-arrow-left"
                                    style="font-size: 1rem"></i>
                                    <i *ngIf="requisition.type.nom == 'Réquisition'" class="pi pi-arrow-right"
                                    style="font-size: 1rem"></i>
                                    <i *ngIf="requisition.type.nom == 'Transfert'" class="pi pi-minus"
                                    style="font-size: 1rem"></i>
                                    {{requisition.type.nom}}
                                </span></td> -->
                                <td><span [class]="'statut ' + requisition.statut.nom.toLowerCase()">
                                    {{requisition.statut.nom}}
                                </span>
                            </td>
                        <td>{{requisition.de.nom}} ({{requisition.de.code}})</td>
                        <td>{{requisition.a.nom}} ({{requisition.a.code}})</td>
                        <td *ngIf="EstColonneSelectionnee('demandeur')">
                            {{requisition.demandeur | nomEmploye}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('camion')">
                            {{requisition.camion}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('remorque')">
                            {{requisition.remorque}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('chauffeur')">
                            {{requisition.chauffeur}}
                        </td>
                        <td>
                            <button *ngIf="estAutorise('requisition_detail')" pButton pRipple icon="pi pi-eye"
                                class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetailRequisition(requisition.requisitionId)"></button>
                             <button *ngIf="estAutorise('requisition_detail')" pButton pRipple icon="pi pi-external-link"
                                class="p-button-secondary p-mr-2 boutonGrille"
                                (click)="OuvrirDetailRequisitionNouvelOnglet(requisition.requisitionId)"></button>
                        </td>
                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">Aucune réquisition.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>

<p-sidebar [(visible)]="afficherDetailRequisition" [style]="{width:'95%'}" position="left">
	<app-detail-requisition [requisitionId]="detailRequisitionId" *ngIf="afficherDetailRequisition"></app-detail-requisition>
	</p-sidebar>