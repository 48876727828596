<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<!-- <div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Location
				<span class="statut nouveau">
					Nouveau
				</span>
			</h5>
		</div>

	</div>
</div> -->


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-divider align="left">
				<div class="p-d-inline-flex p-ai-center">
					<b>Informations générales</b>
				</div>
			</p-divider>
			<div class="p-fluid p-formgrid p-grid">

				<div class="p-field p-col-12 p-lg-3">
					<label for="lastname">Type de location<span class="requis">*</span></label>
					<p-dropdown [options]="typesLocation" [(ngModel)]="location.typeLocation" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="numero,nom"
						(onHide)="surFermetureTypeLocation()"
						placeholder="Sélectionner un type de location...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="location.typeLocation">
								<div>{{location.typeLocation.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-type pTemplate="item">
							<div>
								<div>{{type.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !location.typeLocation">Le type de location est
						obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-lg-6">
					<label for="lastname">Fournisseur<span class="requis">*</span></label>
					<p-dropdown [options]="fournisseurs" [(ngModel)]="location.fournisseur" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="numero,nom"
						placeholder="Sélectionner un fournisseur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="location.fournisseur">
								<div>{{location.fournisseur.nom}} ({{location.fournisseur.numero}})</div>
							</div>
						</ng-template>
						<ng-template let-fournisseur pTemplate="item">
							<div>
								<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !location.fournisseur">Le fournisseur est
						obligatoire.</small>
				</div>
				
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12">
					<label for="description">Description</label>
					<input type="text" pInputText id="description" [(ngModel)]="location.description"
						autocomplete="off" />
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="lastname">Date de début de location</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="location.dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040">
					</p-calendar>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="dateFin">Date prévue de fin de location</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="location.dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040">
					</p-calendar>
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-3">
					<label for="description">Valeur de l'équipement</label>
					<p-inputNumber #champPrix [(ngModel)]="location.valeurEquipement" mode="currency" currency="USD"
						locale="en-US"></p-inputNumber>
				</div>
				<div class="p-field p-col-3">
					<label for="description">Heures au début de la location</label>
					<p-inputNumber [min]="0" [(ngModel)]="location.heuresDebut"></p-inputNumber>
				</div>
			</div>
			<p-divider align="left">
				<div class="p-d-inline-flex p-ai-center">
					<b>Coûts</b>
				</div>
			</p-divider>

			<div class="p-fluid p-formgrid p-grid" *ngFor="let cout of location.couts">
				<div class="p-field p-col-3">
					<label for="descriptionCout">Description<span class="requis">*</span></label>
					<i class="pi pi-copy p-ml-3" style="font-size: 1rem" (click)="copierDescription(cout)" ></i>
					<input type="text" pInputText id="descriptionCout" [(ngModel)]="cout.description" autocomplete="off" />
				</div>
				<div class="p-field p-col-3">
					<label for="quantiteCout">Quantité</label>
					<p-inputNumber [min]="0" [(ngModel)]="cout.quantite"></p-inputNumber>
				</div>
				<div class="p-field p-col-3">
					<label for="lastname">Unité</label>
					<p-dropdown [options]="unites" [(ngModel)]="cout.unite" optionLabel="nom"
						appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
						placeholder="Sélectionner une unité...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="cout.unite">
								<div>{{cout.unite.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-unite pTemplate="item">
							<div>
								<div>{{unite.nom}} ({{unite.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="p-field p-col-2">
					<label for="prixCout">Prix</label>
					<p-inputNumber [(ngModel)]="cout.prix" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
					
				</div>
				<div class="p-field p-col-1">
					<button pButton pRipple icon="pi pi-trash"
											class="p-button-danger p-mr-2 boutonGrille"
                                            (click)="supprimerCout(cout)"></button>
					
				</div>
			</div>
			<button pButton pRipple icon="pi pi-plus"
			class="p-button-primary p-mr-2 boutonGrille"
			(click)="ajouterCout()"></button>

			<p-divider align="left">
				<div class="p-d-inline-flex p-ai-center">
					<b>Information supplémentaire</b>
				</div>
			</p-divider>
		
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12">
					<label for="lastname">Emplacement de la location<span class="requis">*</span></label>
					<p-dropdown [options]="emplacements" [(ngModel)]="location.emplacement" optionLabel="nom"
						appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
						placeholder="Sélectionner un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="location.emplacement">
								<div>{{location.emplacement.nom}} ({{location.emplacement.code}})</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !location.emplacement">L'emplacement est
						obligatoire.</small>
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12">
					<label for="description">Notes</label>
					<input type="text" pInputText id="description" [(ngModel)]="location.note" autocomplete="off" />
				</div>
			</div>
			<div class="p-col-12 p-text-right">
				<p-button label="Créer" (click)="creerLocation()" icon="pi pi-save" iconPos="left"></p-button>
			</div>
		</div>
	</div>


</div>