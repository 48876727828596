import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { CommandeMin } from '../../entites/commande-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Commande } from '../../entites/commande';
import { CommandeService } from '../../services/commande-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { EntreeCommande } from '../../entites/entree-commande';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ItemCommandeEntite } from 'src/app/entites/item-commande';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { Statut } from 'src/app/entites/statut';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { RequeteCreerItemCommande } from 'src/app/services/requetes/requete-creer-item-commande';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { ProfilService } from 'src/app/services/profil-service';
import { InfoChangementStatut } from 'src/app/entites/info-changement-statut';
import { RequeteModifierItemCommande } from 'src/app/services/requetes/requete-modifier-item-commande';
import { AppMainComponent } from 'src/app/app.main.component';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './detail-precommande.component.html',
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailPrecommandeComponent extends BaseComponent implements OnInit {

    emplacementId: number;
    emplacement: Emplacement;

    itemsCommande: ItemCommandeEntite[];
    selectionItemCommande: ItemCommandeEntite;
    selectionMultipleItemCommande: ItemCommandeEntite[] = [];
    menuContextuelEntrees: MenuItem[];
    
    codeActiviteId?: number = null;
    codeCategorieId?: number = null;

    statuts: Statut[] = this.sdb.StatutsCommande();

    ref: DynamicDialogRef;

    menu: MenuItem[];


    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        private breadcrumbService: BreadcrumbService,
        private CommandeService: CommandeService,
        private EmplacementService: EmplacementService,
        private ItemsService: ItemService,
        private messageService: MessageService,
        private rapportService: RapportService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router,
        route: ActivatedRoute,
        appMain: AppMainComponent) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([{ label: 'Détail de la pré-commande' }]);
        this.route.params.subscribe(params => { console.log(params.id); this.emplacementId = params.id as number; });
    }

    ngOnInit() {

        this.verifierAccesPage('precommande_detail');

        this.CommandeService.ObtenirPrecommande(this.emplacementId, this.profilService.ProfilCourant().employeId).then(x => {

            this.itemsCommande = x.valeur.itemsCommande;
            this.emplacement = x.valeur.emplacement;


            this.CompleterChargement(`Pré-commande - ${this.emplacement.nom} (${this.emplacement.code})`);
        });

        this.initialiserMenu();
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('precommandes')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.imprimer()
            },
            {
                label: 'Actions multiples',
                icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('precommande_modifier') || this.estAutorise('precommande_envoyer_approbation'),
                disabled: this.selectionMultipleItemCommande.length == 0,
                items: [
                    {
                        label: 'Envoyer en approbation',
                        icon: 'pi pi-fw pi-forward',
                        visible: this.estAutorise('precommande_envoyer_approbation'),
                        command: () => this.changerStatutLot(6)
                    },
                    {
                        separator: true, visible: this.estAutorise('precommande_modifier')
                    },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('precommande_modifier'),
                        command: () => this.supprimerItemsCommande(this.selectionMultipleItemCommande)
                    },
                ]
            },
        ];
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutItemActive) {
        //     this.surAjoutItem();
        // }
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportPreCommande(this.emplacementId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    estFenetreAjoutItemActive: boolean = false;
    surAjoutItem() {
        this.estFenetreAjoutItemActive = true;
        const ref = this.dialogService.open(SelectionItemComponent, {
            data: {
                type: ETypeSelectionItem.PreCommande,
                employeId: this.profilService.ProfilCourant().employeId,
                selectionCodeProjet: this.emplacement.codeProjet?.codeProjetId,
                selectionCodeActivite: this.codeActiviteId,
                selectionCodeCategorie: this.codeCategorieId
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {
            if (res) {
                
                this.estFenetreAjoutItemActive = false;
                
                var itemCommande: ItemCommandeEntite = {
                    itemCommandeId: 0,
                    emplacement: { emplacementId: this.emplacement.emplacementId },
                    item: res.item,
                    statut: this.sdb.Statut(EStatuts.EnCours),
                    employe: { employeId: this.profilService.ProfilCourant().employeId },
                    dateDemande: new Date(),
                    quantiteDemande: res.quantiteDemande,
                    quantite: res.quantiteDemande,
                    expediteur: res.expediteur,
                    demandeur: res.demandeur,
                    dateRequis: res.dateRequis,
                    codeProjet: res.codeProjet,
                    codeActivite: res.codeActivite,
                    codeCategorie: res.codeCategorie,
                    note: res.note,
                    indPrecommande: true
                }

                this.CommandeService.CreerItemCommande({
                    itemCommande: itemCommande
                }).then(res => {
                    if (res.estUnSucces) {


                        itemCommande.itemCommandeId = res.valeur.itemCommande.itemCommandeId;
                        this.itemsCommande.push(itemCommande);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${itemCommande.item.description} (${itemCommande.item.numero})' a été ajouté.` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

                this.codeActiviteId = res.codeActivite?.codeActiviteId;
                this.codeCategorieId = res.codeCategorie.codeCategorieId;
                
            }


        });

    }

    surChangementSelection() {
        this.selectionMultipleItemCommande = this.selectionMultipleItemCommande.filter(x => x.statut.statutId == EStatuts.EnCours);
        this.initialiserMenu();
    }

    supprimerItemcommnde(vent, item: ItemCommandeEntite) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer l\'item "' + item.item.description + '" ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.CommandeService.SupprimerItemCommande(item.itemCommandeId).then(res => {
                    if (res.estUnSucces) {
                        this.itemsCommande = this.itemsCommande.filter(val => val.itemCommandeId != item.itemCommandeId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    supprimerItemsCommande(items: ItemCommandeEntite[]) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer les items sélectionnnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                var ids = items.map(x => x.itemCommandeId);
                this.CommandeService.SupprimerItemsCommande(ids).then(res => {
                    if (res.estUnSucces) {

                        this.itemsCommande = this.itemsCommande.filter((i: ItemCommandeEntite) => !ids.includes(i.itemCommandeId))

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Items supprimés.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    surMenuContextuelItem(event, itemCommande: ItemCommandeEntite) {
        this.selectionItemCommande = itemCommande;

        this.menuContextuelEntrees = [
            {
                label: 'Voir l\'item',
                icon: 'pi pi-fw pi-search',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl(`item/${itemCommande.item.itemId}`)
            },
            {
                label: 'Changer la quantité',
                icon: 'pi pi-fw pi-sort',
                visible: this.estAutorise('precommande_modifier'),
                command: () => this.modifierQuantite(itemCommande)
            },
            { separator: true },
            {
                label: 'Envoyer en approbation',
                icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('precommande_envoyer_approbation'),
                command: x => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 6, itemCommande.item.description)
            },
        ];


        this.menuContextuelItemCommande.toggle(event);
    }

    modifierQuantite(itemCommande: ItemCommandeEntite) {
        const ref = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: itemCommande.quantiteDemande,
                min: 1
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((res: number) => {
            if (res) {
                this.CommandeService.ModifierqteItemCommande({
                    employe: this.profilService.ProfilCourant(),
                    emplacement: this.emplacement,
                    itemsCommande: [{
                        itemCommandeId: itemCommande.itemCommandeId,
                        quantite: res
                    }]
                }).then(res => {
                    if (res.estUnSucces) {
            
                        res.valeur.itemCommandeModifies.forEach(x => {
                            var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                            itemCommandeAModifier.quantiteDemande = x.quantiteDemande;
                            itemCommandeAModifier.quantite = x.quantite;
                        });
        
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Quantité modifiée` });
        
                        this.initialiserMenu();
        
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }).catch(() => {
                    this.routerService.navigateByUrl('error');
                });
            }

        });
    }

    changerStatut(itemCommandeId: number, quantite: number, statutId: number, description: string) {

        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment envoyer l\'item "' + description + ' en approbation" ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.confirmerChangerStatut([{
                    itemCommandeId: itemCommandeId,
                    quantite: quantite,
                    statutId: statutId,
                    description: description,
                    indPrecommande: true
                }]);
            }
        });
    }

    changerStatutLot(statutId: number) {
        if (this.selectionMultipleItemCommande.length > 0) {
            var infos: InfoChangementStatut[] = [];

            this.selectionMultipleItemCommande.forEach(ic => {
                infos.push({
                    itemCommandeId: ic.itemCommandeId,
                    quantite: ic.quantite,
                    statutId: statutId,
                    description: ic.item.description,
                    indPrecommande: true
                });
            });

            this.confirmationService.confirm({
                message: 'Voulez-vous vraiment envoyer les items sélectionnés en approbation" ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.confirmerChangerStatut(infos);
                }
            });

        }
    }

    confirmerChangerStatut(infos: InfoChangementStatut[]) {

        var requete: RequeteModifierItemCommande = {
            employe: this.profilService.ProfilCourant(),
            emplacement: this.emplacement,
            itemsCommande: []
        };

        infos.forEach(x => {
            requete.itemsCommande.push({
                itemCommandeId: x.itemCommandeId,
                quantite: x.quantite,
                statutId: x.statutId,
                indPrecommande: x.indPrecommande
            });
        });

        this.CommandeService.ModifierItemCommande(requete).then(res => {
            if (res.estUnSucces) {

                infos.forEach(i => {
                    var ic = this.itemsCommande.find(x => x.itemCommandeId == i.itemCommandeId);
                    ic.statut = this.sdb.Statut(EStatuts.EnApprobation);
                });

                this.selectionMultipleItemCommande = [];

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Envoi en approbation complété.` });

                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }
}
