import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { SoumissionService } from 'src/app/services/soumission-service';

@Component({
    templateUrl: './ajout-soumission.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutSoumissionComponent extends BaseComponent implements OnInit {


    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;

    dateDebut: Date;
    dateFin: Date;
    dateDebutSaisie: Date;
    dateFinSaisie: Date;

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private achatService: AchatService,
        private soumissionService: SoumissionService,
        private fournisseurService: FournisseurService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Ajout d\'un bon de commande' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('soumission_creer');

       // this.dateBonCommande = new Date();

        this.fournisseurService.ObtenirFournisseurs().then(x => {
            this.fournisseurs = x.valeur;
            this.CompleterChargement();
        });
    }

    CreerSoumission() {

        

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.DemarrerChargement();
            
            this.soumissionService.CreerSoumission({
                fournisseur: this.selectionFournisseur,
                dateDebut: this.dateDebut,
                dateFin: this.dateFin,
                dateDebutSaisie: this.dateDebutSaisie,
                dateFinSaisie: this.dateFinSaisie
            }).then(x => {
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('soumission/' + x.valeur);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.CompleterChargement();
                }
            });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
        }
    }

    valider(): boolean {


        if (!this.selectionFournisseur || !this.dateDebut || !this.dateFin || !this.dateDebutSaisie || !this.dateFinSaisie) {
            return false;
        }
            
        return true;
    }
}
