import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { ReparationEntite } from '../entites/reparation';
import { ReparationService } from '../services/reparation-service';
import { EStatuts } from '../entites/enums/statuts';
import { AppMainComponent } from '../app.main.component';
import { Emplacement } from '../entites/emplacement';
import { EModeAffichage } from '../base/mode-affichage';

export interface ParametresReparation {
    itemId: number;
    reparationId?: number;
}

export interface ResultatReparation {

}

@Component({
    selector: 'app-reparation',
    templateUrl: './reparation.component.html'
})
export class ReparationComponent extends BaseComponent {

    donnees: ParametresReparation;
    reparation: ReparationEntite = {

    };

    fournisseurs: Fournisseur[];
    alerteActive: boolean = false;


    emplacements: Emplacement[];

    submitted: boolean;
    blocked: boolean = false;


    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        public itemService: ItemService,
        public achatService: AchatService,
        public reparationService: ReparationService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        if (this.donnees.reparationId) {
            this.modeAffichage = EModeAffichage.Modification;
        } else {
            this.modeAffichage = EModeAffichage.Ajout;
        }
        
        this.emplacements = this.sdb.Emplacements();

        var promises: Promise<any>[] = [];



        var p1 = this.fournisseurService.ObtenirFournisseurs();
        promises.push(p1);
        p1.then(x => {
            this.fournisseurs = x.valeur;

        });

        if (this.modeModification()) {
            var p2 = this.reparationService.Obtenir(this.donnees.reparationId);
            promises.push(p2);
            p2.then(x => {
                this.reparation = x.valeur;
                this.reparation.dateEnvoi = new Date(x.valeur.dateEnvoi);
                this.reparation.dateRetour = new Date(x.valeur.dateRetour);
                this.reparation.dateRetourPrevue = new Date(x.valeur.dateRetourPrevue);

                if (this.reparation.fournisseur) {
                    this.reparation.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.reparation.fournisseur.fournisseurId);
                }

                if (this.reparation.emplacement) {
                    this.reparation.emplacement = this.emplacements.find(x => x.emplacementId == this.reparation.emplacement.emplacementId);
                }

            });
        }

        Promise.all(promises).then(x => {
            this.CompleterChargement();
        });

    }

    annuler() {
        this.ref.close(null);
    }

    sauvegarder() {

        this.submitted = true;

        if (this.modeAjout()) {
            this.ajouter();
        } else {
            this.modifier();
        }
        
    }

    ajouter() {
        if (!this.valider()) {
            this.messageService.add({ key: 'reparation', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.itemService.ModifierStatut(this.donnees.itemId, EStatuts.EnReparation).then(x => {

            var rep: ReparationEntite = {
                item: { itemId: this.donnees.itemId },
                fournisseur: { fournisseurId: this.reparation.fournisseur.fournisseurId},
                statut: this.sdb.Statut(EStatuts.EnCours),
                dateEnvoi: this.reparation.dateEnvoi,
                dateRetour: this.reparation.dateRetour,
                dateRetourPrevue: this.reparation.dateRetourPrevue,
                note: this.reparation.note,
                nbJoursAlerte: this.reparation.nbJoursAlerte,
                indAlerteTraitee: false,
                emplacement: this.reparation.emplacement,
                indRemplace: this.reparation.indRemplace
            };

            if (this.reparation.dateRetourPrevue && this.reparation.dateRetourPrevue < new Date()) {
                rep.statut = this.sdb.Statut(EStatuts.EnRetard);
            }
            
            this.reparationService.Ajouter(rep).then(res => {
                if (res.estUnSucces) {
                    this.Debloquer();
                    this.ref.close(res.valeur);
                }
            });
        });
    }

    modifier() {
        if (!this.valider()) {
            this.messageService.add({ key: 'reparation', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        if (this.reparation.dateRetourPrevue && this.reparation.dateRetourPrevue < new Date()) {
            this.reparation.statut = this.sdb.Statut(EStatuts.EnRetard);
        } else {
            this.reparation.statut = this.sdb.Statut(EStatuts.EnCours);
        }
        
        this.reparationService.Modifier(this.reparation).then(res => {
            if (res.estUnSucces) {
                this.Debloquer();
                this.ref.close(res.valeur);
            }
        });
    }

    valider() {
        return this.reparation.dateEnvoi && 
        this.reparation.fournisseur && 
        (this.reparation.dateRetourPrevue || !this.alerteActive) && 
        (this.reparation.nbJoursAlerte || !this.alerteActive);
    }
}