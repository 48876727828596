import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

export interface Parametres {
    note?: string;
}

@Component({
    templateUrl: './saisie-note.component.html',
})
export class SaisieNoteComponent {

    donnees: Parametres;

    note?: string;
    
    @ViewChild('champNote') champNote;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {

        this.note = this.config.data.note;

        setTimeout(() => { 
            this.champNote.nativeElement.focus();
         }, 100);
        
    }


    Confirmer() {
        this.ref.close(this.note);
    }

    Annuler() {
        this.ref.close(null);
    }
}