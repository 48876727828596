<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">
            <p-table #dt [value]="bonsCommande" [columns]="selectedColumns" dataKey="bonCommandeId" autoLayout="true"
                tableStyleClass="tableNormale" styleClass="p-datatable-sm p-datatable-striped"
                [(contextMenuSelection)]="selectionBonCommande" [rowHover]="true" [rows]="25" [paginator]="true"
                [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['numero','date','fournisseur.nom','fournisseur.numero','projet.nom','employe.prenomNom']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('bdc_creer')" pButton pRipple icon="pi pi-plus"
                                class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                                (click)="AjouterBdc()"></button>
                        </div>
                        <h5 class="titreTableau p-m-0">Bons de commande</h5>
                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                            optionLabel="entete"
                            selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                            placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateAchat">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date
                                <p-sortIcon field="dateAchat"></p-sortIcon>
                                <p-columnFilter type="date" field="dateAchat" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="fournisseur.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Fournisseur
                                <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('projet')" pSortableColumn="projet.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Projet
                                <p-sortIcon field="projet.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="projet.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="type">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="type"></p-sortIcon>
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">

                                                    <span class="p-ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('nbItems')" pSortableColumn="nbItems">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nb items
                                <p-sortIcon field="nbItems"></p-sortIcon>
                                <p-columnFilter type="text" field="nbItems" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('coutTotal')" pSortableColumn="coutTotal">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Coût total
                                <p-sortIcon field="coutTotal"></p-sortIcon>
                                <p-columnFilter type="text" field="coutTotal" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('employe')" pSortableColumn="employe.prenomNom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Employé
                                <p-sortIcon field="employe.prenomNom"></p-sortIcon>
                                <p-columnFilter type="text" field="employe.prenomNom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('dateEnvoi')" pSortableColumn="dateEnvoi">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date d'envoi
                                <p-sortIcon field="dateEnvoi"></p-sortIcon>
                                <p-columnFilter type="date" field="dateEnvoi" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('envoyeA')" pSortableColumn="courrielEnvoi">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Envoyé à
                                <p-sortIcon field="courrielEnvoi"></p-sortIcon>
                                <p-columnFilter type="text" field="courrielEnvoi" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 10rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bdc let-columns="columns">
                    <tr>
                        <!--  <td style="text-align: center">
                            <button pButton (click)="OuvrirDetailBdc(bdc.bdcId)" type="button"
                                class="p-button-secondary" icon="pi pi-angle-double-right"></button>
                        </td> -->
                        <td>{{bdc.numero}}</td>
                        <td>{{bdc.dateAchat | date:'yyyy-MM-dd'}}</td>
                        <td>{{bdc.fournisseur.nom}}</td>
                        <td *ngIf="EstColonneSelectionnee('projet')">{{bdc.projet?.nom}}</td>
                        <td><span [class]="'statut ' + bdc.statut.nom.toLowerCase().replace(' ', '')">
                                {{bdc.statut.nom}}
                            </span></td>
                        <td *ngIf="EstColonneSelectionnee('nbItems')">{{bdc.nbItems}} ({{bdc.nbItemsTotal}})</td>
                        <td *ngIf="EstColonneSelectionnee('coutTotal')">{{bdc.coutTotal | currency}}</td>
                        <td *ngIf="EstColonneSelectionnee('employe')">{{bdc.employe | nomEmploye}}</td>
                        <td *ngIf="EstColonneSelectionnee('dateEnvoi')">{{bdc.dateEnvoi | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td *ngIf="EstColonneSelectionnee('envoyeA')">{{bdc.courrielEnvoi}}</td>
                        <td class="celluleTableau">
                            <button *ngIf="estAutorise('bdc_detail')" pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetailBdc(bdc.bonCommandeId)"></button>
                            <button *ngIf="estAutorise('bdc_detail')" pButton pRipple icon="pi pi-external-link"
                                class="p-button-secondary p-mr-2 boutonGrille"
                                (click)="OuvrirDetailBdcNouvelOnglet(bdc.bonCommandeId)"></button>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary p-mr-2 boutonGrille"
                                (click)="surMenuContextuelItem($event, bdc)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="12">Aucun bon de commande.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>

<p-sidebar [(visible)]="afficherDetailBdc" [style]="{width:'95%'}" position="left">
    <app-detail-bdc [bdcId]="detailBdcId" *ngIf="afficherDetailBdc"></app-detail-bdc>
	</p-sidebar>