import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { SoumissionEntite } from 'src/app/entites/soumission';
import { SoumissionService } from 'src/app/services/soumission-service';
import { DupliquerSoumissionComponent } from './dupliquer-soumission.component';

@Component({
    templateUrl: './soumissions.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class SoumissionsComponent extends BaseComponent implements OnInit {

    menu: MenuItem[];

    soumissions: SoumissionEntite[];
    selectionSoumission: SoumissionEntite;

    menuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private soumissionService: SoumissionService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Soumissions' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('soumissions');

        /* this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'noSerie', entete: 'No série', afficheParDefaut: false },
            { champ: 'noPlaque', entete: 'No plaque', afficheParDefaut: false },
            { champ: 'poids', entete: 'Poids', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut); */

           this.soumissionService.ObtenirSoumissions(null).then(x => {
               this.soumissions = x.valeur;
               this.initialiserMenu();
               this.CompleterChargement();
           });

       
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Nouvelle série', icon: 'pi pi-fw pi-list', 
                visible: this.estAutorise('soumission_action_nouvelle_serie'),
                command: () => this.routerService.navigateByUrl('soumission/serie')
            },
            {
                label: 'Envoi massif des courriels d\'invitation', icon: 'pi pi-fw pi-send', 
                visible: this.estAutorise('soumission_action_envoi_massif'),
                command: () => this.routerService.navigateByUrl('soumission/courriel')
            },
            {
                label: 'Transfert massif des prix', icon: 'pi pi-fw pi-download', 
                visible: this.estAutorise('soumission_action_transfert_massif'),
                command: () => this.routerService.navigateByUrl('soumission/transfertprix')
            }
        ];
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+') {
        //     this.ajouterSoumission();
        // }
    }

    menuContextuelEntrees: MenuItem[];
    surMenuContextuelItem(event, soumission: SoumissionEntite) {
        this.selectionSoumission = soumission;

        this.menuContextuelEntrees = [
            { 
                label: 'Voir le fournisseur', 
                icon: 'pi pi-fw pi-eye', 
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + soumission.fournisseur.fournisseurId) 
            },
            {separator: true},
            { 
                label: 'Dupliquer', 
                icon: 'pi pi-fw pi-copy',
                visible: this.estAutorise('soumission_creer'),
                command: _ => this.dupliquerSoumission(soumission)
            },
            {separator: true},
            { 
                label: 'Supprimer', 
                icon: 'pi pi-fw pi-times', 
                visible: this.estAutorise('soumission_supprimer'),
                disabled: soumission.statut.statutId != EStatuts.EnCours && soumission.statut.statutId != EStatuts.EnPreparation,
                command: () => this.supprimerSoumission(soumission)
            }
        ];

        this.menuContextuelItemCommande.toggle(event);
    }

    supprimerSoumission(soumission: SoumissionEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la soumission sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.soumissionService.SupprimerSoumission(soumission.soumissionId).then(res => {
                    if (res.estUnSucces) {
                        this.soumissions = this.soumissions.filter(val => val.soumissionId != soumission.soumissionId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Soumission supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    ajouterSoumission(){
        this.routerService.navigateByUrl("soumission/nouveau")
    }

    dupliquerSoumission(soumission: SoumissionEntite){
        const ref = this.dialogService.open(DupliquerSoumissionComponent, {
            data: {
                soumissionId: soumission.soumissionId,
                fournisseurId: soumission.fournisseur.fournisseurId,
                dateDebut: soumission.dateDebut,
                dateFin: soumission.dateFin,
                dateDebutSaisie: soumission.dateDebutSaisie,
                dateFinSaisie: soumission.dateFinSaisie
            },
            header: 'Dupliquer une soumission',
            width: '70%'
        });
    }

    OuvrirDetailSoumission(soumissionId: number) {
        this.routerService.navigateByUrl(`soumission/${soumissionId}`)
    }
}
