<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-contextMenu #cm [model]="menuContextuel"></p-contextMenu>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
    <div class="p-col-12">

        <div class="card">
       
            <p-table #dt [value]="categories" dataKey="categorieId" tableStyleClass="tableNormale" autoLayout="true"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionCategorie"
                [contextMenu]="cm" [rowHover]="true" [rows]="25" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['nom','description']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                               <!--  <button pButton pRipple icon="pi pi-plus"
                            class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                            (click)="AjouterBdc()"></button> -->
                        </div>
                        <h5 class="titreTableau p-m-0">Catégories</h5>
                        <div>
                           
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        
                        <th pSortableColumn="nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="type">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Type
                                <p-sortIcon field="type"></p-sortIcon>
                                <p-columnFilter type="text" field="type" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nbAttributs">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nombre d'attributs
                                <p-sortIcon field="nbAttributs"></p-sortIcon>
                                <p-columnFilter type="numeric" field="nbAttributs" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nbItems">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nombre d'items
                                <p-sortIcon field="nbItems"></p-sortIcon>
                                <p-columnFilter type="numeric" field="nbItems" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 5rem; width: 5rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-categorie>
                    <tr [pContextMenuRow]="categorie">
                        <td>{{categorie.nom}}</td>
                        <td>{{categorie.description}}</td>
                        <td>{{categorie.type}}</td>
                        <td>{{categorie.nbAttributs}}</td>
                        <td>{{categorie.nbItems}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetail(categorie.categorieId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucune catégorie.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>