<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>


<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">
            <p-table #dt [value]="series"  dataKey="serieId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped"
                [rowHover]="true" [filterDelay]="0"
                [globalFilterFields]="['dateDebut','dateFin','nombre']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <h5 class="titreTableau p-m-0">Séries de soumission</h5>
                        <div>

                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="dateDebut">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de début
                                <p-sortIcon field="dateDebut"></p-sortIcon>
                                <p-columnFilter type="date" field="dateDebut" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateFin">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de fin
                                <p-sortIcon field="dateFin"></p-sortIcon>
                                <p-columnFilter type="date" field="dateFin" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nombre">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nombre de soumissions
                                <p-sortIcon field="nombre"></p-sortIcon>
                                <p-columnFilter type="text" field="nombre" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                    
                        <th style="min-width: 4rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-serie let-columns="columns">
                    <tr>
                        
                        <td>{{serie.dateDebut | date:'yyyy-MM-dd'}}</td>
                        <td>{{serie.dateFin | date:'yyyy-MM-dd'}}</td>
                        <td>{{serie.nombre}}</td>
                        
                        <td class="celluleTableau">
                            <button pButton pRipple icon="pi pi-check" class="p-button-success p-mr-2 boutonGrille"
                                (click)="creerSerie(serie)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucune série de soumission disponible.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>