import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { LigneKmEntite } from '../entites/mecanique/ligne-km';
import { RequeteAjouterKms } from './requetes/requete-ajouter-kms';
import { MajService } from './maj-service';
import { AjustementKmEntite } from '../entites/ajustement-km';
import { ReleveKmEntite } from '../entites/releve-kms';

@Injectable({ providedIn: 'root' })
export class KmService {

  private obtenirListeUrl = environment.apiBaseUrl + 'km';
  private obtenirListeApprobationUrl = environment.apiBaseUrl + 'km/approbation';
  private obtenirListeParItemUrl = environment.apiBaseUrl + 'km/item/{id}';
  private ajouterKmsUrl = environment.apiBaseUrl + 'km';
  private approuverKmsUrl = environment.apiBaseUrl + 'km/approuver';
  private refuserKmsUrl = environment.apiBaseUrl + 'km/refuser';
  private ObtenirListeAjustementsUrl = environment.apiBaseUrl + 'km/ajustements/item/{id}';
  private ajouterAjustementUrl = environment.apiBaseUrl + 'km/ajustement';
  private supprimerUrl = environment.apiBaseUrl + 'km/{id}';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private majService: MajService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<LigneKmEntite[]>> {
    var p = this.http.get<ReponseBase<LigneKmEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeApprobation(): Promise<ReponseBase<LigneKmEntite[]>> {
    var p = this.http.get<ReponseBase<LigneKmEntite[]>>(this.obtenirListeApprobationUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeParItem(id: number): Promise<ReponseBase<ReleveKmEntite[]>> {
    var p = this.http.get<ReponseBase<ReleveKmEntite[]>>(this.obtenirListeParItemUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(requete: RequeteAjouterKms): Promise<ReponseBase<LigneKmEntite[]>> {
    var p = this.http.post<ReponseBase<LigneKmEntite[]>>(this.ajouterKmsUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.then(() => {
        if (requete.ignorerApprobation) {
          this.majService.Traiter()
        }
        
      });

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Approuver(requete: RequeteAjouterKms): Promise<ReponseBase<LigneKmEntite[]>> {
    var p = this.http.post<ReponseBase<LigneKmEntite[]>>(this.approuverKmsUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Refuser(requete: RequeteAjouterKms): Promise<ReponseBase<LigneKmEntite[]>> {
    var p = this.http.post<ReponseBase<LigneKmEntite[]>>(this.refuserKmsUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeAjustements(itemId: number): Promise<ReponseBase<AjustementKmEntite[]>> {
    var p = this.http.get<ReponseBase<AjustementKmEntite[]>>(this.ObtenirListeAjustementsUrl.replace('{id}', itemId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterAjustement(ajustement: AjustementKmEntite): Promise<ReponseBase<AjustementKmEntite>> {
    var p = this.http.post<ReponseBase<AjustementKmEntite>>(this.ajouterAjustementUrl, JSON.stringify(ajustement), this.httpOptions)
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public SupprimerKms(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
