import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';
import { Fournisseur } from '../entites/fournisseur';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { FournisseurService } from '../services/fournisseur-service';
import { PrixFournisseurEntite } from '../entites/prix-fournisseur';

export interface ParametresConsulterFournisseurs {
    itemId: number;
    afficherSelection: boolean;
    afficherCommander: boolean;
}

@Component({
    templateUrl: './consulter-fournisseurs.component.html',
})
export class ConsulterFournisseursComponent extends BaseComponent {

    donnees: ParametresConsulterFournisseurs;

    prix: PrixFournisseurEntite[];
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        private fournisseurService: FournisseurService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {

            super(sdb, profilService, route, routerService, appMain); }

    ngOnInit() {

        this.donnees = this.config.data;

        this.fournisseurService.ObtenirListePrixActifsPourItem(this.donnees.itemId, new Date()).then(res => {
            this.prix = res.valeur;

            this.CompleterChargement();
        });        
    }

    surEntree() {
        this.ref.close(null);
    }

    selectionner(prix: PrixFournisseurEntite) {
      this.ref.close(prix);
    }

    commander(prix: PrixFournisseurEntite) {
        this.ref.close(prix);
      }

    annuler() {
        this.ref.close(null);
    }
}