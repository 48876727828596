<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
    <div class="p-col-12">

        <div class="card">
       
            <p-table #dt [value]="employes" dataKey="employeId" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionEmploye"
                 [rowHover]="true" [filterDelay]="0"
                [globalFilterFields]="['nom','prenom','prenomNom','courriel']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                                <button pButton pRipple icon="pi pi-plus"
                            class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                            (click)="ajouter()"></button>
                        </div>
                        <h5 class="titreTableau p-m-0">Employes</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="prenom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Prénom
                                <p-sortIcon field="prenom"></p-sortIcon>
                                <p-columnFilter type="text" field="prenom" display="menu"
                                    class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu"
                                    class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="courriel">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Courriel
                                <p-sortIcon field="courriel"></p-sortIcon>
                                <p-columnFilter type="text" field="courriel" display="menu"
                                    class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        
                        <th style="min-width: 5rem; width: 5rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-employe>

                    <tr >
                        <td>{{employe.prenom}}</td>
                        <td>{{employe.nom}}</td>
                        <td>{{employe.courriel}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary p-mr-2 boutonGrille"
                            (click)="surMenuContextuelItem($event, employe)"></button>
                        </td>
                    </tr>
                </ng-template>
                
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucun employe.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>
