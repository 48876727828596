import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ItemService } from './item-service';
import { EntretienPreventifEntite } from '../entites/mecanique/entretien-preventif';
import { EntretienCorrectifEntite } from '../entites/mecanique/entretien-correctif';
import { ESeverites } from '../entites/enums/severites';
import { HistoriqueEntretienPreventifEntite } from '../entites/mecanique/historique-entretien-preventif';
import { dashCaseToCamelCase } from '@angular/compiler/src/util';
import { DonneesBaseService } from './donnees-base-service';
import { EStatuts } from '../entites/enums/statuts';

@Injectable({ providedIn: 'root' })
export class EntretienCorrectifService {

  private obtenirUrl = environment.apiBaseUrl + 'entretiencorrectif';
  private obtenirParItemUrl = environment.apiBaseUrl + 'entretiencorrectif/item/{id}';
  private detailUrl = environment.apiBaseUrl + 'entretiencorrectif/{id}';
  private creerUrl = environment.apiBaseUrl + 'entretiencorrectif';
  private modifierUrl = environment.apiBaseUrl + 'entretiencorrectif/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'entretiencorrectif/{id}';
  

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private sdb: DonneesBaseService,
    public itemService: ItemService,
    private http: HttpClient,

    public router: Router) { }

    public Obtenir(): Promise<ReponseBase<EntretienCorrectifEntite[]>> {
      var p = this.http.get<ReponseBase<EntretienCorrectifEntite[]>>(this.obtenirUrl)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirParItem(id: number): Promise<ReponseBase<EntretienCorrectifEntite[]>> {
      var p = this.http.get<ReponseBase<EntretienCorrectifEntite[]>>(this.obtenirParItemUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Creer(entretien: EntretienCorrectifEntite): Promise<ReponseBase<EntretienCorrectifEntite>> {
      var p = this.http.post<ReponseBase<EntretienCorrectifEntite>>(this.creerUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Modifier(entretien: EntretienCorrectifEntite): Promise<ReponseBase<EntretienCorrectifEntite>> {
      var p = this.http.post<ReponseBase<EntretienCorrectifEntite>>(this.modifierUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }
  
    public Supprimer(id: number): Promise<ReponseBase<number>> {
      var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
