<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelInventaire popup="popup" [model]="contenuMenuContextuelInventaire"></p-menu>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div class="p-fluid p-formgrid p-grid" *ngIf="EstCharge()">
    <div class="p-field p-col-12">
        <p-message severity="warn" text="ATTENTION : Tous les items présents dans la liste ci-bas seront automatiquement archivés."></p-message> 
    </div>
</div>

<div *ngIf="EstCharge()" class="p-grid">
   
    <div class="p-col-12">
		<div class="card">
			<p-menubar [model]="menu">
			</p-menubar>
		</div>

	</div>
</div>

<div *ngIf="EstCharge()" class="p-grid">

    <div class="p-col-12">

        <div class="card">
            <p-table  #dtInventaire [value]="inventaire" dataKey="itemId"
							 tableStyleClass="tableNormale" autoLayout="true"
							styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
							[globalFilterFields]="['numero','quantite','description']">
							<ng-template pTemplate="caption">
								<div class="p-d-flex p-ai-center p-jc-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtInventaire.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>

									</div>
									<h5 class="titreTableau p-m-0">Inventaire</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="categorie.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Catégorie
											<p-sortIcon field="categorie.nom"></p-sortIcon>
											<p-columnFilter type="text" field="categorie.nom" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="numero">
										<div class="p-d-flex p-jc-between p-ai-center">
											Numéro
											<p-sortIcon field="numero"></p-sortIcon>
											<p-columnFilter type="text" field="numero" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="description">
										<div class="p-d-flex p-jc-between p-ai-center">
											Description
											<p-sortIcon field="description"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="quantite">
										<div class="p-d-flex p-jc-between p-ai-center">
											Quantité
											<p-sortIcon field="quantite"></p-sortIcon>
											<p-columnFilter type="text" field="quantite" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
                                    </th>
                                    <th style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>

								<tr >
									<td>{{item.categorie.nom}}</td>
									<td>{{item.numero}}</td>
									<td>{{item.description}}</td>
                                    <td>{{item.quantite}}</td>
                                    <td>
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary p-mr-2 boutonGrille"
											(click)="surMenuContextuelInventaire($event, item)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="5">Aucun item unique en inventaire.</td>
								</tr>
							</ng-template>
						</p-table>



        </div>
    </div>


</div>