import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { LocationEntite } from '../entites/location';
import { RequeteCreerBonLocation } from './requetes/requete-creer-bon-location';
import { BonCommandeEntite } from '../entites/bon-commande';
import { RequeteAjoutItemBonLocation } from './requetes/requete-ajout-item-bon-location';

@Injectable({ providedIn: 'root' })
export class LocationService {

  private obtenirListeUrl = environment.apiBaseUrl + 'location';
  private obtenirUrl = environment.apiBaseUrl + 'location/{id}';
  private ajouterUrl = environment.apiBaseUrl + 'location';
  private modifierUrl = environment.apiBaseUrl + 'location/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'location/{id}';
  private confirmerDebutLocationUrl = environment.apiBaseUrl + 'location/confirmerDebut';
  private finLocationUrl = environment.apiBaseUrl + 'location/finLocation';
  private creerBonLocationUrl = environment.apiBaseUrl + 'location/creerBonLocation';
  private ajoutItemBonLocationUrl = environment.apiBaseUrl + 'location/bonLocation/item';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<LocationEntite[]>> {
    var p = this.http.get<ReponseBase<LocationEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Obtenir(id: number): Promise<ReponseBase<LocationEntite>> {
    var p = this.http.get<ReponseBase<LocationEntite>>(this.obtenirUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(location: LocationEntite): Promise<ReponseBase<LocationEntite>> {
    var p = this.http.post<ReponseBase<LocationEntite>>(this.ajouterUrl, JSON.stringify(location), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Modifier(location: LocationEntite): Promise<ReponseBase<LocationEntite>> {
    var p = this.http.post<ReponseBase<LocationEntite>>(this.modifierUrl, JSON.stringify(location), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Supprimer(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ConfirmerDebutLocation(location: LocationEntite): Promise<ReponseBase<LocationEntite>> {
    var p = this.http.post<ReponseBase<LocationEntite>>(this.confirmerDebutLocationUrl, JSON.stringify(location), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public FinLocation(location: LocationEntite): Promise<ReponseBase<LocationEntite>> {
    var p = this.http.post<ReponseBase<LocationEntite>>(this.finLocationUrl, JSON.stringify(location), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public CreerBonLocation(requete: RequeteCreerBonLocation): Promise<ReponseBase<BonCommandeEntite>> {
    var p = this.http.post<ReponseBase<BonCommandeEntite>>(this.creerBonLocationUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterItemBonLocation(requete: RequeteAjoutItemBonLocation): Promise<ReponseBase<BonCommandeEntite>> {
    var p = this.http.post<ReponseBase<BonCommandeEntite>>(this.ajoutItemBonLocationUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
