import { Component, OnInit, ViewChild, Input, ElementRef, HostListener } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { RapportService } from 'src/app/services/rapport-service';
import { DatePipe } from '@angular/common';
import { AppMainComponent } from 'src/app/app.main.component';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { CodeProjetEntite } from 'src/app/entites/code-projet';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { BaseComponent } from 'src/app/base/base-component';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { Emplacement } from 'src/app/entites/emplacement';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { ItemService } from 'src/app/services/item-service';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { RaisonBonTravailEntite } from 'src/app/entites/mecanique/raison-bon-travail';
import { environment } from 'src/environments/environment';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { SaisieNoteMecaniqueComponent, ParametresSaisieNoteMecanique } from 'src/app/controles/mecanique/saisie-note-mecanique.component';
import { CleValeur, CleValeurNumerique } from 'src/app/entites/cleValeur';
import { SelectionEtapesComponent, ResultatSelectionEtapes } from './controles/selection-etapes.component';
import { PieceBonTravailEntite } from 'src/app/entites/mecanique/piece-bon-travail';
import { SelectionPieceComponent } from 'src/app/controles/mecanique/selection-piece.component';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { HistoriqueEntretienComponent } from 'src/app/controles/mecanique/historique-entretien.component';
import { ItemMin } from 'src/app/entites/item-min';
import { AjustementInventaireComponent, ResultatAjustementInventaire } from 'src/app/controles/ajustement-inventaire.component';
import { ConsulterFournisseursComponent } from 'src/app/controles/consulter-fournisseurs.component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { NoteEntite } from 'src/app/entites/note';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { EmployeEntite } from 'src/app/entites/employe';
import { NoteService } from 'src/app/services/note-service';
import { DetailEntretienCorrectifComponent } from 'src/app/controles/mecanique/detail-entretien-correctif.component';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { SaisieNoteComponent } from 'src/app/controles/saisie-note.component';
import { ApprouverBonTravailComponent } from './controles/approuver-bon-travail.component';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';
import { HistoriquePosePieceComponent } from 'src/app/controles/mecanique/historique-pose-piece.component';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { SelectionEmployesComponent } from 'src/app/controles/selection-employes.component';
import { EnvoyerApprobationBonTravailComponent, RetourEnvoiApprobationBonTravail } from './controles/envoyer-approbation-bon-travail.component';

@Component({
    selector: 'app-detail-bdt',
    templateUrl: './detail-bon-travail.component.html',
    providers: [MessageService, ConfirmationService, DialogService, DatePipe],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailBonTravailComponent extends BaseComponent implements OnInit {

    @Input('bdtId') inputBdcId: number;

    modeApercu: boolean = false;

    bonTravailId: number;
    bonTravail: BonTravailEntite;
    selectionMultipleEtapes: EtapeBonTravailEntite[] = [];
    selectionMultiplePieces: PieceBonTravailEntite[] = [];

    pieces: PieceBonTravailEntite[] = [];

    notes: NoteEntite[];

    typesBonTravail: CleValeurNumerique[];
    selectionTypeBonTravail: CleValeurNumerique;

    emplacements: Emplacement[];
    fournisseurs: Fournisseur[];
    raisons: RaisonBonTravailEntite[];
    employes: EmployeEntite[];

    contenuMenuContextuelEtape: MenuItem[];
    contenuMenuContextuelPiece: MenuItem[];


    contenuMenuContextuelNote: MenuItem[];
    @ViewChild('menuContextuelNote') menuContextuelNote: ContextMenu;


    menu: MenuItem[];

    @ViewChild('descriptionItem') champDescription: ElementRef;
    @ViewChild('menuContextuelEtape') menuContextuelEtape: ContextMenu;
    @ViewChild('menuContextuelPiece') menuContextuelPiece: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private bonTravailService: BonTravailService,
        private ItemsService: ItemService,
        private fournisseurService: FournisseurService,
        private rapportService: RapportService,
        private noteService: NoteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router,
        public datepipe: DatePipe) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Détail du bon de travail' }
        ]);

        this.route.params.subscribe(params => this.bonTravailId = params.id);
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutEtapeActive) {
        //     this.surAjoutEtape();
        // }
    }

    ngOnInit() {

        if (this.inputBdcId) {
            this.bonTravailId = this.inputBdcId;
            this.modeApercu = true;

            console.log('Mode apercu');
            console.log(this.bonTravailId);
        }

        this.verifierAccesPageAuMoisUnAcces(['bonstravail','temp_mec']);

        var mode = this.route.snapshot.paramMap.get('mode');
        if (mode && mode == 'M') {
            this.Modifier();
        } else {
            this.Consulter();
        }

        this.raisons = this.sdb.RaisonsBonTravail();

        this.employes = this.sdb.EmployesMecanique();

        this.emplacements = this.sdb.Emplacements();

        this.typesBonTravail = [
            { "cle": 1, "valeur": "Interne" },
            { "cle": 2, "valeur": "Externe" }
        ];

        var p1 = new Promise<number>((resolve, reject) => {

            this.bonTravailService.ObtenirDetail(this.bonTravailId).then(x => {

                console.log(x.valeur);

                this.bonTravail = x.valeur;

                //TODO : TEMP
                //this.selectionTypeBonTravail = this.typesBonTravail[0];

                if (this.bonTravail.type == ETypeBonTravail.Externe) {
                    this.selectionTypeBonTravail = this.typesBonTravail[1];
                } else {
                    this.selectionTypeBonTravail = this.typesBonTravail[0];
                }

                //TODO : Essayer de rendre l'obtention des notes asynchrones
                this.ItemsService.ObtenirNotesItem(this.bonTravail.item.itemId).then(x => {
                    this.notes = x.valeur;
                    resolve(null);
                });
            });
        });

        var p3 = new Promise<number>((resolve, reject) => {
            this.fournisseurService.ObtenirFournisseurs().then(x => {
                this.fournisseurs = x.valeur;
                resolve(null);
            });
        });

        Promise.all([p1, p3]).then(x => {

            this.initialiserBonTravail();

            this.initialiserNoEtapes();


            this.initialiserMenu();

            this.CompleterChargement(`Bon de travail #${this.bonTravail.numero} (${this.bonTravail.item.numero})`)
        });

        this.colonnesDisponibles = [
            /* { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'noSerie', entete: 'No série', afficheParDefaut: false },
            { champ: 'noPlaque', entete: 'No plaque', afficheParDefaut: false },
            { champ: 'poids', entete: 'Poids', afficheParDefaut: false } */
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

    }

    initialiserMenu() {

        //TODO: Compléter accès
        this.estModifiable = this.bonTravail.statut.statutId != EStatuts.Complete;

        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('mecanique/bonstravail')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print',
                disabled: !this.modeConsultation(),
                items: [
                    {
                        label: '1 page', icon: 'pi pi-fw pi-print',
                        command: () => this.imprimer(false)
                    },
                    {
                        label: '2 pages', icon: 'pi pi-fw pi-print',
                        command: () => this.imprimer(true)
                    },
                ]
            },
            {
                label: 'Envoyer', icon: 'pi pi-fw pi-send',
                visible: this.estAutorise('temp_mec'),
                disabled: !this.modeConsultation(),
                items: [
                    {
                        label: '1 page', icon: 'pi pi-fw pi-print',
                        command: () => this.envoyer(false)
                    },
                    {
                        label: '2 pages', icon: 'pi pi-fw pi-print',
                        command: () => this.envoyer(true)
                    },
                ]
            },
            {
                label: 'Actions', icon: 'pi pi-fw pi-bars',
                disabled: !this.modeConsultation(),
                items: [
                    {
                        label: 'Envoyer en approbation', icon: 'pi pi-fw pi-forward',
                        visible: (this.estAutorise('bontravail_envoyer_approb') || this.estAutorise('temp_mec')) && this.bonTravail.statut.statutId == EStatuts.EnCours, //TODO
                        disabled: !this.auMoinsUnEtapeFait(),
                        command: _ => this.envoyerApprobation()
                    },
                    {
                        label: 'Approuver le bon de travail', icon: 'pi pi-fw pi-forward',
                        visible: (this.estAutorise('bontravail_approuver') || this.estAutorise('temp_mec')) && this.bonTravail.statut.statutId != EStatuts.Complete, //TODO
                        disabled: !this.auMoinsUnEtapeFait(),
                        command: _ => this.approuver() 
                    },
                    { separator: true },
                    {
                        label: 'Supprimer le bon de travail', icon: 'pi pi-fw pi-times',
                        styleClass: 'menuSuppression',
                        visible: (this.estAutorise('bontravail_supprimer') || this.estAutorise('temp_mec')) && this.bonTravail.statut.statutId != EStatuts.Complete,
                        //TODO
                        disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
                        command: () => this.supprimerbonTravail()
                    }
                ]
            },
            /* {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('temp_mec'),
                disabled: this.selectionMultipleEtapes.length == 0 || this.bonTravail.statut.statutId == EStatuts.Complete,
                items: [
                    {
                        label: 'Supprimer', icon: 'pi pi-fw pi-times', command: () => this.supprimerEtapes(this.selectionMultipleEtapes)
                    },
                ]
            }, */
        ];
    }

    initialiserBonTravail() {
        if (this.bonTravail) {

            this.bonTravail.dateCreation = this.bonTravail.dateCreation ? new Date(this.bonTravail.dateCreation) : null;
            this.bonTravail.dateAssignation = this.bonTravail.dateAssignation ? new Date(this.bonTravail.dateAssignation) : null;
            this.bonTravail.dateTravail = this.bonTravail.dateTravail ? new Date(this.bonTravail.dateTravail) : null;
            this.bonTravail.dateFermeture = this.bonTravail.dateFermeture ? new Date(this.bonTravail.dateFermeture) : null;

            if (this.bonTravail.mecanicien) {
                this.bonTravail.mecanicien = this.employes.find(x => x.employeId == this.bonTravail.mecanicien.employeId);
            }
            if (this.bonTravail.contreMaitre) {
                this.bonTravail.contreMaitre = this.employes.find(x => x.employeId == this.bonTravail.contreMaitre.employeId);
            }
            if (this.bonTravail.raison) {
                this.bonTravail.raison = this.raisons.find(x => x.raisonBonTravailId == this.bonTravail.raison.raisonBonTravailId);
            }
            if (this.bonTravail.fournisseur) {
                this.bonTravail.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.bonTravail.fournisseur.fournisseurId);
            }
            if (this.bonTravail.emplacement) {
                this.bonTravail.emplacement = this.emplacements.find(x => x.emplacementId == this.bonTravail.emplacement.emplacementId);
            }

            if (this.bonTravail.etapes) {
                 this.bonTravail.etapes.forEach(et => {

                    et.idTemporaire = this.newGuid();

                    if (et.mecanicien) {
                        et.mecanicien = this.employes.find(x => x.employeId == et.mecanicien.employeId);
                    }

                    if (et.pieces) {
                        et.pieces.forEach(p => {
                           p.idTemporaire = et.idTemporaire;
                           p.idTemporaire2 = this.newGuid();
                        });
                    }
                });
            }
       
           
        }
    }

    initialiserDonneesRetour(bonTravail: BonTravailEntite) {
        this.bonTravail.statut = this.sdb.Statut(bonTravail.statut.statutId);
        this.bonTravail.dateAssignation = bonTravail.dateAssignation ? new Date(bonTravail.dateAssignation) : null;
        this.bonTravail.dateFermeture = bonTravail.dateFermeture ? new Date(bonTravail.dateFermeture) : null;
        this.bonTravail.heuresCompteur = bonTravail.heuresCompteur;
        this.bonTravail.heuresReelles = bonTravail.heuresReelles;
    }

    initialiserNoEtapes() {

        // if (!this.pieces) {
        //     this.pieces = [];
        // }

        this.pieces = [];

        var noEtape: number = 1;
            this.bonTravail.etapes.forEach(et => {

                

                et.noEtape = noEtape;
                et.ordre = noEtape;

                if (et.pieces) {
                    et.pieces.forEach(p => {
                        p.noEtape = noEtape;
                        this.pieces.push(p);
                    });
                }

                noEtape = noEtape + 1;
            });

    }


    ouvrirFicheComplete() {
        this.routerService.navigateByUrl('mecanique/bontravail/' + this.bonTravailId);
        this.appMain.afficherDetailBonTravail = false;
    }

    imprimer(multiPages: boolean) {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

         this.rapportService.ProduireRapportBonTravail(this.bonTravail.bonTravailId, multiPages).then(x => {
             if (x) {
                 this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
             } else {
                 this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
             }
         });
    }

    btnModifier_click() {
        this.Modifier();
        this.initialiserMenu();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.bonTravailService.ObtenirDetail(this.bonTravailId).then(x => {

            this.bonTravail = x.valeur;

            this.initialiserBonTravail();
            
            this.bonTravail.numero = x.valeur.numero;
            this.bonTravail.note = x.valeur.note;
            this.initialiserMenu();

            this.CompleterChargement(`Bon de travail #${this.bonTravail.numero} (${this.bonTravail.item.numero})`)
        });

        this.initialiserMenu();

        this.Consulter();
    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (!this.validerModification()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
        }

        this.Bloquer();

        this.bonTravail.type = this.selectionTypeBonTravail.cle;

        this.bonTravailService.Modifier(this.bonTravail).then(x => {
            this.Debloquer();
            if (x.estUnSucces) {
                this.initialiserBonTravail();
                this.initialiserDonneesRetour(x.valeur);
                this.Consulter();
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail modifié.' });
            } else {
                x.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });

            }

        });

    
        

        /* if (this.validerModificationBdc()) {
            this.DemarrerChargement();
            this.achatService.ModifierBonTravail(this.bonTravail).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande modifié.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        } */
    }

    validerModification(): boolean {

        /*  if (!this.bonTravail.fournisseur) {
             return false;
         }
 
         if (!this.bonTravail.emplacement) {
             return false;
         }
 
         if (!this.bonTravail.projet) {
             return false;
         }
 
         if (!this.bonTravail.dateAchat) {
             return false;
         } */

        return true;
    }

    auMoinsUnEtapeFait() {
        return this.bonTravail.etapes.filter(x => x.indComplete).length > 0
    }

    surChangementTypeBonTravail() {
        // TODO
    }

    supprimerbonTravail() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le bon de travail courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.bonTravailService.Supprimer(this.bonTravail.bonTravailId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('mecanique/bonstravail');
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    envoyerApprobation() {

        const ref = this.dialogService.open(EnvoyerApprobationBonTravailComponent, {
            data: {
                bonTravail: this.bonTravail
            },
            header: 'Envoyer le bon de travail en approbation',
            width: '50%'
        }).onClose.subscribe((res: RetourEnvoiApprobationBonTravail) => {

            if (res) {

                this.bonTravail.heuresMecanicien = res.nbHeures;
                this.bonTravail.kmMecanicien = res.nbKm;

                this.bonTravailService.EnvoyerApprobation(this.bonTravail).then(res => {
                    if (res.estUnSucces) {
                        this.initialiserDonneesRetour(res.valeur);
                        this.initialiserMenu();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail envoyé en approbation.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });

    }

    approuver() {

        const ref = this.dialogService.open(ApprouverBonTravailComponent, {
            data: {
                bonTravail: this.bonTravail,
                alerteEtapesNonCompletes: this.bonTravail.etapes.filter(x => !x.indComplete).length > 0
            },
            header: 'Approuver le bon de travail',
            width: '50%'
        }).onClose.subscribe((res: BonTravailEntite) => {

            if (res) {
                this.initialiserDonneesRetour(res);
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail complété.' });

                // this.bonTravailService.Completer(res).then(resApprobation => {
                //     if (resApprobation.estUnSucces) {
                //         this.initialiserDonneesRetour(resApprobation.valeur);
                //         this.initialiserMenu();
                //         this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail complété.' });
                //     } else {
                //         resApprobation.messages.forEach(x => {
                //             this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                //         });
                //     }
                // });
            }
        });
    }


    /* ------------   ÉTAPES   ----------- */

    surMenuContextuelEtape(event, etape: EtapeBonTravailEntite) {

        this.contenuMenuContextuelEtape = [
            {
                label: 'Voir l\'entretien', icon: 'pi pi-fw pi-eye',
                visible: this.modeConsultation() && this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienPreventif,
                command: _ => this.routerService.navigateByUrl('mecanique/entretienpreventif/' + etape.entretienPreventif.entretienPreventifId)
            },
            {
                label: 'Voir l\'historique', icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienPreventif,
                command: _ => this.consulterHistoriqueEntretienPreventif(this.bonTravail.item, etape.entretienPreventif)
            },
            {
                label: 'Annuler l\'entretien préventif', icon: 'pi pi-fw pi-times',
                visible: this.modeConsultation() && this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienPreventif && this.bonTravail.statut.statutId == EStatuts.Complete && etape.indComplete,
                command: _ => this.annulerEntretienPreventifComplete(etape)
            },
            {
                label: 'Modifier l\'entretien correctif', icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienCorrectif,
                command: _ => this.modifierEntretienCorrectif(etape, etape.entretienCorrectif)
            },
            // { separator: true },
            // {
            //     label: 'Ajouter une note', icon: 'pi pi-fw pi-pencil',
            //     visible: this.estAutorise('temp_mec') && !this.modeApercu,
            //     disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
            //     command: () => this.ajouterNoteEtape(etape)
            // },
            // { separator: true },
            // {
            //     label: 'Supprimer', icon: 'pi pi-fw pi-times',
            //     visible: this.estAutorise('temp_mec') && !this.modeApercu,
            //     disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
            //     command: () => this.supprimerEtapes(event, [etape])
            // }
        ];


        this.menuContextuelEtape.toggle(event);
    }

    descendreEtape(event, etape: EtapeBonTravailEntite)
    {
        var index = this.bonTravail.etapes.indexOf(etape);

        this.bonTravail.etapes.splice(index,1);
        this.bonTravail.etapes.splice(index+1,0,etape);

        this.initialiserNoEtapes();
    }

    
    monterEtape(event, etape: EtapeBonTravailEntite)
    {
        var index = this.bonTravail.etapes.indexOf(etape);

        this.bonTravail.etapes.splice(index,1);
        this.bonTravail.etapes.splice(index-1,0,etape);

        this.initialiserNoEtapes();
    }

    descendreEtapeComplet(event, etape: EtapeBonTravailEntite)
    {
        var index = this.bonTravail.etapes.indexOf(etape);

        this.bonTravail.etapes.splice(index,1);
        this.bonTravail.etapes.push(etape);

        this.initialiserNoEtapes();
    }

    
    monterEtapeComplet(event, etape: EtapeBonTravailEntite)
    {
        var index = this.bonTravail.etapes.indexOf(etape);

        this.bonTravail.etapes.splice(index,1);
        this.bonTravail.etapes.unshift(etape);

        this.initialiserNoEtapes();
    }

    supprimerEtapes(event, itemsBonsTravail: EtapeBonTravailEntite[]) {
        var ids = itemsBonsTravail.map(b => b.idTemporaire);
        var noEtapes = itemsBonsTravail.map(b => b.noEtape);

        this.confirmationService.confirm({
            target: event.target,
            message: itemsBonsTravail.length == 1 ? 'Voulez-vous vraiment supprimer l\'étape sélectionnée ?' : 'Voulez-vous vraiment supprimer les étapes sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
             
                this.pieces = this.pieces.filter((p: PieceBonTravailEntite) => !noEtapes.includes(p.noEtape))        

                this.bonTravail.etapes = this.bonTravail.etapes.filter((et: EtapeBonTravailEntite) => !ids.includes(et.idTemporaire))

                this.initialiserNoEtapes();

            }
        });
    }

    modifierEtapeBonTravail(itemBonTravail: EtapeBonTravailEntite) {

        /* const ref = this.dialogService.open(ModificationItemBonTravailComponent, {
            data: {
                itemBonTravail: itemBonTravail
            },
            header: 'Modifier un item',
            width: '90%'
        }).onClose.subscribe((res: ItemBonTravail) => {
            console.log(res);
            if (res) {
                itemBonTravail.quantite = res.quantite;
                itemBonTravail.noFournisseur = res.noFournisseur;
                itemBonTravail.prix = res.prix;
                itemBonTravail.codeProjet = res.codeProjet;
                itemBonTravail.codeActivite = res.codeActivite;
                itemBonTravail.codeCategorie = res.codeCategorie;
                itemBonTravail.note = res.note;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Item modifié.` });
            }

        }); */

    }

    modifierNote(event, etape: EtapeBonTravailEntite) {
        const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
            data: {
                note: etape.note ? etape.note : '',
                afficherAjoutFiche: true,
                indAjouterFiche: false
            },
            header: 'Ajouter une note',
            width: '80%'
        }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
            if (res) {
                etape.note = res.note;

                if (res.indAjouterFiche) {
                    this.Bloquer();
                    console.log('Ajout note a la fiche');
                    this.noteService.Ajouter({
                        date: new Date(),
                        employe: this.profilService.ProfilCourant(),
                        item: this.bonTravail.item,
                        description: res.note
                    }).then(res => {
                        this.Debloquer();
                    });
                }
            }
        });
    }

    annulerEntretienPreventifComplete(etape: EtapeBonTravailEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment l\'entretien préventif sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                
                this.bonTravailService.AnnulerEntretienPreventifComplete(etape.etapeBonTravailId).then(res => {
                    if (res.estUnSucces) {
                        etape.indComplete = false;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Étape annulée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    // ajouterNoteEtape(etapeBonTravail: EtapeBonTravailEntite) {
    //     const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
    //         data: {
    //             note: etapeBonTravail.note
    //         },
    //         header: 'Ajouter une note',
    //         width: '80%'
    //     }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
    //         if (res) {

    //             this.Bloquer();

    //             const ancienneNote = etapeBonTravail.note;
    //             const anciennIndNotePersistante = etapeBonTravail.indNotePersistante;
    //             etapeBonTravail.note = res.note;
    //             etapeBonTravail.note = res.note;
    //             this.bonTravailService.Modifier(etapeBonTravail).then(resModif => {

    //                 this.Debloquer();

    //                 if (resModif.estUnSucces) {

    //                     this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

    //                 } else {
    //                     etapeBonTravail.note = ancienneNote;
    //                     etapeBonTravail.indNotePersistante = anciennIndNotePersistante;
    //                     resModif.messages.forEach(x => {
    //                         this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
    //                     });
    //                 }
    //             });
    //         }
    //     });
    // }


    estFenetreAjoutEtapeActive: boolean = false;

    surAjoutEtape() {
        this.estFenetreAjoutEtapeActive = true;
        const ref = this.dialogService.open(SelectionEtapesComponent, {
            data: {
                bonTravailId: this.bonTravail.bonTravailId,
                itemId: this.bonTravail.item.itemId,
                entretienPreventifIds: this.bonTravail.etapes.filter(x => x.entretienPreventif).map(x => x.entretienPreventif.entretienPreventifId),
                entretienCorrectifIds: this.bonTravail.etapes.filter(x => x.entretienCorrectif).map(x => x.entretienCorrectif.entretienCorrectifId)
            },
            header: 'Ajouter une étape',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionEtapes) => {
            this.estFenetreAjoutEtapeActive = false;
            if (res) {

                if (res.etapesPreventif) {
                    res.etapesPreventif.forEach(et => {
                        console.log(et);
                        et.idTemporaire = this.newGuid();
                        this.bonTravail.etapes.push(et);

                        if (et.entretienPreventif.pieces) {
                            et.entretienPreventif.pieces.forEach(p => {
                                
                                var piece = {
                                    idTemporaire: this.newGuid(),
                                    idTemporaire2: this.newGuid(),
                                    item: p.item,
                                    noEtape: et.noEtape,
                                    quantite: p.quantite
                                };

                                //this.pieces.push(piece);

                                if (!et.pieces) {
                                    et.pieces = [];
                                }

                                et.pieces.push(piece)
                            
                            });
                        }
                    });
                }
                if (res.etapesCorrectif) {
                    res.etapesCorrectif.forEach(et => {
                        et.idTemporaire = this.newGuid();
                        this.bonTravail.etapes.push(et);
                    });
                }
                if (res.etapesVerification) {
                    res.etapesVerification.forEach(et => {
                        et.idTemporaire = this.newGuid();
                        this.bonTravail.etapes.push(et);
                    });
                }
                if (res.etapeManuel) {
                    res.etapeManuel.idTemporaire = this.newGuid();
                    this.bonTravail.etapes.push(res.etapeManuel);
                }

                this.initialiserNoEtapes();

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Étape(s) ajoutée(s).' });
            }


        });

    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    consulterHistoriqueEntretienPreventif(item: ItemMin, entretien: EntretienPreventifEntite) {
        const ref = this.dialogService.open(HistoriqueEntretienComponent, {
            data: {
                itemId: item.itemId,
                entretienPreventifId: entretien.entretienPreventifId
            },
            header: `Historique d'entretien - ${item.numero} - ${item.description} - ${entretien.description}`,
            width: '75%'
        }).onClose.subscribe(() => {


        });
    }

    modifierEntretienCorrectif(etape: EtapeBonTravailEntite, entretien: EntretienCorrectifEntite) {

        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: entretien
            },
            header: `Modifier un entretien correctif`,
            width: '75%'
        }).onClose.subscribe((res: EntretienCorrectifEntite) => {

            if (res) {
                entretien = res;
                etape.description = res.description;
                etape.categorie = res.categorie;
                etape.note = res.note;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif modifié.' });
            }

        });

    }


    /* --------------  PIÈCES  ---------------- */


    surMenuContextuelPiece(event, pieceBonTravail: PieceBonTravailEntite) {

        this.contenuMenuContextuelPiece = [
            {
                label: 'Voir la pièce', icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.routerService.navigateByUrl('item/' + pieceBonTravail.item.itemId)
            },
            {
                label: 'Voir les fournisseurs', icon: 'pi pi-fw pi-user',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.consulterFournisseurs(pieceBonTravail)
            },
            {
                label: 'Voir l\'historique de pose (unité)',
                icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec'),
                command: () => this.historiquePosePiece(pieceBonTravail, false)
            },
            {
                label: 'Voir l\'historique de pose (complet)',
                icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec'),
                command: () => this.historiquePosePiece(pieceBonTravail, true)
            },
            { separator: true },
            {
                label: 'Modifier la quantité', icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec') && !this.modeApercu,
                command: _ => this.modifierQuantite(pieceBonTravail)
            },
            // {
            //     label: 'Ajouter une note', icon: 'pi pi-fw pi-pencil',
            //     visible: this.estAutorise('temp_mec') && !this.modeApercu,
            //     disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
            //     command: _ => this.ajouterNotePiece(pieceBonTravail)
            // },
            { separator: true, visible: this.estAutorise('temp_mec') },
            {
                label: 'Commander', icon: 'pi pi-fw pi-shopping-cart',
                visible: this.estAutorise('temp_mec'),
                /*  disabled: !itemCommande.item.categorie.estSuivi,
                 command: () => this.commander(itemCommande) */
            },
            {
                label: 'Ajuster l\'inventaire', icon: 'pi pi-fw pi-sliders-h',
                visible: this.estAutorise('temp_mec'),
                disabled: !pieceBonTravail.item.categorie.estSuivi,
                command: () => this.ajusterInventaire(pieceBonTravail)
            },
            { separator: true },
            {
                label: 'Supprimer', icon: 'pi pi-fw pi-times',
                visible: this.estAutorise('temp_mec') && !this.modeApercu,
                disabled: this.bonTravail.statut.statutId == EStatuts.Complete
            }
        ];


        this.menuContextuelPiece.toggle(event);
    }

    ajouterPiece(event, etape: EtapeBonTravailEntite) {
        const ref = this.dialogService.open(SelectionPieceComponent, {
            data: {
                uniteId: this.bonTravail.item.itemId
            },
            header: 'Sélection d\'une pièce',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {

            if (res) {

                if (!etape.pieces) {
                    etape.pieces = [];
                }
                if (!etape.pieces) {
                    etape.pieces = [];
                }

                etape.pieces.push({
                    pieceBonTravailId: 0,
                    noEtape: etape.noEtape,
                    item: res.item,
                    quantite: res.quantiteDemande.toString(),
                    idTemporaire: etape.idTemporaire,
                    idTemporaire2: this.newGuid(),
                    note: res.note
                })

                this.initialiserNoEtapes();
            }
        });
    }

    modifierNotePiece(event, piece: PieceBonTravailEntite) {
        const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
            data: {
                note: piece.note ? piece.note : '',
                afficherAjoutFiche: false,
                indAjouterFiche: false
            },
            header: 'Ajouter une note',
            width: '80%'
        }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
            if (res) {
                piece.note = res.note;
            }
        });
    }

   

    supprimerPieces(event, pieces: PieceBonTravailEntite[]) {
        var ids = pieces.map(b => b.idTemporaire2);
        this.confirmationService.confirm({
            target: event.target,
            message: pieces.length == 1 ? 'Voulez-vous vraiment supprimer la pièce sélectionnée ?' : 'Voulez-vous vraiment supprimer les pièces sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                
                this.pieces = this.pieces.filter((p: PieceBonTravailEntite) => !ids.includes(p.idTemporaire2))

                if (this.bonTravail.etapes) {
                    this.bonTravail.etapes.forEach(et => {
                        if (et.pieces) {
                            et.pieces = et.pieces.filter((p: PieceBonTravailEntite) => !ids.includes(p.idTemporaire2))
                        }
                    });
                }

               

            }
        });
    }

    ajusterInventaire(pieceBonTravail: PieceBonTravailEntite) {

        const ref = this.dialogService.open(AjustementInventaireComponent, {
            data: {
                itemId: pieceBonTravail.item.itemId,
                emplacementId: 10000,
                numero: pieceBonTravail.item.numero,
                description: pieceBonTravail.item.description,
                emplacement: this.sdb.Emplacement(10000),
                quantiteCourante: pieceBonTravail.qteInventaire // TODO : donner la responsabilité au composant d'ajustement d'aller chercher l'info sur l'inventaire courant
            },
            header: 'Ajustement d\'inventaire',
            width: '50%'
        }).onClose.subscribe((res: ResultatAjustementInventaire) => {
            if (res) {
                pieceBonTravail.qteInventaire = res.nouvelleQuantite;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'inventaire a été ajusté.` });
            }
        });

    }

    historiquePosePiece(piece: PieceBonTravailEntite, complet: boolean) {
        const ref = this.dialogService.open(HistoriquePosePieceComponent, {
            data: {
                pieceId: piece.item.itemId,
                uniteId: complet ? null : this.bonTravail.item.itemId
            },
            header: 'Historique de pose',
            width: '90%'
        });
    }


    consulterFournisseurs(pieceBonTravail: PieceBonTravailEntite) {
        const ref = this.dialogService.open(ConsulterFournisseursComponent, {
            data: {
                itemId: pieceBonTravail.item.itemId,
                afficherSelection: true,
                afficherCommander: false
            },
            header: 'Consulter les fournisseurs',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            console.log(res);
            if (res) {
                
            }

        });
    }


    // ajouterNotePiece(pieceBonTravail: PieceBonTravailEntite) {
    //     const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
    //         data: {
    //             note: pieceBonTravail.note,
    //             indAjouterFiche: false
    //         },
    //         header: 'Ajouter une note',
    //         width: '80%'
    //     }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
    //         if (res) {

    //             //TODO: TEMP
    //             pieceBonTravail.note = res.note;

    //             /* this.Bloquer();

    //             const ancienneNote = pieceBonTravail.note;
    //             const anciennIndNotePersistante = pieceBonTravail.indNotePersistante;
    //             pieceBonTravail.note = res.note;
    //             pieceBonTravail.note = res.note;
    //             this.bonTravailService.ModifierPieceBonTravail(pieceBonTravail).then(resModif => {

    //                 this.Debloquer();

    //                 if (resModif.estUnSucces) {

    //                     this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

    //                 } else {
    //                     etapeBonTravail.note = ancienneNote;
    //                     etapeBonTravail.indNotePersistante = anciennIndNotePersistante;
    //                     resModif.messages.forEach(x => {
    //                         this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
    //                     });
    //                 }
    //             }); */
    //         }
    //     });
    // }

    modifierQuantite(pieceBonTravail: PieceBonTravailEntite) {
        const ref = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: pieceBonTravail.quantite,
                min: 1
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((res: number) => {
            if (res) {

                //TODO: TEMP
                pieceBonTravail.quantite = res.toString();

                /* this.bonTravailService.ModifierPieceBonCommande({
                    employe: this.profilService.ProfilCourant(),
                    emplacement: this.emplacement,
                    itemsCommande: [{
                        itemCommandeId: itemCommande.itemCommandeId,
                        quantite: res
                    }]
                }).then(res => {
                    if (res.estUnSucces) {
            
                        res.valeur.itemCommandeModifies.forEach(x => {
                            var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                            itemCommandeAModifier.quantiteDemande = x.quantiteDemande;
                            itemCommandeAModifier.quantite = x.quantite;
                        });
        
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Quantité modifiée` });
        
                        this.initialiserMenu();
        
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }).catch(() => {
                    this.routerService.navigateByUrl('error');
                }); */
            }

        });
    }
















 





    envoyer(multiPages: boolean) {
        const ref = this.dialogService.open(SelectionEmployesComponent, {
            data: {
                employesIds: this.bonTravail.mecanicien ? [this.bonTravail.mecanicien.employeId] : []
            },
            header: 'Envoi du bon de travail',
            width: '60%'
        }).onClose.subscribe((res: EmployeEntite[]) => {
            if (res) {

                this.Bloquer();

                var destinataires: string[] = res.map(x => x.courriel);

                this.rapportService.EnvoyerRapportBonTravail(this.bonTravail.bonTravailId, multiPages, destinataires).then(res => {

                    this.Debloquer();

                    if (res) {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le bon de travail a été envoyée.' });
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite lors de l\'envoi du courriel.' });
                    }
                });
            }
        });
    }



    /*   NOTES    */

    surMenuContextuelNote(event, note: NoteEntite) {
        this.contenuMenuContextuelNote = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('item_note_modifier'),
                command: () => this.modifierNoteUnite(note)
            },
            { separator: true },
            {
                label: 'Ajouter en tant qu\'entretien correctif',
                icon: 'pi pi-fw pi-briefcase',
                visible: this.estAutorise('entretien_correctif_creer') || this.estAutorise('temp_mec'),
                disabled: !this.modeModification(),
                command: () => this.creerEntretienCorrectifDepuisNote(note)
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_note_supprimer'),
                command: () => this.supprimerNote(note)
            },
        ];

        this.menuContextuelNote.toggle(event);
    }

    ajouterNoteUnite() {
        const ref = this.dialogService.open(SaisieNoteComponent, {
            data: {
            },
            header: 'Ajouter une note',
            width: '80%'
        }).onClose.subscribe((res: string) => {
            if (res) {

                this.Bloquer();

                this.noteService.Ajouter({
                    date: new Date(),
                    description: res,
                    employe: this.profilService.ProfilCourant(),
                    item: {
                        itemId: this.bonTravail.item.itemId
                    }
                }).then(resAjoutNote => {

                    this.Debloquer();

                    if (resAjoutNote.estUnSucces) {

                        this.notes.push(resAjoutNote.valeur)

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été ajoutée.' });

                    } else {
                        resAjoutNote.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }
        });
    }

    modifierNoteUnite(note: NoteEntite) {
        const ref = this.dialogService.open(SaisieNoteComponent, {
            data: {
                note: note.description
            },
            header: 'Modifier une note',
            width: '80%'
        }).onClose.subscribe((res: string) => {
            if (res) {

                this.Bloquer();

                const ancienneNote = note.description;
                note.description = res
                this.noteService.Modifier(note).then(resModifNote => {

                    this.Debloquer();

                    if (resModifNote.estUnSucces) {

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

                    } else {
                        note.description = ancienneNote;
                        resModifNote.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }
        });
    }


    supprimerNote(note: NoteEntite) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer le note sélectionnée ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.noteService.Supprimer(note.noteId).then(res => {
                    if (res.estUnSucces) {
                        this.notes = this.notes.filter(val => val.noteId !== note.noteId);
                        //this.nbEntreesNotes--;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note supprimée' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    creerEntretienCorrectifDepuisNote(note: NoteEntite) {
        
        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: {
                    entretienCorrectifId: 0,
                    item: {
                        itemId: this.bonTravail.item.itemId
                    },
                    description: note.description,
                    employe: this.profilService.ProfilCourant()
                }
            },
            header: `Ajouter un entretien correctif`,
            width: '75%'
        }).onClose.subscribe((res: EntretienCorrectifEntite) => {

            if (res) {


                this.bonTravail.etapes.push({
                    idTemporaire: this.newGuid(),
                    etapeBonTravailId: 0,
                    entretienCorrectif: res,
                    categorie: res.categorie,
                    description: res.description,
                    indAFaire: true,
                    indComplete: false,
                    typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienCorrectif)
                });

                this.initialiserNoEtapes();

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif ajouté. Vous devez sauvegarder le bon de travail pour appliquer les modifications.' });
            }

        });
    }

    copierMecanicien(etape: EtapeBonTravailEntite) {
        if (etape.mecanicien) {
            var employeId = etape.mecanicien.employeId;

            if (this.selectionMultipleEtapes && this.selectionMultipleEtapes.length > 0) {
                this.selectionMultipleEtapes.forEach(et => {
                    et.mecanicien = this.employes.find(x => x.employeId == employeId);
                });
            } else {
                this.bonTravail.etapes.forEach(et => {
                    et.mecanicien = this.employes.find(x => x.employeId == employeId);
                });
            }
            
        }
    }


}
