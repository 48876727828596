<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi pi-chevron-left"></i>
        </a>
        <span class="topbar-separator"></span>
        <ul class="topbar-menu">
            <li>
                <a tabindex="0" (click)="appMain.onHome($event)">
                    <i class="pi pi-home"></i>
                </a>
            </li>
        </ul>
        <span class="topbar-separator"></span>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <span class="viewname" style="text-transform: uppercase">{{item.label}}</span>
        </ng-template>
        <img id="logo-mobile" class="mobile-logo" [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" alt="diamond-layout" />
    </div>

    <div class="topbar-right">
        <ul class="topbar-menu">


            <li *ngIf="afficherAlerteDev">
                <h5 style="color: darkred; background-color: yellow; padding: 5px; margin: 1px 12px 0 0;">ENVIRONNEMENT DE DÉVELOPPEMENT</h5>
            </li>
            <li class="search-item">
                <a tabindex="0" (click)="appMain.onSearchClick($event)">
                    <i class="pi pi-search"></i>
                </a>
            </li>
            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarFavoriteMenuActive}">
                <a href="#" tabindex="0" (click)="appMain.onTopbarFavoriteMenuButtonClick($event)">
                    <i class="pi pi-heart"></i>
                    <!-- <span class="topbar-badge">5</span> -->
                </a>
                <ul class="notifications-menu fade-in-up">
                    <li role="menuitem" *ngFor="let favori of favoris" style="min-width: 400px;"> 
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-11">
                                <a (click)="naviguer(favori.url)">
                                    <i class="pi pi-heart"></i>
                                    <div class="notification-item">
                                        <div class="notification-summary">{{favori.description}}</div>
                                        <div class="notification-detail"></div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-field p-col-1">
                        
                                    <i class="pi pi-times" (click)="supprimerFavori(favori.favoriId)"></i>
                         
                            </div>
                        </div>
                        
                    </li>
                    <li role="menuitem" *ngIf="favoris.length == 0" style="min-width: 400px;"> 
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                Aucun favori.
                            </div>
                        </div>
                        
                    </li>
                </ul>
            </li>
            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarRapportsMenuActive}">
                <a href="#" tabindex="0" (click)="appMain.onTopbarRapportsMenuButtonClick($event)">
                    <i class="pi pi-file-pdf"></i>
                    <span class="topbar-badge" *ngIf="rapports.length != 0">
                        {{rapports.length}}
                         <!-- <i class="pi pi-spin pi-spinner" style="font-size: 0.5rem"></i> -->
                        </span> 
                </a>
                <ul class="notifications-menu fade-in-up">
                    <li role="menuitem" *ngFor="let rapport of rapports" style="min-width: 400px;">
                        <a (click)="supprimerRapport(rapport)" [href]="rapport.url" target="_blank">
                            <i class="pi pi-file-pdf"></i>
                            <div class="notification-item">
                                <div class="notification-summary">{{rapport.type}}</div>
                                <div class="notification-detail">{{rapport.detail}}</div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="rapports.length == 0" style="min-width: 400px;"> 
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                Aucun rapport.
                            </div>
                        </div>
                        
                    </li>
                    <li role="menuitem" *ngIf="rapports.length > 0">
                        <hr>
                    </li>
                    <li role="menuitem" *ngIf="rapports.length > 0">
                        <a (click)="viderRapports()">
                            <i class="pi pi-times"></i>
                            <div class="notification-item">
                                <div class="notification-summary">Vider les rapports</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">
                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">
                    <i class="pi pi-clock"></i>
                    <!-- <span class="topbar-badge">5</span> -->
                </a>
                <ul class="notifications-menu fade-in-up">
                    <li role="menuitem" *ngFor="let historique of historiques" style="min-width: 400px;">
                        <a (click)="naviguer(historique.url)">
                            <i class="pi pi-clock"></i>
                            <div class="notification-item">
                                <div class="notification-summary">{{historique.description}}</div>
                                <div class="notification-detail"></div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="historiques.length == 0" style="min-width: 400px;"> 
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12">
                                Aucun historique.
                            </div>
                        </div>
                        
                    </li>
                    <li role="menuitem" *ngIf="historiques.length > 0">
                        <hr>
                    </li>
                    <li role="menuitem" *ngIf="historiques.length > 0">
                        <a (click)="supprimerHistorique()">
                            <i class="pi pi-times"></i>
                            <div class="notification-item">
                                <div class="notification-summary">Vider l'historique</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">
                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">
                    <i class="pi pi-bell"></i>
                </a>
                <ul class="notifications-menu fade-in-up">
                    <li role="menuitem">
                        <a href="#" tabindex="0">
                            <div class="notification-item">
                                <div class="notification-summary">Aucun message</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </li> -->

            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <img src="assets/layout/images/user.png" alt="diamond-layout" class="profile-image"/>
                    <span class="profile-name">{{nomUtilisateur}}</span>
                </a>
                <ul class="profile-menu fade-in-up">
                    <!-- <li>
                        <a href="#">
                            <i class="pi pi-user"></i>
                            <span>Profile</span>
                        </a>
                    </li> -->
                    <li>
                        <a href="{{url}}">
                            <i class="pi pi-refresh"></i>
                            <span >Recharger</span>
                        </a>
                    </li>

                    <li>
                        <a href="#">
                            <i class="pi pi-cog"></i>
                            <span>Paramètres</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="pi pi-power-off"></i>
                            <span (click)="logout()">Déconnexion</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
