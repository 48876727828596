<p-toast key="selectionRetour"></p-toast>



<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <!-- <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="numero">Numéro</label>
            <input id="numero" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.numeroItem">
        </div>
        <div class="p-field p-col-12 p-md-12 p-lg-9">
            <label for="description">Description</label>
            <input id="description" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.descriptionItem">
        </div>
    </div> -->
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <button pButton pRipple label="Nouveau retour" icon="pi pi-plus" class="p-button-primary"
                    (click)="nouveau()"></button>
    
                        <p-table #dt [value]="retours" dataKey="requisitionId" tableStyleClass="tableNormale"
                        [(selection)]="selectionRetour"
                            autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
                            [rowHover]="true"  [filterDelay]="0"
                            [globalFilterFields]="['nom','prenom','courriel']">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex p-ai-center p-jc-between">
                                    <h5 class="titreTableau p-m-0">Retours actifs</h5>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"></th>
                                    <th>Numéro</th>
									<th>Date</th>
									<th>Demandeur</th>
									<th>Camion</th>
									<th>Remorque</th>
									<th>Chauffeur</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-retour>
                            
                                <tr>
                                    <td>
                                        <p-tableRadioButton [value]="retour"></p-tableRadioButton>
                                    </td>
									<td>{{retour.numero}}</td>
									<td>{{retour.date | date:'yyyy-MM-dd'}}</td>
									<td>{{retour.demandeur | nomEmploye}}</td>
									<td>{{retour.camion}}</td>
									<td>{{retour.remorque}}</td>
									<td>{{retour.chauffeur}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
    
            </div>
        </div>
    
    
    </div>
    
    
    

    <div class="p-text-right">

        <button #boutonConfirmer pButton pRipple label="Confirmer" icon="pi pi-send" class="p-button-primary"
            (click)="envoyer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>