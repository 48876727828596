export class ServiceBase {

  constructor() { }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );

}
}


