<p-toast key="selectionItem"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <!------------ TYPE SAISIE ------------>

    <div class="p-fluid p-formgrid p-grid" *ngIf="estTypeSaisieDisponible">
        <div class="p-col-12 p-md-6 p-lg-3 p-mb-3">
            <p-selectButton [options]="typesSaisie" [(ngModel)]="selectionTypeSaisie" optionLabel="valeur">
            </p-selectButton>
        </div>

    </div>

    <div class="p-fluid p-formgrid p-grid" *ngIf="typeSaisiePiece() && !itemSelectionne">
        <div class="p-col-12">
            <p-table #dtPieces [value]="pieces" [columns]="selectedColumns" dataKey="pieceUniteId" autoLayout="true"
                tableStyleClass="tableNormale" styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true"
                [filterDelay]="0"
                [globalFilterFields]="['piece.numero','piece.description','piece.modele','piece.marque','piece.ancienNumero','categorieEntretien.code','categorieEntretien.description']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                    (input)="dtPieces.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau p-m-0">Pièces</h5>
                        </div>

                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes...">
                            </p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="categorieEntretien.code">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Catégorie
                                <p-sortIcon field="categorieEntretien.code"></p-sortIcon>
                                <p-columnFilter type="text" field="categorieEntretien.code" display="menu"
                                    class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="piece.numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="piece.numero"></p-sortIcon>
                                <p-columnFilter type="text" field="piece.numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="piece.description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="piece.description"></p-sortIcon>
                                <p-columnFilter type="text" field="piece.description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="piece.marque">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Marque
                                <p-sortIcon field="piece.marque"></p-sortIcon>
                                <p-columnFilter type="text" field="piece.marque" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="piece.modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Modèle
                                <p-sortIcon field="piece.modele"></p-sortIcon>
                                <p-columnFilter type="text" field="piece.modele" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="piece.description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Fournisseur principal
                                <p-sortIcon field="piece.description"></p-sortIcon>
                                <p-columnFilter type="text" field="piece.description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="piece.description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                No pièce fournisseur
                                <p-sortIcon field="piece.description"></p-sortIcon>
                                <p-columnFilter type="text" field="piece.description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 5rem; width: 5rem;"></th>

                    </tr>


                </ng-template>
                <ng-template pTemplate="body" let-piece let-columns="columns">
                    <tr>



                        <td>{{piece.categorieEntretien?.code}} -
                            {{piece.categorieEntretien?.description}}</td>
                        <td>{{piece.piece.numero}}</td>
                        <td>{{piece.piece.description}}</td>
                        <td>{{piece.piece.marque}}</td>
                        <td>{{piece.piece.modele}}</td>
                        <td *ngIf="piece.prixFournisseur">{{piece.prixFournisseur?.fournisseur.nom}}
                            ({{piece.prixFournisseur?.fournisseur.code}})</td>
                        <td *ngIf="!piece.prixFournisseur"></td>
                        <td>{{piece.prixFournisseur?.numeroFournisseur}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-check" class="p-button-success p-mr-2 boutonGrille"
                                (click)="surSelectionItemPiece(piece)"></button>
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucune pièce.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <!------------ LISTE SÉLECTION INVENTAIRE ------------>

    <div class="p-fluid p-formgrid p-grid" *ngIf="typeSaisieInventaire() && !itemSelectionne">
        <div class="p-col-12">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="itemId" autoLayout="true"
                tableStyleClass="tableNormale" styleClass="p-datatable-sm p-datatable-striped"
                [(contextMenuSelection)]="selectionItem" [contextMenu]="cm" [rowHover]="true" [rows]="10"
                [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['numero','description','statut','categorie','sousCategorie','marque','modele','noSerie','noPlaque','ancienNumero']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    (keyup.enter)="surEntreeRecherche()" placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau p-m-0">Items</h5>
                        </div>

                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes...">
                            </p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">

                                                    <span class="p-ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="quantite">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Quantité
                                <p-sortIcon field="quantite"></p-sortIcon>
                                <p-columnFilter type="number" field="quantite" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="categorie.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Catégorie
                                <p-sortIcon field="categorie.nom"></p-sortIcon>
                                <p-columnFilter #filtreCategorie field="categorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect #champFiltreCategorie [ngModel]="value" [options]="categories"
                                            placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">
                                                    <span class="p-ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('sousCategorie')" pSortableColumn="sousCategorie.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Sous-catégorie
                                <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="sousCategorie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Marque
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="modele" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Modèle
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('noProduit')">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                No pièce Fournisseur
                            </div>
                        </th>
                  
                        <th style="min-width: 4rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns">
                    <tr [pContextMenuRow]="item">
                        <td>{{item.numero}}
                        </td>
                        <td>{{item.description}}</td>
                        <td>
                            <span [class]="'statut ' + item.statut.nom.toLowerCase()">
                                {{item.statut.nom}}
                            </span>
                        </td>
                        <td>
                            <span [ngClass]="item.quantite > 0 ? 'inventaire disponible' : 'inventaire nondisponible'">
                                {{item.quantite}}
                            </span>
                        </td>
                        <td *ngIf="EstColonneSelectionnee('categorie')">
                            {{item.categorie.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('sousCategorie')">
                            {{item.sousCategorie?.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('marque')">
                            {{item.marque}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('modele')">
                            {{item.modele}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('noProduit')" style="white-space: pre-line;">
                            {{item.produitsFournisseurChaine}}
                        </td>
                        <td>
                            <button pButton pRipple icon="pi pi-check" class="p-button-success p-mr-2 boutonGrille"
                                (click)="surSelectionItemInventaire(item)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucun item.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <!------------ NUMÉRO/DESCRIPTION ------------>

    <div class="p-fluid p-formgrid p-grid" *ngIf="(!typeSaisiePiece() && !typeSaisieInventaire()) || itemSelectionne">
        <div *ngIf="typeSaisiePiece() || typeSaisieInventaire()" class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="noSIHC">No SIHC<span class="requis">*</span></label>
            <input type="text" pInputText id="noSIHC" [(ngModel)]="selectionItem.numero" required readonly disabled
                autocomplete="off" autofocus />
            <small class="p-error" *ngIf="submitted && !selectionItem.numero">Le numéro SIHC est
                obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-6">
            <label for="description">Description<span class="requis">*</span></label>
            <input #descriptionItem type="text" pInputText id="description" [(ngModel)]="selectionItem.description"
                [readonly]="typeSaisieInventaire()" [disabled]="typeSaisieInventaire()" autocomplete="off" autofocus />
            <small class="p-error" *ngIf="submitted && !selectionItem.description">La description
                est
                obligatoire.</small>
        </div>
        <div *ngIf="typeSaisiePiece() || typeSaisieInventaire()" class="p-field p-col-12 p-md-6 p-lg-3">
            <button pButton pRipple icon="pi pi-search" class="p-button-rounded p-button-primary p-mr-2 p-mt-3"
                (click)="RechercherItem()"></button>
        </div>
    </div>

    <!------------ CHAMPS SPÉCIFIQUES ------------>

    <div class="p-fluid p-formgrid p-grid" *ngIf="(!typeSaisiePiece() && !typeSaisieInventaire()) || itemSelectionne">
        <div class="p-field p-col-12 p-lg-3">
            <label for="quantity">Quantite<span class="requis">*</span></label>
            <!-- <p-inputNumber #champQuantite [(ngModel)]="quantiteDemande"
                [readonly]="typeSaisieInventaire() && selectionItem && selectionItem.categorie.estUnique"
                [disabled]="typeSaisieInventaire() && selectionItem && selectionItem.categorie.estUnique">
            </p-inputNumber> -->
            <input #champNoFournisseur type="text" pInputText id="quantiteDemande" [(ngModel)]="quantiteDemande"
            autocomplete="off" />
            <small class="p-error" *ngIf="submitted && !quantiteDemande">La quantité est obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="noFournisseur">No pièce fournisseur</label>
            <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="noFournisseur"
                autocomplete="off" />
            <!--  <small class="p-error" *ngIf="submitted && !noFournisseur">Le numéro de fournisseur est obligatoire.</small> -->
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="prix">Prix</label>
            <!--  <p-inputNumber #champPrix [(ngModel)]="prix" mode="currency"></p-inputNumber> -->
            <p-inputNumber #champPrix [(ngModel)]="prix" mode="currency" currency="USD" locale="en-US"
                [minFractionDigits]="2" [maxFractionDigits]="4"></p-inputNumber>
            <!-- <small class="p-error" *ngIf="submitted && !prix && prix != 0">Le prix est obligatoire.</small> -->
        </div>
    </div>

    <!------------ NOTE ------------>

    <div class="p-fluid p-formgrid p-grid" *ngIf="(!typeSaisiePiece() && !typeSaisieInventaire()) || itemSelectionne">
        <div class="p-field p-col-12 p-lg-9">
            <label for="note">Note</label>
            <input #champNote type="text" pInputText id="note" [(ngModel)]="note" autocomplete="off" />
        </div>

    </div>



    <!------------ BOUTONS ------------>

    <div class="p-text-right" *ngIf="(!typeSaisiePiece() && !typeSaisieInventaire()) || itemSelectionne">

        <button pButton pRipple label="Ajouter" icon="pi pi-check" class="p-button-primary p-mr-2"
            (click)="selectionner(false)"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-outlined p-mr-2"
            (click)="annuler()"></button>
    </div>

</div>