import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';
import { Fournisseur } from '../entites/fournisseur';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { FournisseurService } from '../services/fournisseur-service';

export interface ParametresSelectionFournisseur {
    fournisseur: Fournisseur;
    numeroFournisseur?: string;
    prix?: number;
}

@Component({
    templateUrl: './selection-fournisseur.component.html',
})
export class SelectionFournisseurComponent extends BaseComponent {

    donnees: ParametresSelectionFournisseur;

    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;
    numeroFournisseur?: string;
    prix?: number;
    
    @ViewChild('champPrix') champPrix;
    @ViewChild('champNoFournisseur') champNoFournisseur;
            
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        private fournisseurService: FournisseurService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {

            super(sdb, profilService, route, routerService, appMain); }

    ngOnInit() {

        
        this.donnees = this.config.data;

        this.numeroFournisseur = this.donnees.numeroFournisseur;
        this.prix = this.donnees.prix;

        this.fournisseurService.ObtenirFournisseurs().then(res => {

            this.fournisseurs = res.valeur;

            this.CompleterChargement();

            setTimeout(() => { 
                this.champNoFournisseur.nativeElement.focus();
                this.champNoFournisseur.nativeElement.select();
             }, 100);
        });

        
    }

    surEntree() {
        this.confirmer();
    }

    confirmer() {

        var retour: ParametresSelectionFournisseur = {
            fournisseur: this.selectionFournisseur,
            numeroFournisseur: this.numeroFournisseur,
            prix: this.prix
        }

      this.ref.close(retour);
    }

    annuler() {
        this.ref.close(null);
    }
}