<p-toast key="reparation"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="lastname">Date de retour<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateRetour" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !dateRetour">La date de retour est obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="noFacture">Numéro de facture</label>
            <input  type="text" pInputText id="noFacture" [(ngModel)]="noFacture"
            autocomplete="off"  />
        </div>
        
        <div class="p-field p-col-12">
            <label for="lastname">Garantie</label><br>
            <p-inputSwitch [(ngModel)]="garantieActive"></p-inputSwitch>
        </div>
        <div class="p-field p-col-12" *ngIf="garantieActive && donnees.reparations.length > 1">
            <h6><i class="pi pi-exclamation-triangle " style="color:#dd8500; font-size: 1.4rem"></i> Attention, la garantie s'appliquera aux {{donnees.reparations.length}} items sélectionnés.</h6>
        </div>
      
    </div>

    <div *ngIf="garantieActive" class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="type">Type de garantie<span class="requis">*</span></label>
			<p-dropdown appendTo="body" [options]="typesGarantie" [(ngModel)]="garantie.type" optionLabel="nom" [filter]="true" [style]="{'width':'100%'}"
                filterBy="nom,prenom" [showClear]="true" placeholder="Selectionnez un type de garantie..." appendTo="body">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="garantie.type">
                        <div>{{garantie.type.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-type pTemplate="item">
                    <div>
                        <div>{{type.nom}}</div>
                    </div>
                </ng-template>
			</p-dropdown>
			<small class="p-error" *ngIf="submitted && garantieActive && !garantie.type">Le type de garantie est obligatoire.</small>
		</div>

		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="lastname">Date de début<span class="requis">*</span></label>
			<p-calendar showIcon=true [(ngModel)]="garantie.dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" appendTo="body"></p-calendar>
			<small class="p-error" *ngIf="submitted && garantieActive && !garantie.dateDebut">La date de début est obligatoire.</small>
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="lastname">Date fin<span class="requis">*</span></label>
            <i *ngIf="garantie.dateDebut" class="pi pi-question-circle p-ml-3" style="font-size: 1rem"  (click)="selectionnerDelai()" ></i>
            <p-calendar showIcon=true [(ngModel)]="garantie.dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" appendTo="body"></p-calendar>
            
			<small class="p-error" *ngIf="submitted && garantieActive && !garantie.dateFin">La date de fin est obligatoire.</small>
		</div>
	</div>
	<div *ngIf="garantieActive" class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12">
			<label for="description">Description de la garantie</label>
			<input  type="text" pInputText id="description" [(ngModel)]="garantie.description"
				autocomplete="off"  />
		</div>
		
	</div>

    <div class="p-text-right">

        <button pButton pRipple label="Sauvegarder" icon="pi pi-save" class="p-button-primary"
        (click)="sauvegarder()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>