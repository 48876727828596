import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';
import { Fournisseur } from '../entites/fournisseur';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { FournisseurService } from '../services/fournisseur-service';
import { BonCommandeEntite } from '../entites/bon-commande';

export interface ParametresSelectionBdc {
    bdcs: BonCommandeEntite[];
}

@Component({
    templateUrl: './selection-bdc.component.html',
})
export class SelectionBdcComponent extends BaseComponent {

    donnees: ParametresSelectionBdc;

    bdcs: BonCommandeEntite[];
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        private fournisseurService: FournisseurService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {

            super(sdb, profilService, route, routerService, appMain); }

    ngOnInit() {

        
        this.donnees = this.config.data;

        this.bdcs = this.donnees.bdcs;

        this.CompleterChargement();
    }

    surSelection(bdc: BonCommandeEntite) {
        this.ref.close(bdc);
    }

    annuler() {
        this.ref.close(null);
    }
}