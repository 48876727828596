<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Saisie des km
			</h5>
			<p-menubar [model]="menu">
			</p-menubar>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView #tabView [(activeIndex)]="tabIndex" orientation="left">
				<p-tabPanel header="Saisie manuelle" leftIcon="pi pi-info-circle">

					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-2">
							<label for="noUnite">Date de prise des km<span class="requis">*</span></label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateKms" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
					</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-3 p-lg-2">
							<label for="noUnite">No unité<span class="requis">*</span></label>
							<input #inputNumero type="text" pInputText id="noUnite" (keyup.enter)="validerNoItem()" [disabled]="etape != 1"
								[(ngModel)]="kmManuelle.numero" autocomplete="off" />
							<small class="p-error" *ngIf="submittedManuel && !kmManuelle.numero">Le numéro est
								obligatoire.</small>
						</div>
						<div class="p-field p-col-12 p-lg-3">
							<label for="description">Description<span class="requis">*</span></label>
							<input type="text" pInputText id="description" [readonly]="true"
								[(ngModel)]="kmManuelle.description" autocomplete="off" />
							<small class="p-error" *ngIf="submittedManuel && !kmManuelle.numero">Le numéro est
								obligatoire.</small>
						</div>
						<div class="p-col-12 p-md-2 p-lg-1 p-text-right">
							<label>&nbsp;</label>
							<p-button label="Valider" [disabled]="etape != 1" (click)="validerNoItem()"
								icon="pi pi-search" iconPos="left"></p-button>
						</div>



					</div>

					<div class="p-fluid p-formgrid p-grid" *ngIf="etape == 2">
						<div class="p-field p-col-12 p-lg-3">
							<label for="kmsActuelles">Derniers km<span class="requis">*</span></label>
							<input type="text" pInputText id="kmsActuelles" [readonly]="true"
								[(ngModel)]="kmManuelle.kmsActuelles" autocomplete="off" />
						</div>
						<div class="p-field p-col-12 p-lg-3">
							<label for="dateKmsActuelles">Date dernière prise<span class="requis">*</span></label>
							<input type="text" pInputText id="dateKmsActuelles" [readonly]="true"
							readonly value="{{kmManuelle.dateKmsActuelles | date:'yyyy-MM-dd'}}" autocomplete="off" />
						</div>
						<div class="p-field p-col-12 p-lg-3">
							<label for="kms">Nouveaux km<span class="requis">*</span></label>
							<input #inputKms type="number" pInputText id="kms" (keyup.enter)="sauvegarderKmManuelle()"
								[(ngModel)]="kmManuelle.kms" autocomplete="off" />
							<small class="p-error" *ngIf="submittedManuel && !kmManuelle.kms">Les km sont
								obligatoires.</small>
						</div>
						<div class="p-col-12 p-md-2 p-lg-1 p-text-right">
							<label>&nbsp;</label>
							<p-button label="Confirmer" [disabled]="!kmManuelle.kms" (click)="sauvegarderKmManuelle()"
								icon="pi pi-search" iconPos="left"></p-button>
						</div>
						<div class="p-col-12 p-md-2 p-lg-1 p-text-right" *ngIf="etape == 2">
							<label>&nbsp;</label>
							<p-button label="Annuler" class="p-button-text p-ml-2" icon="pi pi-ban" iconPos="left"
								(onClick)="annuler()"></p-button>
						</div>
					</div>





				</p-tabPanel>
				<p-tabPanel header="Saisie par liste" leftIcon="pi pi-list">

					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-3">
							<label for="noUnite">Emplacement<span class="requis">*</span></label>
							<p-dropdown [options]="emplacements" [(ngModel)]="emplacement" optionLabel="nom"
								appendTo="body" filter="true" filterBy="nom, code"
								placeholder="Sélectionnez un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="emplacement">
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="p-field p-col-12 p-lg-3">
							<label for="description">Département</label>
							<p-dropdown [options]="departements" [(ngModel)]="departement" optionLabel="nom"
								appendTo="body" filter="true" filterBy="nom"
								placeholder="Sélectionnez un departement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="departement">
										<div>{{departement.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-departement pTemplate="item">
									<div>
										<div>{{departement.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>
						<!-- <div class="p-col-12 p-md-2 p-lg-1 p-text-right">
							<label>&nbsp;</label>
							<p-button label="Filtrer" (click)="filtrer()" icon="pi pi-filter" iconPos="left"></p-button>
						</div> -->
						<div class="p-col-12 p-md-6 p-lg-3">
							<label>&nbsp;</label>
							<button style="width: auto; margin-top: 22px; margin-right: 10px;" pButton pRipple label="Filtrer" icon="pi pi-filter" class="p-button-primary"
								(click)="filtrer()"></button>
							<button style="width: auto; margin-top: 22px;" pButton pRipple label="Annuler" icon="pi pi-filter-slash" class="p-button-secondary"
								(click)="retirerFiltres()"></button>
						</div>



					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-2">
							<label for="noUnite">Date de prise des km<span class="requis">*</span></label>
						<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateKms" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
					</div>
					</div>
					<div class="p-col-12 p-text-right">
						<p-button label="Sauvegarder" (click)="sauvegarderKms()" icon="pi pi-save" iconPos="left"></p-button>
					</div>

					<p-table #dtKms *ngIf="EstCharge()" [value]="kms" dataKey="itemId"
						tableStyleClass="tableMoyen" autoLayout="true"
						[globalFilterFields]="['numero','description']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtKms.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header">
							<tr>
								<th>Unité</th>
								<th>Description</th>
								<th>Département</th>
								<th>Emplacement</th>
								<th>Derniers km</th>
								<th>Date derniers km</th>
								<th>Nouveaux km</th>
								<th></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree>
							<tr>
								<td>{{entree.numero}}</td>
								<td>{{entree.description}}</td>
								<td>{{entree.departement}}</td>
								<td>{{entree.emplacement}}</td>
								<td>{{entree.kmsActuelles}}</td>
								<td>{{entree.dateKmsActuelles | date:'yyyy-MM-dd'}}</td>
								<td pEditableColumn>
									<p-cellEditor>
										<ng-template pTemplate="input">
											<!-- <input pInputText type="text" [(ngModel)]="entree.quantite"> -->
											<p-inputNumber [readonly]="!entree.indApprouve" [(ngModel)]="entree.kms"></p-inputNumber>
										</ng-template>
										<ng-template pTemplate="output">
											<p-inputNumber [readonly]="true" [(ngModel)]="entree.kms"></p-inputNumber>
										</ng-template>
									</p-cellEditor>
								</td>
								<td class="erreurLigneKm">{{entree.erreur}}</td>
							</tr>
						</ng-template>
					</p-table>

					<div class="p-col-12 p-text-right">
						<p-button label="Sauvegarder" (click)="sauvegarderKms()" icon="pi pi-save" iconPos="left"></p-button>
					</div>

				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>


