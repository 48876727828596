import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoriqueEntretienPreventifEntite } from 'src/app/entites/mecanique/historique-entretien-preventif';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { MenuItem, TreeNode } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';

export interface ParametresHistoriqueEntretien {
    itemId: number;
    entretienPreventifId: number;
}

@Component({
    templateUrl: './historique-entretien.component.html',
})
export class HistoriqueEntretienComponent extends BaseComponent {

    donnees: ParametresHistoriqueEntretien;

    historique: HistoriqueEntretienPreventifEntite[];

    contenuMenuContextuel: MenuItem[];
    @ViewChild('menuContextuel') menuContextuel: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        private entretienPreventifService: EntretienPreventifService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {

            super(sdb, profilService, route, routerService, appMain); }

    ngOnInit() {

        this.donnees = this.config.data;

        //TODO
         this.entretienPreventifService.ObtenirHistorique(this.donnees.entretienPreventifId).then(res => {
            this.historique = res.valeur;

            this.CompleterChargement();
        });         
    }

     fermer() {
        this.ref.close(null);
    }

    surMenuContextuel(event, historique: HistoriqueEntretienPreventifEntite) {

        this.contenuMenuContextuel = [
            {
                label: 'Voir le bon de travail',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec'),
                //TODO
                command: () => { 
                    this.ref.close(null); 
                    this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                        this.routerService.navigate(['mecanique/bontravail/' + historique.bonTravail.bonTravailId]));
                }
            },
        ];

        this.menuContextuel.toggle(event);

    }
}