<p-toast key="archivage"></p-toast>



<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-3">
            <label for="quantity">Quantite<span class="requis">*</span></label>
            <p-inputNumber #champQuantite [(ngModel)]="quantite" (keyup.enter)="surEntree()">
            </p-inputNumber>
            <small class="p-error" *ngIf="submitted && !quantite">La quantité est obligatoire.</small>
        </div>
        <div class="p-col-12 p-md-9 p-lg-6 p-mb-3">
            <p-selectButton [options]="periodes" [(ngModel)]="selectionPeriode" optionLabel="valeur">
            </p-selectButton>
        </div>

    </div>

    
    <div class="p-text-right">

        <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-primary"
            (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

    </div>

</div>