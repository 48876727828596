import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/item-service';

@Component({
  selector: 'app-error',
  templateUrl: './app.error.component.html',
})
export class AppErrorComponent implements OnInit {

  constructor(private itemService: ItemService) {}

  ngOnInit() {
    this.itemService.Desactiver();
  }

}
