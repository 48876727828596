<p-toast key="selectionTransporteur"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
    
                        <p-table #dt [value]="requisitions" dataKey="requisitionId" tableStyleClass="tableNormale" autoLayout="true"
                            autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
                            [rowHover]="true" >
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex p-ai-center p-jc-between">
                                    <h5 class="titreTableau p-m-0">Réquisitions</h5>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Numéro</th>
									<th>Demandeur</th>
									<th>Camion</th>
									<th>Remorque</th>
									<th>Chauffeur</th>
									<th style="min-width: 5rem;"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-requisition>
                                <tr>
									<td>{{requisition.numero}}</td>
									<td>{{requisition.demandeur | nomEmploye}}</td>
                                    <td>{{requisition.camion}}</td>
                                    <td>{{requisition.remorque}}</td>
                                    <td>{{requisition.chauffeur}}</td>
                                    <td>
                                        <button pButton pRipple icon="pi pi-eye"
                                            class="p-button-success p-mr-2 boutonGrille"
                                            (click)="voirDetailRequisition(requisition)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Aucune réquisition.</td>
                                </tr>
                            </ng-template>
                        </p-table>
    
            </div>
        </div>
    
    
    </div>

    <div class="p-text-right">

        <button  #boutonConfirmer pButton pRipple label="OK" icon="pi pi-check" class="p-button-primary"
            (click)="confirmer()"></button>

    </div>

</div>