<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Réquisition
				<span class="statut nouveau">
					Nouveau
				</span>
			</h5>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<p-selectButton [options]="typesRequisition" [(ngModel)]="selectionTypeRequisition" optionLabel="valeur" (onChange)="surChangementTypeRequisition()"></p-selectButton>	
				</div>
				
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="lastname">Date</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateRequisition" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="demandeur">Demandeur<span *ngIf="this.selectionTypeRequisition.cle == 1 || this.selectionTypeRequisition.cle == 3" class="requis">*</span></label>
					<p-dropdown #champDemandeur [options]="listeEmployes" [(ngModel)]="selectionDemandeur" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="prenom,nom"
						appendTo="body" placeholder="Sélectionner un demandeur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionDemandeur">
								<div>{{selectionDemandeur.prenom}} {{selectionDemandeur.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-employe pTemplate="item">
							<div>
								<div>{{employe.prenom}} {{employe.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionDemandeur">Le demandeur est obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-2">
					<label for="camion">Camion</label>
					<input id="camion" type="text" pInputText [(ngModel)]="camion">
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-2">
					<label for="remorque">Remorque</label>
					<input id="remorque" type="text" pInputText [(ngModel)]="remorque">
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-2">
					<label for="chauffeur">Chauffeur</label>
					<input id="chauffeur" type="text" pInputText [(ngModel)]="chauffeur">
				</div>
			</div>
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-lg-6">
	
					<label for="lastname">Emplacement de départ<span class="requis">*</span></label>
					<p-dropdown [options]="emplacements" [(ngModel)]="selectionEmplacementSource" optionLabel="nom"
					[disabled]="this.selectionTypeRequisition.cle == 1"
						appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
						placeholder="Sélectionner un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionEmplacementSource">
								<div>{{selectionEmplacementSource.nom}} ({{selectionEmplacementSource.code}})</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionEmplacementSource">L'emplacement de départ
						est
						obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-lg-6">
	
					<label for="lastname">Emplacement de destination<span class="requis">*</span></label>
					<p-dropdown [options]="emplacements" [(ngModel)]="selectionEmplacementDestination" optionLabel="nom"
					[disabled]="this.selectionTypeRequisition.cle == 2"
						appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
						placeholder="Sélectionner un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectionEmplacementDestination">
								<div>{{selectionEmplacementDestination.nom}} ({{selectionEmplacementDestination.code}})</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !selectionEmplacementDestination">L'emplacement de destination
						est
						obligatoire.</small>
				</div>
			</div>
			<div class="p-col-12 p-text-right">
				<p-button label="Suivant" (click)="CreerRequisition()" icon="pi pi-save"
					iconPos="left"></p-button>
			</div>
		</div>
	</div>


</div>