import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { RaisonArchive } from '../entites/raison-archive';
import { ExecFileOptionsWithStringEncoding } from 'child_process';
import { AppMainComponent } from '../app.main.component';

export interface ParametresArchivage {
    itemId: number;
    numero: string;
    description: string;
}

export interface RetourArchivage {
    raisonArchiveId: number;
    commentaire: string;
}

@Component({
    selector: 'app-archivage',
    templateUrl: './archivage.component.html'
})
export class ArchivageComponent extends BaseComponent {

    donnees: ParametresArchivage;

    raisons: RaisonArchive[];
    selectionRaison: RaisonArchive;

    commentaire: string;

    submitted: boolean;
    blocked: boolean = false;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public achatService: AchatService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.raisons = this.sdb.RaisonsArchive();

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    archiver() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'archivage', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        this.itemService.Archiver({
            itemId: this.donnees.itemId,
            raisonArchive: this.selectionRaison,
            commentaireArchive: this.commentaire
        }).then(res => {
            if (res.estUnSucces) {
                this.ref.close({
                    raisonArchiveId: this.selectionRaison.raisonArchiveId,
                    commentaire: this.commentaire
                });
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ key: 'archivage', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
            }
        });

    }

    valider() {
        return this.selectionRaison;
    }
}