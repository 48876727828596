<p-toast key="assignerEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	
	<div class="p-fluid p-formgrid p-grid">
		
		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="date">Heures au compteur</label>
			<input #inputHeures type="number" pInputText id="heures"
								[(ngModel)]="nbHeures" autocomplete="off" />
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="date">KMs au compteur</label>
			<input #inputHeures type="number" pInputText id="heures"
								[(ngModel)]="nbKm" autocomplete="off" />
		</div>
		
	</div>



	<div class="p-text-right">

		<button pButton pRipple label="Envoyer" icon="pi pi-save" class="p-button-primary"
			(click)="approuver()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

	</div>

</div>