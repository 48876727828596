import { Component, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { BoiteOutilService } from 'src/app/services/boite-outil-service';
import { SousCategorieService } from 'src/app/services/sous-categorie-service';
import { MessageService } from 'primeng/api';

export interface ParametresSelection {
    categorieId: number;
    idSource: number;
    nom: string;
}

@Component({
    templateUrl: './fusion-sous-categorie.component.html',
})
export class FusionSousCategorieComponent extends BaseComponent {

    donnees: ParametresSelection;

    categorieId: number;
    idSource: number;
    nom: string;

    liste: SousCategorieEntite[];
    selection: SousCategorieEntite;

    submitted: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent,
        public messageService: MessageService,
        private sousCategorieService: SousCategorieService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }


    ngOnInit() {

        this.donnees = this.config.data;
        this.categorieId = this.donnees.categorieId;
        this.idSource = this.donnees.idSource;
        this.nom = this.donnees.nom;

        this.sousCategorieService.ObtenirListe().then(x => {
            this.liste = x.valeur.filter(x => x.categorieId == this.categorieId && x.sousCategorieId != this.idSource);
            this.CompleterChargement();
        });

    }

    confirmer() {

        this.submitted = true;

        if(!this.selection) {
            return;
        }


        this.sousCategorieService.Fusionner(this.idSource, this.selection.sousCategorieId).then(x => {
            if (x.estUnSucces) {
                this.ref.close(x.valeur);
            }
            else{
                x.messages.forEach(x => {
                    this.messageService.add({ key: 'fusionSousCategorie', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
            }
        });

        this.ref.close(this.selection);
    }

    annuler() {
        this.ref.close(null);
    }
}