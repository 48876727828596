<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-menubar [model]="menu"></p-menubar>
		</div>
	</div>
</div>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">
            <p-table #dt [value]="soumissions" [columns]="selectedColumns" dataKey="soumissionId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionSoumission"
                [rowHover]="true" [rows]="100" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['numero','date','fournisseur.nom','fournisseur.numero']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                                <button *ngIf="estAutorise('soumission_creer')" pButton pRipple icon="pi pi-plus"
											class="p-button-success p-mr-2 p-ml-4 p-mb-2 boutonGrille"
                                            (click)="ajouterSoumission()"></button>
                        </div>
                        <h5 class="titreTableau p-m-0">Soumissions</h5>
                        <div>
                            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="fournisseur.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Fournisseur
                                <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateDebut">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de début
                                <p-sortIcon field="dateDebut"></p-sortIcon>
                                <p-columnFilter type="text" field="dateDebut" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateFin">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de fin
                                <p-sortIcon field="dateFin"></p-sortIcon>
                                <p-columnFilter type="date" field="dateFin" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateDebutSaisie">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date début saisie
                                <p-sortIcon field="dateDebutSaisie"></p-sortIcon>
                                <p-columnFilter type="date" field="dateDebutSaisie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateFinSaisie">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date Fin Saisie
                                <p-sortIcon field="dateFinSaisie"></p-sortIcon>
                                <p-columnFilter type="date" field="dateFinSaisie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        
                        <th style="min-width: 10rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-soumission let-columns="columns">
                    <tr>
                        
                        <td>{{soumission.fournisseur.nom}}</td>
                        <td><span [class]="'statut ' + soumission.statut.nom.toLowerCase().replace(' ', '')">
                                    {{soumission.statut.nom}}
                                </span></td>
                                <td>{{soumission.dateDebut | date:'yyyy-MM-dd'}}</td>
                                <td>{{soumission.dateFin | date:'yyyy-MM-dd'}}</td>
                                <td>{{soumission.dateDebutSaisie | date:'yyyy-MM-dd'}}</td>
                                <td>{{soumission.dateFinSaisie | date:'yyyy-MM-dd'}}</td>
                      
                        <td class="celluleTableau">
                            <button pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetailSoumission(soumission.soumissionId)"></button>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary p-mr-2 boutonGrille"
                                (click)="surMenuContextuelItem($event, soumission)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">Aucune soumission.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>