import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';

export interface ParametresAjustementInventaire {
    itemId: number;
    emplacementId: number;
    numero: string;
    description: string;
    emplacement: string;
    quantiteCourante: number;
}

export interface ResultatAjustementInventaire {
    itemId: number;
    nouvelleQuantite: number;
}

@Component({
    selector: 'app-ajustement-inventaire',
    templateUrl: './ajustement-inventaire.component.html'
})
export class AjustementInventaireComponent extends BaseComponent {

    donnees: ParametresAjustementInventaire;

    quantite: number;

    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champQuantite') inputQuantite;
    @ViewChild('champDemandeur') inputDemandeur;
    @ViewChild('champExpediteur') inputExpediteur;
    @ViewChild('champFiltreCategorie') inputFiltreCategorie;
    @ViewChild('filtreCategorie') filtreCategorie;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.CompleterChargement();

        setTimeout(() => { 
            this.inputQuantite.input.nativeElement.focus();
            this.inputQuantite.input.nativeElement.select();
         }, 100);
    }

    annuler() {
        this.ref.close(null);
    }

    ajusterInventaire() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'ajustementInventaire', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

var d = new Date();

        this.itemService.AjusterInventaire({
            employe: this.profilService.ProfilCourant(),
            itemId: this.donnees.itemId,
            emplacementId: this.donnees.emplacementId,
            date: d,
            ajustement: this.quantite
        }).then(x => {
            if (x.estUnSucces) {
                this.ref.close({
                    itemId: this.donnees.itemId,
                    nouvelleQuantite: x.valeur
                });
            } else {
                x.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
                this.blocked = false;
            }
        });
    }

    valider() {
        return this.quantite;
    }
}