import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

export interface ParametresSelectionEmployes {
    employesIds: number[];
}

@Component({
    templateUrl: './selection-employes.component.html',
})
export class SelectionEmployesComponent {

    donnees: ParametresSelectionEmployes;

    employes: EmployeEntite[];
    selection: EmployeEntite[];
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {

        this.donnees = this.config.data;

        this.employes = this.sdb.Employes();
        this.employes.forEach(emp => {
            emp.prenomNom = `${emp.prenom} ${emp.nom}`;
        });

        this.selection = this.employes.filter((emp: EmployeEntite) => this.donnees.employesIds.includes(emp.employeId))
    }   

    Envoyer() {
        this.ref.close(this.selection);
    }

    Annuler() {
        this.ref.close(null);
    }
}