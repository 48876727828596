<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Rapport d'entrées / sorties
			</h5>

<!-- 
			<p-menubar [model]="menu">
			</p-menubar> -->
		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView orientation="left" [(activeIndex)]="tabIndex" >
				<p-tabPanel header="Critères" leftIcon="pi pi-info-circle">
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-4">
							<p-selectButton [options]="typesMouvement" [(ngModel)]="selectionTypeMouvement" optionLabel="valeur">
							</p-selectButton>
						</div>
						
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-4">
							<label for="categorie">Catégorie<span class="requis">*</span></label>
							<p-multiSelect [options]="categories" [(ngModel)]="selectionCategorie"
                                optionLabel="nom" appendTo="body"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Sélectionnez..."></p-multiSelect>
						
							<small class="p-error" *ngIf="submitted && !selectionCategorie">La catégorie est obligatoire.</small>
						</div>
						<div class="p-field p-col-12 p-lg-4">
	
							<label for="lastname">Emplacement courant<span class="requis">*</span></label>
							<p-dropdown [options]="emplacements" [(ngModel)]="selectionEmplacement" optionLabel="nom"
								appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
								placeholder="Sélectionner un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="selectionEmplacement">
										<div>{{selectionEmplacement.nom}} ({{selectionEmplacement.code}})</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !selectionEmplacement">L'emplacement est obligatoire.</small>
						</div>
						
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date de début<span class="requis">*</span></label>
							<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						</div>
						<div class="p-field p-col-12 p-md-6 p-lg-3">
							<label for="lastname">Date de fin<span class="requis">*</span></label>
							<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						</div>
					</div>
					
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<button pButton pRipple label="Générer rapport" icon="pi pi-chart-bar" class="p-button-primary"
            					(click)="generer()"></button>
						</div>
					</div>
					

				</p-tabPanel>
				<p-tabPanel header="Résultat" leftIcon="pi pi-list">
					<p-menu #menuContextuel popup="popup" [model]="contenuMenuContextuel"></p-menu>

					<p-table  #dtResultats [value]="resulats" dataKey="resulatId"
							 tableStyleClass="tableNormale" autoLayout="true"
							styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
							[globalFilterFields]="['numero','quantite','description']">
							<ng-template pTemplate="caption">
								<div class="p-d-flex p-ai-center p-jc-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtResultats.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>

									</div>
									<h5 class="titreTableau p-m-0">Résultats</h5>
									<div>
										<p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th *ngIf="EstColonneSelectionnee('date')" pSortableColumn="requisition.date">
										<div class="p-d-flex p-jc-between p-ai-center">
											Date
											<p-sortIcon field="requisition.date"></p-sortIcon>
											<p-columnFilter type="date" field="requisition.date" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="item.categorie.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Catégorie
											<p-sortIcon field="item.categorie.nom"></p-sortIcon>
											<p-columnFilter type="text" field="item.categorie.nom" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('numero')" pSortableColumn="item.numero">
										<div class="p-d-flex p-jc-between p-ai-center">
											Numéro
											<p-sortIcon field="item.numero"></p-sortIcon>
											<p-columnFilter type="text" field="item.numero" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('description')" pSortableColumn="item.description">
										<div class="p-d-flex p-jc-between p-ai-center">
											Description
											<p-sortIcon field="item.description"></p-sortIcon>
											<p-columnFilter type="text" field="item.description" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('de')" pSortableColumn="requisition.de.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											De
											<p-sortIcon field="requisition.de.nom"></p-sortIcon>
											<p-columnFilter type="text" field="requisition.de.nom" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('a')" pSortableColumn="requisition.a.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											À
											<p-sortIcon field="requisition.a.nom"></p-sortIcon>
											<p-columnFilter type="text" field="requisition.a.nom" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('noRequisition')" pSortableColumn="requisition.numero">
										<div class="p-d-flex p-jc-between p-ai-center">
											No réquisition
											<p-sortIcon field="requisition.numero"></p-sortIcon>
											<p-columnFilter type="text" field="requisition.numero" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('quantite')" pSortableColumn="quantite">
										<div class="p-d-flex p-jc-between p-ai-center">
											Quantité
											<p-sortIcon field="quantite"></p-sortIcon>
											<p-columnFilter type="text" field="quantite" display="menu"
												class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="modele">
										<div class="p-d-flex p-jc-between p-ai-center">
											Marque
											<p-sortIcon field="marque"></p-sortIcon>
											<p-columnFilter type="text" field="marque" display="menu" class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
										<div class="p-d-flex p-jc-between p-ai-center">
											Modèle
											<p-sortIcon field="modele"></p-sortIcon>
											<p-columnFilter type="text" field="modele" display="menu" class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('noCtrl')" pSortableColumn="noCtrl">
										<div class="p-d-flex p-jc-between p-ai-center">
											No Ctrl
											<p-sortIcon field="noCtrl"></p-sortIcon>
											<p-columnFilter type="text" field="noCtrl" display="menu" class="p-ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('emplacement')" pSortableColumn="emplacement.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Emplacement
											<p-sortIcon field="emplacement"></p-sortIcon>
											<p-columnFilter field="emplacement.nom" matchMode="in" display="menu"
											[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect [ngModel]="value" [options]="filtreEmplacement" placeholder="Any"
													(onChange)="filter($event.value)" optionLabel="name">
													<ng-template let-option pTemplate="item">
														<div class="p-multiselect-representative-option">
															<span class="p-ml-1">{{option}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
										</div>
									</th>
									<th *ngIf="EstColonneSelectionnee('compagnie')" pSortableColumn="compagnie.nom">
										<div class="p-d-flex p-jc-between p-ai-center">
											Compagnie
											<p-sortIcon field="compagnie.nom"></p-sortIcon>
											<p-columnFilter field="compagnie.nom" matchMode="in" display="menu"
											[showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
											<ng-template pTemplate="filter" let-value let-filter="filterCallback">
												<p-multiSelect [ngModel]="value" [options]="filtreCompagnies" placeholder="Any"
													(onChange)="filter($event.value)" optionLabel="name">
													<ng-template let-option pTemplate="item">
														<div class="p-multiselect-representative-option">
															<span class="p-ml-1">{{option}}</span>
														</div>
													</ng-template>
												</p-multiSelect>
											</ng-template>
										</p-columnFilter>
										</div>
									</th>
									<ng-container *ngFor="let col of colonnesSelectionnees">
									<th *ngIf="!col.base">
										<div class="p-d-flex p-jc-between p-ai-center">
											{{col.entete}}
										</div>
									</th>
									</ng-container>
									
									<!-- <th *ngIf="EstColonneSelectionnee('cout')">
										<div class="p-d-flex p-jc-between p-ai-center">
											Coût
										</div>
									</th> -->
									
									<th style="min-width: 7rem"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-info>

								<tr >
									<td *ngIf="EstColonneSelectionnee('date')">{{info.requisition.date | date:'yyyy-MM-dd'}}</td>
									<td *ngIf="EstColonneSelectionnee('categorie')">{{info.item.categorie.nom}}</td>
									<td *ngIf="EstColonneSelectionnee('numero')">{{info.item.numero}}</td>
									<td *ngIf="EstColonneSelectionnee('description')">{{info.item.description}}</td>
									<td *ngIf="EstColonneSelectionnee('de')">{{info.requisition.de.nom}} ({{info.requisition.de.code}})</td>
									<td *ngIf="EstColonneSelectionnee('a')">{{info.requisition.a.nom}} ({{info.requisition.a.code}})</td>
									<td *ngIf="EstColonneSelectionnee('noRequisition')">{{info.requisition.numero}}</td>
									<td *ngIf="EstColonneSelectionnee('quantite')">{{info.quantite}}</td>
									<td *ngIf="EstColonneSelectionnee('marque')">
										{{info.item.marque}}
									</td>
									<td *ngIf="EstColonneSelectionnee('modele')">
										{{info.item.modele}}
									</td>
									<td *ngIf="EstColonneSelectionnee('noCtrl')">
										{{info.item.noCtrl}}
									</td>
									<td *ngIf="EstColonneSelectionnee('emplacement')">
										{{info.item.emplacement | nomEmplacement}}
									</td>
									<td *ngIf="EstColonneSelectionnee('compagnie')">
										{{info.item.compagnie?.nom}}
									</td>
									<ng-container *ngFor="let col of colonnesSelectionnees">
										<td *ngIf="!col.base">
											{{obtenirValeurAttribut(info.item, col.champ)}}
										</td>
										</ng-container>
									<td>
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary p-mr-2 boutonGrille"
											(click)="surMenuContextuel($event, info)"></button>
											<button *ngIf="estAutorise('item_detail')" pButton pRipple icon="pi pi-external-link"
												class="p-button-secondary p-mr-2 boutonGrille"
												(click)="OuvrirDetailItemNouvelOnglet(info.item.itemId)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="9">Aucune entrée / sortie.</td>
								</tr>
							</ng-template>
						</p-table>

				</p-tabPanel>
				
			</p-tabView>
		</div>
	</div>


</div>

<!-- 

<p-dialog [(visible)]="dialogueAjustementInventaire" [style]="{width: '40%'}" header="Ajustement d'inventaire"
	[modal]="true" [contentStyle]="{'overflow': 'visible'}" styleClass="p-fluid p-formgrid p-grid">
	<ng-template pTemplate="content">

		<div class="p-formgrid p-grid">
			<div class="p-field p-col-12 p-lg-8">
				<label for="noFournisseur">Quantité</label>
				<p-inputNumber id="noFournisseur">
				</p-inputNumber>
			</div>
		</div>

	</ng-template>

	<ng-template pTemplate="footer">
		<button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
			(click)="hideDialog()"></button>
		<button pButton pRipple label="Ajuster" icon="pi pi-check" class="p-button-text"
			(click)="ajusterInventaire()"></button>
	</ng-template>
</p-dialog> -->

<p-sidebar [(visible)]="afficherDetailItem" [style]="{width:'95%'}" position="left">
	<app-detail-item [itemId]="detailItemId" *ngIf="afficherDetailItem"></app-detail-item>
</p-sidebar>