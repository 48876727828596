<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">

            <p-tabView orientation="left">
                <p-tabPanel header="Par emplacement" leftIcon="pi pi-info-circle">

            <p-table #dt [value]="listeEmplacementsActifs" [columns]="selectedColumns" dataKey="emplacementId" tableStyleClass="tableNormale"
                autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
             [rowHover]="true" [filterDelay]="0" [globalFilterFields]="['nom','code']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span><button *ngIf="estAutorise('commande_gerer_active')" pButton pRipple icon="pi pi-cog"
                                class="p-button-rounded p-button-secondary p-button-sm p-mb-2 p-ml-4"
                                (click)="SelectionnerCommandesActives()"></button>
                        </div>
                        <h5 class="titreTableau p-m-0">Commandes</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="code">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="code"></p-sortIcon>
                                <p-columnFilter type="text" field="code" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        <th pSortableColumn="nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Emplacement
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        <th style="min-width: 5rem; width: 5rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-commande>
                    <tr [pContextMenuRow]="commande">
                        <!-- <td>
							<p-tableCheckbox [value]="Item"></p-tableCheckbox>
                        </td> -->

                        <td>{{commande.code}}</td>

                        <td>{{commande.nom}}</td>
                        <td>
                            <button *ngIf="estAutorise('commande_detail')" pButton pRipple icon="pi pi-eye"
                                class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetailCommande(commande.emplacementId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">Aucune commande.</td>
                    </tr>
                </ng-template>
            </p-table>

        </p-tabPanel>

        <p-tabPanel header="Par département" leftIcon="pi pi-info-circle">

            <p-table #dtDepartements [value]="listeDepartements" [columns]="selectedColumns" dataKey="departementId" tableStyleClass="tableNormale"
            autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
             [rowHover]="true" [filterDelay]="0" [globalFilterFields]="['nom']">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <div>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dtDepartements.filterGlobal($event.target.value, 'contains')"
                                placeholder="Recherche..." />
                        </span>
                    </div>
                    <h5 class="titreTableau p-m-0">Commandes</h5>
                    <div>
                        <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>


                    <th pSortableColumn="departement">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Département
                            <p-sortIcon field="departement"></p-sortIcon>
                            <p-columnFilter type="text" field="departement" display="menu" class="p-ml-auto">
                            </p-columnFilter>
                        </div>
                    </th>

                    <th style="min-width: 5rem; width: 5rem;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-departement>
                <tr [pContextMenuRow]="departement">

                    <td>{{departement.nom}}</td>
                    <td>
                        <button *ngIf="estAutorise('commande_detail')" pButton pRipple icon="pi pi-eye"
                            class="p-button-success p-mr-2 boutonGrille"
                            (click)="OuvrirDetailCommandeParDepartement(departement.departementId)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">Aucun département.</td>
                </tr>
            </ng-template>
        </p-table>

        </p-tabPanel>
    </p-tabView>



        </div>
    </div>


</div>



<p-dialog [(visible)]="dialogueCommandesActives" [style]="{width: '85%'}" header="Commandes actives" [modal]="true"
    styleClass="p-fluid p-formgrid p-grid">
    <ng-template pTemplate="content">

        <div class="p-formgrid p-grid">
            <div class="p-field p-col-12">
                <p-pickList [source]="listeEmplacementsInactifs" [target]="listeEmplacementsActifs" sourceHeader="Inactif"
                    targetHeader="Actif" [dragdrop]="true" [responsive]="true" [sourceStyle]="{'height':'30rem'}"
                    [targetStyle]="{'height':'30rem'}" filterBy="nom,code" sourceFilterPlaceholder="Recherche..."
                    targetFilterPlaceholder="Recherche...">
                    <ng-template let-emplacement pTemplate="item">
                        <div class="product-item">
                            <div class="product-list-detail">
                                <h5 class="p-mb-2">{{emplacement.nom}}</h5>
                                <span class="product-category">{{emplacement.code}}</span>
                            </div>
                        </div>
                    </ng-template>
                </p-pickList>
            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="annulerConfigurationCommandesActives()"></button>
        <button pButton pRipple label="Sauvegarder" icon="pi pi-check" class="p-button-text"
            (click)="sauvegarderConfigurationCommandesActives()"></button>
    </ng-template>
</p-dialog>