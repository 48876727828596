import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ItemBonCommande } from 'src/app/entites/item-bon-commande';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { RapportService } from 'src/app/services/rapport-service';
import { DatePipe } from '@angular/common';
import { EnvoiBonCommandeComponent } from 'src/app/controles/envoi-bon-commande.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { ModificationItemBonCommandeComponent } from './controles/modification-item-bon-commande.component';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { CodeProjetEntite } from 'src/app/entites/code-projet';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { PieceService } from 'src/app/services/piece-service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-detail-bdc',
    templateUrl: './detail-achat.component.html',
    providers: [MessageService, ConfirmationService, DialogService, DatePipe],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailAchatComponent extends BaseComponent implements OnInit {

    @Input('bdcId') inputBdcId: number;

    modeApercu: boolean = false;

    bonCommandeId: number;
    bonCommande: BonCommandeEntite;
    selectionEntree: ItemBonCommande;
    selectionMultipleEntree: ItemBonCommande[] = [];

    codeActiviteId?: number = null;
    codeCategorieId?: number = null;


    frequences: CleValeur[];
    selectionFrequence: CleValeur;

    fournisseurs: Fournisseur[];
    listeCodeProjet: CodeProjetEntite[];

    listeEmplacement: Emplacement[];

    prixTotal: number = 0.0;

    menuContextuelEntrees: MenuItem[];

    ref: DynamicDialogRef;

    menu: MenuItem[];



    @ViewChild('descriptionItem') champDescription: ElementRef;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private achatService: AchatService,
        private EmplacementService: EmplacementService,
        private ItemsService: ItemService,
        private fournisseurService: FournisseurService,
        private rapportService: RapportService,
        public titleService: Title,
        private pieceService: PieceService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router,
        public datepipe: DatePipe) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Détail du bon de commande' }
        ]);

        this.route.params.subscribe(params => this.bonCommandeId = params.id);
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutItemActive) {
        //     this.surAjoutItem();
        // }
    }

    ngOnInit() {

        if (this.inputBdcId) {
            this.bonCommandeId = this.inputBdcId;
            this.modeApercu = true;
        }

        this.verifierAccesPage('bdc_detail');

        this.colonnesDisponibles = [
            { base: true, champ: 'numero', entete: 'No SIHC', afficheParDefaut: true },
            { base: true, champ: 'description', entete: 'Description', afficheParDefaut: true },
            { base: true, champ: 'noFournisseur', entete: 'No pièce fournisseur', afficheParDefaut: true },
            { base: true, champ: 'quantite', entete: 'Quantité', afficheParDefaut: true },
            { base: true, champ: 'unite', entete: 'Unité', afficheParDefaut: true },
            { base: true, champ: 'prix', entete: 'Prix', afficheParDefaut: true },
            { base: true, champ: 'projet', entete: 'Code de projet', afficheParDefaut: true },
            { base: true, champ: 'activite', entete: 'Code d\'activité', afficheParDefaut: true },
            { base: true, champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: true }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.listeCodeProjet = this.sdb.CodesProjet();

        this.frequences = [
            { cle: 'N', valeur: 'Aucune' },
            { cle: 'J', valeur: 'Jours' },
            { cle: 'H', valeur: 'Semaine' },
            { cle: 'M', valeur: 'Mois' },
            { cle: 'A', valeur: 'Année' },
            { cle: 'D', valeur: 'Détaillée' }
        ]

        var p1 = new Promise<number>((resolve, reject) => {


            this.achatService.ObtenirBonCommande(this.bonCommandeId).then(x => {

                this.bonCommande = x.valeur;

                //this.titleService.setTitle(`SIHC - ${this.bonCommande.numero}`);

                this.bonCommande.dateAchat = new Date(this.bonCommande.dateAchat);
                if (this.bonCommande.requisLe) {
                    this.bonCommande.requisLe = new Date(this.bonCommande.requisLe);
                }


                if (this.bonCommande.projet) {
                    this.bonCommande.projet = this.listeCodeProjet.find(x => x.codeProjetId == this.bonCommande.projet.codeProjetId);
                }

                if (this.bonCommande.frequence) {
                    this.selectionFrequence = this.frequences.find(x => x.cle == this.bonCommande.frequence);
                }

                this.majPrixTotal();

                resolve();
            });
        });

        var p2 = new Promise<number>((resolve, reject) => {
            this.EmplacementService.Obtenir().then(x => {
                this.listeEmplacement = x.valeur.filter(x => x.statut.statutId != EStatuts.Archive && x.adresse != null && x.adresse != undefined && x.adresse.adresseId != 0);;
                resolve();
            });
        });

        var p3 = new Promise<number>((resolve, reject) => {
            this.fournisseurService.ObtenirFournisseurs().then(x => {
                this.fournisseurs = x.valeur;
                resolve();
            });
        });

        Promise.all([p1, p2, p3]).then(x => {
            this.bonCommande.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.bonCommande.fournisseur.fournisseurId);
            this.bonCommande.emplacement = this.listeEmplacement.find(x => x.emplacementId == this.bonCommande.emplacement.emplacementId);

            this.initialiserMenu();


            this.CompleterChargement(`Bon de commande #${this.bonCommande.numero} (${this.bonCommande.fournisseur.nom})`)
        });



        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'noSerie', entete: 'No série', afficheParDefaut: false },
            { champ: 'noPlaque', entete: 'No plaque', afficheParDefaut: false },
            { champ: 'poids', entete: 'Poids', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

    }

    initialiserMenu() {

        this.estModifiable = this.bonCommande.statut.statutId == EStatuts.EnCours ||
            this.bonCommande.statut.statutId == EStatuts.EnCorrection;

        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('achats')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.imprimer(),
                disabled: !this.modeConsultation()
            },
            {
                label: 'Envoyer', icon: 'pi pi-fw pi-send',
                visible: this.estAutorise('bdc_courriel'),
                disabled: this.bonCommande.numero == '0' || !this.modeConsultation(),
                command: () => this.envoyer()
            },
            {
                label: 'Actions', icon: 'pi pi-fw pi-bars',
                disabled: !this.modeConsultation(),
                items: [
                    {
                        label: 'Obtenir numéro', icon: 'pi pi-fw pi-dollar',
                        visible: this.estAutorise('bdc_numero_ctrl'),
                        disabled: this.bonCommande.numero != '0' || this.bonCommande.statut.statutId == EStatuts.Complete,
                        command: () => this.ObtenirNumeroCtrl()
                    },
                    {
                        label: 'Transférer vers CTRL', icon: 'pi pi-fw pi-dollar',
                        visible: this.estAutorise('bdc_transfert_ctrl'),
                        disabled: this.bonCommande.statut.statutId == EStatuts.Complete,
                        command: () => this.TransfertCtrl()
                    },
                    {
                        label: 'Compléter sans transfert vers CTRL', icon: 'pi pi-fw pi-dollar',
                        visible: this.estAutorise('bdcs_completer'),
                        disabled: this.bonCommande.statut.statutId == EStatuts.Complete,
                        command: () => this.CompleterSansTransfert()
                    },
                    {
                        label: 'Corriger le bon de commande', icon: 'pi pi-fw pi-pencil',
                        visible: this.estAutorise('bdc_modifier') && this.bonCommande.statut.statutId == EStatuts.Complete && this.bonCommande.numero != '0',
                        command: () => this.corriger()
                    },
                    { separator: true },
                    {
                        label: 'Supprimer le bon de commande', icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('bdc_supprimer'),
                        disabled: this.bonCommande.statut.statutId != EStatuts.EnCours,
                        command: () => this.supprimerbonCommande()
                    }
                ]
            },
            {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('bdc_modifier'),
                disabled: !this.modeConsultation() || this.selectionMultipleEntree.length == 0 || this.bonCommande.statut.statutId == EStatuts.Complete,
                items: [
                    {
                        label: 'Supprimer', icon: 'pi pi-fw pi-times', command: () => this.supprimerItems(this.selectionMultipleEntree)
                    },
                ]
            },
        ];
    }

    surMenuContextuelItem(event, itemBonCommande: ItemBonCommande) {
        this.selectionEntree = itemBonCommande;

        this.menuContextuelEntrees = [
            {
                label: 'Voir l\'item', icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl(`item/${itemBonCommande.item.itemId}`)
            },
            { separator: true },
            {
                label: 'Modifier', icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('bdc_modifier') && !this.modeApercu,
                disabled: this.bonCommande.statut.statutId == EStatuts.Complete,
                command: () => this.modifierItemBonCommande(itemBonCommande)
            },
            { separator: true },
            {
                label: 'Supprimer', icon: 'pi pi-fw pi-times',
                visible: this.estAutorise('bdc_modifier') && !this.modeApercu,
                disabled: this.bonCommande.statut.statutId == EStatuts.Complete,
                command: () => this.supprimerItems([itemBonCommande])
            }
        ];


        this.menuContextuelItemCommande.toggle(event);
    }

    ouvrirFicheComplete() {
        this.routerService.navigateByUrl('achat/' + this.bonCommandeId);
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportBonCommande(this.bonCommande.bonCommandeId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    btnModifier_click() {
        this.Modifier();
        this.initialiserMenu();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.achatService.ObtenirBonCommande(this.bonCommandeId).then(x => {

            this.bonCommande = x.valeur;
            this.bonCommande.dateAchat = new Date(this.bonCommande.dateAchat);
            this.bonCommande.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.bonCommande.fournisseur.fournisseurId);
            this.bonCommande.projet = this.listeCodeProjet.find(x => x.codeProjetId == this.bonCommande.projet.codeProjetId);
            this.bonCommande.emplacement = this.listeEmplacement.find(x => x.emplacementId == this.bonCommande.emplacement.emplacementId);
            this.bonCommande.numero = x.valeur.numero;
            this.bonCommande.projet = x.valeur.projet;
            this.bonCommande.fournisseur = x.valeur.fournisseur;
            this.bonCommande.employe = x.valeur.employe;
            this.bonCommande.note = x.valeur.note;
            this.initialiserMenu();

            this.CompleterChargement(`Bon de commande #${this.bonCommande.numero} (${this.bonCommande.fournisseur.nom})`)
        });

        this.Consulter();

        this.initialiserMenu();
    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.validerModificationBdc()) {
            this.DemarrerChargement();

            this.bonCommande.frequence = this.selectionFrequence?.cle;

            this.achatService.ModifierBonCommande(this.bonCommande).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.initialiserMenu();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande modifié.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModificationBdc(): boolean {

        if (!this.bonCommande.fournisseur) {
            return false;
        }

        if (!this.bonCommande.emplacement) {
            return false;
        }

        if (!this.bonCommande.projet) {
            return false;
        }

        if (!this.bonCommande.dateAchat) {
            return false;
        }

        return true;
    }


    supprimerbonCommande() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le bon de commande courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.achatService.SupprimerBonCommande(this.bonCommande.bonCommandeId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('achats');
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    supprimerItems(itemsBonsCommande: ItemBonCommande[]) {
        var ids = itemsBonsCommande.map(b => b.itemBonCommandeId);
        this.confirmationService.confirm({
            target: event.target,
            message: itemsBonsCommande.length == 1 ? 'Voulez-vous vraiment supprimer l\'item sélectionné ?' : 'Voulez-vous vraiment supprimer les items sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.achatService.SupprimerItemsBonCommande(ids).then(res => {
                    if (res.estUnSucces) {
                        this.bonCommande.itemsBonCommande = this.bonCommande.itemsBonCommande.filter((ibc: ItemBonCommande) => !ids.includes(ibc.itemBonCommandeId))
                        this.majPrixTotal();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    modifierItemBonCommande(itemBonCommande: ItemBonCommande) {

        const ref = this.dialogService.open(ModificationItemBonCommandeComponent, {
            data: {
                itemBonCommande: itemBonCommande
            },
            header: 'Modifier un item',
            width: '90%'
        }).onClose.subscribe((res: ItemBonCommande) => {
            if (res) {
                itemBonCommande.quantite = res.quantite;
                itemBonCommande.noFournisseur = res.noFournisseur;
                itemBonCommande.prix = res.prix;
                itemBonCommande.codeProjet = res.codeProjet;
                itemBonCommande.codeActivite = res.codeActivite;
                itemBonCommande.codeCategorie = res.codeCategorie;
                itemBonCommande.note = res.note;

                this.majPrixTotal();

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Item modifié.` });
            }

        });

    }


    estFenetreAjoutItemActive: boolean = false;
    surAjoutItem() {
        this.estFenetreAjoutItemActive = true;
        const ref = this.dialogService.open(SelectionItemComponent, {
            data: {
                type: ETypeSelectionItem.BonCommande,
                fournisseurId: this.bonCommande.fournisseur.fournisseurId,
                selectionCodeProjet: this.bonCommande.projet?.codeProjetId,
                selectionCodeActivite: this.codeActiviteId,
                selectionCodeCategorie: this.codeCategorieId
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {
            this.estFenetreAjoutItemActive = false;
            if (res) {
                this.Bloquer();

                var itemEquivalent = this.bonCommande.itemsBonCommande.filter(x =>
                    x.item.itemId == res.item.itemId &&
                    x.noFournisseur == res.noFournisseur &&
                    x.prix == res.prix &&
                    x.codeProjet?.codeProjetId == res.codeProjet?.codeProjetId &&
                    x.codeActivite?.codeActiviteId == res.codeActivite?.codeActiviteId &&
                    x.codeCategorie?.codeCategorieId == res.codeCategorie?.codeCategorieId &&
                    x.codeEquipement?.codeEquipementId == res.codeEquipement?.codeEquipementId &&
                    x.codeExtra?.codeExtraId == res.codeExtra?.codeExtraId);

                if (res.quantiteDemande > 0 && itemEquivalent.length > 0) {

                    itemEquivalent[0].quantite += res.quantiteDemande;

                    this.achatService.ModifierItemBonCommande(itemEquivalent[0]).then(res2 => {
                        if (res2.estUnSucces) {

                            itemEquivalent[0].quantite = res2.valeur.quantite;
                            itemEquivalent[0].noFournisseur = res2.valeur.noFournisseur;
                            itemEquivalent[0].prix = res2.valeur.prix;
                            itemEquivalent[0].codeProjet = res2.valeur.codeProjet;
                            itemEquivalent[0].codeActivite = res2.valeur.codeActivite;
                            itemEquivalent[0].codeCategorie = res2.valeur.codeCategorie;
                            itemEquivalent[0].note = res2.valeur.note;

                            this.majPrixTotal();

                            //Association
                            if (res.indAssociation && res.codeEquipement) {

                                this.pieceService.AssocierEquipementCtrl(res.item.itemId, res.codeEquipement.code, res.categorieEntretienId).then(resAssoc => {
                                    this.Debloquer();
                                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res2.valeur.item.description} (${res2.valeur.item.numero})' a été ajouté.` });
                                });
                            } else {
                                this.Debloquer();
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res2.valeur.item.description} (${res2.valeur.item.numero})' a été ajouté.` });
                            }

                        } else {
                            this.Debloquer();
                            res2.messages.forEach(x => {
                                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                            });
                        }
                    });

                } else {

                    var bdc = { ...this.bonCommande };
                    bdc.itemsBonCommande = [];

                    this.achatService.CreerItemBonCommande({
                        itemBonCommandeId: 0,
                        bonCommande: bdc,
                        bonCommandeId: this.bonCommande.bonCommandeId,
                        item: res.item,
                        noFournisseur: res.noFournisseur,
                        quantite: res.quantiteDemande,
                        note: res.note,
                        prix: res.prix,
                        codeProjet: res.codeProjet,
                        codeActivite: res.codeActivite,
                        codeCategorie: res.codeCategorie,
                        codeEquipement: res.codeEquipement,
                        codeExtra: res.codeExtra,
                        emplacement: res.emplacement,
                        indConserverPrix: true
                    }).then(res2 => {
                        if (res2.estUnSucces) {
                            this.bonCommande.itemsBonCommande.push(res2.valeur);

                            this.majPrixTotal();

                            //Association
                            if (res.indAssociation && res.codeEquipement) {

                                this.pieceService.AssocierEquipementCtrl(res.item.itemId, res.codeEquipement.code, res.categorieEntretienId).then(resAssoc => {
                                    this.Debloquer();
                                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res2.valeur.item.description} (${res2.valeur.item.numero})' a été ajouté.` });
                                });
                            } else {
                                this.Debloquer();
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res2.valeur.item.description} (${res2.valeur.item.numero})' a été ajouté.` });
                            }

                        } else {
                            this.Debloquer();
                            res2.messages.forEach(x => {
                                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                            });
                        }
                    });

                }

                this.codeActiviteId = res.codeActivite?.codeActiviteId;
                this.codeCategorieId = res.codeCategorie.codeCategorieId;




            }

        });
    }

    majPrixTotal() {
        this.prixTotal = 0.0;
        this.bonCommande.itemsBonCommande.forEach(x => {
            if (x.quantite && x.prix) {
                this.prixTotal += (x.quantite * x.prix);
            }
        });
    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    ObtenirNumeroCtrl() {
        this.Bloquer();

        this.achatService.ObtenirNumeroCtrl(this.bonCommande.bonCommandeId).then(res => {
            this.Debloquer();
            if (res.estUnSucces) {
                this.messageService.add({ severity: 'success', summary: 'Bon de commande créé', detail: `Nouveau numéro du bon de commande: ${res.valeur}` });
                this.bonCommande.numero = res.valeur;
                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, life: 10000 });
                });
            }
        })
    }

    TransfertCtrl() {

        this.confirmationService.confirm({
            message: `Voulez-vous vraiment transférer le bon de commande vers CTRL?`,
            accept: () => {
                this.Bloquer();
                this.achatService.TransfererVersCtrl({
                    bonCommandeId: this.bonCommande.bonCommandeId,
                    emplacementId: 10000 //TODO Hard-code 10000. 
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.messageService.add({ severity: 'success', summary: 'Bon de commande transféré', detail: `Numéro du bon de commande: ${res.valeur}` });
                        this.bonCommande.numero = res.valeur;
                        this.bonCommande.statut = {
                            "statutId": 13,
                            "nom": "Complété"
                        };
                        this.initialiserMenu();
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, life: 10000 });
                        });
                    }
                })
            }
        });
    }

    CompleterSansTransfert() {

        this.confirmationService.confirm({
            message: `Voulez-vous vraiment compléter le bon de commande sans transfert vers CTRL?`,
            accept: () => {
                this.Bloquer();

                this.achatService.CompleterBonCommandeSansTransfertCtrl(this.bonCommande).then(x => {
                    this.Debloquer();
                    if (x.estUnSucces) {
                        this.bonCommande.statut = this.sdb.Statut(EStatuts.Complete);
                        this.Consulter();
                        this.initialiserMenu();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande modifié.' });
                    } else {
                        x.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });

                    }

                });
            }
        });


    }

    corriger() {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment corriger le bon de commande courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.achatService.CorrigerBonCommande(this.bonCommande).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le bon de commande est maintenant en correction' });
                        this.bonCommande = res.valeur;
                        this.bonCommande.dateAchat = new Date(this.bonCommande.dateAchat);
                        this.bonCommande.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.bonCommande.fournisseur.fournisseurId);
                        this.bonCommande.projet = this.listeCodeProjet.find(x => x.codeProjetId == this.bonCommande.projet.codeProjetId);
                        this.bonCommande.emplacement = this.listeEmplacement.find(x => x.emplacementId == this.bonCommande.emplacement.emplacementId);
                        this.initialiserMenu();
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }

                });

            }
        });


    }

    envoyer() {
        const ref = this.dialogService.open(EnvoiBonCommandeComponent, {
            data: {
                bonCommandeId: this.bonCommande.bonCommandeId,
                fournisseurId: this.bonCommande.fournisseur.fournisseurId
            },
            header: 'Envoi d\'un bon de commande',
            width: '60%'
        }).onClose.subscribe((res: boolean) => {
            if (res) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le bon de commande a été envoyé.' });
                this.bonCommande.dateEnvoi = new Date();
            }


        });
    }



}
