import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ValeurAttribut } from 'src/app/entites/valeur-attribut';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { InfoUtilisationEquipement } from 'src/app/services/reponses/reponse-rapport-utilisation-equipements';
import { RapportService } from 'src/app/services/rapport-service';
import { TypeFacturationEntite } from 'src/app/entites/type-facturation';

@Component({
    templateUrl: './rapport-utilisation-equipement.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class RapportUtilisationEquipementComponent extends BaseComponent implements OnInit {

    dateDebut: Date;
    dateFin: Date;

    filtreEmplacement: string[];

    categories: CategorieEntite[];
    selectionCategorie: CategorieEntite;
    
    emplacements: Emplacement[];
    selectionEmplacement: Emplacement;

    typesFacturation: TypeFacturationEntite[];
    typeFacturation: TypeFacturationEntite;

    chargementCompleteResultats: boolean = false;

    resulats: InfoUtilisationEquipement[] = [];

    tabIndex: number = 0;
  
    @ViewChild('dtResultats') tableItems: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Rapport d\'utilisation des équipements' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('rapport_utilisation_equipement');

        this.categories = this.sdb.Categories().filter(x => x.estUnique);
        this.selectionCategorie = this.categories.find(x => x.categorieId == 2)

        this.emplacements = this.sdb.Emplacements();
        this.filtreEmplacement = [];
        this.emplacements.forEach(x => {
            this.filtreEmplacement.push(x.nom);
        });

        this.typesFacturation = this.sdb.TypesFacturation();

        this.rapportService.ObtenirDatesRapportUtilisationEquipements().then(res => {
            this.dateDebut = new Date(res.valeur.dateDebut);
            this.dateFin = new Date(res.valeur.dateFin);

            this.CompleterChargement();
        });

    }

    generer() {
        this.Bloquer();
        this.rapportService.ObtenirRapportUtilisationEquipements({
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            categorieId: this.selectionCategorie?.categorieId,
            emplacementId: this.selectionEmplacement?.emplacementId,
            typeFacturationId: this.typeFacturation?.typeFacturationId
        }).then(res => {
            this.Debloquer();

            if (res.estUnSucces) {
                this.resulats = res.valeur.infosUtilisation;
                this.tabIndex = 1;
                console.log(this.resulats);
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
            
        });
    }

    initialiserMenu() {
        /* this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('pilotage/emplacements')
            }
        ]; */
    }


    /* TAB: INVENTAIRE */

    surMenuContextuelInventaire(event, info: InfoUtilisationEquipement) {
      /*   this.itemInventaire = inventaire;

        this.contenuMenuContextuelInventaire = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl('item/' + this.itemInventaire.itemId) }
        ];

        this.menuContextuelInventaire.toggle(event); */

    }

    exportExcel() {

        var t = this.tableItems.value;
        
        if (this.tableItems.filteredValue) {
            t = this.tableItems.filteredValue;
        }

        var lignes: any[] = t.map(x => ({
            Numéro: x.item.numero,
            NuméroCTRL: x.item.noCtrl ? x.item.noCtrl : '',
            Description: x.item.description ? x.item.description : '',
            NomEmplacement: x.emplacement ? x.emplacement.nom : '',
            NumeroEmplacement: x.emplacement ? x.emplacement.code : '',
            DateDebut: x.dateDebut,
            DateFin: x.dateFin,
            NbJours: x.nbJours,
          }));

          /* for (let index = 0; index < lignes.length; index++) {
              if (this.colonnesSelectionnees.filter(x => x.champ == 'categorie').length == 1) {
                if (t[index].categorie && t[index].categorie.nom){
                    lignes[index].Categorie = t[index].categorie?.nom;
                } else{
                    lignes[index].Categorie = '';
                }
                lignes[index].Categorie = t[index].categorie?.nom;
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'sousCategorie').length == 1) {
                if (t[index].sousCategorie && t[index].sousCategorie.nom){
                    lignes[index].SousCategorie = t[index].sousCategorie?.nom;
                } else{
                    lignes[index].SousCategorie = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'marque').length == 1) {
                if (t[index].marque){
                    lignes[index].Marque = t[index].marque;
                } else{
                    lignes[index].Marque = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'modele').length == 1) {
                if (t[index].modele){
                    lignes[index].Modele = t[index].modele;
                } else{
                    lignes[index].Modele = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'emplacement').length == 1) {
                if (t[index].emplacement && t[index].emplacement.nom){
                    lignes[index].Emplacement = t[index].emplacement?.nom;
                } else{
                    lignes[index].Emplacement = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'compagnie').length == 1) {
                if (t[index].compagnie && t[index].compagnie.nom){
                    lignes[index].Compagnie = t[index].compagnie?.nom;
                } else{
                    lignes[index].Compagnie = '';
                }
              }
          } */



          this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

          this.rapportService.ProduireRapportExcel({
            contenu: JSON.stringify(lignes)
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        }); 
          

    }


}
