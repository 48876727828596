<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-contextMenu #cm [model]="menuContextuel"></p-contextMenu>


<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="p-grid" *ngIf="EstCharge()">
    <div class="p-col-12">

        <div class="card">
            <div class="p-grid">
                <div class="p-col-6">
                    <h5>Approbation des listes d'achat</h5>
                </div>
            </div>
            <p-table #dt [value]="listes" dataKey="listeAchatId" tableStyleClass="tableNormale" autoLayout="true"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionListe"
                [contextMenu]="cm" [rowHover]="true" [rows]="25" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['nom','description']">
                <ng-template pTemplate="caption">
                    <div class="p-grid">
                        <div class="p-col-6">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                       
                        <th pSortableColumn="dateSaisie">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date
                                <p-sortIcon field="dateSaisie"></p-sortIcon>
                                <p-columnFilter type="date" field="dateSaisie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="statut"></p-sortIcon>
                                <p-columnFilter type="text" field="statut" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="employe">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Saisi par
                                <p-sortIcon field="employe"></p-sortIcon>
                                <p-columnFilter type="text" field="employe" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 4rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-liste >
                    <tr [pContextMenuRow]="liste">
                        <td>{{liste.dateSaisie | date:'yyyy-MM-dd'}}</td>
                        <td>{{liste.modele?.nom}}</td>
                        <td><span [class]="'statut ' + liste.statut.nom.replace(' ','').toLowerCase()">
                                    {{liste.statut.nom}}
                                </span></td>
                        <td>{{liste.employe | nomEmploye}}</td>
                        <td>
                            <button *ngIf="liste.statut.statutId == 6" pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetailListeAchat(liste.listeAchatId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucune liste d'achat.</td>
                    </tr>
                </ng-template>
            </p-table>
            
        </div>
    </div>


</div>