<p-toast key="archivage"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label for="numero">Raison de l'archivage</label>
            <p-dropdown appendTo="body" [options]="raisons" [(ngModel)]="selectionRaison" optionLabel="nom" [filter]="true" [style]="{'width':'100%'}"
                filterBy="nom" [showClear]="true" placeholder="Selectionnez une raison...">
                <ng-template pTemplate="selectedItem">
                    <div  *ngIf="selectionRaison">
                        <div>{{selectionRaison.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-raison pTemplate="item">
                    <div>
                        <div>{{raison.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionRaison">La raison est obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-12 p-lg-12">
            <label for="description">Commentaire</label>
            <input id="description" type="text" pInputText [(ngModel)]="commentaire">
        </div>
    </div>

    <div class="p-text-right">

        <button pButton pRipple label="Archiver" icon="pi pi-save" class="p-button-primary"
            (click)="archiver()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

    </div>

</div>