<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>


<div *ngIf="EstCharge() && !estConfirme" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-menubar [model]="menu"></p-menubar>
		</div>
	</div>
</div>

<div class="p-grid" *ngIf="EstCharge() && !estConfirme">

    <div class="p-col-12">
		<div class="card">
            <div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="dateDebut">Date de début d'effectivité</label>
                    <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !dateDebut">La date de début d'effectivité est obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="dateFin">Date de fin d'effectivité</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !dateFin">La date de fin d'effectivité est obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="dateDebutSaisie">Date de début de disponibilité</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDebutSaisie" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !dateDebutSaisie">La date de début de disponibilité est obligatoire.</small>
				</div>
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					<label for="dateFinSaisie">Date de fin de disponibilité</label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFinSaisie" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !dateFinSaisie">La date de fin de disponibilité est obligatoire.</small>
				</div>
            </div>
            <p-table #dt [value]="soumissions" [columns]="selectedColumns" dataKey="soumissionId" autoLayout="true" tableStyleClass="tableNormale"
            [(selection)]="selectionSoumission"
                styleClass="p-datatable-sm p-datatable-striped" 
                [rowHover]="true"  [filterDelay]="0"
                [globalFilterFields]="['fournisseur.nom','fournisseur.numero']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <h5 class="titreTableau p-m-0">Fournisseurs</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="min-width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="fournisseur.numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="fournisseur.numero"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="fournisseur.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Fournisseur
                                <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-soumission let-columns="columns">
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="soumission"></p-tableCheckbox>
                        </td>
                        <td>{{soumission.fournisseur.numero}}</td>
                        <td>{{soumission.fournisseur.nom}}</td>
                        
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


            


</div>

<div *ngIf="EstCharge() && estConfirme" class="p-grid">
	<div class="p-col-12">
		<div class="card">
            <h5>{{this.nbSoumissionsCrees}} soumissions créées.</h5>
            <br>
            <button pButton pRipple label="Voir les soumissions" icon="pi pi-reply" class="p-button-text"
                (click)="naviguerSoumissions()"></button>
		</div>
	</div>
</div>