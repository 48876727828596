import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { MessageService, ConfirmationService } from 'primeng/api';

import { DialogService } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Statut } from 'src/app/entites/statut';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { RapportService } from 'src/app/services/rapport-service';


@Component({
    templateUrl: './heures.component.html',
    styleUrls: ['./heures.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class HeuresComponent extends BaseComponent implements OnInit {

    items: ItemMin[];

    detailItemId: number;
    afficherDetailItem: boolean = false;

    filtreCategories: string[];
    filtreSousCategories: string[];
    filtreCompagnies: string[];
    filtreEmplacement: string[];

    statuts: Statut[] = this.sdb.Statuts();

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private rapportService: RapportService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Heures' }
        ]);
    }

    ngOnInit() {

        //TODO
        this.verifierAccesPage('items');

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { champ: 'noCTRL', entete: 'No CTRL', afficheParDefaut: true },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: false },
            { champ: 'compagnie', entete: 'Compagnie', afficheParDefaut: false }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.filtreCategories = [];
        this.sdb.Categories().forEach(x => {
            this.filtreCategories.push(x.nom);
        });

        this.filtreSousCategories = [];
        this.sdb.SousCategories().forEach(x => {
            this.filtreSousCategories.push(x.nom);
        });

        this.filtreCompagnies = [];
        this.sdb.Compagnies().forEach(x => {
            this.filtreCompagnies.push(x.nom);
        });

        this.filtreEmplacement = [];
        this.sdb.Emplacements().forEach(x => {
            this.filtreEmplacement.push(x.nom);
        });


        this.ItemsService.ObtenirHeures().then(x => {
            this.items = x.valeur;

            this.CompleterChargement();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
        });
    }

    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.OuvrirDetailItem(this.tableItems.filteredValue[0].itemId);
        }

    }

    OuvrirDetailItem(itemId: number) {
        this.routerService.navigateByUrl(`item/${itemId}`)
    }

    OuvrirDetailItemNouvelOnglet(itemId: number) {
        this.detailItemId = itemId;
        this.afficherDetailItem = true;
    }

    exportExcel() {

        var t = this.tableItems.value;
        
        if (this.tableItems.filteredValue) {
            t = this.tableItems.filteredValue;
        }

        var lignes: any[] = t.map(x => ({
            Numéro: x.numero,
            Description: x.description,
            Statut: x.statut.nom,
            NbHeures: x.nbHeures
          }));

          for (let index = 0; index < lignes.length; index++) {
              if (this.colonnesSelectionnees.filter(x => x.champ == 'categorie').length == 1) {
                if (t[index].categorie && t[index].categorie.nom){
                    lignes[index].Categorie = t[index].categorie?.nom;
                } else{
                    lignes[index].Categorie = '';
                }
                lignes[index].Categorie = t[index].categorie?.nom;
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'sousCategorie').length == 1) {
                if (t[index].sousCategorie && t[index].sousCategorie.nom){
                    lignes[index].SousCategorie = t[index].sousCategorie?.nom;
                } else{
                    lignes[index].SousCategorie = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'noCTRL').length == 1) {
                if (t[index].noCtrl){
                    lignes[index].NoCtrl = t[index].noCtrl;
                } else{
                    lignes[index].NoCtrl = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'marque').length == 1) {
                if (t[index].marque){
                    lignes[index].Marque = t[index].marque;
                } else{
                    lignes[index].Marque = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'modele').length == 1) {
                if (t[index].modele){
                    lignes[index].Modele = t[index].modele;
                } else{
                    lignes[index].Modele = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'emplacement').length == 1) {
                if (t[index].emplacement && t[index].emplacement.nom){
                    lignes[index].Emplacement = t[index].emplacement?.nom;
                } else{
                    lignes[index].Emplacement = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'compagnie').length == 1) {
                if (t[index].compagnie && t[index].compagnie.nom){
                    lignes[index].Compagnie = t[index].compagnie?.nom;
                } else{
                    lignes[index].Compagnie = '';
                }
              }
          }



          this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

          this.rapportService.ProduireRapportExcel({
            contenu: JSON.stringify(lignes)
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        }); 
          

    }

}