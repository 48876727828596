export const environment = {
  urlBase: 'https://devsihc.hamelconstruction.com/',
  apiBaseUrl: 'https://devsihc.hamelconstruction.com:448/SIHC.Service/api/v1/',
  apiDocumentUrl: 'https://devsihc.hamelconstruction.com:448/SIHC.Service/doc/',
  apiRapportsBaseUrl: 'https://devsihc.hamelconstruction.com:448/SIHC.Rapports.Service/api/v1/',
  viewRapportsBaseUrl: 'https://devsihc.hamelconstruction.com:448/SIHC.Rapports.Service/',
  production: false,
  mecanique: false,
  auth0: {
    urlGestionUtilisateurs: "https://manage.auth0.com/dashboard/us/dev-u2uzb8h1/users",
    token_url: "https://dev-u2uzb8h1.us.auth0.com/oauth/token",
    roles_url: "https://dev-u2uzb8h1.us.auth0.com/api/v2/users/{userId}/roles",
    client_id: "aScgbgzY7TMDgu8jUMcTFb622r1B2lmz",
    client_secret: "Dgne3Z3lXVLn8FKtBmIocGSvlu3maF4wUBlQngAAO0VBQNxraDKcREuK0aKUs9eI",
    audience: "https://dev-u2uzb8h1.us.auth0.com/api/v2/",
    grant_type: "client_credentials"
  },
  auth: {
    domain: "dev-u2uzb8h1.us.auth0.com",
    clientId: "kuNaieyC72OZWsiaSKj9QYWGq84HyhhP",
    redirectUri: window.location.origin,
    audience: "https://devsihc.hamelconstruction.com:448/SIHC.Service/api/v1/"
  },
  dev: {
    serverUrl: "https://devsihc.hamelconstruction.com:448/SIHC.Service/api/v1/"
  },
  parametres: {
    garanties: {
      seuilRouge: 15,
      seuilJaune: 60
    }
  }
};