import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { SeveriteEntite } from 'src/app/entites/mecanique/severite';
import { CodeVmrsEntite } from 'src/app/entites/mecanique/code-vmrs';
import { EmployeEntite } from 'src/app/entites/employe';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ItemMin } from 'src/app/entites/item-min';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { PieceService } from 'src/app/services/piece-service';

export interface ParametresDetailPieceUnite {
    piece: ItemMin;
    unite: ItemMin;
    pieceUnite?: PieceUniteEntite;
    obligatoire: boolean;
}

@Component({
    selector: 'app-detail-piece-unite',
    templateUrl: './detail-piece-unite.component.html'
})
export class DetailPieceUniteComponent extends BaseComponent {

    donnees: ParametresDetailPieceUnite;

    pieceUnite: PieceUniteEntite;

    categoriesEntretien: CategorieEntretienEntite[];
   /*  prixFournisseur: PrixFournisseurEntite[];
    selectionPrixFournisseur: PrixFournisseurEntite; */

    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champDescription') inputDescription;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public pieceService: PieceService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.categoriesEntretien = this.sdb.CategoriesEntretien();

        if (this.donnees.pieceUnite) {
            this.pieceUnite = {...this.donnees.pieceUnite};  
            if (this.pieceUnite.categorieEntretien) {
                this.pieceUnite.categorieEntretien = this.categoriesEntretien.find(x => x.categorieEntretienId == this.pieceUnite.categorieEntretien.categorieEntretienId);
            }   
        } else {
            this.pieceUnite = {
                pieceUniteId: 0,
                piece: this.donnees.piece,
                unite: this.donnees.unite
            };
        }

        

       /*  this.fournisseurService.ObtenirListePrixActifsPourItem(this.donnees.piece.itemId, new Date()).then(res => {

            if (res.estUnSucces) {
                 this.prixFournisseur = res.valeur;
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
            }

            this.CompleterChargement();

        }); */

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    enregistrer() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        if (this.pieceUnite.pieceUniteId == 0)
        {
            this.pieceService.Creer([this.pieceUnite]).then(res => {
                if (res.estUnSucces) {

                    this.ref.close(res.valeur[0]);

                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                    this.blocked = false;
                }
            });

        } else {

            this.pieceService.Modifier(this.pieceUnite).then(res => {
                if (res.estUnSucces) {

                    this.ref.close(res.valeur);

                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                    this.blocked = false;
                }
            });

        }
    }

    valider() {
        return !this.donnees.obligatoire || this.pieceUnite.categorieEntretien;
        
    }
}