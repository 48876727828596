<p-toast key="detailEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="description">Pièce</label>
            <span class="champConsultation">{{pieceUnite.piece.description}} ({{pieceUnite.piece.numero}})</span>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="lastname">Code de catégorie</label>
            <p-dropdown [options]="categoriesEntretien" [(ngModel)]="pieceUnite.categorieEntretien"
                optionLabel="description" [showClear]="true" filter="true" filterBy="code,description" appendTo="body"
                placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="pieceUnite.categorieEntretien">
                        <div>{{pieceUnite.categorieEntretien.code}} - {{pieceUnite.categorieEntretien.description}}
                        </div>
                    </div>
                </ng-template>
                <ng-template let-categorie pTemplate="item">
                    <div>
                        <div>{{categorie.code}} - {{categorie.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <small class="p-error" *ngIf="submitted && donnees.obligatoire && !pieceUnite.categorieEntretien">La catégorie est
                obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="note">Note</label>
            <input id="note" type="text" pInputText [(ngModel)]="pieceUnite.note">
        </div>
    </div>

    <!-- <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
        <p-table #dtFournisseurs [value]="prixFournisseur" [(selection)]="pieceUnite.prixFournisseur"
						tableStyleClass="tableNormale" autoLayout="true" dataKey="prixFournisseurId"
						[globalFilterFields]="['fournisseur.nom','prix','fournisseur.numero','numeroFournisseur']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
								</div>
								<div>
									<h5 class="titreTableau p-m-0">Fournisseur principal</h5>
								</div>
								<div>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
                                <th style="width: 3rem"></th>
								<th pSortableColumn="numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Numéro
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="Nom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Nom
										<p-sortIcon field="Nom"></p-sortIcon>
										<p-columnFilter type="text" field="Nom" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="numeroFournisseur">
									<div class="p-d-flex p-jc-between p-ai-center">
										No pièce fournisseur
										<p-sortIcon field="numeroFournisseur"></p-sortIcon>
										<p-columnFilter type="text" field="numeroFournisseur" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="prix">
									<div class="p-d-flex p-jc-between p-ai-center">
										Prix
										<p-sortIcon field="prix"></p-sortIcon>
										<p-columnFilter type="text" field="prix" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateDebut">
									<div class="p-d-flex p-jc-between p-ai-center">
										Date de début
										<p-sortIcon field="dateDebut"></p-sortIcon>
										<p-columnFilter type="dateDebut" field="date" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.prenom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Contact principal
										<p-sortIcon field="fournisseur.contactPrincipal.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.prenom"
											display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.courriel">
									<div class="p-d-flex p-jc-between p-ai-center">
										Courriel
										<p-sortIcon field="fournisseur.contactPrincipal.courriel"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.courriel"
											display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.telephone">
									<div class="p-d-flex p-jc-between p-ai-center">
										Téléphone
										<p-sortIcon field="fournisseur.contactPrincipal.telephone"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.telephone"
											display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="fournisseur.contactPrincipal.cellulaire">
									<div class="p-d-flex p-jc-between p-ai-center">
										Cellulaire
										<p-sortIcon field="fournisseur.contactPrincipal.cellulaire"></p-sortIcon>
										<p-columnFilter type="text" field="fournisseur.contactPrincipal.cellulaire"
											display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-prixFournisseur>
							<tr >
                                <td>
                                    <p-tableRadioButton [value]="prixFournisseur"></p-tableRadioButton>
                                </td>
								<td>
									{{prixFournisseur.fournisseur.numero}}
								</td>
								<td>
									{{prixFournisseur.fournisseur.nom}}
								</td>
								<td>
									{{prixFournisseur.numeroFournisseur}}
								</td>
								<td>
									{{prixFournisseur.prix | currency}}
								</td>
								<td>
									{{prixFournisseur.dateDebut | date:'yyyy-MM-dd'}}
								</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.prenom}}
									{{prixFournisseur.fournisseur.contactPrincipal?.nom}}</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.courriel}}</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.telephone}}</td>
								<td>{{prixFournisseur.fournisseur.contactPrincipal?.cellulaire}}</td>
								
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="9">Aucun fournisseur.</td>
							</tr>
						</ng-template>
                    </p-table>
                    
        </div>

    </div> -->



</div>


<div class="p-text-right">

    <button pButton pRipple label="Enregistrer" icon="pi pi-save" class="p-button-primary"
        (click)="enregistrer()"></button>
    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

</div>