import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { CategorieService } from 'src/app/services/categorie-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { EmployeService } from 'src/app/services/employe-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './employes.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class EmployesComponent extends BaseComponent implements OnInit {

    employes: EmployeEntite[];

    menuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private employeService: EmployeService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Employes' }
        ]);
    }

    ngOnInit() {
        this.verifierAccesPage('admin_employes');

        this.employeService.ObtenirListe().then(x => {
            this.employes = x.valeur;

            this.employes.forEach(x => {
                x.prenomNom = x.prenom + ' ' + x.nom;
            });

            this.CompleterChargement();
        });


    }

    menuContextuelEntrees: MenuItem[];
    surMenuContextuelItem(event, employe: EmployeEntite) {

        this.menuContextuelEntrees = [
            { label: 'Modifier', icon: 'pi pi-fw pi-pencil', command: () => this.modifier(employe) },
            { separator: true },
            { label: 'Supprimer', icon: 'pi pi-fw pi-trash',  command: () => this.supprimer(employe) },
        ];

        this.menuContextuelItemCommande.toggle(event);
    }

    ajouter() {
        this.routerService.navigateByUrl('admin/employe/0');
    }

    modifier(attr: EmployeEntite) {
        this.routerService.navigateByUrl(`admin/employe/${attr.employeId}`);
    }

    supprimer(attr: EmployeEntite) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer l\'employe ' + attr.prenom + ' ' + attr.nom + ' ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.employeService.Supprimer(attr.employeId).then(res => {
                    if (res.estUnSucces) {
                        this.employes = this.employes.filter(val => val.employeId !== attr.employeId);
                        this.sdb.ReinitialiserEmployes();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Employe supprimé' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }
}
