import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Emplacement } from '../entites/emplacement';
import { Fournisseur } from '../entites/fournisseur';
import { EntreeHistoriqueAchat } from '../entites/entree-historique-achat';
import { PrixFournisseurEntite } from '../entites/prix-fournisseur';
import { EStatuts } from '../entites/enums/statuts';
import { SommaireAchatEntite } from '../entites/sommaireAchatentite';

@Injectable({ providedIn: 'root' })
export class FournisseurService {

  private obtenirListeUrl = environment.apiBaseUrl + 'fournisseur';
  private obtenirListeCtrlUrl = environment.apiBaseUrl + 'fournisseur/ctrl';
  private obtenirDetailUrl = environment.apiBaseUrl + 'fournisseur/';
  private obtenirHistoriqueUrl = environment.apiBaseUrl + 'fournisseur/{id}/historique';
  private obtenirSommaireUrl = environment.apiBaseUrl + 'fournisseur/{id}/sommaire';
  private obtenirListePrixUrl = environment.apiBaseUrl + 'fournisseur/{fournisseurId}/prix/{itemId}';
  private obtenirDernierPrixUrl = environment.apiBaseUrl + 'fournisseur/{fournisseurId}/dernierPrix/{itemId}';
  private obtenirListePrixPourItemUrl = environment.apiBaseUrl + 'fournisseur/prix/{itemId}';
  private creerFournisseurUrl = environment.apiBaseUrl + 'fournisseur';
  private modifierFournisseurUrl = environment.apiBaseUrl + 'fournisseur/modifier';
  private creerPrixFournisseurUrl = environment.apiBaseUrl + 'fournisseur/prix';
  private archiverFournisseurUrl = environment.apiBaseUrl + 'fournisseur/archiver';
  private modifierPrixFournisseurUrl = environment.apiBaseUrl + 'fournisseur/prix/modifier';
  private supprimerPrixFournisseurUrl = environment.apiBaseUrl + 'fournisseur/prix/{id}';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirFournisseurs(): Promise<ReponseBase<Fournisseur[]>> {
    var p = this.http.get<ReponseBase<Fournisseur[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirFournisseursCtrl(): Promise<ReponseBase<Fournisseur[]>> {
    var p = this.http.get<ReponseBase<Fournisseur[]>>(this.obtenirListeCtrlUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirFournisseur(fournisseurId: number): Promise<ReponseBase<Fournisseur>> {

    var p = this.http.get<ReponseBase<Fournisseur>>(this.obtenirDetailUrl + fournisseurId)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirHistorique(id: number): Promise<ReponseBase<EntreeHistoriqueAchat[]>> {
    var p = this.http.get<ReponseBase<EntreeHistoriqueAchat[]>>(this.obtenirHistoriqueUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirSommaire(id: number): Promise<ReponseBase<SommaireAchatEntite[]>> {
    var p = this.http.get<ReponseBase<SommaireAchatEntite[]>>(this.obtenirSommaireUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirAutreFournisseurs(itemId: number): Promise<ReponseBase<Fournisseur[]>> {
      
    var p = this.http.get<any>('assets/demo/data/autresFournisseurs.json')
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirListePrix(fournisseurId: number, itemId: number): Promise<ReponseBase<PrixFournisseurEntite[]>> {
    var p = this.http.get<ReponseBase<PrixFournisseurEntite[]>>(this.obtenirListePrixUrl.replace('{fournisseurId}', fournisseurId.toString()).replace('{itemId}', itemId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirDernierPrix(fournisseurId: number, itemId: number): Promise<ReponseBase<PrixFournisseurEntite>> {
    var p = this.http.get<ReponseBase<PrixFournisseurEntite>>(this.obtenirDernierPrixUrl.replace('{fournisseurId}', fournisseurId.toString()).replace('{itemId}', itemId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  public ObtenirListePrixPourItem(itemId: number): Promise<ReponseBase<PrixFournisseurEntite[]>> {
    var p = this.http.get<ReponseBase<PrixFournisseurEntite[]>>(this.obtenirListePrixPourItemUrl.replace('{itemId}', itemId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListePrixActifsPourItem(itemId: number, date: Date): Promise<ReponseBase<PrixFournisseurEntite[]>> {
    var p = this.http.get<ReponseBase<PrixFournisseurEntite[]>>(this.obtenirListePrixPourItemUrl.replace('{itemId}', itemId.toString()) + `?dateEffective=${date.toISOString()}`)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
 
  public CreerFournisseur(fournisseur: Fournisseur): Promise<ReponseBase<Fournisseur>> {
    var p = this.http.post<ReponseBase<Fournisseur>>(this.creerFournisseurUrl, JSON.stringify(fournisseur), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
 
  public ModifierFournisseur(fournisseur: Fournisseur): Promise<ReponseBase<Fournisseur>> {
    var p = this.http.post<ReponseBase<Fournisseur>>(this.modifierFournisseurUrl, JSON.stringify(fournisseur), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  
  public ArchiverFournisseur(fournisseur: Fournisseur): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.archiverFournisseurUrl, JSON.stringify(fournisseur), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public CreerPrixFournisseur(prixFournisseur: PrixFournisseurEntite): Promise<ReponseBase<PrixFournisseurEntite>> {
    var p = this.http.post<ReponseBase<PrixFournisseurEntite>>(this.creerPrixFournisseurUrl, JSON.stringify(prixFournisseur), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  
  public ModifierPrixFournisseur(prixFournisseur: PrixFournisseurEntite): Promise<ReponseBase<PrixFournisseurEntite>> {
    var p = this.http.post<ReponseBase<PrixFournisseurEntite>>(this.modifierPrixFournisseurUrl, JSON.stringify(prixFournisseur), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public SupprimerPrixFournisseur(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerPrixFournisseurUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
