<div class="card">
<div class="p-grid">
    <div class="p-col-6">
        

            <h5>Destinataire</h5>
            <p-dropdown appendTo="body" [options]="employes" [(ngModel)]="selectionEmploye" optionLabel="nom" [filter]="true" [style]="{'width':'100%'}"
                filterBy="nom,prenom" [showClear]="true" placeholder="Selectionnez un employé...">
                <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="selectionEmploye">
                        <div>{{selectionEmploye.nom}}, {{selectionEmploye.prenom}}</div>
                    </div>
                </ng-template>
                <ng-template let-employe pTemplate="item">
                    <div class="country-item">
                        <div>{{employe.nom}}, {{employe.prenom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <br>

    </div>
</div>
<div class="p-grid">
    <div class="p-col-12 p-text-right ">
        <p-button label="Envoyer" class="" icon="pi pi-send" iconPos="left" (onClick)="Envoyer()"></p-button>     
        <p-button label="Annuler" class="p-button-secondary p-ml-2" icon="pi pi-check" iconPos="left" (onClick)="Annuler()"></p-button>
    </div>
</div>
</div>