import { Pipe, PipeTransform } from '@angular/core';
import { EmployeEntite } from '../entites/employe';

@Pipe({
  name: 'valeurVide'
})
export class ValeurVidePipe implements PipeTransform {

  transform(value: string): string {
    if (value != null && value != undefined && value != '') {
      return value;
    } else {
        return '---';
    }
  }

}
