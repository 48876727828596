<p-toast key="selectionItem"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">
    
    <!------------ LISTE SÉLECTION INVENTAIRE ------------>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
                [(selection)]="selectionItems"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionItem"
                [contextMenu]="cm" [rowHover]="true" [rows]="10" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['numero','description','statut','categorie','sousCategorie','marque','modele','noSerie','noPlaque']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    (keyup.enter)="surEntreeRecherche()" placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau p-m-0">Unités</h5>
                        </div>

                        <div>
                         
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="min-width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th >
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">

                                                    <span class="p-ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="categorie.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Catégorie
                                <p-sortIcon field="categorie.nom"></p-sortIcon>
                                <p-columnFilter #filtreCategorie field="categorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect #champFiltreCategorie [ngModel]="value" [options]="categories" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">
                                                    <span class="p-ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('sousCategorie')" pSortableColumn="sousCategorie.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Sous-catégorie
                                <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="sousCategorie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="marque">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Marque
                                <p-sortIcon field="marque"></p-sortIcon>
                                <p-columnFilter type="text" field="marque" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Modèle
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="modele" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
           
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns">
                    <tr [pContextMenuRow]="item">
                        <td>
                            <p-tableCheckbox [value]="item"></p-tableCheckbox>
                        </td>
                        <td >{{item.numero}}
                 
                        </td>
                        <td>{{item.description}}</td>
                        <td>
                            <span [class]="'statut ' + item.statut.nom.toLowerCase()">
                                {{item.statut.nom}}
                            </span>
                        </td>
                        <td *ngIf="EstColonneSelectionnee('categorie')">
                            {{item.categorie.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('sousCategorie')">
                            {{item.sousCategorie?.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('marque')">
                            {{item.marque}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('modele')">
                            {{item.modele}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucun item.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-text-right ">
            <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-primary"
                (click)="confirmer()"></button>
            <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
                (click)="annuler()"></button>
        </div>
    </div>
</div>