import { Pipe, PipeTransform } from '@angular/core';
import { EmployeEntite } from '../entites/employe';
import { Emplacement } from '../entites/emplacement';

@Pipe({
  name: 'nomEmplacement'
})
export class NomEmplacementPipe implements PipeTransform {

  transform(value: Emplacement): string {
    if (value != null && value != undefined) {
      return `${value.nom} (${value.code})`
    } else {
        return '';
    }
  }

}
