import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { TypeLocationEntite } from 'src/app/entites/type-location';
import { LocationService } from 'src/app/services/location-service';
import { ETypesLocation } from 'src/app/entites/enums/type-location';
import { UniteEntite } from 'src/app/entites/unite';
import { CoutLocationEntite } from 'src/app/entites/cout-location';

@Component({
    templateUrl: './ajout-location.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutLocationComponent extends BaseComponent implements OnInit {


    fournisseurs: Fournisseur[];
    typesLocation: TypeLocationEntite[];
    emplacements: Emplacement[];
    unites: UniteEntite[];

    location: LocationEntite = {};
    submitted: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private achatService: AchatService,
        private locationService: LocationService,
        private fournisseurService: FournisseurService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Ajout d\'une location' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('location_creer');

        this.location.dateDebut = new Date();

        this.typesLocation = [
            { typeLocationId: 1, nom: "Date fixe" },
            { typeLocationId: 2, nom: "Variable" },
            { typeLocationId: 3, nom: "Journée" },
            { typeLocationId: 4, nom: "Semaine" },
            { typeLocationId: 5, nom: "Mois" },
            { typeLocationId: 6, nom: "Année" }
        ];

        this.emplacements = this.sdb.Emplacements();

        this.unites = this.sdb.Unites();

        this.fournisseurService.ObtenirFournisseurs().then(x => {
            this.fournisseurs = x.valeur;
            this.CompleterChargement();
        });
    }

    surFermetureTypeLocation() {
        switch (this.location.typeLocation.typeLocationId) {
            case ETypesLocation.Variable:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('JRS')
                    },
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('SEM')
                    },
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('MOIS')
                    }
                ]
                break;
            case ETypesLocation.Jour:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('JRS')
                    },
                ]
                break;
            case ETypesLocation.Semaine:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('SEM')
                    },
                ]
                break;
            case ETypesLocation.Mois:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('MOIS')
                    },
                ]
                break;
            case ETypesLocation.Annee:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('AN')
                    },
                ]
                break;

            default:
                this.location.couts = [{}]
                break;
        }
    }

    copierDescription(cout: CoutLocationEntite) {
        cout.description = this.location.description;
    }

    supprimerCout(cout: CoutLocationEntite) {
        this.location.couts.splice(this.location.couts.indexOf(cout), 1);
    }

    ajouterCout() {
        this.location.couts.push({});
    }

    creerLocation() {



        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.Bloquer();

            this.locationService.Ajouter(this.location).then(res => {
                this.Debloquer();
                if (res.estUnSucces) {
                    this.routerService.navigateByUrl('location/' + res.valeur.locationId);
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ key: 'archivage', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                }
            });


            /*  this.DemarrerChargement();
             
             this.achatService.CreerBonCommande({
                 fournisseur: this.selectionFournisseur,
                 dateAchat: this.dateDebut,
                 employe: this.profilService.ProfilCourant()
             }).then(x => {
                 if (x.estUnSucces) {
                     this.routerService.navigateByUrl('achat/' + x.valeur);
                 } else {
                     x.messages.forEach(x => {
                         this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                     });
 
                     this.CompleterChargement();
                 }
             }); */
        } else {

        }
    }

    valider(): boolean {


        if (!this.location.fournisseur || !this.location.typeLocation || !this.location.description) {
            return false;
        }

        return true;
    }
}
