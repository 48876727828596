<p-toast></p-toast>
<!-- <p-contextMenu #cm [model]="menuContextuelRequisitions"></p-contextMenu> -->
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="p-grid table-demo">

    <div class="p-col-12">
        <p-selectButton [options]="typesRecherche" [(ngModel)]="selectionTypeRecherche" (onChange)="surChangementTypeRecherche()" optionLabel="valeur">
        </p-selectButton>
    </div>


    <div class="p-col-12">

		<div class="p-fluid p-formgrid p-grid">

			<div class="p-field p-col-12">
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
                    <input #champRecherche type="text" class="p-shadow-4" pInputText placeholder="Recherche"
                    [(ngModel)]="chaineRecherche"
                        (keyup.enter)="Rechercher(chaineRecherche)">
                 
                        
				</span>
            </div>
            <div class="p-field p-col-12">
                <button  pButton pRipple label="Rechercher" icon="pi pi-search" class="p-button-primary" style="max-width: 200px;"
                (click)="Rechercher(chaineRecherche)"></button>
			</div>
		</div>

    </div>

    <div class="p-col-12" *ngIf="rechercheDeclenchee && !chargementComplete">
		<div class="card">
			<div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-text-center">
					<p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
				</div>
            </div>
        </div>
    </div>
    
	<div class="p-col-12" *ngIf="rechercheDeclenchee && chargementComplete">
		<div class="card" *ngIf="selectionTypeRecherche.cle == 'I'">
			<h5>Items</h5>

			<p-table #dt [value]="items" *ngIf="chargementComplete" dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [rows]="25" [paginator]="true" >
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">

                                                    <span class="p-ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th  pSortableColumn="categorie.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Catégorie
                                <p-sortIcon field="categorie.nom"></p-sortIcon>
                                <p-columnFilter field="categorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="categories" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="p-multiselect-representative-option">
                                                    <span class="p-ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="sousCategorie.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Sous-catégorie
                                <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="sousCategorie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th  pSortableColumn="modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Marque
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="modele" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th  pSortableColumn="modele">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Modèle
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="emplacement">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Emplacement
                                <p-sortIcon field="emplacement"></p-sortIcon>
                                <p-columnFilter type="text" field="emplacement" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        
                        <th style="min-width: 4rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns">
                    <tr [pContextMenuRow]="item">
                        <td>{{item.numero}}
                        </td>
                        <td>{{item.description}}</td>
                        <td><span [class]="'statut ' + item.statut.nom.toLowerCase()">
                                {{item.statut.nom}}
                            </span>
                        </td>
                        <td>
                            {{item.categorie.nom}}
                        </td>
                        <td>
                            {{item.sousCategorie?.nom}}
                        </td>
                        <td>
                            {{item.marque}}
                        </td>
                        <td>
                            {{item.modele}}
                        </td>
                        <td>
                            {{item.emplacement | nomEmplacement}}
                        </td>
                        <td>
                            <button pButton pRipple icon="pi pi-eye"
                                class=" p-button-success p-button-sm p-mr-2 boutonGrille"
                                (click)="OuvrirDetailItem(item.itemId)"></button>

                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucun item.</td>
                    </tr>
                </ng-template>
            </p-table>

			
		</div>
		<div class="card" *ngIf="selectionTypeRecherche.cle == 'R'">
			<h5>Réquisitions</h5>

			<p-table [value]="reqs" *ngIf="chargementComplete" dataKey="requisitionId"  autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" 
               [rowHover]="true" [rows]="25" [paginator]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        
                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="date">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date
                                <p-sortIcon field="date"></p-sortIcon>
                                <p-columnFilter type="text" field="date" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                    
                        <th pSortableColumn="de">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                De
                                <p-sortIcon field="de"></p-sortIcon>
                                <p-columnFilter type="text" field="de" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="a">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                A
                                <p-sortIcon field="a"></p-sortIcon>
                                <p-columnFilter type="text" field="a" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
						</th>
						<th pSortableColumn="transporteur">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Transporteur
                                <p-sortIcon field="transporteur"></p-sortIcon>
                                <p-columnFilter type="text" field="transporteur" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th ></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-requisition let-columns="columns">
                    <tr [pContextMenuRow]="requisition">
                       
                        <td>{{requisition.numero}}
                            <span>
                                <i *ngIf="requisition.type.nom == 'Retour'" class="pi pi-arrow-left"
                                    style="font-size: 1rem"></i>
                                    <i *ngIf="requisition.type.nom == 'Réquisition'" class="pi pi-arrow-right"
                                    style="font-size: 1rem"></i>
                                    <i *ngIf="requisition.type.nom == 'Transfert'" class="pi pi-minus"
                                    style="font-size: 1rem"></i>
                                </span>
                        </td>
                        <td>{{requisition.date | date:'yyyy-MM-dd'}}</td>
                     
                                <td><span [class]="'statut ' + requisition.statut.nom.toLowerCase()">
                                    {{requisition.statut.nom}}
                                </span>
                            </td>
                        <td>{{requisition.de | nomEmplacement}}</td>
                        <td>{{requisition.a  | nomEmplacement}}</td>
                        <td>{{requisition.transporteur}}</td>
                      
                        <td>
                            <button pButton pRipple icon="pi pi-eye"
                                class="p-button-success p-mr-2 boutonGrille"
                                (click)="OuvrirDetailRequisition(requisition.requisitionId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">Aucune réquisition.</td>
                    </tr>
                </ng-template>
            </p-table>
		</div>
		<div class="card" *ngIf="selectionTypeRecherche.cle == 'F'">
			<h5>Fournisseurs</h5>

			<p-table [value]="fournisseurs" *ngIf="chargementComplete" dataKey="fournisseurId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped"
                [rowHover]="true" [rows]="25" [paginator]="true" sortField="nom">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        
                        <th pSortableColumn="numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="Nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Nom
                                <p-sortIcon field="Nom"></p-sortIcon>
                                <p-columnFilter type="text" field="Nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fournisseur>
                    <tr [pContextMenuRow]="fournisseur">
                        <td>{{fournisseur.numero}}</td>
                        <td>{{fournisseur.nom}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-eye"
                                class="p-button-success p-button-sm p-mr-2 boutonGrille"
                                (click)="OuvrirDetailFournisseur(fournisseur.fournisseurId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">Aucun fournisseur.</td>
                    </tr>
                </ng-template>
            </p-table>
		</div>
		<div class="card" *ngIf="selectionTypeRecherche.cle == 'B'">
			<h5>Bons de commande</h5>

			<p-table [value]="bdcs" *ngIf="chargementComplete" styleClass="p-datatable-sm" tableStyleClass="tableNormale">
				<ng-template pTemplate="header">
					<tr>
						<th>Numero</th>
						<th>Description</th>
						<th>Marque</th>
						<th>Modèle</th>
						<th>Numero BDC</th>
						<th>Date</th>
						<th>Fournisseur</th>
                        <th>Statut</th>
                        <th>Quantité</th>
                        <th>Prix</th>
                        <th style="min-width: 7rem; width: 7rem;"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-bdc>
					<tr>
						<td>{{bdc.item.numero}}</td>
						<td>{{bdc.item.description}}</td>
						<td>{{bdc.item.marque}}</td>
						<td>{{bdc.item.modele}}</td>
						<td>{{bdc.bonCommande.numero}}</td>
						<td>{{bdc.bonCommande.dateAchat | date:'yyyy-MM-dd' }}</td>
						<td>{{bdc.bonCommande.fournisseur.nom}}</td>
						<td><span [class]="'statut ' + bdc.bonCommande.statut.nom.toLowerCase().replace(' ', '')">
								{{bdc.bonCommande.statut.nom}}
                            </span></td>
                            <td>{{bdc.quantite}}</td>
                            <td>{{bdc.prix | currency:'CAD':'symbol-narrow':'1.4'}}</td>
                            
                            <button *ngIf="estAutorise('bdc_detail')" pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                                    (click)="OuvrirDetailBdc(bdc.bonCommande.bonCommandeId)"></button>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<!-- <div class="p-col-12">
		<div class="card">
			<h5>Réquisitions récentes</h5>
			
			<p-table #dt [value]="requisitions" [(contextMenuSelection)]="selectionRequisition" dataKey="id" [contextMenu]="cm" styleClass=""
			
				[rowHover]="true" [rows]="10" [paginator]="true" 
				[filterDelay]="0" [globalFilterFields]="['numero','de','a','type']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">

						<span class="p-input-icon-left">
							<i class="pi pi-search"></i>
							<input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
								placeholder="Recherche..." />
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="numero">
							<div class="p-d-flex p-jc-between p-ai-center">
								Numéro
								<p-sortIcon field="numero"></p-sortIcon>
								<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="date">
							<div class="p-d-flex p-jc-between p-ai-center">
								Date
								<p-sortIcon field="date"></p-sortIcon>
								<p-columnFilter type="date" field="date" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="type">
							<div class="p-d-flex p-jc-between p-ai-center">
								Type
								<p-sortIcon field="type"></p-sortIcon>
								<p-columnFilter type="text" field="type" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="de">
							<div class="p-d-flex p-jc-between p-ai-center">
								De
								<p-sortIcon field="de"></p-sortIcon>
								<p-columnFilter type="text" field="de" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="a">
							<div class="p-d-flex p-jc-between p-ai-center">
								A
								<p-sortIcon field="a"></p-sortIcon>
								<p-columnFilter type="text" field="a" display="menu" class="p-ml-auto"></p-columnFilter>
							</div>
						</th>
						<th style="min-width: 8rem"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-requisition>
					<tr class="p-selectable-row" [pContextMenuRow]="requisition">
						<td>
							{{requisition.numero}}
						</td>
						<td>
							{{requisition.date}}
						</td>
						<td>
							{{requisition.type}}
						</td>
						<td>
							{{requisition.de}}
						</td>
						<td>
							{{requisition.a}}
						</td>
						<td style="text-align: center">
							<button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">Aucune réquisition.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div> -->


</div>