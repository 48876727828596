import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { ReparationEntite } from '../entites/reparation';
import { ReparationService } from '../services/reparation-service';
import { EStatuts } from '../entites/enums/statuts';
import { GarantieEntite, TypeGarantieEntite } from '../entites/garantie';
import { GarantieService } from '../services/garantie-service';
import { AppMainComponent } from '../app.main.component';
import { SelectionDelaiComponent } from './selection-delai.component';
import { DocumentService } from '../services/document-service';
import { ETypeDocumentLie } from '../entites/enums/type-document-lie';

export interface ParametresRetourReparation {
    reparations: ReparationEntite[];
}

export interface ResultatRetourReparation {

}

@Component({
    selector: 'app-retour-reparation',
    templateUrl: './retour-reparation.component.html'
})
export class RetourReparationComponent extends BaseComponent {

    donnees: ParametresRetourReparation;

    dateRetour: Date = new Date();
    noFacture: string;
    
    garantieActive: boolean = false;
    garantie: GarantieEntite = {
        dateDebut: new Date()
    };
    typesGarantie: TypeGarantieEntite[];

    submitted: boolean;
    blocked: boolean = false;


    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public achatService: AchatService,
        public reparationService: ReparationService,
        public fournisseurService: FournisseurService,
        private garantieService: GarantieService,
        private documentService: DocumentService,
        private dialogService: DialogService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.typesGarantie = this.sdb.TypesGarantie();

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    sauvegarder() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'reparation', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        this.itemService.RetourReparation({
            reparations: this.donnees.reparations,
            dateRetour: this.dateRetour
        }).then(res => {
            if (res.estUnSucces) {
                var promises: Promise<any>[] = [];

                if (this.garantieActive) {

                    this.garantie.statut = this.sdb.Statut(EStatuts.Actif);

                    this.donnees.reparations.forEach(rep => {

                        this.garantie.item = {
                            itemId: rep.item.itemId
                        }

                        var p = this.garantieService.Ajouter(this.garantie);
                        promises.push(p);
                        
                        p.then(res => {

                        }).catch(err => {
                            this.ref.close(null);
                        });
                    });
                    
                }

                if (this.noFacture) {
                    this.donnees.reparations.forEach(rep => {
                        var p = this.documentService.AjouterDocumentsLie({
                            itemId: rep.item.itemId,
                            date: this.dateRetour,
                            numero: this.noFacture,
                            typeDocumentLie: this.sdb.TypeDocumentLie(ETypeDocumentLie.Facture)
                        });
                        promises.push(p);

                        p.then(res => {
                        }).catch(err => {
                            this.ref.close(null);
                        });
                    });
                }

                Promise.all(promises).then(x => {
                    this.ref.close(true);
                });
                
            } else {
               
                //TODO
            }

        }).catch(err => {
            this.ref.close(null);
        });
    }

    valider() {
        return this.dateRetour && 
        (this.garantie.type || !this.garantieActive) && 
        (this.garantie.dateDebut || !this.garantieActive) && 
        (this.garantie.dateFin || !this.garantieActive);
    }

    selectionnerDelai() {
        const ref = this.dialogService.open(SelectionDelaiComponent, {
            data: {
                dateDebut: this.garantie.dateDebut
            },
            header: 'Sélectionner un délai',
            width: '50%'
        }).onClose.subscribe((res: Date) => {
            if (res) {
                this.garantie.dateFin = new Date(res);
            }

        });
    }
}