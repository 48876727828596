<p-toast></p-toast>
<p-contextMenu #cm [model]="menuContextuelRequisitions"></p-contextMenu>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="p-grid table-demo">


	<!-- 	<button *ngIf="(auth.isAuthenticated$ | async) == false" class="btn btn-primary btn-block" (click)="loginWithRedirect()">
		Log in
	  </button>

	  <button class="btn btn-primary btn-block" (click)="logout()">
		Log out
	  </button>

	  <button class="btn btn-primary btn-block" (click)="getProfile()">
		Get profile
	  </button> -->
<!-- 
	  <div class="p-col-12">


		<div class="p-fluid p-formgrid p-grid">

			<div class="p-field p-col-12">
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<input type="text" class="p-shadow-4" pInputText placeholder="Recherche"
						(keyup.enter)="Rechercher()">
				</span>
			</div>
		</div>

	</div>
 -->

	<div class="p-col-12" *ngIf="!rechercheDeclenchee">
		<div class="card p-shadow-4">
			<div class="p-grid">
				<div *ngIf="estAutorise('items')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/items">
						<i class="pi pi-id-card" style="font-size: 2rem"></i>
						<h4>Items</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('reparations')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/items/reparations">
						<i class="pi pi-id-card" style="font-size: 2rem"></i>
						<h4>Réparations</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('emplacements')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/pilotage/emplacements">
						<i class="pi pi-map" style="font-size: 2rem"></i>
						<h4>Emplacements</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('garanties')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/garanties">
						<i class="pi pi-shield" style="font-size: 2rem"></i>
						<h4>Garanties</h4>
					</div>
				</div>
	
				<div *ngIf="estAutorise('precommande')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/precommandes">
						<i class="pi pi-pencil" style="font-size: 2rem"></i>
						<h4>Pré-commandes</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('approbation_precommande')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/approbationprecommandes">
						<i class="pi pi-check-square" style="font-size: 2rem"></i>
						<h4>Approbation pré-commandes</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('commandes')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/commandes">
						<i class="pi pi-book" style="font-size: 2rem"></i>
						<h4>Commandes</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('requisitions')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/requisitions">
						<i class="pi pi-tablet" style="font-size: 2rem"></i>
						<h4>Réquisitions</h4>
					</div>
				</div>
			
				<div *ngIf="estAutorise('fournisseurs')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/fournisseurs">
						<i class="pi pi-user" style="font-size: 2rem"></i>
						<h4>Fournisseurs</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('bdcs') || estAutorise('bdcs_proprietaire')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/achats">
						<i class="pi pi-shopping-cart" style="font-size: 2rem"></i>
						<h4>Bons de commande</h4>
					</div>
				</div>
			</div>
			<div class="p-grid">
				<div *ngIf="estAutorise('unites')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/mecanique/unites">
						<i class="pi pi-id-card" style="font-size: 2rem"></i>
						<h4>Unités</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('pieces')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/mecanique/pieces">
						<i class="pi pi-id-card" style="font-size: 2rem"></i>
						<h4>Pièces</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('bonstravail') || estAutorise('temp_mec')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/mecanique/bonstravail">
						<i class="pi pi-check-square" style="font-size: 2rem"></i>
						<h4>Bons de travail</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('temp_mec')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/mecanique/planification">
						<i class="pi pi-calendar" style="font-size: 2rem"></i>
						<h4>Planification</h4>
					</div>
				</div>
				<div *ngIf="estAutorise('heures_saisie')" class="p-col-12 p-md-6 p-lg-3">
					<div class="card widget-feature-box p-shadow-4" routerLink="/mecanique/heures">
						<i class="pi pi-clock" style="font-size: 2rem"></i>
						<h4>Heures</h4>
					</div>
				</div>
				
			</div>
		</div>
	</div>

	<!-- 

	<div class="p-col-12" *ngIf="rechercheDeclenchee">
		<div class="card">
			<h5>Items</h5>

			<div class="p-fluid p-formgrid p-grid" *ngIf="!itemsLoaded">
				<div class="p-field p-col-12 p-text-center">
					<p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
				</div>
			</div>

			<p-table [value]="items" *ngIf="itemsLoaded" styleClass="p-datatable-sm">
				<ng-template pTemplate="header">
					<tr>
						<th>Numero</th>
						<th>Description</th>
						<th>Marque</th>
						<th>Modèle</th>
						<th>No de série</th>
						<th>No de plaque</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>
					<tr>
						<td>{{item.numero}}</td>
						<td>{{item.description}}</td>
						<td>{{item.marque}}</td>
						<td>{{item.modele}}</td>
						<td>{{item.noSerie}}</td>
						<td>{{item.noPlaque}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
		<div class="card">
			<h5>Réquisitions</h5>

			<div class="p-fluid p-formgrid p-grid" *ngIf="!reqsLoaded">
				<div class="p-field p-col-12 p-text-center">
					<p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
				</div>
			</div>

			<p-table [value]="reqs" *ngIf="reqsLoaded" styleClass="p-datatable-sm">
				<ng-template pTemplate="header">
					<tr>
						<th>Numero</th>
						<th>Date</th>
						<th>Type</th>
						<th>De</th>
						<th>A</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-requisition>
					<tr>
						<td>{{requisition.numero}}</td>
						<td>{{requisition.date}}</td>
						<td><span [class]="'type-requisition ' + requisition.type.toLowerCase()">
								<i *ngIf="requisition.type == 'Retour' || requisition.type == 'Transfert'"
									class="pi pi-angle-double-left" style="font-size: 1rem"></i>
								<i *ngIf="requisition.type == 'Requisition' || requisition.type == 'Transfert'"
									class="pi pi-angle-double-right" style="font-size: 1rem"></i>
								{{requisition.type}}
							</span></td>
						<td>{{requisition.de}}</td>
						<td>{{requisition.a}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
		<div class="card">
			<h5>Fournisseurs</h5>

			<div class="p-fluid p-formgrid p-grid" *ngIf="!fournisseursLoaded">
				<div class="p-field p-col-12 p-text-center">
					<p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
				</div>
			</div>

			<p-table [value]="fournisseurs" *ngIf="fournisseursLoaded" styleClass="p-datatable-sm">
				<ng-template pTemplate="header">
					<tr>
						<th>Numero</th>
						<th>Nom</th>
						<th>Contact principal</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-fournisseur>
					<tr>
						<td>{{fournisseur.numero}}</td>
						<td>{{fournisseur.nom}}</td>
						<td>{{fournisseur.contactPrincipal.prenom}} {{fournisseur.contactPrincipal.nom}}</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
		<div class="card">
			<h5>Bons de commande</h5>

			<div class="p-fluid p-formgrid p-grid" *ngIf="!bdcsLoaded">
				<div class="p-field p-col-12 p-text-center">
					<p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
				</div>
			</div>

			<p-table [value]="bdcs" *ngIf="bdcsLoaded" styleClass="p-datatable-sm">
				<ng-template pTemplate="header">
					<tr>
						<th>Numero</th>
						<th>Date</th>
						<th>Fournisseur</th>
						<th>Statut</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-bdc>
					<tr>
						<td>{{bdc.numero}}</td>
						<td>{{bdc.date}}</td>
						<td>{{bdc.fournisseur.nom}}</td>
						<td><span [class]="'statut ' + bdc.statut.toLowerCase()">
								{{bdc.statut}}
							</span></td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div> -->

	<!-- <div class="p-col-12">
		<div class="card">
			<h5>Réquisitions récentes</h5>
			
			<p-table #dt [value]="requisitions" [(contextMenuSelection)]="selectionRequisition" dataKey="id" [contextMenu]="cm" styleClass=""
			
				[rowHover]="true" [rows]="10" [paginator]="true" 
				[filterDelay]="0" [globalFilterFields]="['numero','de','a','type']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">

						<span class="p-input-icon-left">
							<i class="pi pi-search"></i>
							<input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
								placeholder="Recherche..." />
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="numero">
							<div class="p-d-flex p-jc-between p-ai-center">
								Numéro
								<p-sortIcon field="numero"></p-sortIcon>
								<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="date">
							<div class="p-d-flex p-jc-between p-ai-center">
								Date
								<p-sortIcon field="date"></p-sortIcon>
								<p-columnFilter type="date" field="date" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="type">
							<div class="p-d-flex p-jc-between p-ai-center">
								Type
								<p-sortIcon field="type"></p-sortIcon>
								<p-columnFilter type="text" field="type" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="de">
							<div class="p-d-flex p-jc-between p-ai-center">
								De
								<p-sortIcon field="de"></p-sortIcon>
								<p-columnFilter type="text" field="de" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="a">
							<div class="p-d-flex p-jc-between p-ai-center">
								A
								<p-sortIcon field="a"></p-sortIcon>
								<p-columnFilter type="text" field="a" display="menu" class="p-ml-auto"></p-columnFilter>
							</div>
						</th>
						<th style="min-width: 8rem"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-requisition>
					<tr class="p-selectable-row" [pContextMenuRow]="requisition">
						<td>
							{{requisition.numero}}
						</td>
						<td>
							{{requisition.date}}
						</td>
						<td>
							{{requisition.type}}
						</td>
						<td>
							{{requisition.de}}
						</td>
						<td>
							{{requisition.a}}
						</td>
						<td style="text-align: center">
							<button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="8">Aucune réquisition.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div> -->


</div>