<p-toast key="creationRetour"></p-toast>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="lastname">Date</label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="retour.date" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="demandeur">Demandeur</label>
            <p-dropdown [options]="listeEmployes" [(ngModel)]="retour.demandeur" optionLabel="nom"
                [showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
                placeholder="Sélectionner un demandeur...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="retour.demandeur">
                        <div>{{retour.demandeur.prenom}} {{retour.demandeur.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-employe pTemplate="item">
                    <div>
                        <div>{{employe.prenom}} {{employe.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-2">
            <label for="camion">Camion</label>
            <input id="camion" type="text" pInputText [(ngModel)]="retour.camion">
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-2">
            <label for="remorque">Remorque</label>
            <input id="remorque" type="text" pInputText [(ngModel)]="retour.remorque">
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-2">
            <label for="chauffeur">Chauffeur</label>
            <input id="chauffeur" type="text" pInputText [(ngModel)]="retour.chauffeur">
        </div>
    </div>
    <div class="p-text-right">

        <button pButton pRipple label="Créer" icon="pi pi-save" class="p-button-primary"
            (click)="creerRetour()"></button>

        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

    </div>

</div>