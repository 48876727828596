import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';

import { Observable, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Emplacement } from '../entites/emplacement';
import { ItemMin } from '../entites/item-min';
import { RapportEntite } from '../entites/rapport';
import { ParametresRapportItem, RequeteProduireRapportItem } from '../services/requetes/rapports/parametres-rapport-item';
import { ReponseProduireRapport } from './reponses/reponse-produire-rapport';
import { ParametresRapportUtilisationEquipements } from './requetes/rapports/parametres-rapport-utilisation-equipements';
import { ReponseRapportUtilisationEquipements } from './reponses/reponse-rapport-utilisation-equipements';
import { ReponseObtenirDatesRapportUtilisationEquipements } from './reponses/reponse-dates-rapport-utilisation-equipements';
import { ParametresRapportEntreesSorties } from './requetes/rapports/parametres-rapport-entrees-sorties';
import { ReponseRapportEntreesSorties } from './reponses/reponse-rapport-entrees-sorties';
import { RequeteProduireRapportExcel } from './requetes/requete-produire-excel';
import { ParametresRapportCommande } from './requetes/rapports/parametres-rapport-commande';

@Injectable({ providedIn: 'root' })
export class RapportService {

  @Output() changementRapports = new EventEmitter<RapportEntite[]>();

  rapports: RapportEntite[] = [];

  private precommandeUrl = environment.apiBaseUrl + 'rapport/precommande/{id}';
  private commandeUrl = environment.apiBaseUrl + 'rapport/commande';
  private commandeParDepartementUrl = environment.apiBaseUrl + 'rapport/commande/departement';
  private bonCommandeUrl = environment.apiBaseUrl + 'rapport/bonCommande/{id}';
  private requisitionUrl = environment.apiBaseUrl + 'rapport/requisition/{id}';
  private itemUrl = environment.apiBaseUrl + 'rapport/item';
  private datesUtilisationEquipementUrl = environment.apiBaseUrl + 'rapport/datesutilisationequipements';
  private utilisationEquipementUrl = environment.apiBaseUrl + 'rapport/utilisationequipements';
  private entresSortiesUrl = environment.apiBaseUrl + 'rapport/entreessorties';
  private locationUrl = environment.apiBaseUrl + 'rapport/location';
  private locationIsoUrl = environment.apiBaseUrl + 'rapport/locationiso';
  private listeEquipementUrl = environment.apiBaseUrl + 'rapport/listeequipement';
  private boiteOutilUrl = environment.apiBaseUrl + 'rapport/boiteoutil/{id}';
  private bonTravailUrl = environment.apiBaseUrl + 'rapport/bontravail/{id}/{multiPages}';
  private soumissionUrl = environment.apiBaseUrl + 'rapport/soumission/{id}';
  private entretienPEPUrl = environment.apiBaseUrl + 'rapport/entretienpep/{dateDebut}/{dateFin}';
  private entretienSAAQUrl = environment.apiBaseUrl + 'rapport/entretiensaaq/{dateDebut}/{dateFin}';

  private exportationExcelUrl = environment.apiBaseUrl + 'rapport/excel';
  

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

    public ProduireRapportCommande(requete: ParametresRapportCommande): Promise<boolean> {
      return new Promise((resolve, reject) => {
          this.http.post<ReponseBase<ReponseProduireRapport>>(this.commandeUrl, JSON.stringify(requete), this.httpOptions).toPromise().then(x => {
            console.log(x);
              if (x.estUnSucces) {
                this.ajouterRapport({
                  nom: x.valeur.chemin,
                  url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                  type: 'Commande',
                  detail: x.valeur.nom
                })
                resolve(true);
              } else {
                resolve(false);
              }
          });
      });
    }

    public ProduireRapportCommandeParDepartement(requete: ParametresRapportCommande): Promise<boolean> {
      return new Promise((resolve, reject) => {
          this.http.post<ReponseBase<ReponseProduireRapport>>(this.commandeParDepartementUrl, JSON.stringify(requete), this.httpOptions).toPromise().then(x => {
              if (x.estUnSucces) {
                this.ajouterRapport({
                  nom: x.valeur.chemin,
                  url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                  type: 'Commande',
                  detail: x.valeur.nom
                })
                resolve(true);
              } else {
                resolve(false);
              }
          });
      });
    }

    public ProduireRapportPreCommande(id: number): Promise<boolean> {
      return new Promise((resolve, reject) => {
          this.http.get<ReponseBase<ReponseProduireRapport>>(this.precommandeUrl.replace('{id}', id.toString())).toPromise().then(x => {
              if (x.estUnSucces) {
                this.ajouterRapport({
                  nom: x.valeur.chemin,
                  url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                  type: 'Pré-Commande',
                  detail: x.valeur.nom
                })
                resolve(true);
              } else {
                resolve(false);
              }
          });
      });
    }

  /* public ProduireRapportCommande(parametres: ParametresRapportCommande): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.post<ReponseBase<ReponseProduireRapport>>(this.commandeUrl, JSON.stringify(parametres), this.httpOptions).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Commande',
                detail: parametres.parametresCommuns.detail
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  } */

  public ProduireRapportItem(requete: RequeteProduireRapportItem): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.post<ReponseBase<ReponseProduireRapport>>(this.itemUrl, JSON.stringify(requete), this.httpOptions).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Item',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportBonCommande(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.bonCommandeUrl.replace('{id}', id.toString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Bon de commande',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public EnvoyerRapportBonCommande(id: number, destinataires: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.post<ReponseBase<string>>(this.bonCommandeUrl.replace('{id}', id.toString()), JSON.stringify(destinataires), this.httpOptions).toPromise().then(x => {
            if (x.estUnSucces) {
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }


  public ProduireRapportRequisition(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.requisitionUrl.replace('{id}', id.toString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Réquisition',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public EnvoyerRapportRequisition(id: number, destinataires: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.post<ReponseBase<string>>(this.requisitionUrl.replace('{id}', id.toString()), JSON.stringify(destinataires), this.httpOptions).toPromise().then(x => {
            if (x.estUnSucces) {
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ObtenirDatesRapportUtilisationEquipements(): Promise<ReponseBase<ReponseObtenirDatesRapportUtilisationEquipements>> {
    var p = this.http.get<ReponseBase<ReponseObtenirDatesRapportUtilisationEquipements>>(this.datesUtilisationEquipementUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }
  
  public ObtenirRapportUtilisationEquipements(requete: ParametresRapportUtilisationEquipements): Promise<ReponseBase<ReponseRapportUtilisationEquipements>> {
    var p = this.http.post<ReponseBase<ReponseRapportUtilisationEquipements>>(this.utilisationEquipementUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirRapportEntreesSorties(requete: ParametresRapportEntreesSorties): Promise<ReponseBase<ReponseRapportEntreesSorties>> {
    var p = this.http.post<ReponseBase<ReponseRapportEntreesSorties>>(this.entresSortiesUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ProduireRapportLocation(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.locationUrl).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Rapport de location',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportLocationIso(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.locationIsoUrl).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Rapport de location (ISO)',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportListeEquipement(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.listeEquipementUrl).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Rapport de liste des équipements',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportBoiteOutil(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.boiteOutilUrl.replace('{id}', id.toString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Boite outil',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportSoumission(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.soumissionUrl.replace('{id}', id.toString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Soumission',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportExcel(parametres: RequeteProduireRapportExcel): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.post<ReponseBase<ReponseProduireRapport>>(this.exportationExcelUrl,  JSON.stringify(parametres), this.httpOptions).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Exportation excel',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportBonTravail(id: number, multiPages: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.bonTravailUrl.replace('{id}', id.toString()).replace('{multiPages}', multiPages.toString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Bon de travail',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public EnvoyerRapportBonTravail(id: number, multiPages: boolean, destinataires: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.post<ReponseBase<string>>(this.bonTravailUrl.replace('{id}', id.toString()).replace('{multiPages}', multiPages.toString()), JSON.stringify(destinataires), this.httpOptions).toPromise().then(x => {
            if (x.estUnSucces) {
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }
  
  public ProduireRapportEntretienPEP(dateDebut: Date, dateFin: Date): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.entretienPEPUrl.replace('{dateDebut}', dateDebut.toISOString()).replace('{dateFin}', dateFin.toISOString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Entretiens PEP',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }

  public ProduireRapportEntretienSAAQ(dateDebut: Date, dateFin: Date): Promise<boolean> {
    return new Promise((resolve, reject) => {
        this.http.get<ReponseBase<ReponseProduireRapport>>(this.entretienSAAQUrl.replace('{dateDebut}', dateDebut.toISOString()).replace('{dateFin}', dateFin.toISOString())).toPromise().then(x => {
            if (x.estUnSucces) {
              this.ajouterRapport({
                nom: x.valeur.chemin,
                url: `${environment.viewRapportsBaseUrl}v/${x.valeur.chemin}`,
                type: 'Entretiens SAAQ',
                detail: x.valeur.nom
              })
              resolve(true);
            } else {
              resolve(false);
            }
        });
    });
  }
  public obtenirRapports() {
    return this.rapports;
  }

  public ajouterRapport(requete: RapportEntite) {
    this.rapports.unshift(requete);
    this.changementRapports.emit(this.rapports);
  }

  public supprimerRapport(requete: RapportEntite) {
    this.rapports = this.rapports.filter(x => x.nom != requete.nom);
    this.changementRapports.emit(this.rapports);
  }

  public viderRapports() {
    this.rapports = [];
    this.changementRapports.emit(this.rapports);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
