<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>


<div *ngIf="EstCharge()" class="p-grid">
	<!-- <div class="p-col-12">
		<a [routerLink]="['/mecanique/saisieheureskms']">
			<div class="card">
				Saisir les heures / kms
			</div>
		</a>
	</div> -->
	<div *ngIf="estAutorise('heures_saisie')" class="p-col-12">
		<a [routerLink]="['/mecanique/saisieheures']">
			<div class="card">
				Saisir les heures
			</div>
		</a>
	</div>
	<div *ngIf="estAutorise('heures_approbation')" class="p-col-12">
		<a [routerLink]="['/mecanique/approbationheures']">
			<div class="card">
				Approuver les heures
			</div>
		</a>
	</div>
	<div *ngIf="estAutorise('heures_saisie')" class="p-col-12">
		<a [routerLink]="['/mecanique/saisiekms']">
			<div class="card">
				Saisir les KM
			</div>
		</a>
	</div>
	<div *ngIf="estAutorise('heures_approbation')" class="p-col-12">
		<a [routerLink]="['/mecanique/approbationkms']">
			<div class="card">
				Approuver les KM
			</div>
		</a>
	</div>
</div>