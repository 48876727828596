import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { BoiteOutilEntite, ItemBoiteOutilEntite } from '../entites/boite-outil';

@Injectable({ providedIn: 'root' })
export class BoiteOutilService {

  private obtenirListeUrl = environment.apiBaseUrl + 'boiteoutil';
  private obtenirUrl = environment.apiBaseUrl + 'boiteoutil/{id}';
  private ajouterUrl = environment.apiBaseUrl + 'boiteoutil';
  private modifierUrl = environment.apiBaseUrl + 'boiteoutil/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'boiteoutil/{id}';
  private itemsUrl = environment.apiBaseUrl + 'boiteoutil/{id}/items';
  private ajouterItemUrl = environment.apiBaseUrl + 'boiteoutil/{id}/items';
  private supprimerItemUrl = environment.apiBaseUrl + 'boiteoutil/item/{id}';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<BoiteOutilEntite[]>> {
    var p = this.http.get<ReponseBase<BoiteOutilEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Obtenir(id: number): Promise<ReponseBase<BoiteOutilEntite>> {
    var p = this.http.get<ReponseBase<BoiteOutilEntite>>(this.obtenirUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(boiteOutil: BoiteOutilEntite): Promise<ReponseBase<BoiteOutilEntite>> {
    var p = this.http.post<ReponseBase<BoiteOutilEntite>>(this.ajouterUrl, JSON.stringify(boiteOutil), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Modifier(boiteOutil: BoiteOutilEntite): Promise<ReponseBase<BoiteOutilEntite>> {
    var p = this.http.post<ReponseBase<BoiteOutilEntite>>(this.modifierUrl, JSON.stringify(boiteOutil), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Supprimer(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  public ObtenirItems(id: number): Promise<ReponseBase<BoiteOutilEntite>> {
    var p = this.http.get<ReponseBase<BoiteOutilEntite>>(this.itemsUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterItem(itemBoiteOutil: ItemBoiteOutilEntite): Promise<ReponseBase<ItemBoiteOutilEntite>> {
    var p = this.http.post<ReponseBase<ItemBoiteOutilEntite>>(this.ajouterItemUrl, JSON.stringify(itemBoiteOutil), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public SupprimerItem(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerItemUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
