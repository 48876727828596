import { Component, ViewChild, ElementRef, ɵConsole, Input } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { RaisonArchive } from '../entites/raison-archive';
import { AppMainComponent } from '../app.main.component';
import { RequisitionService } from '../services/requisition-service';
import { CommandessComponent } from '../pages/requisitions/commandes.component';
import { CommandeService } from '../services/commande-service';
import { TransporteurEntite } from '../entites/transporteur';
import { RequisitionMin } from '../entites/requisition-min';
import { BonCommandeEntite } from '../entites/bon-commande';

export interface ParametresConfirmationCreationBonCommande {
    bonsCommande: BonCommandeEntite[];
}

@Component({
    selector: 'app-confirmation-creation-bon-commande',
    templateUrl: './confirmation-creation-bon-commande.component.html'
})
export class ConfirmationCreationBonCommandeComponent extends BaseComponent {

    donnees: ParametresConfirmationCreationBonCommande;

    @ViewChild('boutonConfirmer') boutonConfirmer;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        appMain: AppMainComponent,
        private requisitionService: RequisitionService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public router: Router,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, router, appMain);
    }

    ngOnInit() {
        this.donnees = this.config.data;
        this.CompleterChargement();
        setTimeout(() => {this.boutonConfirmer.nativeElement.focus(); }, 100);
    }

    confirmer() {
        this.ref.close(null);
    }

    voirBonCommande(bdc: BonCommandeEntite) {
        this.ref.close(true);
        this.router.navigateByUrl('achat/' + bdc.bonCommandeId.toString());
    }
}