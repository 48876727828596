<div class="exception-body notfound">
    <!-- <img src="assets/layout/images/logo-white.svg" alt="diamond-layout" class="logo"> -->
    <!-- <img src="assets/images/logo-blanc.png" alt="Hamel Construction" class="logo"> -->

    <div class="exception-content">
        <div class="exception-title">Oops</div>
        <div class="exception-detail">La page que vous cherchez est introuvable.</div>
        <a [routerLink]="['/']">Retour à l'accueil</a>
    </div>
</div>
