<p-toast key="ajustements"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>



<div #contenu *ngIf="EstCharge()">

    <div class="p-col-12">
        <div class="card">
            <div class="p-grid">
                <div class="p-col-12 p-text-right p-mt-3">
                    <button pButton pRipple label="Nouvel ajustement" icon="pi pi-plus" class="p-button-text"
                        (click)="ajouter()"></button>
                </div>
            </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-col-12">
                            <p-table #dt [value]="ajustements"
                                dataKey="ajustementHeuresId" autoLayout="true" tableStyleClass="tableNormale"
                                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" >
                                <ng-template pTemplate="caption">
                                    <div class="p-d-flex p-ai-center p-jc-between">
                                        <div>
                                        </div>
                                        <div>
                                            <h5 class="titreTableau p-m-0">Ajustements</h5>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                    <th>Date</th>
                                    <th>Kms avant</th>
                                    <th>Kms après</th>
                                    <th>Ajustement</th>
                                    <th style="min-width: 7rem; width: 7rem;"></th>
                                </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-ajustement let-columns="columns">
                                    <tr>
                                        <td>{{ajustement.date | date:'yyyy-MM-dd'}}</td>
                                        <td>{{ajustement.nbKmsAvant}}</td>
                                        <td>{{ajustement.nbKmsApres}}</td>
                                        <td>{{ajustement.nbKms}}</td>
                                        <td></td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4">Aucun ajustement.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>

                    <div class="p-grid">
                        <div class="p-col-12 p-text-right p-mt-3">
                            <button pButton pRipple label="Fermer" icon="pi pi-ban" class="p-button-text"
                                (click)="fermer()"></button>
                        </div>
                    </div>

        </div>
    </div>

</div>