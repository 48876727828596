import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { AppMainComponent } from '../app.main.component';
import { CodeProjetEntite } from '../entites/code-projet';
import { CodeActiviteEntite } from '../entites/code-activite';
import { CodeCategorieEntite } from '../entites/code-categorie';
import { CategorieEntretienEntite } from '../entites/mecanique/categorie-entretien';
import { CodeEquipementEntite } from '../entites/code-equipement';
import { PieceService } from '../services/piece-service';

export interface ParametresCreationBonCommande {
    itemId: number;
    fournisseurId: number;
    numeroItem: string;
    descriptionItem: string;
    nomFournisseur: string;
    numeroFournisseur: string;
    numeroItemFournisseur: string;
    prix: number;    
    selectionCodeProjet?: number;
    selectionCodeActivite?: number;
    selectionCodeCategorie?: number;
    selectionCodeEquipement?: number;
}

export interface ResultatCreationBonCommande {
    bonCommandeId: number;
    redirection: boolean;
}

@Component({
    selector: 'app-creation-commande',
    templateUrl: './creation-commande.component.html'
})
export class CreationCommandeComponent extends BaseComponent {

    donnees: ParametresCreationBonCommande;

    quantite: number;
    bonCommandeOuvert: boolean = false;
    idBonCommande: number;

    selectionNouveauBonCommande: number = 1;

    listeCodeProjet: CodeProjetEntite[];
    selectionCodeProjet: CodeProjetEntite;

    listeCodeActivite: CodeActiviteEntite[];
    selectionCodeActivite: CodeActiviteEntite;

    listeCodeCategorie: CodeCategorieEntite[];
    selectionCodeCategorie: CodeCategorieEntite;

    listeCodeEquipement: CodeEquipementEntite[];
    selectionCodeEquipement: CodeEquipementEntite;

    indAssocier: boolean = true;
    listeCategoriesEntretien: CategorieEntretienEntite[];
    selectionCategorieEntretien: CategorieEntretienEntite;
    
    submitted: boolean; 
    blocked: boolean = false;

    @ViewChild('champQuantite') inputQuantite;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public achatService: AchatService,
        public pieceService: PieceService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;


        this.listeCodeProjet = this.sdb.CodesProjet();

        if (this.donnees.selectionCodeProjet) {
            this.selectionCodeProjet = this.listeCodeProjet.find(x => x.codeProjetId == this.donnees.selectionCodeProjet);
            this.surChangementCodeProjet();

            if (this.donnees.selectionCodeActivite) {
                this.selectionCodeActivite = this.listeCodeActivite.find(x => x.codeActiviteId == this.donnees.selectionCodeActivite);
            }

        }

        this.listeCodeCategorie =this.sdb.CodesCategorie();
        if (this.donnees.selectionCodeCategorie) {
            this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.codeCategorieId == this.donnees.selectionCodeCategorie);
        }

        this.listeCodeEquipement = this.sdb.CodesEquipement();
        if (this.donnees.selectionCodeEquipement) {
            this.selectionCodeEquipement = this.listeCodeEquipement.find(x => x.codeEquipementId == this.donnees.selectionCodeEquipement);
        }

        this.listeCategoriesEntretien = this.sdb.CategoriesEntretien();

        this.achatService.ObtenirBonCommandeOuvertFournisseur(this.donnees.fournisseurId, this.profilService.ProfilCourant().employeId).then(res => {
            if (res.estUnSucces && res.valeur) {
                this.bonCommandeOuvert = true;
                this.selectionNouveauBonCommande = 2;
                this.idBonCommande = res.valeur.bonCommandeId;
            }

            this.CompleterChargement();

            setTimeout(() => {
                this.inputQuantite.input.nativeElement.focus();
                this.inputQuantite.input.nativeElement.select();
            }, 50);
        })

        

        
    }

    annuler() {
        this.ref.close(null);
    }

    surChangementCodeProjet() {
        this.selectionCodeActivite = null;
        this.listeCodeActivite = this.selectionCodeProjet.codesActivite;
    }

    creerBonDeCommande() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'creationBonCommande', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        var p = new Promise<number>((resolve, reject) => {

            if (!this.bonCommandeOuvert || this.selectionNouveauBonCommande == 1) {
                this.achatService.CreerBonCommande({
                        fournisseur: {fournisseurId: this.donnees.fournisseurId},
                        dateAchat: new Date(),
                        projet: this.selectionCodeProjet,
                        emplacement: this.sdb.Emplacement(10000),
                        employe: this.profilService.ProfilCourant()
                }).then(x => {
                    if (x.estUnSucces) {
                        resolve(x.valeur);

                    } else {
                        x.messages.forEach(x => {
                            this.messageService.add({ key: 'creationBonCommande', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
                        
                        reject();
                        this.blocked = false;
                    }
                });
            } else {
                    resolve(this.idBonCommande);
            }

        }).then(resBdc => {
            this.achatService.CreerItemBonCommande({
                itemBonCommandeId: 0,
                bonCommandeId: resBdc,
                item: {
                    itemId: this.donnees.itemId
                },
                noFournisseur: this.donnees.numeroItemFournisseur,
                quantite: this.quantite,
                note: '',
                prix: this.donnees.prix,
                codeProjet: this.selectionCodeProjet,
                codeActivite: this.selectionCodeActivite,
                codeCategorie: this.selectionCodeCategorie,
                codeEquipement: this.selectionCodeEquipement
            }).then(res => {
                if (res.estUnSucces) {

                    //Association
                    if (this.indAssocier && this.selectionCodeEquipement) {
                        this.pieceService.AssocierEquipementCtrl(this.donnees.itemId, this.selectionCodeEquipement.code, this.selectionCategorieEntretien?.categorieEntretienId).then(resAssoc => {
                            this.blocked = false;
                            this.ref.close({
                                bonCommandeId: res.valeur.bonCommandeId,
                                redirection: true
                            });
                        });
                    } else {
                        this.blocked = false;
                        this.ref.close({
                            bonCommandeId: res.valeur.bonCommandeId,
                            redirection: true
                        });
                    }

                    
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            })


        });
    }

    valider() {
        return this.quantite;
    }
}