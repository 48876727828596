import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequisitionService } from '../services/requisition-service';
import { AppMainComponent } from '../app.main.component';
import { CategorieEntite } from '../entites/categorie';

export interface ParametresSelectionItemSimple {
    choixCategorieDisponible: boolean;
    categorieIds?: number[];
}

export interface ResultatSelectionItemSimple {
    item?: ItemMin;
}

@Component({
    selector: 'app-selection-item-simple',
    templateUrl: './selection-item-simple.component.html',
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class SelectionItemSimpleComponent extends BaseComponent {


    //Général

    donnees: ParametresSelectionItemSimple;

    categories: CleValeurNumerique[];
    selectionCategorie: CleValeurNumerique[];

    submitted: boolean;

    itemsComplets: ItemMin[];
    items: ItemMin[];
    selectionItem: ItemMin = { itemId: 0 };


    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public requisitionService: RequisitionService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.categories = [{ "cle": 0, "valeur": "Tous" }];
            this.sdb.Categories().forEach(x => {
                this.categories.push({ "cle": x.categorieId, "valeur": x.nom })
            });
            this.selectionCategorie = [this.categories[0]];

            if (this.donnees.categorieIds)
            {
                this.selectionCategorie = this.categories.filter((c: CleValeurNumerique) => this.donnees.categorieIds.includes(c.cle))
            }

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.itemService.ObtenirItems().then(x => {
            this.itemsComplets = x.valeur;
            this.items = x.valeur;

            this.CompleterChargement();

            this.surChangementCategorie();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);

        });

    }

    handleKeyboardEvents(event: KeyboardEvent) {
        //TODO: mettre la touche raccoruci dans l'entite et générliser
        //TODO : valider si l'option est disponible ...

        //TEMP
        if (!this.donnees.choixCategorieDisponible) {
            return;
        }

        if (event.key == 't' && event.altKey) {
            this.selectionCategorie = [this.categories[0]];
            this.surChangementCategorie();
        } else if (event.key == 'a' && event.altKey) {
            this.selectionCategorie = [this.categories[1]];
            this.surChangementCategorie();
        } else if (event.key == 'e' && event.altKey) {
            this.selectionCategorie = [this.categories[2]];
            this.surChangementCategorie();
        } else if (event.key == 'i' && event.altKey) {
            this.selectionCategorie = [this.categories[3]];
            this.surChangementCategorie();
        } else if (event.key == 'o' && event.altKey) {
            this.selectionCategorie = [this.categories[4]];
            this.surChangementCategorie();
        }
    }

    surChangementCategorie() {
        if (!this.selectionCategorie || (this.selectionCategorie.length == 1 && this.selectionCategorie[0].cle == 0)) {
            this.items = this.itemsComplets;
        } else {
            var ids = this.selectionCategorie.map(x => x.cle);
            this.items = this.itemsComplets.filter(x => ids.includes(x.categorie.categorieId));
        }
    }

    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.surSelectionItemInventaire(this.tableItems.filteredValue[0]);
        }

    }

    annuler() {
        this.ref.close(null);
    }

    surSelectionItemInventaire(item: ItemMin) {

        this.ref.close({
            item: item
        });

    }
}