<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h5>Rapport de liste des équipements
			</h5>

<!-- 
			<p-menubar [model]="menu">
			</p-menubar> -->
		</div>
	</div>


</div>

<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<button pButton pRipple label="Générer le rapport" icon="pi pi-chart-bar" class="p-button-primary"
            					(click)="generer()"></button>
						</div>
					</div>
					

		</div>
	</div>


</div>


<!-- 
<div *ngIf="EstCharge()" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-tabView orientation="left" [(activeIndex)]="tabIndex" >
				<p-tabPanel header="Critères" leftIcon="pi pi-info-circle">
					
					<div class="p-formgrid p-grid">
						<div class="p-field p-col-12">
							<button pButton pRipple label="Générer le rapport" icon="pi pi-chart-bar" class="p-button-primary"
            					(click)="generer()"></button>
						</div>
					</div>
					

				</p-tabPanel>
				
			</p-tabView>
		</div>
	</div>


</div>
 -->