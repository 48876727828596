import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { LigneHeureEntite } from '../entites/mecanique/ligne-heure';
import { RequeteAjouterHeures } from './requetes/requete-ajouter-heures';

@Injectable({ providedIn: 'root' })
export class MajService {

  private traiterUrl = environment.apiBaseUrl + 'maj/traiter';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public Traiter(): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.traiterUrl, null, this.httpOptions)
      .toPromise();

      p.then(res => {
        console.log('Résultat traitement: ' + res.valeur);
      })

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
