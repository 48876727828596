<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div *ngIf="EstCharge()">
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">

		<p-menu #menuContextuel popup="popup" [model]="contenuMenuContextuel" appendTo="body"></p-menu>

        <p-table #dtHistorique [value]="historique"
						tableStyleClass="tableNormale" autoLayout="true"
						dataKey="historiqueEntretienPreventifId"
						[globalFilterFields]="['entretien.description','date','mecanicien.nom','mecanicien.prenom']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
								<span class="p-input-icon-left">
									<i class="pi pi-search"></i>
									<input pInputText type="text"
										(input)="dtHistorique.filterGlobal($event.target.value, 'contains')"
										placeholder="Recherche..." />
								</span>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="date">
									<div class="p-d-flex p-jc-between p-ai-center">
										Date
										<p-sortIcon field="date"></p-sortIcon>
										<p-columnFilter type="date" field="date" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="bonTravail.numero">
									<div class="p-d-flex p-jc-between p-ai-center">
										Bon travail
										<p-sortIcon field="bonTravail.numero"></p-sortIcon>
										<p-columnFilter type="text" field="bonTravail.numero" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
								<th pSortableColumn="bonTravail.heuresCompteur">
									<div class="p-d-flex p-jc-between p-ai-center">
										Heures
										<p-sortIcon field="bonTravail.heuresCompteur"></p-sortIcon>
										<p-columnFilter type="text" field="bonTravail.heuresCompteur" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
								<th pSortableColumn="bonTravail.kmCompteur">
									<div class="p-d-flex p-jc-between p-ai-center">
										KM
										<p-sortIcon field="bonTravail.kmCompteur"></p-sortIcon>
										<p-columnFilter type="text" field="bonTravail.kmCompteur" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="bonTravail.statut">
									<div class="p-d-flex p-jc-between p-ai-center">
										Statut
										<p-sortIcon field="bonTravail.statut"></p-sortIcon>
										<p-columnFilter type="text" field="bonTravail.statut" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="bonTravail.mecanicien.prenom">
									<div class="p-d-flex p-jc-between p-ai-center">
										Mécanicien
										<p-sortIcon field="bonTravail.mecanicien.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="bonTravail.mecanicien.prenom" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="bonTravail.mecanicien.note">
									<div class="p-d-flex p-jc-between p-ai-center">
										Note
										<p-sortIcon field="bonTravail.mecanicien.note"></p-sortIcon>
										<p-columnFilter type="text" field="bonTravail.mecanicien.note" display="menu"
											class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
                                <th style="min-width: 5rem; width: 5rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-historique>
							<tr [pContextMenuRow]="historique">
								<td>
									{{historique.bonTravail.dateTravail | date:'yyyy-MM-dd'}}
								</td>
								<td>
									{{historique.bonTravail.numero}}
								</td>
								<td>
									{{historique.bonTravail.heuresCompteur | valeurVide}}
								</td>
								<td>
									{{historique.bonTravail.kmCompteur | valeurVide}}
								</td>
								<td><span [class]="'statut ' + historique.bonTravail.statut.nom.toLowerCase().replace(' ', '')">
									{{historique.bonTravail.statut.nom}}
								</span></td>
								<td>
									{{historique.bonTravail.mecanicien | nomEmploye}}
								</td>
								<td>
									{{historique.bonTravail.note}}
								</td>
                                <td class="celluleTableau">
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="surMenuContextuel($event, historique)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="5">Aucun historique.</td>
							</tr>
						</ng-template>
					</p-table>
    </div>
  
</div>
<div class="p-grid">
    <div class="p-col-12 p-text-right ">
        <button pButton pRipple label="Fermer" icon="pi pi-ban" class="p-button-text" (click)="fermer()"></button>
    </div>
</div>
</div>