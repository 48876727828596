import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { SeveriteEntite } from 'src/app/entites/mecanique/severite';
import { CodeVmrsEntite } from 'src/app/entites/mecanique/code-vmrs';
import { EmployeEntite } from 'src/app/entites/employe';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EStatuts } from 'src/app/entites/enums/statuts';

export interface ParametresDetailEntretienCorrectif {
    entretienCorrectif: EntretienCorrectifEntite;
}

export interface ResultatDetailEntretienCorrectif {
    entretienCorrectif: EntretienCorrectifEntite;
}

@Component({
    selector: 'app-detail-entretien-correctif',
    templateUrl: './detail-entretien-correctif.component.html'
})
export class DetailEntretienCorrectifComponent extends BaseComponent {

    donnees: ParametresDetailEntretienCorrectif;

    entretien: EntretienCorrectifEntite;

    severites: SeveriteEntite[];
    categoriesEntretien: CategorieEntretienEntite[];
    employes: EmployeEntite[];
    
    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champDescription') inputDescription;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public entretienCorrectifService: EntretienCorrectifService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.severites = this.sdb.Severites();
        this.categoriesEntretien = this.sdb.CategoriesEntretien();
        this.employes = this.sdb.Employes();

        if (this.donnees.entretienCorrectif) {
            this.entretien = this.donnees.entretienCorrectif;     

            console.log(this.entretien);

            if (this.entretien.categorie) {
                this.entretien.categorie = this.categoriesEntretien.find(x => x.categorieEntretienId == this.entretien.categorie.categorieEntretienId);
            }

            if (this.entretien.severite) {
                this.entretien.severite = this.severites.find(x => x.severiteId == this.entretien.severite.severiteId);
            }

       


        } else {
            this.entretien = {
                entretienCorrectifId: 0,
                dateCreation: new Date(),
                statut: this.sdb.Statut(EStatuts.AFaire),
                employe: this.profilService.ProfilCourant(),
                categorie: this.categoriesEntretien[0]
            };
        }
        

        if (this.entretien.employe) {
            this.entretien.employe = this.employes.find(x => x.employeId == this.entretien.employe.employeId);
        } else {
            if (this.entretien.entretienCorrectifId == 0) {
                this.entretien.employe = this.employes.find(x => x.employeId == this.profilService.ProfilCourant().employeId);
            }
        }


        this.CompleterChargement();

        setTimeout(() => { 
            this.inputDescription.nativeElement.focus();
            this.inputDescription.nativeElement.select();
         }, 100);
    }

    annuler() {
        this.ref.close(null);
    }

    enregistrer() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        if (this.entretien.entretienCorrectifId == 0)
        {
            this.entretien.dateCreation = new Date();
            this.entretien.statut = this.sdb.Statut(EStatuts.AFaire);
            this.entretienCorrectifService.Creer(this.entretien).then(res => {
                if (res.estUnSucces) {

                    this.ref.close(res.valeur);

                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                    this.blocked = false;
                }
            });

        } else {

            this.entretienCorrectifService.Modifier(this.entretien).then(res => {
                if (res.estUnSucces) {

                    this.ref.close(res.valeur);

                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                    this.blocked = false;
                }
            });

        }
    }

    valider() {
        return this.entretien.description && this.entretien.categorie && this.entretien.employe && this.entretien.severite;
        
    }
}