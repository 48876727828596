import { Component, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from '@angular/core';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequisitionService } from '../services/requisition-service';
import { AppMainComponent } from '../app.main.component';
import { CategorieEntite } from '../entites/categorie';

export interface ParametresSelectionItemMultiple {
    itemIdsExclus?: number[];
}

export interface ResultatSelectionItemMultiple {
    items?: ItemMin[];
}

@Component({
    selector: 'app-selection-item-multiple',
    templateUrl: './selection-item-multiple.component.html'
})
export class SelectionItemMultipleComponent extends BaseComponent {


    //Général

    donnees: ParametresSelectionItemMultiple;

    submitted: boolean;
    
    menu: MenuItem[];

    itemsComplets: ItemMin[];
    items: ItemMin[];
    selectionItem: ItemMin[];


    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent,
        public itemService: ItemService,
        public requisitionService: RequisitionService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.itemService.ObtenirItems().then(x => {
            this.itemsComplets = x.valeur.filter(x => x.categorie.estSuivi && !x.categorie.estUnique);
            if (this.donnees.itemIdsExclus) {
                this.items = this.itemsComplets.filter(x => !this.donnees.itemIdsExclus.includes(x.itemId));
            } else {
                this.items = this.itemsComplets;
            }

            this.initialiserMenu();
            
            this.CompleterChargement();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);

        });

    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Annuler', icon: 'pi pi-fw pi-ban', command: () => this.ref.close(null)
            },
            {
                label: 'Sélectionner', icon: 'pi pi-fw pi-check', 
                disabled: !this.selectionItem || this.selectionItem.length == 0,
                command: () => this.surSelectionItemInventaire(this.selectionItem)
            }
        ];
    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.surSelectionItemInventaire([this.tableItems.filteredValue[0]]);
        }

    }

    annuler() {
        this.ref.close(null);
    }

    surSelectionItemInventaire(items: ItemMin[]) {

        this.ref.close({
            items: items
        });

    }
}