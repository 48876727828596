<p-toast></p-toast>
<p-toast key="c" sticky="true">
    <ng-template let-message pTemplate="message">
        <div class="p-toast-message-content" role="alert" aria-live="assertive" aria-atomic="true">
            <ng-container>
                <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')" [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
                    'pi-times-circle': message.severity == 'error', 'pi-check' :message.severity == 'success'}"></span>
                <div class="p-toast-message-text">
                    <div class="p-toast-summary">{{message.summary}}</div>
                    <div class="p-toast-detail">{{message.detail}}</div>
                    <br>
                        <button (click)="ConsulterBdc()" pButton type="button" label="Consulter" icon="pi pi-search" class="p-button-outlined p-button-sm p-button-success"></button>
                </div>
            </ng-container>
            <button type="button" class="p-toast-icon-close p-link" (click)="onCloseIconClick($event)" (keydown.enter)="onCloseIconClick($event)" *ngIf="message.closable !== false" pRipple>
                <span class="p-toast-icon-close-icon pi pi-times"></span>
            </button>
            <ng-container *ngTemplateOutlet="template; context: {$implicit: message}"></ng-container>
        </div>
    </ng-template>
   
</p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="p-grid">
    <div class="p-col-12">

        <div class="card">
            <div class="p-grid">
                <div class="p-col-12">
                    <h5>Approbation de la liste d'achat</h5>

                    <p-menubar [model]="menu">
                    </p-menubar>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="liste.note">
<div class="p-grid">
                <div class="p-col-12">
                    <h6>Note</h6>
                    {{liste.note}}
                </div>
            </div>
        </div>

        <div class="card">
            <p-table [value]="liste.itemsListeAchat" dataKey="itemsListeAchatId" tableStyleClass="tableNormale"> 
                <ng-template pTemplate="header">
                    <tr>
                        <th>Quantité</th>
                        <th>Numéro</th>
                        <th>Description</th>
                        <th>Fournisseur</th>
                        <th>Numéro fournisseur</th>
                        <th>Prix</th>
                        <th>Code projet</th>
                        <th>Code activité</th>
                        <th style="min-width: 10rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entree>
                    <tr>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="entree.quantite">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{entree.quantite}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td>
                          {{entree.item.numero}}
                        </td>
                        <td>
                          {{entree.item.description}}
                        </td>
                        <td>
                            <i style="color:red" *ngIf="!entree.fournisseur" class="pi pi-exclamation-triangle"></i>
                          <span *ngIf="entree.fournisseur">{{entree.fournisseur.nom}}</span>
                        </td>
                        <td>
                            {{entree.numeroFournisseur}}
                          </td>
                        <td>
                          {{entree.prix | currency}}
                        </td>
                        <td>
                            {{entree.codeProjet?.code}}
                          </td>
                          <td>
                            {{entree.codeActivite?.code}}
                          </td>
                          <td>
                            <button *ngIf="entree.fournisseur" pButton pRipple icon="pi pi-eye" class="p-button-success p-mr-2 boutonGrille"
                            (click)="consulterFournisseurs(entree)"></button>
                        <button *ngIf="!entree.fournisseur" pButton pRipple icon="pi pi-pencil" class="p-button-success p-mr-2 boutonGrille"
                            (click)="selectionnerFournisseur(entree)"></button>
                          </td>
                    </tr>
                </ng-template>
               
            </p-table>
        </div>
    </div>


</div>


<p-dialog [(visible)]="dialogueApprobation" [style]="{width: '50%'}" header="Approbation de la liste d'achat" [modal]="true"
	[contentStyle]="{'overflow': 'visible'}" styleClass="p-fluid p-formgrid p-grid">
	<ng-template pTemplate="content">

        <p-toast key="dialogueApprobation"></p-toast>

		<div class="p-formgrid p-grid">
			<div class="p-field p-col-12 p-md-6">
				<label for="lastname">Date d'achat<span class="requis">*</span></label>
                <p-calendar showIcon=true [(ngModel)]="dateAchat"></p-calendar>
                
			</div>
		
			
	
		</div>

	</ng-template>

	<ng-template pTemplate="footer">
        <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
			(click)="confirmerApprobation()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
			(click)="annulerApprobation()"></button>
		
	</ng-template>
</p-dialog>
