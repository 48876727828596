import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { BoiteOutilEntite } from 'src/app/entites/boite-outil';
import { BoiteOutilService } from 'src/app/services/boite-outil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';

@Component({
    templateUrl: './boites-outil.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class BoitesOutilComponent extends BaseComponent implements OnInit {

    boiteOutils: BoiteOutilEntite[];
    selectionBoiteOutil: BoiteOutilEntite;

    dialogueNouvelBoiteOutil = false;
    submitted = false;
    nouvelBoiteOutil: BoiteOutilEntite;

    
    contenuMenuContextuelItem: MenuItem[];
    @ViewChild('menuContextuelItem') menuContextuelItem: ContextMenu;
    
    @ViewChild('champRecherche') inputRecherche;
    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private boiteOutilService: BoiteOutilService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Boites d\'outils' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPageAuMoisUnAcces(['boites_outils_consulter','admin_boites_outils']);

        this.boiteOutilService.ObtenirListe().then(res => {
            this.boiteOutils = res.valeur;
            this.CompleterChargement();    
            setTimeout(() => { this.inputRecherche.nativeElement.focus(); }, 100);
        });       
           
    }

    surEntreeRecherche() {

        if (this.table.filteredValue != undefined &&
            this.table.filteredValue.length == 1) {
                this.OuvrirDetail(this.table.filteredValue[0].boiteOutilId);
            }

    }

    OuvrirDetail(boiteOutilId: number) {
        this.routerService.navigateByUrl(`pilotage/boiteOutil/${boiteOutilId}`)
    }

    surAjoutBoiteOutil() {
        this.nouvelBoiteOutil = { boiteOutilId: 0};
        this.dialogueNouvelBoiteOutil = true;
    }

    creerNouvelBoiteOutil() {

        //this.dialogueNouvelItem = false;
        //this.routerService.navigateByUrl('item/595');
        this.submitted = true;
        this.messageService.clear("nouvelBoiteOutil");

        if (this.validerNouvelBoiteOutil()) {

            this.boiteOutilService.Ajouter(this.nouvelBoiteOutil).then(x => {
                console.log(x);
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('pilotage/boiteOutil/' + x.valeur.boiteOutilId);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ key: "nouvelBoiteOutil" ,severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                }
            });
        }
    }

    validerNouvelBoiteOutil(): boolean {
        if (!this.nouvelBoiteOutil.nom) {
            return false;
        }

        return true;
    }

    annulerNouvelBoiteOutil() {
        this.dialogueNouvelBoiteOutil = false;
    }

    surMenuContextuelItem(event, boite: BoiteOutilEntite) {
        this.contenuMenuContextuelItem = [
            { label: 'Supprimer', icon: 'pi pi-fw pi-times', command: () => this.supprimerBoite(boite) }
        ];

        this.menuContextuelItem.toggle(event);

    }

    supprimerBoite(boite: BoiteOutilEntite) {
        
        console.log('Aaa');
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la boite sélectionnée ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.boiteOutilService.Supprimer(boite.boiteOutilId).then(res => {
                    if (res.estUnSucces) {
                        this.boiteOutils = this.boiteOutils.filter(x => x.boiteOutilId != boite.boiteOutilId)
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Boite supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });

    }

}
