import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { LigneHeureEntite } from '../entites/mecanique/ligne-heure';
import { RequeteAjouterHeures } from './requetes/requete-ajouter-heures';
import { MajService } from './maj-service';
import { AjustementHeureEntite } from '../entites/ajustement-heure';
import { ReleveHeureEntite } from '../entites/releve-heures';

@Injectable({ providedIn: 'root' })
export class HeureService {

  private obtenirListeUrl = environment.apiBaseUrl + 'heure';
  private obtenirListeApprobationUrl = environment.apiBaseUrl + 'heure/approbation';
  private obtenirListeParItemUrl = environment.apiBaseUrl + 'heure/item/{id}';
  private ajouterHeuresUrl = environment.apiBaseUrl + 'heure';
  private approuverHeuresUrl = environment.apiBaseUrl + 'heure/approuver';
  private refuserHeuresUrl = environment.apiBaseUrl + 'heure/refuser';
  private ObtenirListeAjustementsUrl = environment.apiBaseUrl + 'heure/ajustements/item/{id}';
  private ajouterAjustementUrl = environment.apiBaseUrl + 'heure/ajustement';
  private supprimerUrl = environment.apiBaseUrl + 'heure/{id}';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private majService: MajService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<LigneHeureEntite[]>> {
    var p = this.http.get<ReponseBase<LigneHeureEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeApprobation(): Promise<ReponseBase<LigneHeureEntite[]>> {
    var p = this.http.get<ReponseBase<LigneHeureEntite[]>>(this.obtenirListeApprobationUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeParItem(id: number): Promise<ReponseBase<ReleveHeureEntite[]>> {
    var p = this.http.get<ReponseBase<ReleveHeureEntite[]>>(this.obtenirListeParItemUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(requete: RequeteAjouterHeures): Promise<ReponseBase<LigneHeureEntite[]>> {
    var p = this.http.post<ReponseBase<LigneHeureEntite[]>>(this.ajouterHeuresUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.then(() => {
        if (requete.ignorerApprobation) {
          this.majService.Traiter()
        }
        
      });

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Approuver(requete: RequeteAjouterHeures): Promise<ReponseBase<LigneHeureEntite[]>> {
    var p = this.http.post<ReponseBase<LigneHeureEntite[]>>(this.approuverHeuresUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Refuser(requete: RequeteAjouterHeures): Promise<ReponseBase<LigneHeureEntite[]>> {
    var p = this.http.post<ReponseBase<LigneHeureEntite[]>>(this.refuserHeuresUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeAjustements(itemId: number): Promise<ReponseBase<AjustementHeureEntite[]>> {
    var p = this.http.get<ReponseBase<AjustementHeureEntite[]>>(this.ObtenirListeAjustementsUrl.replace('{id}', itemId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterAjustement(ajustement: AjustementHeureEntite): Promise<ReponseBase<AjustementHeureEntite>> {
    var p = this.http.post<ReponseBase<AjustementHeureEntite>>(this.ajouterAjustementUrl, JSON.stringify(ajustement), this.httpOptions)
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  

  public SupprimerHeures(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
