<p-toast key="creationPiece"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">
	<div class="p-fluid p-formgrid p-grid" *ngIf="nouvelItem.categorie">
		<div class="p-field p-col-12 p-md-6 p-lg-8">
			<label for="description">Description<span class="requis">*</span></label>
				<p-autoComplete #champDescription appendTo="body" [(ngModel)]="nouvelItem.description" [suggestions]="suggestionDescription" (completeMethod)="rechercherDescritpion($event)"></p-autoComplete>
			<small class="p-error" *ngIf="submitted && !nouvelItem.description">La description
				est
				obligatoire.</small>
		</div>
	</div>
	<div class="p-fluid p-formgrid p-grid" *ngIf="nouvelItem.categorie">
		<div class="p-field p-col-12 p-md-6 p-lg-4">
			<label for="marque">Marque</label>
			<p-autoComplete appendTo="body" [(ngModel)]="nouvelItem.marque" [suggestions]="suggestionMarque" (completeMethod)="rechercherMarque($event)"></p-autoComplete>
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-4">
			<label for="modele">Modèle</label>
			<input type="text" pInputText id="modele" [(ngModel)]="nouvelItem.modele" autocomplete="off"
				autofocus />
		</div>

	</div>
	<div class="p-fluid p-formgrid p-grid" *ngIf="nouvelItem.categorie">

		<div class="p-field p-col-12 p-md-6 p-lg-4">

			<label for="lastname">Localisation entrepot</label>
			<p-dropdown [options]="listeLocalisations" [(ngModel)]="nouvelItem.localisation" optionLabel="nom"
				appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
				placeholder="Sélectionner une localisation...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="nouvelItem.localisation">
						<div>{{nouvelItem.localisation.nom}}</div>
					</div>
				</ng-template>
				<ng-template let-loc pTemplate="item">
					<div>
						<div>{{loc.nom}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>

	</div>

	<p-divider align="left">
		<div class="p-d-inline-flex p-ai-center">
			<b>Fournisseur</b>
		</div>
	</p-divider>

	<div class="p-fluid p-formgrid p-grid" *ngIf="nouvelItem.categorie">
		<div class="p-field p-col-12 p-lg-6">
			<label for="lastname">Fournisseur</label>
			<p-dropdown [options]="listeFournisseurs" [(ngModel)]="prixFournisseur.fournisseur" optionLabel="nom"
				[showClear]="true" filter="true" filterBy="numero,nom"
				placeholder="Sélectionner un fournisseur...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="prixFournisseur.fournisseur">
						<div>{{prixFournisseur.fournisseur.nom}} ({{prixFournisseur.fournisseur.numero}})</div>
					</div>
				</ng-template>
				<ng-template let-fournisseur pTemplate="item">
					<div>
						<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-3" *ngIf="prixFournisseur.fournisseur != null">
            <label for="noFournisseur">No pièce fournisseur</label>
            <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="prixFournisseur.numeroFournisseur"
                autocomplete="off" />
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3" *ngIf="prixFournisseur.fournisseur != null">
            <label for="prix">Prix</label>
            <p-inputNumber #champPrix [(ngModel)]="prixFournisseur.prix" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="2" [maxFractionDigits]="4"></p-inputNumber>
        </div>
	</div>


	<p-divider align="left">
		<div class="p-d-inline-flex p-ai-center">
			<b>Association d'unités</b>
		</div>
	</p-divider>

	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-md-3">
			<label for="lastname">Code de catégorie</label>
			<p-dropdown [options]="listeCategoriesEntretien" [(ngModel)]="selectionCategorieEntretien" [showClear]="true"
				optionLabel="description" [showClear]="true" filter="true" filterBy="code,description" appendTo="body"
				placeholder="Sélectionner une catégorie...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionCategorieEntretien">
						<div>{{selectionCategorieEntretien.code}} - {{selectionCategorieEntretien.description}}
						</div>
					</div>
				</ng-template>
				<ng-template let-categorie pTemplate="item">
					<div>
						<div>{{categorie.code}} - {{categorie.description}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
	</div>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12">
			<a class="lienFicheComplete" (click)="ajouterAssociations()">Ajouter des unités</a>
		</div>
	</div>
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12">
			<p-table #dt [value]="listeUnites" [columns]="selectedColumns" dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
			styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionItem"
			[contextMenu]="cm" [rowHover]="true" [rows]="10" [paginator]="true" [filterDelay]="0"
			[globalFilterFields]="['numero','description','statut','categorie','sousCategorie','marque','modele','noSerie','noPlaque']">
			
			<ng-template pTemplate="header" let-columns>
				<tr>

					<th pSortableColumn="numero">
						<div class="p-d-flex p-jc-between p-ai-center">
							Numéro
							<p-sortIcon field="numero"></p-sortIcon>
							<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
							</p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="description">
						<div class="p-d-flex p-jc-between p-ai-center">
							Description
							<p-sortIcon field="description"></p-sortIcon>
							<p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
							</p-columnFilter>
						</div>
					</th>
					<th >
						<div class="p-d-flex p-jc-between p-ai-center">
							Statut
							<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
								[showOperator]="false" [showAddButton]="false">
								<ng-template pTemplate="filter" let-value let-filter="filterCallback">
									<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
										(onChange)="filter($event.value)" optionLabel="name">
										<ng-template let-option pTemplate="item">
											<div class="p-multiselect-representative-option">

												<span class="p-ml-1">{{option.nom}}</span>
											</div>
										</ng-template>
									</p-multiSelect>
								</ng-template>
							</p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="modele">
						<div class="p-d-flex p-jc-between p-ai-center">
							Marque
							<p-sortIcon field="modele"></p-sortIcon>
							<p-columnFilter type="text" field="modele" display="menu" class="p-ml-auto">
							</p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="modele">
						<div class="p-d-flex p-jc-between p-ai-center">
							Modèle
							<p-sortIcon field="modele"></p-sortIcon>
							<p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
							</p-columnFilter>
						</div>
					</th>
					<th style="min-width: 4rem"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-item let-columns="columns">
				<tr [pContextMenuRow]="item">
					<td >{{item.numero}}
			 
					</td>
					<td>{{item.description}}</td>
					<td>
						<span [class]="'statut ' + item.statut.nom.toLowerCase()">
							{{item.statut.nom}}
						</span>
					</td>
					<td >
						{{item.marque}}
					</td>
					<td>
						{{item.modele}}
					</td>
					<td>
						<button pButton pRipple icon="pi pi-times"
							class="p-button-rounded p-button-danger p-button-sm p-mr-2"
							(click)="supprimerUnite(item)"></button>
					</td>
				</tr>
			</ng-template>
		</p-table>
		</div>
	</div>

</div>


<div class="p-text-right">
	<button pButton pRipple label="Créer" icon="pi pi-save" class="p-button-primary"
	(click)="enregistrer()"></button>
<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
	(click)="annuler()"></button>
	

</div>