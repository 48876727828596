import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { BonCommandeEntite } from '../entites/bon-commande';
import { Statut } from '../entites/statut';
import { CategorieMin, CategorieEntite } from '../entites/categorie';
import { SousCategorieEntite } from '../entites/sousCategorie';
import { Emplacement } from '../entites/emplacement';
import { AttributEntite } from '../entites/attribut';
import { EmployeEntite } from '../entites/employe';
import { EmplacementService } from './emplacement-service';
import { sharedStylesheetJitUrl } from '@angular/compiler';
import { RaisonArchive } from '../entites/raison-archive';
import { TypeGarantieEntite } from '../entites/garantie';
import { CodeProjetEntite } from '../entites/code-projet';
import { CompagnieEntite } from '../entites/compagnie';
import { DepartementEntite } from '../entites/departement';
import { UniteEntite } from '../entites/unite';
import { AuthService } from '@auth0/auth0-angular';
import { ProfilService } from './profil-service';
import { CodeCategorieEntite } from '../entites/code-categorie';
import { LocalisationEntrepotEntite } from '../entites/localisation-entrepot';
import { Local } from 'protractor/built/driverProviders';
import { TypeFacturationEntite } from '../entites/type-facturation';
import { TypeDocumentEntite } from '../entites/type-document';
import { TypeDocumentLieEntite } from '../entites/type-document-lie';
import { EStatuts } from '../entites/enums/statuts';
import { CodeExtraEntite } from '../entites/code-extra';
import { CodeEquipementEntite } from '../entites/code-equipement';
import { EmployesComponent } from '../pages/admin/employes.component';
import { AdresseEntite } from '../entites/adresse';
import { SeveriteEntite } from '../entites/mecanique/severite';
import { CodeVmrsEntite } from '../entites/mecanique/code-vmrs';
import { TypeEtapeBonTravailEntite } from '../entites/mecanique/type-etape-bon-travail';
import { CategorieEntretienEntite } from '../entites/mecanique/categorie-entretien';
import { RaisonBonTravailEntite } from '../entites/mecanique/raison-bon-travail';
import { DepartementMecaniqueEntite } from '../entites/mecanique/departement-mecanique';

export interface Auth0TokenResponse {
  access_token: string;
  scope: string;
  expires_in: number;
  token_type: string;
}

export interface Auth0Role {
  id: string;
  name: string;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class DonneesBaseService {

  private obtenirSousCategoriesUrl = environment.apiBaseUrl + 'donneesbase/souscategories';
  private obtenirCategoriesUrl = environment.apiBaseUrl + 'donneesbase/categories';
  private obtenirEmplacementsUrl = environment.apiBaseUrl + 'donneesbase/emplacements';
  private obtenirEmployesUrl = environment.apiBaseUrl + 'donneesbase/employes';
  private obtenirStatutsUrl = environment.apiBaseUrl + 'donneesbase/statuts';
  private obtenirRaisonsArchiveUrl = environment.apiBaseUrl + 'donneesbase/raisonsArchive';
  private obtenirTypesGarantieUrl = environment.apiBaseUrl + 'donneesbase/typesGarantie';
  private obtenirCodesProjetUrl = environment.apiBaseUrl + 'donneesbase/codesProjet';
  private obtenirCodesCategorieUrl = environment.apiBaseUrl + 'donneesbase/codesCategorie';
  private obtenirCodesEquipementUrl = environment.apiBaseUrl + 'donneesbase/codesEquipement';
  private obtenirCodesExtraUrl = environment.apiBaseUrl + 'donneesbase/codesExtra';
  private obtenirDescriptionsUrl = environment.apiBaseUrl + 'donneesbase/descriptions';
  private obtenirMarquessUrl = environment.apiBaseUrl + 'donneesbase/marques';
  private obtenirDepartementsUrl = environment.apiBaseUrl + 'donneesbase/departements';
  private obtenirCompagniesUrl = environment.apiBaseUrl + 'donneesbase/compagnies';
  private obtenirUnitesUrl = environment.apiBaseUrl + 'donneesbase/unites';
  private obtenirLocalisationsEntrepotUrl = environment.apiBaseUrl + 'donneesbase/localisationsentrepot';
  private obtenirTypesFacturationUrl = environment.apiBaseUrl + 'donneesbase/typesfacturation';
  private obtenirTypesDocumentUrl = environment.apiBaseUrl + 'donneesbase/typesdocument';
  private obtenirTypesDocumentLieUrl = environment.apiBaseUrl + 'donneesbase/typesdocumentlie';
  private obtenirAttributsUrl = environment.apiBaseUrl + 'donneesbase/attributs';
  
  private obtenirSeveritesUrl = environment.apiBaseUrl + 'donneesbase/severites';
  private obtenirCategoriesEntretienUrl = environment.apiBaseUrl + 'donneesbase/categoriesEntretien';
  private obtenirRaisonsBonTravailUrl = environment.apiBaseUrl + 'donneesbase/raisonsBonTravail';
  private obtenirTypesEtapeBonTravailUrl = environment.apiBaseUrl + 'donneesbase/typesEtapeBonTravail';
  private obtenirDepartementsMecaniqueUrl = environment.apiBaseUrl + 'donneesbase/departementsMecanique';

  private obtenirAdresseUrl = environment.apiBaseUrl + 'donneesbase/adresse/{numero}/{codePostal}';
  
  private calculerDelaiUrl = environment.apiBaseUrl + 'donneesbase/calculDelai/{dateDebut}/{qte}/{periode}';

  private statuts: Statut[] = null;
  private categories: CategorieEntite[] = null;
  private sousCategories: SousCategorieEntite[] = null;
  private emplacements: Emplacement[] = null;
  private employes: EmployeEntite[] = null;
  private raisonsArchive: RaisonArchive[] = null;
  private typesGarantie: TypeGarantieEntite[] = null;
  private codesProjet: CodeProjetEntite[] = null;
  private codesCategorie: CodeCategorieEntite[] = null;
  private codesEquipement: CodeEquipementEntite[] = null;
  private codesExtra: CodeExtraEntite[] = null;
  private descriptions: string[] = null;
  private marques: string[] = null;
  private departements: DepartementEntite[] = null;
  private compagnies: CompagnieEntite[] = null;
  private unites: UniteEntite[] = null;
  private localisationsEntrepot: LocalisationEntrepotEntite[] = null;
  private typesFacturation:TypeFacturationEntite[] = null;
  private typesDocument:TypeDocumentEntite[] = null;
  private typesDocumentLie:TypeDocumentLieEntite[] = null;
  private attributs:AttributEntite[] = null;
  private severites:SeveriteEntite[] = null;
  private categoriesEntretien:CategorieEntretienEntite[] = null;
  private raisonsBonTravail:RaisonBonTravailEntite[] = null;
  private typesEtapeBonTravail:TypeEtapeBonTravailEntite[] = null;
  private departementsMecanique:DepartementMecaniqueEntite[] = null;

  private chargementComplet: boolean = false;

  private enErreur: boolean = false;

  public httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200/' 
    })
  };

  constructor(
    private http: HttpClient,
    public router: Router,
    public auth: AuthService) { }

  public initialiser() {

    return new Promise((resolve, reject) => {

      console.log('SDB: Début initialisation');


      

      const p1 = this.initialiserBatch1();
      const p2 = this.initialiserBatch2();
      const p3 = this.initialiserBatch3();

      Promise.all([p1, p2, p3]).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });

    });



  }

  public initialiserBatch1() {

    return new Promise((resolve, reject) => {

      const p1 = this.http.get<ReponseBase<CategorieEntite[]>>(this.obtenirCategoriesUrl).toPromise();
      p1.then(x => {
        this.categories = x.valeur;
        console.log('SDB: Categories OK');
      }).catch(x =>  {
        this.handleError("Obtenir Catégories", x);
      });

      const p2 = this.http.get<ReponseBase<SousCategorieEntite[]>>(this.obtenirSousCategoriesUrl).toPromise();
      p2.then(x => {
        this.sousCategories = x.valeur;
        console.log('SDB: sous-catégories OK');
      }).catch(x =>  {
        this.handleError("Obtenir Sous catégories", x);
      });

      const p3 = this.http.get<ReponseBase<Emplacement[]>>(this.obtenirEmplacementsUrl).toPromise();
      p3.then(x => {
        this.emplacements = x.valeur;
        console.log('SDB: Emplacements OK');
      }).catch(x =>  {
        this.handleError("Obtenir Emplacements", x);
      });

      const p4 = this.http.get<ReponseBase<EmployeEntite[]>>(this.obtenirEmployesUrl).toPromise();
      p4.then(x => {
        this.employes = x.valeur;
        console.log('SDB: Employes OK');
      }).catch(x =>  {
        this.handleError("Obtenir Employés", x);
      });

      const p5 = this.http.get<ReponseBase<Statut[]>>(this.obtenirStatutsUrl).toPromise();
      p5.then(x => {
        this.statuts = x.valeur;
        console.log('SDB: Statuts OK');
      }).catch(x =>  {
        this.handleError("Obtenir Statuts", x);
      });

      const p6 = this.http.get<ReponseBase<RaisonArchive[]>>(this.obtenirRaisonsArchiveUrl).toPromise();
      p6.then(x => {
        this.raisonsArchive = x.valeur;
        console.log('SDB: Raisons Archive OK');
      }).catch(x =>  {
        this.handleError("Obtenir raisons archive", x);
      });

      const p7 = this.http.get<ReponseBase<TypeGarantieEntite[]>>(this.obtenirTypesGarantieUrl).toPromise();
      p7.then(x => {
        this.typesGarantie = x.valeur;
        console.log('SDB: Types garantie OK');
      }).catch(x =>  {
        this.handleError("Obtenir type garantie", x);
      });

      const p8 = this.http.get<ReponseBase<CodeProjetEntite[]>>(this.obtenirCodesProjetUrl).toPromise();
      p8.then(x => {
        this.codesProjet = x.valeur;
        console.log('SDB: Codes projet OK');
      }).catch(x =>  {
        this.handleError("Obtenir codes projet", x);
      });

      const p9 = this.http.get<ReponseBase<string[]>>(this.obtenirDescriptionsUrl).toPromise();
      p9.then(x => {
        this.descriptions = x.valeur;
        console.log('SDB: Descriptions OK');
      }).catch(x =>  {
        this.handleError("Obtenir descriptions", x);
      });

      const p10 = this.http.get<ReponseBase<string[]>>(this.obtenirMarquessUrl).toPromise();
      p10.then(x => {
        this.marques = x.valeur;
        console.log('SDB: MArques OK');
      }).catch(x =>  {
        this.handleError("Obtenir marques", x);
      });


      Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10]).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });

    });
  }

  public initialiserBatch2() {

    return new Promise((resolve, reject) => {

      const p11 = this.http.get<ReponseBase<DepartementEntite[]>>(this.obtenirDepartementsUrl).toPromise();
      p11.then(x => {
        this.departements = x.valeur;
        console.log('SDB: Departements OK');
      }).catch(x =>  {
        this.handleError("Obtenir Departements", x);
      });

      const p12 = this.http.get<ReponseBase<CompagnieEntite[]>>(this.obtenirCompagniesUrl).toPromise();
      p12.then(x => {
        this.compagnies = x.valeur;
        console.log('SDB: Compagnies OK');
      }).catch(x =>  {
        this.handleError("Obtenir Compagnies", x);
      });

      const p13 = this.http.get<ReponseBase<UniteEntite[]>>(this.obtenirUnitesUrl).toPromise();
      p13.then(x => {
        this.unites = x.valeur;
        console.log('SDB: Unites OK');
      }).catch(x =>  {
        this.handleError("Obtenir Unites", x);
      });

      const p14 = this.http.get<ReponseBase<CodeCategorieEntite[]>>(this.obtenirCodesCategorieUrl).toPromise();
      p14.then(x => {
        this.codesCategorie = x.valeur;
        console.log('SDB: Codes categorie OK');
      }).catch(x =>  {
        this.handleError("Obtenir codes categorie", x);
      });

      const p15 = this.http.get<ReponseBase<LocalisationEntrepotEntite[]>>(this.obtenirLocalisationsEntrepotUrl).toPromise();
      p15.then(x => {
        this.localisationsEntrepot = x.valeur;
        console.log('SDB: localisations OK');
      }).catch(x =>  {
        this.handleError("Obtenir localisations", x);
      });

      const p16 = this.http.get<ReponseBase<TypeFacturationEntite[]>>(this.obtenirTypesFacturationUrl).toPromise();
      p16.then(x => {
        this.typesFacturation = x.valeur;
        console.log('SDB: types facturation OK');
      }).catch(x =>  {
        this.handleError("Obtenir types facturation", x);
      });

      const p17 = this.http.get<ReponseBase<TypeDocumentEntite[]>>(this.obtenirTypesDocumentUrl).toPromise();
      p17.then(x => {
        this.typesDocument = x.valeur;
        console.log('SDB: types document OK');
      }).catch(x =>  {
        this.handleError("Obtenir types document", x);
      });

      const p18 = this.http.get<ReponseBase<AttributEntite[]>>(this.obtenirAttributsUrl).toPromise();
      p18.then(x => {
        this.attributs = x.valeur;
        console.log('SDB: attributs OK');
      }).catch(x =>  {
        this.handleError("Obtenir attributs", x);
      });

      const p19 = this.http.get<ReponseBase<TypeDocumentLieEntite[]>>(this.obtenirTypesDocumentLieUrl).toPromise();
      p19.then(x => {
        this.typesDocumentLie = x.valeur;
        console.log('SDB: types document lié OK');
      }).catch(x =>  {
        this.handleError("Obtenir types document lié", x);
      });

      Promise.all([p11, p12, p13, p14, p15, p16, p17, p18, p19]).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });

    });



  }

  public initialiserBatch3() {

    return new Promise((resolve, reject) => {

      const p21 = this.http.get<ReponseBase<CodeEquipementEntite[]>>(this.obtenirCodesEquipementUrl).toPromise();
      p21.then(x => {
        this.codesEquipement = x.valeur;
        console.log('SDB: Codes equipement OK');
      }).catch(x =>  {
        this.handleError("Obtenir codes equipement", x);
      });

      const p22 = this.http.get<ReponseBase<CodeExtraEntite[]>>(this.obtenirCodesExtraUrl).toPromise();
      p22.then(x => {
        this.codesExtra = x.valeur;
        console.log('SDB: Codes extra OK');
      }).catch(x =>  {
        this.handleError("Obtenir codes extra", x);
      });

      const p23 = this.http.get<ReponseBase<SeveriteEntite[]>>(this.obtenirSeveritesUrl).toPromise();
      p23.then(x => {
        this.severites = x.valeur;
        console.log('SDB: Sévérités OK');
      }).catch(x =>  {
        
        this.handleError("Obtenir Sévérités", x);
      });

      const p24 = this.http.get<ReponseBase<CategorieEntretienEntite[]>>(this.obtenirCategoriesEntretienUrl).toPromise();
      p24.then(x => {
        this.categoriesEntretien = x.valeur;
        console.log('SDB: Catégories entretien OK');
      }).catch(x =>  {
        this.handleError("Obtenir Catégories entretien", x);
      });

      const p25 = this.http.get<ReponseBase<RaisonBonTravailEntite[]>>(this.obtenirRaisonsBonTravailUrl).toPromise();
      p25.then(x => {
        this.raisonsBonTravail = x.valeur;
        console.log('SDB: Raisons bon travail OK');
      }).catch(x =>  {
        this.handleError("Obtenir Raisons bon travail", x);
      });

      const p26 = this.http.get<ReponseBase<TypeEtapeBonTravailEntite[]>>(this.obtenirTypesEtapeBonTravailUrl).toPromise();
      p26.then(x => {
        this.typesEtapeBonTravail = x.valeur;
        console.log('SDB: Types etape bon travail OK');
      }).catch(x =>  {
        this.handleError("Obtenir Types etape bon travail", x);
      });

      const p27 = this.http.get<ReponseBase<DepartementMecaniqueEntite[]>>(this.obtenirDepartementsMecaniqueUrl).toPromise();
      p27.then(x => {
        this.departementsMecanique = x.valeur;
        console.log('SDB: Departements mecanique OK');
      }).catch(x =>  {
        this.handleError("Obtenir Departements mecanique", x);
      });

      Promise.all([p21, p22, p23, p24, p25, p26, p27]).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });

    });



  }

  public ReinitialiserEmplacements(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.http.get<ReponseBase<Emplacement[]>>(this.obtenirEmplacementsUrl).toPromise().then(x => {
        this.emplacements = x.valeur;
        console.log('SDB: Emplacements OK');
        resolve(true);
      });
    });
    
  }

  public ReinitialiserCategories(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get<ReponseBase<CategorieEntite[]>>(this.obtenirCategoriesUrl).toPromise().then(x => {
        this.categories = x.valeur;
        resolve(true);
      });
    });
  }

  public ReinitialiserSousCategories(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get<ReponseBase<SousCategorieEntite[]>>(this.obtenirSousCategoriesUrl).toPromise().then(x => {
        this.sousCategories = x.valeur;
        resolve(true);
      });
    });
  }

  public ReinitialiserEmployes(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get<ReponseBase<EmployeEntite[]>>(this.obtenirEmployesUrl).toPromise().then(x => {
        this.employes = x.valeur;
        resolve(true);
      });
    });
  }

  /* public AttendreChargementComplet(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {
      if (this.categories == null ||
        this.sousCategories == null ||
        this.emplacements == null ||
        this.employes == null ||
        this.statuts == null) {
        this.initialiser();
      }

      while (this.categories == null ||
        this.sousCategories == null ||
        this.emplacements == null ||
        this.employes == null ||
        this.statuts == null) {
        console.log('waiting');
        setTimeout(() => { }, 1000);

      }

      resolve(true);
    });

  } */

  public Statuts(): Statut[] {
    return this.statuts;
  }

  public Statut(id: number): Statut {
    return this.statuts.find(x => x.statutId == id);
  }

  public StatutsCommande(): Statut[] {
    return [
      { statutId: 27, nom: 'À traiter' },
      { statutId: 5, nom: 'En attente' },
      { statutId: 9, nom: 'Prêt' },
      { statutId: 11, nom: 'Chargé' }
    ];;
  }

  public StatutsPreCommande(): Statut[] {
    return [
      { statutId: 18, nom: 'En cours' },
      { statutId: 28, nom: 'En approbation' },
      { statutId: 15, nom: 'En traitement' }
    ];;
  }


  public Categories(): CategorieEntite[] {
    return this.categories;
  }

  public Categorie(id: number): CategorieEntite {
    return this.categories.find(x => x.categorieId == id);
  }

  public SousCategories(): SousCategorieEntite[] {
    return this.sousCategories;
  }

  public Emplacements(): Emplacement[] {

    return this.emplacements.filter(x => x.statut.statutId != EStatuts.Archive);

  }

  public EmplacementsComplet(): Emplacement[] {

    return this.emplacements;

  }

  public Emplacement(id: number): Emplacement{

    return this.emplacements.find(x => x.emplacementId == id);

  }

  /* public Emplacements2(): Promise<Emplacement[]> {


    return new Promise<Emplacement[]>((resolve) => {
      if (this.emplacements != null) {
        resolve(this.emplacements);
      } else {
        this.http.get<ReponseBase<Emplacement[]>>(this.obtenirEmplacementsUrl).toPromise().then(x => {
          this.emplacements = x.valeur;
          resolve(this.emplacements);
        });
      }
    });

  } */

  public Employes(): EmployeEntite[] {
    return this.employes;
  }

  public EmployesMecanique(): EmployeEntite[] {
    return this.employes.filter(x => x.indMecanique);
  }

  public RaisonsArchive(): RaisonArchive[] {
    return this.raisonsArchive;
  }

  public RaisonArchive(id: number): RaisonArchive {
    return this.raisonsArchive.find(x => x.raisonArchiveId == id);
  }

  public TypesGarantie(): TypeGarantieEntite[] {
    return this.typesGarantie;
  }

  public TypeGarantie(id: number): TypeGarantieEntite {
    return this.typesGarantie.find(x => x.typeGarantieId == id);
  }

  public CodesProjet(): CodeProjetEntite[] {
    return this.codesProjet;
  }

  public CodeProjet(id: number): CodeProjetEntite {
    return this.codesProjet.find(x => x.codeProjetId == id);
  }

  public CodesCategorie(): CodeCategorieEntite[] {
    return this.codesCategorie;
  }

  public CodeCategorie(id: number): CodeCategorieEntite {
    return this.codesCategorie.find(x => x.codeCategorieId == id);
  }

  public CodesEquipement(): CodeEquipementEntite[] {
    return this.codesEquipement;
  }

  public CodeEquipement(id: number): CodeEquipementEntite {
    return this.codesEquipement.find(x => x.codeEquipementId == id);
  }

  public CodesExtra(): CodeExtraEntite[] {
    return this.codesExtra;
  }

  public CodeExtra(id: number): CodeExtraEntite {
    return this.codesExtra.find(x => x.codeExtraId == id);
  }

  public Descriptions(): string[] {
    return this.descriptions;
  }

  public Marques(): string[] {
    return this.marques;
  }

  public Departements(): DepartementEntite[] {
    return this.departements;
  }

  public Departement(id: number): DepartementEntite {
    return this.departements.find(x => x.departementId == id);
  }

  public Compagnies(): CompagnieEntite[] {
    return this.compagnies;
  }

  public Compagnie(id: number): CompagnieEntite {
    return this.compagnies.find(x => x.compagnieId == id);
  }

  public Unites(): UniteEntite[] {
    return this.unites;
  }

  public Unite(id: number): UniteEntite {
    return this.unites.find(x => x.uniteId == id);
  }

  public UniteParCode(code: string): UniteEntite {
    return this.unites.find(x => x.code == code);
  }

  public LocalisationsEntrepot(): LocalisationEntrepotEntite[] {
    return this.localisationsEntrepot;
  }

  public LocalisationEntrepot(id: number): LocalisationEntrepotEntite {
    return this.localisationsEntrepot.find(x => x.localisationEntrepotEntiteId == id);
  }

  public TypesFacturation(): TypeFacturationEntite[] {
    return this.typesFacturation;
  }

  public TypeFacturation(id: number): TypeFacturationEntite {
    return this.typesFacturation.find(x => x.typeFacturationId == id);
  }

  public TypesDocument(): TypeDocumentEntite[] {
    return this.typesDocument;
  }

  public TypeDocument(id: number): TypeDocumentEntite {
    return this.typesDocument.find(x => x.typeDocumentId == id);
  }


  public TypesDocumentLie(): TypeDocumentLieEntite[] {
    return this.typesDocumentLie;
  }

  public TypeDocumentLie(id: number): TypeDocumentLieEntite {
    return this.typesDocumentLie.find(x => x.typeDocumentLieId == id);
  }


  public Attributs(): AttributEntite[] {
    return this.attributs;
  }

  public Attribut(id: number): AttributEntite {
    return this.attributs.find(x => x.attributId == id);
  }

  public Severites(): SeveriteEntite[] {
    return this.severites;
  }

  public Severite(id: number): SeveriteEntite {
    return this.severites.find(x => x.severiteId == id);
  }
  
  public CategoriesEntretien(): CategorieEntretienEntite[] {
    return this.categoriesEntretien;
  }

  public CategorieEntretien(id: number): CategorieEntretienEntite {
    return this.categoriesEntretien.find(x => x.categorieEntretienId == id);
  }
  
  public RaisonsBonTravail(): RaisonBonTravailEntite[] {
    return this.raisonsBonTravail;
  }

  public RaisonBonTravail(id: number): RaisonBonTravailEntite {
    return this.raisonsBonTravail.find(x => x.raisonBonTravailId == id);
  }
  
  public TypesEtapeBonTravail(): TypeEtapeBonTravailEntite[] {
    return this.typesEtapeBonTravail;
  }

  public TypeEtapeBonTravail(id: number): TypeEtapeBonTravailEntite {
    return this.typesEtapeBonTravail.find(x => x.typeEtapeBonTravailId == id);
  }
  
  public DepartementsMecanique(): DepartementMecaniqueEntite[] {
    return this.departementsMecanique;
  }

  public DepartementMecanique(id: number): DepartementMecaniqueEntite {
    return this.departementsMecanique.find(x => x.departementMecaniqueId == id);
  }



  // public CodesVmrs(): CodeVmrsEntite[] {
  //   return [
  //     {code: '013', nom: 'Freins'},
  //     {code: '015', nom: 'Direction'},
  //     {code: '016', nom: 'Suspension'},
  //     {code: '026', nom: 'Transmission'},
  //     {code: '030', nom: 'Groupe électrique'},
  //     {code: '040', nom: 'Moteur'},
  //     {code: '043', nom: 'Système d\'échappement'}
  //   ];
  // }

  // public CodeVmrs(code: string): CodeVmrsEntite {
  //   return { code: '013', nom: 'Freins'};
  // }

 
  public CalculerDelai(dateDebut: Date, qte: number, periode: string): Promise<ReponseBase<Date>> {

    var p = this.http.get<ReponseBase<Date>>(this.calculerDelaiUrl.replace('{dateDebut}', dateDebut.toISOString()).replace('{qte}', qte.toString()).replace('{periode}', periode))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  public ObtenirAdresse(noCivique: string, codePostal: string): Promise<ReponseBase<AdresseEntite>> {

    var p = this.http.get<ReponseBase<AdresseEntite>>(this.obtenirAdresseUrl.replace('{numero}', noCivique).replace('{codePostal}', codePostal))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }





  private handleError(operation: string, error: string) {

      this.log(`${operation} failed: ${error}`);

      this.router.navigateByUrl('error');
  }

  private log(message: string) {
    console.log(message);
  }
}
