<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>


<div *ngIf="EstCharge() && !estConfirme" class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<p-menubar [model]="menu"></p-menubar>
		</div>
	</div>
</div>

<div class="p-grid" *ngIf="EstCharge() && !estConfirme">

    <div class="p-col-12">
		<div class="card">
            <!-- <div class="p-fluid p-formgrid p-grid">
				<div class="p-field p-col-12 p-md-6 p-lg-3">
					
				</div>
            </div> -->
            <div class="p-fluid p-formgrid p-grid" *ngIf="courrielManquant">
				<div class="p-field p-col-12">
                    <!-- <h5><i class="pi pi-exclamation-triangle " style="color:#dd8500; font-size: 1.4rem"></i> Certains fournisseurs n'ont pas de contact principal ayant un courriel valide. Vous ne pouvez pas envoyer le courriel d'invitation à ces fournisseurs.</h5> -->
                    <p-message severity="warn" text="ATTENTION : Certains fournisseurs n'ont pas de contact principal ayant un courriel valide. Vous ne pouvez pas envoyer le courriel d'invitation à ces fournisseurs."></p-message> 
				</div>
            </div>
            <p-table #dt [value]="soumissions" [columns]="selectedColumns" dataKey="soumissionId" autoLayout="true" tableStyleClass="tableNormale"
            [(selection)]="selectionSoumission"
            (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
					(onHeaderCheckboxToggle)="surChangementSelection()"
                styleClass="p-datatable-sm p-datatable-striped" 
                [rowHover]="true"  [filterDelay]="0"
                [globalFilterFields]="['fournisseur.nom','fournisseur.numero']">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-ai-center p-jc-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <h5 class="titreTableau p-m-0">Fournisseurs</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="min-width: 3rem">
                            <p-tableHeaderCheckbox [disabled]="courrielManquant"></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="fournisseur.numero">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Numéro
                                <p-sortIcon field="fournisseur.numero"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.numero" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="fournisseur.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Fournisseur
                                <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="courriel">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Courriel principal
                                <p-sortIcon field="courriel"></p-sortIcon>
                                <p-columnFilter type="text" field="courriel" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateDebut">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de début
                                <p-sortIcon field="dateDebut"></p-sortIcon>
                                <p-columnFilter type="text" field="dateDebut" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateFin">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date de fin
                                <p-sortIcon field="dateFin"></p-sortIcon>
                                <p-columnFilter type="date" field="dateFin" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateDebutSaisie">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date début saisie
                                <p-sortIcon field="dateDebutSaisie"></p-sortIcon>
                                <p-columnFilter type="date" field="dateDebutSaisie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateFinSaisie">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Date Fin Saisie
                                <p-sortIcon field="dateFinSaisie"></p-sortIcon>
                                <p-columnFilter type="date" field="dateFinSaisie" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-soumission let-columns="columns">
                    <tr>
                        <td>
                            <p-tableCheckbox [disabled]="!soumission.fournisseur.contactPrincipal?.courriel" [value]="soumission"></p-tableCheckbox>
                        </td>
                        <td>{{soumission.fournisseur.numero}}</td>
                        <td>{{soumission.fournisseur.nom}}</td>
                        <td>{{soumission.fournisseur.contactPrincipal?.courriel}}</td>
                        <td><span [class]="'statut ' + soumission.statut.nom.toLowerCase().replace(' ', '')">
                            {{soumission.statut.nom}}
                        </span></td>
                        <td>{{soumission.dateDebut | date:'yyyy-MM-dd'}}</td>
                        <td>{{soumission.dateFin | date:'yyyy-MM-dd'}}</td>
                        <td>{{soumission.dateDebutSaisie | date:'yyyy-MM-dd'}}</td>
                        <td>{{soumission.dateFinSaisie | date:'yyyy-MM-dd'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


            


</div>

<div *ngIf="EstCharge() && estConfirme" class="p-grid">
	<div class="p-col-12">
		<div class="card">
            <h5>{{this.nbCourrielEnvoye}} courriels envoyés.</h5>
            <br>
            <button pButton pRipple label="Voir les soumissions" icon="pi pi-reply" class="p-button-text"
                (click)="naviguerSoumissions()"></button>
		</div>
	</div>
</div>