import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Table } from 'primeng/table';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { ListeVerificationEntite } from 'src/app/entites/mecanique/liste-verification';
import { ItemListeVerificationEntite } from 'src/app/entites/mecanique/item-liste-verification';
import { BaseComponent } from 'src/app/base/base-component';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ItemService } from 'src/app/services/item-service';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';
import { SaisieNoteComponent } from 'src/app/controles/saisie-note.component';
import { DetailItemListeVerificationComponent } from './controles/detail-item-liste-verification.component';
import { DupliquerListeVerificationComponent } from './controles/dupliquer-liste-verification.component';

@Component({
    templateUrl: './detail-liste-verification.component.html',
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailListeVerificationComponent extends BaseComponent implements OnInit {

    listeVerificationId: number;
    listeVerification: ListeVerificationEntite;
    selectionMultipleEntree: ItemListeVerificationEntite[] = [];

    menuContextuelEntrees: MenuItem[];

    ref: DynamicDialogRef;

    menu: MenuItem[];

    @ViewChild('descriptionItem') champDescription: ElementRef;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private listeVerificationService: ListeVerificationService,
        private ItemsService: ItemService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Détail de la liste de vérification' }
        ]);

        this.route.params.subscribe(params => this.listeVerificationId = params.id);
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutItemActive) {
        //     this.surAjoutItem();
        // }
    }

    ngOnInit() {

        this.listeVerificationService.ObtenirListe(this.listeVerificationId).then(x => {

            this.listeVerification = x.valeur;

            this.initialiserMenu();

            this.CompleterChargement()
        });

    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', 
                icon: 'pi pi-fw pi-angle-double-left', 
                disabled: !this.modeConsultation(),
                command: () => this.routerService.navigateByUrl('mecanique/pilotage/listesverification')
            },
            {
                label: 'Imprimer', 
                icon: 'pi pi-fw pi-print', 
                disabled: !this.modeConsultation(),
                command: () => this.imprimer()
            },
            {
                label: 'Actions', 
                icon: 'pi pi-fw pi-bars',
                disabled: !this.modeConsultation(),
                items: [
                    {
                        label: 'Dupliquer la liste', icon: 'pi pi-fw pi-copy', 
                        command: () => this.dupliquer()
                    },
                    {
                        label: 'Supprimer la liste', icon: 'pi pi-fw pi-times', 
                        command: () => this.supprimermodeleListeAchat()
                    }
                ]
            },
            {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars', 
                disabled: !this.modeConsultation() || this.selectionMultipleEntree.length == 0,
                items: [
                    {
                        label: 'Supprimer les items sélectionnés', icon: 'pi pi-fw pi-times', command: () => this.supprimerItems(this.selectionMultipleEntree)
                    },
                ]
            },
        ];
    }

    btnModifier_click() {
        this.Modifier();
        this.initialiserMenu();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.listeVerificationService.ObtenirListe(this.listeVerificationId).then(x => {

            this.listeVerification = x.valeur;

            this.initialiserMenu();

            this.CompleterChargement();

            this.Consulter();
        });
    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (!this.validerModification()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
        }

        this.Bloquer();

        this.listeVerificationService.ModifierListe(this.listeVerification).then(x => {
            this.Debloquer();
            if (x.estUnSucces) {
                this.Consulter();
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Liste modifiée.' });
            } else {
                x.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });

            }

        });

    
        

        /* if (this.validerModificationBdc()) {
            this.DemarrerChargement();
            this.achatService.ModifierBonTravail(this.bonTravail).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de commande modifié.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        } */
    }

    validerModification(): boolean {

         if (!this.listeVerification.nom) {
             return false;
         }
 
 

        return true;
    }


    surMenuContextuelItem(event, item: ItemListeVerificationEntite) {

        this.menuContextuelEntrees = [
            { label: 'Supprimer', icon: 'pi pi-fw pi-times', command: () => this.supprimerItems([item]) }
        ];


        this.menuContextuelItemCommande.toggle(event);
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });
    }

    dupliquer() {
        
        const ref = this.dialogService.open(DupliquerListeVerificationComponent, {
            data: this.listeVerification.listeVerificationId,
            header: 'Dupliquer une liste de vérification',
            width: '60%'
        }).onClose.subscribe((id: number) => {
            if (id) {

                this.naviguer(`mecanique/pilotage/listeverification/${id}`)

            }
        });

        

        
    }

    supprimermodeleListeAchat() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le modèle courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                this.listeVerificationService.SupprimerListe(this.listeVerification.listeVerificationId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('mecanique/pilotage/listesVerification');
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Liste supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    supprimerItems(items: ItemListeVerificationEntite[]) {
        var ids = items.map(b => b.itemListeVerificationId);
        this.confirmationService.confirm({
            target: event.target,
            message: items.length == 1 ? 'Voulez-vous vraiment supprimer l\'item sélectionné ?' : 'Voulez-vous vraiment supprimer les items sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.listeVerificationService.SupprimerItemsListe(ids).then(res => {
                    if (res.estUnSucces) {
                        this.listeVerification.items = this.listeVerification.items.filter((ibc: ItemListeVerificationEntite) => !ids.includes(ibc.itemListeVerificationId))
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: items.length == 1 ? 'Item supprimé.' : 'Items supprimés' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    estFenetreAjoutItemActive: boolean = false;
    surAjoutItem() {
//        this.estFenetreAjoutItemActive = true;

        const ref = this.dialogService.open(DetailItemListeVerificationComponent, {
            data: {
            },
            header: 'Ajouter un item',
            width: '80%'
        }).onClose.subscribe((res: ItemListeVerificationEntite) => {
            if (res) {

                this.Bloquer();

                res.listeVerificationId = this.listeVerification.listeVerificationId;

                this.listeVerificationService.CreerItemListe(res).then(res => {
                    this.Debloquer();
                    this.estFenetreAjoutItemActive = false;
                    if (res.estUnSucces) {


                        if (!this.listeVerification.items) {
                            this.listeVerification.items = [];
                        }

                        this.listeVerification.items.push(res.valeur);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item a été ajouté.` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })

            }
        });

    }

    surChangementSelection() {
        this.initialiserMenu();
    }


    descendreEtape(event, item: ItemListeVerificationEntite)
    {
        var index = this.listeVerification.items.indexOf(item);

        this.listeVerification.items.splice(index,1);
        this.listeVerification.items.splice(index+1,0,item);

        this.initialiserNoEtapes();
    }

    
    monterEtape(event, item: ItemListeVerificationEntite)
    {
        var index = this.listeVerification.items.indexOf(item);

        this.listeVerification.items.splice(index,1);
        this.listeVerification.items.splice(index-1,0,item);

        this.initialiserNoEtapes();
    }

    descendreEtapeComplet(event, item: ItemListeVerificationEntite)
    {
        var index = this.listeVerification.items.indexOf(item);

        this.listeVerification.items.splice(index,1);
        this.listeVerification.items.push(item);

        this.initialiserNoEtapes();
    }

    
    monterEtapeComplet(event, item: ItemListeVerificationEntite)
    {
        var index = this.listeVerification.items.indexOf(item);

        this.listeVerification.items.splice(index,1);
        this.listeVerification.items.unshift(item);

        this.initialiserNoEtapes();
    }

    initialiserNoEtapes() {

        var ordre: number = 1;
        this.listeVerification.items.forEach(i => {
            i.ordre = ordre;
            ordre = ordre + 1;
        });

    }
}
