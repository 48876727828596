<p-toast key="assignerEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	<div class="p-fluid p-formgrid p-grid">
		<div class="p-col-12 p-md-9 p-lg-6 p-mb-3">
			<p-selectButton [options]="types" [(ngModel)]="selectionType" optionLabel="valeur">
			</p-selectButton>
		</div>

	</div>

	<div *ngIf="selectionType.cle == 'E'" class="p-fluid p-formgrid p-grid">
		<div class="p-col-12">
			<p-table #dt [value]="bonsTravail" dataKey="bonTravailId" autoLayout="true" tableStyleClass="tableNormale"
				styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
				[globalFilterFields]="['numero','date']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-ai-center p-jc-between">
						<div>
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
									placeholder="Recherche..." />
							</span>
						</div>
						<h5 class="titreTableau p-m-0">Bons de travail</h5>
						<div>
							<p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes...">
							</p-multiSelect>
						</div>
					</div>
				</ng-template>

				<ng-template pTemplate="header" let-columns>
					<tr>
						<th pSortableColumn="numero">
							<div class="p-d-flex p-jc-between p-ai-center">
								Numéro
								<p-sortIcon field="numero"></p-sortIcon>
								<p-columnFilter type="text" field="numero" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="dateDebut">
							<div class="p-d-flex p-jc-between p-ai-center">
								Date
								<p-sortIcon field="dateDebut"></p-sortIcon>
								<p-columnFilter type="date" field="dateDebut" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.numero">
							<div class="p-d-flex p-jc-between p-ai-center">
								Unité
								<p-sortIcon field="item.numero"></p-sortIcon>
								<p-columnFilter type="text" field="item.numero" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.description">
							<div class="p-d-flex p-jc-between p-ai-center">
								Description
								<p-sortIcon field="item.description"></p-sortIcon>
								<p-columnFilter type="text" field="item.description" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="type">
							<div class="p-d-flex p-jc-between p-ai-center">
								Statut
								<p-sortIcon field="type"></p-sortIcon>
								<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
									[showOperator]="false" [showAddButton]="false">
									<ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
											(onChange)="filter($event.value)" optionLabel="name">
											<ng-template let-option pTemplate="item">
												<div class="p-multiselect-representative-option">

													<span class="p-ml-1">{{option.nom}}</span>
												</div>
											</ng-template>
										</p-multiSelect>
									</ng-template>
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="contreMaitre.prenom">
							<div class="p-d-flex p-jc-between p-ai-center">
								Contre-Maître
								<p-sortIcon field="contreMaitre.prenom"></p-sortIcon>
								<p-columnFilter type="text" field="contreMaitre.prenom" display="menu"
									class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="mecanicien.prenom">
							<div class="p-d-flex p-jc-between p-ai-center">
								Mécanicien
								<p-sortIcon field="mecanicien.prenom"></p-sortIcon>
								<p-columnFilter type="text" field="mecanicien.prenom" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th style="min-width: 7rem; width: 7rem;"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-bdt let-columns="columns">
					<tr>
						<td>{{bdt.numero}}</td>
						<td>{{bdt.dateTravail | date:'yyyy-MM-dd'}}</td>
						<td>{{bdt.item.numero}}</td>
						<td>{{bdt.item.description}}</td>
						<td><span [class]="'statut ' + bdt.statut.nom.toLowerCase().replace(' ', '')">
								{{bdt.statut.nom}}
							</span></td>
						<td>{{bdt.contreMaitre | nomEmploye}}</td>
						<td>{{bdt.mecanicien | nomEmploye}}</td>
						<td class="celluleTableau">
							<button *ngIf="estAutorise('temp_mec')" pButton pRipple icon="pi pi-check"
								class="p-button-success p-mr-2 boutonGrille"
								(click)="selectionnerBonTravail(bdt)"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="6">Aucun bon de travail.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>

	</div>

	<div class="p-fluid p-formgrid p-grid" *ngIf="selectionType.cle == 'N'">
		<div class="p-col-12 p-md-9 p-lg-6 p-mb-3">
			<p-selectButton [options]="typesBonTravail" [(ngModel)]="selectionTypeBonTravail" optionLabel="valeur">
			</p-selectButton>
		</div>

	</div>

	<div *ngIf="selectionType.cle == 'N'" class="p-fluid p-formgrid p-grid">
		

	

		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="date">Date</label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="date" dateFormat="yy-mm-dd"></p-calendar>
		</div>
		
		<div class="p-field p-col-12 p-md-6 p-lg-4" >
			<label for="numero">Contre-maître</label>
			<p-dropdown #champDemandeur [options]="employes" [(ngModel)]="selectionContreMaitre" optionLabel="nom"
				[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
				placeholder="Sélectionner un contre-maitre...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionContreMaitre">
						<div>{{selectionContreMaitre.prenom}} {{selectionContreMaitre.nom}}</div>
					</div>
				</ng-template>
				<ng-template let-employe pTemplate="item">
					<div>
						<div>{{employe.prenom}} {{employe.nom}}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<small class="p-error" *ngIf="submitted && !selectionContreMaitre">Le contre-maitre est obligatoire.</small>
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-4" *ngIf="selectionTypeBonTravail.cle == 1">
			<label for="numero">Mécanicien</label>
			<p-dropdown #champDemandeur [options]="employes" [(ngModel)]="selectionMecanicien" optionLabel="nom"
				[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
				placeholder="Sélectionner un mécanicien...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionMecanicien">
						<div>{{selectionMecanicien.prenom}} {{selectionMecanicien.nom}}</div>
					</div>
				</ng-template>
				<ng-template let-employe pTemplate="item">
					<div>
						<div>{{employe.prenom}} {{employe.nom}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div class="p-field p-col-12 p-md-6 lg-4" *ngIf="selectionTypeBonTravail.cle == 2">
			<label for="lastname">Fournisseur</label>
			<p-dropdown [options]="fournisseurs" [(ngModel)]="selectionFournisseur" optionLabel="nom" 
				[showClear]="!modeConsultation()" filter="true" filterBy="numero,nom" appendTo="body"
				placeholder="Sélectionner un fournisseur...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionFournisseur">
						<div>{{selectionFournisseur.nom}} ({{selectionFournisseur.numero}})</div>
					</div>
				</ng-template>
				<ng-template let-fournisseur pTemplate="item">
					<div>
						<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		
		<div class="p-field p-col-12 p-md-3">
			<label for="contreMaitre">Emplacement</label>
			<p-dropdown [options]="emplacements" [(ngModel)]="selectionEmplacement" optionLabel="nom"
				[showClear]="true" filter="true" filterBy="nom" appendTo="body"
				placeholder="Sélectionner un emplacement...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionEmplacement">
						<div>{{selectionEmplacement.nom}}</div>
					</div>
				</ng-template>
				<ng-template let-emplacement pTemplate="item">
					<div>
						<div>{{emplacement.nom}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		
	</div>

	<div *ngIf="selectionType.cle == 'N'" class="p-text-right">

		<button pButton pRipple label="Assigner" icon="pi pi-save" class="p-button-primary"
			(click)="assigner()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

	</div>

</div>