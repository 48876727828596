import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from '../../services/liste-achat-service';

import {StepsModule} from 'primeng/steps';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './saisir-liste-achat.component.html',
    styleUrls: ['./saisir-liste-achat.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class SaisirListeAchatComponent extends BaseComponent implements OnInit {

    items: MenuItem[];

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private listeAchatService: ListeAchatService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Saisir une liste d\'achat' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('liste_achat');

        this.listeAchatService.etape = 1;

        this.items = [{
            label: 'Liste',
            routerLink: 'etape1'
        },
        {
            label: 'Items',
            routerLink: 'etape2'
        },
        {
            label: 'Notes',
            routerLink: 'etape3'
        },
        {
            label: 'Confirmation',
            routerLink: 'etape4'
        }
    ];

       
    }

}
