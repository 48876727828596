import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { SoumissionEntite } from 'src/app/entites/soumission';
import { SoumissionService } from 'src/app/services/soumission-service';

@Component({
    templateUrl: './envoi-courriel.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class EnvoiCourrielComponent extends BaseComponent implements OnInit {

    menu: MenuItem[];

    soumissionId: number;

    soumissions: SoumissionEntite[];
    selectionSoumission: SoumissionEntite[];

    submitted: boolean = false;

    estConfirme: boolean = false;
    nbCourrielEnvoye: number;

    courrielManquant: boolean = false;

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private soumissionService: SoumissionService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Envoi massif des courriel d\'invitation' }
        ]);

        this.route.params.subscribe(x => {
            console.log(x.soumissionId);
            if (x.soumissionId) {
                this.soumissionId = x.soumissionId;
            }

        });
    }

    ngOnInit() {

        this.verifierAccesPage('soumission_action_envoi_massif');

        if (this.soumissionId) {
            this.soumissionService.ObtenirSoumission(this.soumissionId).then(x => {

                this.soumissions = [x.valeur];
                this.selectionSoumission = this.soumissions.filter(x => x.fournisseur.contactPrincipal?.courriel);
                this.courrielManquant = this.selectionSoumission.length != this.soumissions.length;
                this.initialiserMenu();
                this.CompleterChargement();
            });
        } else {
            console.log('C');
            this.soumissionService.ObtenirSoumissions(EStatuts.EnPreparation).then(x => {

                this.soumissions = x.valeur;
                this.selectionSoumission = this.soumissions.filter(x => x.fournisseur.contactPrincipal?.courriel);
                this.courrielManquant = this.selectionSoumission.length != this.soumissions.length;
                this.initialiserMenu();
                this.CompleterChargement();
            });
        }

    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.retour()
            },
            {
                label: 'Confirmer l\'envoi des courriels d\'invitation', 
                icon: 'pi pi-fw pi-send', 
                disabled: this.selectionSoumission.length == 0,
                command: () => this.confirmer()
            }
        ];
    }

    retour() {
        if (this.soumissionId) {
            this.routerService.navigateByUrl('soumission/' + this.soumissionId);
        } else {
            this.routerService.navigateByUrl('soumissions');
        }
    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    confirmer() {
        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment envoyer les courriels d\'invitation ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();

                this.soumissionService.EnvoyerCourriels({
                    listeSoumissionId: this.selectionSoumission.map(x => x.soumissionId)
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.nbCourrielEnvoye = res.valeur;
                        this.estConfirme = true;
                        
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });

        
    }

    valider(): boolean {
            
        return true;
    }

    naviguerSoumissions() {
        this.routerService.navigateByUrl('soumissions');
    }
}
