<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12">
			<p-table #dt [value]="entretiens" tableStyleClass="tableNormale" autoLayout="true"
				dataKey="entretienPreventifId"
				[rows]="100" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100,500]" [filterDelay]="0"
				[globalFilterFields]="['nom','description','item.numero','item.description','item.marque','item.modele']">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
						<span class="p-input-icon-left">
							<i class="pi pi-search"></i>
							<input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
								placeholder="Recherche..." />
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th pSortableColumn="item.numero">
							<div class="p-d-flex p-jc-between p-ai-center">
								Numéro
								<p-sortIcon field="item.numero"></p-sortIcon>
								<p-columnFilter type="text" field="item.numero" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.description">
							<div class="p-d-flex p-jc-between p-ai-center">
								Description
								<p-sortIcon field="item.description"></p-sortIcon>
								<p-columnFilter type="text" field="item.description" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.marque">
							<div class="p-d-flex p-jc-between p-ai-center">
								Marque
								<p-sortIcon field="item.marque"></p-sortIcon>
								<p-columnFilter type="text" field="item.marque" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.modele">
							<div class="p-d-flex p-jc-between p-ai-center">
								Modèle
								<p-sortIcon field="item.modele"></p-sortIcon>
								<p-columnFilter type="text" field="item.modele" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="description">
							<div class="p-d-flex p-jc-between p-ai-center">
								Nom
								<p-sortIcon field="description"></p-sortIcon>
								<p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
								</p-columnFilter>
							</div>
						</th>
						
						<th style="min-width: 5rem;"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-entretien>
					<tr>
						<td>{{entretien.item.numero}}</td>
						<td>{{entretien.item.description}}</td>
						<td>{{entretien.item.marque}}</td>
						<td>{{entretien.item.modele}}</td>
						<td>{{entretien.description}}</td>
						
						<td>
							<button pButton pRipple icon="pi pi-check" class="p-button-success p-mr-2 boutonGrille"
								(click)="selectionner(entretien)"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="6">Aucun entretien.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>

	</div>
	<div class="p-grid">
		<div class="p-col-12 p-text-right ">
			<button pButton pRipple label="Fermer" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
		</div>
	</div>
</div>