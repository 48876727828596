import { Component, OnInit, ViewChild, Input, ɵConsole } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { TypeLocationEntite } from 'src/app/entites/type-location';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { LocationService } from 'src/app/services/location-service';
import { UniteEntite } from 'src/app/entites/unite';
import { CoutLocationEntite } from 'src/app/entites/cout-location';
import { ETypesLocation } from 'src/app/entites/enums/type-location';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { DocumentEntite } from 'src/app/entites/document';
import { DocumentService } from 'src/app/services/document-service';
import { environment } from 'src/environments/environment';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { ContexteService } from 'src/app/services/contexte-service';
import { BaseComponent } from 'src/app/base/base-component';
import { Emplacement } from 'src/app/entites/emplacement';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { ConfirmerDebutLocationComponent, ResultatConfirmerDebutLocation } from '../../locations/controles/confirmer-debut-location.component';
import { InfoBonLocationComponent, ResultatInfoBonLocation } from '../../locations/controles/info-bon-location.component';
import { ConfirmerFinLocationComponent } from '../../locations/controles/confirmer-fin-location.component';
import { DetailDocumentComponent } from '../../documents/controles/detail-document.component';
import { LigneHeureEntite } from 'src/app/entites/mecanique/ligne-heure';
import { DepartementEntite } from 'src/app/entites/departement';
import { HeureService } from 'src/app/services/heure-service';
import { DepartementMecaniqueEntite } from 'src/app/entites/mecanique/departement-mecanique';

@Component({
    templateUrl: './saisie-heures.component.html',
    styleUrls: ['./saisie-heures.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class SaisieHeuresComponent extends BaseComponent implements OnInit {

    heureManuelle: LigneHeureEntite = {};
    heuresCompletes: LigneHeureEntite[] = [];
    heures: LigneHeureEntite[] = [];

    dateHeures: Date = new Date();

    departements: DepartementMecaniqueEntite[];
    departement: DepartementMecaniqueEntite;
    emplacements: Emplacement[];
    emplacement: Emplacement;

    etape: number = 1;

    menu: MenuItem[];

    submittedManuel: boolean = false;
    submittedListe: boolean = false;

    @ViewChild('inputNumero') inputNumero;
    @ViewChild('inputHeures') inputHeures;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent,
        private breadcrumbService: BreadcrumbService,
        private heureService: HeureService,
        private documentService: DocumentService,
        private locationService: LocationService,
        private fournisseurService: FournisseurService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Saisie des heures' }
        ]);

    }

    ngOnInit() {

        this.verifierAccesPage('heures_saisie');

        this.departements = this.sdb.DepartementsMecanique();
        this.emplacements = this.sdb.Emplacements();

        this.heureService.ObtenirListe().then(res => {
            
            if (res.estUnSucces) {
                this.heuresCompletes = res.valeur;
                this.filtrer();
            }

            this.initialiserMenu();

            this.CompleterChargement();
            
            setTimeout(() => { this.inputNumero.nativeElement.focus(); }, 50);

        });
       
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('mecanique/heures')
            }
        ];
    }

    filtrer() {

        console.log(this.departement);
        console.log(this.emplacement);

        this.heures = this.heuresCompletes;
        if (this.departement) {
            this.heures = this.heures.filter(x => x.departementId == this.departement.departementMecaniqueId);
        }
        if (this.emplacement) {
            this.heures = this.heures.filter(x => x.emplacementId == this.emplacement.emplacementId);
        }

    }

    retirerFiltres() {
        this.departement = null;
        this.emplacement = null;
        this.filtrer();
    }

    validerNoItem(){

        const h = this.heures.find(x => x.numero.toLowerCase() == this.heureManuelle.numero.toLowerCase());

        if (h) {

            if (h.indSuiviHeures) {

                if (!h.indApprouve) {
                    this.heureManuelle.indItemValide = false;
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'L\'unité a déjà des heures en approbation' });
                    return;
                }
    
                this.heureManuelle.itemId = h.itemId;
                this.heureManuelle.description = h.description;
                this.heureManuelle.heuresActuelles = h.heuresActuelles;
                this.heureManuelle.dateHeuresActuelles = h.dateHeuresActuelles;
                this.heureManuelle.indApprouve = h.indApprouve;
                this.heureManuelle.indItemValide = true;
                this.etape = 2;
                setTimeout(() => { this.inputHeures.nativeElement.focus(); }, 50);
                    
            } else {

                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Les heures ne sont pas suivies sur cette unité.' });

            }

           
        } else {
            this.heureManuelle.indItemValide = false;
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Unité introuvable' });
        }

    }

    sauvegarderHeureManuelle(){

        console.log(this.heureManuelle);

        this.heureManuelle.dateNouvellesHeures = this.dateHeures;

        this.heureService.Ajouter({
            employeId: this.profilService.ProfilCourant().employeId,
            lignesHeure: [
                this.heureManuelle
            ]
        }).then(res => {
            if (res.estUnSucces) {
                this.heureManuelle = {
                    indItemValide: false
                }
                this.etape = 1;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Heures sauvegardées.` });
                setTimeout(() => { this.inputNumero.nativeElement.focus(); }, 50);
            }
            else{
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: res.valeur[0].erreur });
            }
        })
    }

    sauvegarderHeures() {

        this.heures.forEach(h => {
            h.dateNouvellesHeures = this.dateHeures;
            h.erreur = null;
        });

        this.heureService.Ajouter({
            employeId: this.profilService.ProfilCourant().employeId,
            lignesHeure: this.heures.filter(x => x.heures)
        }).then(res => {
            if (res.estUnSucces) {
                this.heures.forEach(h => {
                    h.heures = null;
                });
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Heures sauvegardées.` });
                setTimeout(() => { this.inputNumero.nativeElement.focus(); }, 50);
            }
            else{
                res.valeur.forEach(l => {
                    if (l.erreur) {
                        var ligneAMettreEnErreur = this.heures.find(x => x.itemId == l.itemId);
                        ligneAMettreEnErreur.erreur = l.erreur;
                    }
                });
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Des erreurs empêchent la sauvegarde des heures.' });
            }
        })

    }

    annuler() {
        this.heureManuelle = {
            indItemValide: false
        }
        this.etape = 1;
        setTimeout(() => { this.inputNumero.nativeElement.focus(); }, 50);
    }
}
