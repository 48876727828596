import {Component, ViewChild} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

@Component({
    templateUrl: './saisie-numero.component.html',
})
export class SaisieNumeroComponent {

    numero?: string;
    
    @ViewChild('champNumero') inputNumero;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {
        setTimeout(() => { 
            this.inputNumero.nativeElement.focus();
         }, 100);
        
    }

    Confirmer() {
        if (this.numero) {
            this.ref.close(this.numero);
        }
    }

    Annuler() {
        this.ref.close(null);
    }
}