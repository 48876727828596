<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div *ngIf="EstCharge()">
<div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12">

		<p-menu #menuContextuel popup="popup" [model]="contenuMenuContextuel" appendTo="body"></p-menu>

        <p-table #dtHistorique [value]="vad.items"
						tableStyleClass="tableNormale" autoLayout="true"
						dataKey="itemVadId"
						[globalFilterFields]="['description']">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
								<span class="p-input-icon-left">
									<i class="pi pi-search"></i>
									<input pInputText type="text"
										(input)="dtHistorique.filterGlobal($event.target.value, 'contains')"
										placeholder="Recherche..." />
								</span>
							</div>
						</ng-template>
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="type.description">
									<div class="p-d-flex p-jc-between p-ai-center">
										Type
										<p-sortIcon field="type.description"></p-sortIcon>
										<p-columnFilter type="text" field="type.description" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="noBonTravail">
									<div class="p-d-flex p-jc-between p-ai-center">
										Bon travail
										<p-sortIcon field="noBonTravail"></p-sortIcon>
										<p-columnFilter type="text" field="noBonTravail" display="menu" class="p-ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
                                <th style="min-width: 7rem; width: 7rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>
							<tr [pContextMenuRow]="item">
								<td>{{item.description}}</td>
								<td><span [class]="'severite ' + item.type.description.toLowerCase()">
									{{item.type.description}}
								</span>
							</td>
								<td>{{item.noBonTravail}}</td>
                                <td class="celluleTableau">
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-secondary p-mr-2 boutonGrille"
										(click)="surMenuContextuel($event, item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="5">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>
    </div>
  
</div>
<div class="p-grid">
    <div class="p-col-12 p-text-right ">
        <button pButton pRipple label="Fermer" icon="pi pi-ban" class="p-button-text" (click)="fermer()"></button>
    </div>
</div>
</div>