<p-toast key="modificationItem"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12" *ngIf="itemBonCommande.item.categorie.estSuivi == false">
            <label for="description">Description</label>
            <input type="text" pInputText id="noFournisseur" [(ngModel)]="itemBonCommande.item.description"
                autocomplete="off" />
                <small class="p-error" *ngIf="submitted && !itemBonCommande.item.description">La description est obligatoire.</small>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-3">
            <label for="quantity">Quantite<span class="requis">*</span></label>
            <p-inputNumber #champQuantite [(ngModel)]="itemBonCommande.quantite" locale="en-US" [minFractionDigits]="2" [maxFractionDigits]="2"
                [disabled]="itemBonCommande.item.categorie.estUnique">
            </p-inputNumber>
            <small class="p-error" *ngIf="submitted && !itemBonCommande.quantite">La quantité est obligatoire.</small>
        </div>

        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="noFournisseur">No pièce fournisseur</label>
            <input type="text" pInputText id="noFournisseur" [(ngModel)]="itemBonCommande.noFournisseur"
                autocomplete="off" />
        </div>

        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="prix">Prix<span class="requis">*</span></label>
            <p-inputNumber #champPrix [(ngModel)]="itemBonCommande.prix" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="2" [maxFractionDigits]="4">
            </p-inputNumber>
            <small class="p-error" *ngIf="submitted && !itemBonCommande.prix && itemBonCommande.prix != 0">Le prix est
                obligatoire.</small>
        </div>

    </div>
    <div class="p-fluid p-formgrid p-grid">

        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="codeProjet">Code projet<span class="requis">*</span></label>
            <p-dropdown #champCodeProjet [options]="listeCodeProjet" [(ngModel)]="selectionCodeProjet"
                optionLabel="code" [showClear]="true" (onHide)="surFermetureCodeProjet()" filter="true"
                filterBy="code,nom" (onChange)="surChangementCodeProjet()" appendTo="body"
                placeholder="Sélectionner un code de projet...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeProjet">
                        <div>{{selectionCodeProjet.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeProjet">Le code de projet est
                obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="codeActivite">Code d'activité<span class="requis">*</span></label>
            <p-dropdown #champCodeActivite [options]="listeCodeActivite" [(ngModel)]="selectionCodeActivite"
                optionLabel="code" [disabled]="!selectionCodeProjet" [showClear]="true"
                (onChange)="surChangementCodeActivite()"
                (onHide)="surFermetureCodeActivite()" filter="true" filterBy="code,nom" appendTo="body"
                placeholder="Sélectionner un code d'activité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeActivite">
                        <div>{{selectionCodeActivite.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeActivite">Le
                code d'activité est obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="codeCategorie">Catégorie de coût<span class="requis">*</span></label>
            <p-dropdown #champCodeCategorie [options]="listeCodeCategorie" [(ngModel)]="selectionCodeCategorie"
                optionLabel="code" [disabled]="!selectionCodeProjet || codeCategorieBloque" [showClear]="true"
                (onHide)="surFermetureCodeCategorie()" filter="true" filterBy="code,nom" appendTo="body"
                placeholder="Sélectionner une catégorie de coût...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeCategorie">
                        <div>{{selectionCodeCategorie.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeCategorie">La
                catégorie de coût est obligatoire.</small>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="codeCategorie">Équipement</label>
            <p-dropdown #champCodeEquipement [options]="listeCodeEquipement" [(ngModel)]="selectionCodeEquipement" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                [disabled]="codeEquipementBloque"
                appendTo="body" placeholder="Sélectionner un équipement..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeEquipement">
                        <div>{{selectionCodeEquipement.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="codeCategorie">Extra</label>
            <p-dropdown #champCodeExtra [options]="listeCodeExtra" [(ngModel)]="selectionCodeExtra" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un extra..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeExtra">
                        <div>{{selectionCodeExtra.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-3">
            <label for="emplacement">Emplacement</label>
            <p-dropdown #champEmplacement [options]="listeEmplacement" [(ngModel)]="selectionEmplacement" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un emplacement..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionEmplacement">
                        <div>{{selectionEmplacement.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-emplacement pTemplate="item">
                    <div>
                        <div>{{emplacement | nomEmplacement}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>

    </div>
    <!-- <div class="p-fluid p-formgrid p-grid">

        <div class="p-field p-col-12 p-lg-9">
            <label for="note">Note</label>
            <input #champNote type="text" pInputText id="note" [(ngModel)]="itemBonCommande.note" autocomplete="off" />
        </div>



    </div> -->

    <div class="p-text-right">

        <button pButton pRipple label="Sauvegarder" icon="pi pi-save" class="p-button-primary"
            (click)="sauvegarder()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>


    </div>

</div>