<p-toast key="assignerEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12 p-text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	<div class="p-fluid p-formgrid p-grid" *ngIf="donnees.alerteEtapesNonCompletes">
		<div class="p-field p-col-12">
			<p-message severity="warn" text="Certaines étapes ne sont pas complétés." styleClass="mr-2"></p-message>  
		</div>
	</div>
	
	<div class="p-fluid p-formgrid p-grid">
		
		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="date">Date des travaux</label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateTravail"
								dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
								yearRange="2000:2040"></p-calendar>
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="date">Heures au compteur</label>
			<input #inputHeures type="number" pInputText id="heures"
								[(ngModel)]="bonTravail.heuresCompteur" autocomplete="off" />
		</div>
		<div class="p-field p-col-12 p-md-6 p-lg-3">
			<label for="date">KMs au compteur</label>
			<input #inputHeures type="number" pInputText id="heures"
								[(ngModel)]="bonTravail.kmCompteur" autocomplete="off" />
		</div>
		
	</div>

	<div class="p-fluid p-formgrid p-grid" *ngIf="donnees.alerteEtapesNonCompletes">
		<div class="p-field p-col-12">
			<p-checkbox label="Créer un nouveau bon de travail avec les étapes non complétées" [binary]="true" [(ngModel)]="indCreerPartiel"></p-checkbox>
		</div>
	</div>

	<!-- <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
        <label for="note">Note</label>
        <input #champNote id="note" type="text" pInputText [(ngModel)]="note" (keyup.enter)="Confirmer()" > 
            <br>

    </div> -->

	<div class="p-text-right">

		<button pButton pRipple label="Approuver" icon="pi pi-save" class="p-button-primary"
			(click)="approuver()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

	</div>

</div>