import {Component} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';

export interface ParametresSelectionEntretien {
    
}

@Component({
    templateUrl: './selection-entretien.component.html',
})
export class SelectionEntretienComponent extends BaseComponent {

    donnees: ParametresSelectionEntretien;

    entretiens: EntretienPreventifEntite[];
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        private entretienPreventifService: EntretienPreventifService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) {

            super(sdb, profilService, route, routerService, appMain); }

    ngOnInit() {

        this.donnees = this.config.data;

        //TODO
        this.entretienPreventifService.Obtenir().then(res => {
            this.entretiens = res.valeur;

            this.CompleterChargement();
        });        
    }

    surEntree() {
        //TODO
        this.ref.close(null);
    }

    selectionner(entretien: EntretienPreventifEntite) {
      this.ref.close(entretien);
    }

    annuler() {
        this.ref.close(null);
    }
}