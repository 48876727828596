import {Component, Input} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { ItemService } from '../services/item-service';
import { ItemMin } from '../entites/item-min';

@Component({
    templateUrl: './analyse-item.component.html',
})
export class AnalyseItemComponent {
    
    chargementComplet:boolean = false;

    basicData: any;
    basicOptions: any;
    item: ItemMin;

    constructor(private itemService: ItemService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {
        this.itemService.ObtenirAnalyseItem(this.config.data.itemId).then(x => {

            this.item = x.valeur.item;

            this.basicData = {
                 labels: x.valeur.libelles,
                datasets: [
                    
                ]
            }
           
            x.valeur.jeuxDonnees.forEach(y => {
                this.basicData.datasets.push({
                    "label": y.libelle,
                    "data": y.donnees,
                    fill: false,
                    borderColor: '#' + y.couleur

                });
                

            });


            this.basicOptions = {
                legend: {
                    labels: {
                        fontColor: '#495057'
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            fontColor: '#495057'
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            fontColor: '#495057'
                        }
                    }]
                }
            };
            
        });
    }

   
}