<div class="card">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
            <label for="note">Note</label>
            <input #champNote id="note" type="text" pInputText [(ngModel)]="note">
            <br>

        </div>
        <div *ngIf="afficherAjoutFiche" class="p-field p-col-12">
            <label for="indAjouterFiche">Ajouter à la fiche de l'unité</label><br>
            <p-inputSwitch [(ngModel)]="indAjouterFiche"></p-inputSwitch>

        </div>
    </div>
    <div class="p-grid">
        <div class="p-col-12 p-text-right ">
            <p-button label="Confirmer" class="" icon="pi pi-save" iconPos="left" (onClick)="Confirmer()"></p-button>
            <p-button label="Annuler" class="p-button-text p-ml-2" icon="pi pi-ban" iconPos="left"
                (onClick)="Annuler()"></p-button>
        </div>
    </div>
</div>