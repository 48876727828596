<p-toast key="ajustementInventaire"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-6 p-lg-3">
        <label for="numero">Numéro</label>
        <input id="numero" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.numero">
    </div>
    <div class="p-field p-col-12 p-md-12 p-lg-9">
        <label for="description">Description</label>
        <input id="description" type="text" pInputText [readonly]="true" 
            [(ngModel)]="donnees.description">
    </div>
</div>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-3">
            <label for="quantite">Quantité courante</label>
            <input id="quantiteCourante" type="text" pInputText [readonly]="true" 
            [(ngModel)]="donnees.quantiteCourante">
        </div>
        <div class="p-field p-col-12 p-lg-3">
            <label for="quantite">Ajustement</label><br>
            <p-inputNumber #champQuantite [(ngModel)]="quantite" (keyup.enter)="ajusterInventaire()"  id="quantite" >
            </p-inputNumber>
            <small class="p-error" *ngIf="submitted && !quantite">L'ajustement est obligatoire.</small>
        </div>
    </div>
    
    
    <div class="p-text-right">

        <button pButton pRipple label="Ajuster" icon="pi pi-save" class="p-button-primary"
        (click)="ajusterInventaire()"></button>
            <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
                (click)="annuler()"></button>
      
    </div>

</div>